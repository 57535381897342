import classNames from "classnames";
import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import QMButton from "../QMButton";

import "./styles.scss";

const QMToastMessage = ({
  iconClassName,
  text,
  onClose,
  viewType,
  selfCloseDelay,
}) => {
  const toastClasses = classNames("QM-toast", `QM-toast__${viewType}`);
  let timerId = useRef();
  const handleClose = () => {
    if (timerId.current) {
      clearTimeout(timerId.current);
    }
    onClose();
  };
  useEffect(() => {
    if (selfCloseDelay) {
      timerId.current = setTimeout(() => onClose(), selfCloseDelay);
    }
  }, []); //eslint-disable-line
  return (
    <section className={toastClasses}>
      <p className={iconClassName}>{text}</p>
      <QMButton
        viewType="transparent"
        iconClassName="icon-x"
        onClick={handleClose}
      />
    </section>
  );
};

QMToastMessage.propTypes = {
  iconClassName: PropTypes.string,
  text: PropTypes.string,
  onClose: PropTypes.func,
  viewType: PropTypes.oneOf(["success", "warning", "error", "info"]),
  selfCloseDelay: PropTypes.number,
};

QMToastMessage.defaultProps = {
  iconClassName: "",
  text: "",
  onClose: () => {},
  viewType: "success",
};
export default QMToastMessage;
