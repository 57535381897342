import React, { forwardRef } from "react";
import Select from "react-select";

import { DropdownIndicator } from "./ReactSelectComponents";
import { Option } from "./QMSelectAcquisitionType";
import QMInputWrapper from "./QMInputWrapper";
import reactSelectStylesConfig from "./reactSelectStyles";

const QMSelectAcquisition = forwardRef(
  (
    {
      label,
      isRequired,
      errors,
      iconClassName,
      placeholder,
      saleAcquisitionToolTip,
      ...props
    },
    ref
  ) => {
    return (
      <QMInputWrapper
        label={label}
        isRequired={isRequired}
        errors={errors}
        iconClassName={iconClassName}
      >
        <Select
          ref={ref}
          placeholder={placeholder}
          styles={reactSelectStylesConfig(!!errors, saleAcquisitionToolTip)}
          components={{
            Option,
            IndicatorSeparator: () => null,
            DropdownIndicator,
          }}
          {...props}
        />
      </QMInputWrapper>
    );
  }
);

export default QMSelectAcquisition;
