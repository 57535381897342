import React, { Fragment, useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { getUserPolicies } from "../../../../../api/assignUserApi";
import { manageAccessSelector } from "../../../../../redux/selectors/assign-lawyer-selectors";
import { setAccessLevelsValues } from "../../../../../helpers/assignLawyer-helpers";

import QMButton from "../../../common/QMButton";
import QMCheckbox from "../../../common/QMCheckbox";
import QMToolTipName from "../../../common/QMToolTipName";
import AssignLawyerAccessLevelList from "./AssignLawyerAccessLevelList";

import "./styles.scss";

const AssignLawyerManageAccess = ({
  isNewUser,
  userData,
  handleClose,
  handleManageAccessSubmit,
}) => {
  const manageAccessData = useSelector(manageAccessSelector);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    handleSubmit,
    setValue,
    getValues,
    watch,
    register,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      underwriting_requests_level: "View",
      rwi_application_level: "View",
      contacts_level: "View",
      deal_info_level: "View",
      past_activities_level: "View",
      insurance_level: "View",
      deal_room_level: "View",
      due_diligence_level: "View",
    },
    mode: "onChange",
  });
  const rwiApplicationWatch = watch("rwi_application");

  const setPrevData = useCallback(
    (policies) => {
      setAccessLevelsValues(policies, setValue);
    },
    [setValue]
  );
  useEffect(() => {
    if (!isNewUser) {
      getUserPolicies(dispatch, userData.policy_link, setPrevData);
    }
  }, [isNewUser, dispatch, setPrevData, userData.policy_link]);

  useEffect(() => {
    if (rwiApplicationWatch) {
      setValue("contacts", true);
      setValue("deal_info", true);
      setValue("past_activities", true);
      setValue("insurance", true);
      setValue("due_diligence", true);
    } else {
      setValue("contacts", false);
      setValue("deal_info", false);
      setValue("past_activities", false);
      setValue("insurance", false);
      setValue("due_diligence", false);
    }
  }, [rwiApplicationWatch, setValue]);
  const manageAccessFields = (data) => {
    return data?.map((item) => {
      if (item?.code === "rwi_application") {
        return (
          <Fragment key={item?.code}>
            <li key={item?.code} className="assign-lawyer-manage-access__item">
              <QMCheckbox {...register(`${item?.code}`)} />
              <span className="icon-folder-open" />
              <span className="assign-lawyer-manage-access__item_name">
                {item?.title}
              </span>
              <AssignLawyerAccessLevelList
                code={item?.code}
                register={register}
                setValue={setValue}
                watch={watch}
              />
            </li>
            {item?.policies?.map((item) => {
              return (
                <li
                  className="assign-lawyer-manage-access__item assign-lawyer-manage-access__item-policies"
                  key={item?.code}
                >
                  <QMCheckbox {...register(`${item?.code}`)} />
                  <span className="icon-folder-open" />
                  <span className="assign-lawyer-manage-access__item_name">
                    {item?.title}
                  </span>
                  <AssignLawyerAccessLevelList
                    code={item?.code}
                    register={register}
                    setValue={setValue}
                    watch={watch}
                  />
                </li>
              );
            })}
          </Fragment>
        );
      }

      return (
        <li key={item?.code} className="assign-lawyer-manage-access__item">
          <QMCheckbox {...register(`${item?.code}`)} />
          <span className="icon-folder-open" />
          <span className="assign-lawyer-manage-access__item_name">
            {item?.title}
          </span>
          <AssignLawyerAccessLevelList
            code={item?.code}
            register={register}
            setValue={setValue}
            watch={watch}
          />
        </li>
      );
    });
  };

  const cutEmail = userData?.attorney_email ? (
    userData?.attorney_email.length > 50 ? (
      <QMToolTipName text={userData?.attorney_email} maxLength={50} />
    ) : (
      userData?.attorney_email
    )
  ) : (
    ""
  );
  const checkDisabled = () => {
    const values = Object.entries(getValues()).filter(
      ([key, val]) => typeof val === "boolean"
    );
    const hasSelectedCheckboxes = values.some(([key, val]) => val);
    return !isDirty || !hasSelectedCheckboxes;
  };
  const onSubmit = (data) => {
    handleManageAccessSubmit(dispatch, data);
  };

  return (
    <section className="assign-lawyer-manage-access__wrapper">
      <section className="assign-lawyer-manage-access">
        <header className="assign-lawyer-manage-access__header">
          <h3 className="assign-lawyer-manage-access__title">
            <p className="assign-lawyer-modal__pending-list-item__email assign-lawyer-manage-access__title__user">
              {`${userData?.attorney_name || ""} (${cutEmail})`}
            </p>
            {t("assign_lawyer.manage_access_btn")}
          </h3>
          <QMButton
            viewType="transparent"
            iconClassName={`icon-x`}
            customClassName={`QM-btn__close-text`}
            onClick={(e) => {
              e.stopPropagation();
              handleClose();
            }}
          />
        </header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ul className="assign-lawyer-manage-access__list">
            {manageAccessFields(manageAccessData)}
          </ul>
          <footer className="assign-lawyer-manage-access__footer">
            <QMButton
              text={
                isNewUser ? t("assign_lawyer.send_invite") : t("general.update")
              }
              customClassName={"assign-lawyer-manage-access__invite-btn"}
              type="submit"
              disabled={checkDisabled()}
            />
            <QMButton
              viewType="secondary"
              text={t("general.cancel")}
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                handleClose();
              }}
            />
          </footer>
        </form>
      </section>
    </section>
  );
};

export default AssignLawyerManageAccess;
