import React from "react";

import AssignLawyerPendingListItem from "./AssingLawyerPendingListItem";

import "./styles.scss";

const AssignLawyerPendingList = ({ data, isAccepted }) => {
  return (
    <ul className="assign-lawyer-modal__pending-list">
      {data?.map((item, index) => {
        return (
          <AssignLawyerPendingListItem
            item={item}
            key={index}
            isAccepted={isAccepted}
          />
        );
      })}
    </ul>
  );
};

export default AssignLawyerPendingList;
