import React, { forwardRef } from "react";
import Select from "react-select";

import reactSelectStylesDealConfig from "./reactSelectStylesDealConfig";
import { DropdownIndicator } from "./ReactSelectComponents";
import { Option } from "./QMSelectDealComponents";

const QMSelectDeal = forwardRef(
  ({ label, iconClassName, placeholder, options, ...props }, ref) => {
    return (
      <Select
        options={options}
        ref={ref}
        placeholder={placeholder}
        styles={reactSelectStylesDealConfig()}
        components={{
          Option,
          IndicatorSeparator: () => null,
          DropdownIndicator,
        }}
        {...props}
      />
    );
  }
);

export default QMSelectDeal;
