import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import AuthInput from '../common/AuthInput/AuthInput';
import AuthPageWrapper from '../common/AuthPageWrapper';
import AuthModal from '../common/AuthModal';

import { ERROR_EMAIL_FORMAT, ERROR_REQUIRED } from './validationConsts';
import { requestPasswordReset } from '../../../api/authApi';

const validationSchema = yup.object({
  email: yup.string().required(ERROR_REQUIRED).email(ERROR_EMAIL_FORMAT),
}).required();

const PasswordRecovery = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [sentStatus, setSentStatus] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
      resolver: yupResolver(validationSchema),
      mode: "onBlur",
      defaultValues: {
        email: "",
      }
    });

    const onSubmit = data => {
      console.log(data)
      setIsLoading(true);
      requestPasswordReset(data)
      .then(res => {
        console.log("res", res)
        if(res.status === 200) {
          setSentStatus('success')
        } else {
          setSentStatus('fail')
          setError('email', {message: "check your email"})
        }
        setIsLoading(false)
      })
      .catch(err => console.error(err))
    };
  return (
    <AuthPageWrapper>
      <form onSubmit={handleSubmit(onSubmit)} className="user-auth__form">
        <h1>Password Recovery</h1>
        <p className="c"><small>Please enter your email, and we will send you further instructions.</small></p>
        <ul>
          <AuthInput
            register={register}
            name="email"
            type="email"
            title="Email"
            errors={errors.email}
            autoFocus
          />
          
          {!sentStatus && (
            <li>
              <p className="r">
                <NavLink to="/sign-in">
                  I remembered the password
                </NavLink>
              </p>
            </li>
          )}
          {sentStatus === 'success' && (
            <li>
              <p className="c">
                Email sent. Please check your email to reset your password.
              </p>
            </li>
          )}
        </ul>
        <button type="submit" className="user-auth__btn">{sentStatus ? "Send again": "Submit"}</button>
        {sentStatus && (
          <p className="c">
            <NavLink to="/sign-in">Back to Sign In</NavLink>
          </p>
        )}
      </form>
      {isLoading && (
        <AuthModal setIsOpen={setIsLoading}>
          <h3>Email Confirmation</h3>
          <p>Your email is confirming. Please, wait.</p>
          <p><NavLink to="/sign-in" className="user-auth__btn">Back to Sign In</NavLink></p>
        </AuthModal>
      )}
    </AuthPageWrapper>
  );
}

export default PasswordRecovery;