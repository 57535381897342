import React from "react";
import { useTranslation } from "react-i18next";

import QMNavMenuItem from "../../common/QMNavMenuItem";

import "./styles.scss";
const PortfolioMenu = () => {
  const { t } = useTranslation();
  return (
    <ul className="broker-portfolioMenu">
      <QMNavMenuItem
        title={t("general.deals")}
        to="/broker/portfolio/deals"
        iconClassName="icon-book"
        isInner
        end
      />
      <QMNavMenuItem
        title={t("general.companies")}
        to="/broker/portfolio/companies"
        iconClassName="icon-building2"
        isInner
        end
      />
    </ul>
  );
};

const BrokerNavMenu = () => {
  const { t } = useTranslation();
  return (
    <ul className="broker-sidebarMenu">
      <QMNavMenuItem
        title={t("general.portfolio")}
        to="/broker/portfolio"
        iconClassName="icon-portfolio"
        hasInnerMenu
        innerContent={<PortfolioMenu />}
        end
      />
      <QMNavMenuItem
        title={t("general.markets")}
        to="/broker/markets"
        iconClassName="icon-umbrella"
        end
      />
      <QMNavMenuItem
        title={t("general.faq")}
        to="/broker/faq"
        iconClassName="icon-question-circle"
        end
      />
    </ul>
  );
};

export default BrokerNavMenu;
