import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useDropzone } from "react-dropzone";

import QMButton from "../QMButton";
import QMFileItem from "./QMFileItem";

import "./styles.scss";
import { useDispatch } from "react-redux";
import { setDealDocumentsError } from "../../../../redux/reducers/dealDocs-reducer";

const QMTreeItem = ({
  id,
  name,
  directories,
  files,
  handleFileUpload,
  handleFileDelete,
  handleViewFile,
  isDeletePresent,
  acceptedFileTypes,
}) => {
  const hasDirectories = Boolean(directories && directories.length);
  const hasFiles = Boolean(files && files.length);

  const [showChildren, setShowChildren] = useState(true);
  const dispatch = useDispatch();

  const { getRootProps, getInputProps, open, isDragActive, isDragReject } =
    useDropzone({
      noClick: true,
      noKeyboard: true,
      onDrop: (files) => handleFileUpload(files, id),
      accept: acceptedFileTypes,
    });
  const dropzoneClasses = classNames("QM-treeItem_row", {
    "QM-treeItem_row__dragActive": isDragActive,
  });

  useEffect(() => {
    if (isDragReject) {
      dispatch(
        setDealDocumentsError({ message: "Current file format is not allowed" })
      );
    }
  }, [isDragReject, dispatch]);
  return (
    <section className="QM-treeItem">
      <section
        className="QM-treeItem_row"
        {...getRootProps({ className: dropzoneClasses })}
      >
        <section
          className="QM-treeItem_row_main"
          onClick={() => setShowChildren(!showChildren)}
        >
          <span
            className={
              showChildren ? "icon-triangle-down" : "icon-triangle-right"
            }
          />
          <span className="icon-folder-open" />
          <p>{name}</p>
        </section>
        <section className="QM-treeItem_row_actionsContainer">
          <input {...getInputProps()} />
          <QMButton
            viewType="transparent"
            iconClassName="icon-upload-cloud"
            onClick={open}
          />
        </section>
      </section>
      {showChildren && (
        <section className="QM-treeItem_inner">
          {hasDirectories &&
            directories.map((dir) => (
              <QMTreeItem
                {...dir}
                key={dir.id}
                handleFileUpload={handleFileUpload}
                handleFileDelete={handleFileDelete}
                handleViewFile={handleViewFile}
                isDeletePresent={isDeletePresent}
                acceptedFileTypes={acceptedFileTypes}
              />
            ))}
          {hasFiles &&
            files.map((file) => (
              <QMFileItem
                {...file}
                key={file.document_id}
                handleFileDelete={handleFileDelete}
                handleViewFile={handleViewFile}
                isDeletePresent={isDeletePresent}
              />
            ))}
        </section>
      )}
    </section>
  );
};

QMTreeItem.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  directories: PropTypes.array,
  files: PropTypes.array,
  handleFileUpload: PropTypes.func,
  handleFileDelete: PropTypes.func,
  handleViewFile: PropTypes.func,
  isDeletePresent: PropTypes.bool,
  acceptedFileTypes: PropTypes.any,
};

QMTreeItem.defaultProps = {
  id: "",
  name: "",
  directories: [],
  files: [],
  handleFileUpload: () => {},
  handleFileDelete: () => {},
  handleViewFile: () => {},
  isDeletePresent: true,
  acceptedFileTypes: {
    "application/pdf": [".pdf"],
    "text/csv": [".csv"],
    "application/msword": [".doc"],
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
      ".docx",
    ],
    "application/vnd.ms-excel": [".xls"],
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
      ".xlsx",
    ],
  },
};

export default QMTreeItem;
