import React from "react";
import { useTranslation } from "react-i18next";

import PastActivitiesForm from "./PastActivitiesForm";

import "./styles.scss";

const PastActivitiesTab = () => {
  const { t } = useTranslation();
  return (
    <section className="pastActivities-container">
      <p>{t("past_activities.explanation")}</p>
      <PastActivitiesForm />
    </section>
  );
};

export default PastActivitiesTab;
