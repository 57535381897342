import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import QMButton from "../../common/QMButton";
import QMRadioSet from "../../common/QMRadioSet/QMRadioSet";
import QMToastMessage from "../../common/QMToastMessage";
import QMModal from "../../common/QMModal";

import IntegrationLeadInfoBlock from "../../BrokerPortal/NewDealPage/FormBlocks/IntegrationLeadInfoBlock";
import DataRoomInfoBlock from "../../BrokerPortal/NewDealPage/FormBlocks/DataRoomInfoBlock";
import BrokerageInfoBlock from "./FormBlocks/BrokerageInfoBlock";
import BuyerSellerInfoBlock from "./FormBlocks/BuyerSellerInfoBlock";
import DealAdminGeneralInfoBlock from "./FormBlocks/DealAdminGeneralInfoBlock";
import { TOAST_CLOSE_DELAY } from "../../../../helpers";

import "./styles.scss";

const DealAdminNewDealForm = ({
  register,
  handleSubmit,
  setError,
  getValues,
  clearErrors,
  setValue,
  watch,
  control,
  errors,
  touchedFields,
  isValid,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const boolOpts = [
    { value: true, label: t("general.yes") },
    { value: false, label: t("general.no") },
  ];

  const orgChatTargetAgreementOpts = [
    { value: "yes", label: t("general.yes") },
    { value: "no", label: t("general.no") },
  ];
  const [showIntegrationLeadWarning, setShowIntegrationLeadWarning] =
    useState(null);
  const [isCancelConfirmVisible, setIsCancelConfirmVisible] = useState(false);

  const getFieldErrors = (fieldName) => {
    return errors[fieldName] && touchedFields[fieldName]
      ? errors[fieldName]
      : undefined;
  };

  return (
    <section className="QM-newDeal">
      <h2>{t("broker_portal.new_deal")}</h2>
      <form onSubmit={handleSubmit}>
        <BrokerageInfoBlock
          register={register}
          control={control}
          watch={watch}
          getFieldErrors={getFieldErrors}
          title={t("general.brokerage")}
        />
        <BuyerSellerInfoBlock
          register={register}
          control={control}
          watch={watch}
          getFieldErrors={getFieldErrors}
          boolOpts={boolOpts}
          title={t("general.buyer")}
          dealParticipant={t("general.buyer")}
          setValue={setValue}
        />
        <BuyerSellerInfoBlock
          register={register}
          control={control}
          watch={watch}
          getFieldErrors={getFieldErrors}
          boolOpts={boolOpts}
          title={t("general.seller")}
          dealParticipant={t("general.seller")}
          setValue={setValue}
        />
        <DealAdminGeneralInfoBlock
          register={register}
          control={control}
          watch={watch}
          getFieldErrors={getFieldErrors}
          clearErrors={clearErrors}
          setError={setError}
          getValues={getValues}
          title={t("risk_profile.deal_general_header")}
        />
        <IntegrationLeadInfoBlock
          register={register}
          control={control}
          watch={watch}
          getFieldErrors={getFieldErrors}
          boolOpts={boolOpts}
          setShowIntegrationLeadWarning={setShowIntegrationLeadWarning}
          setValue={setValue}
        />
        <DataRoomInfoBlock
          register={register}
          control={control}
          watch={watch}
          getFieldErrors={getFieldErrors}
          clearErrors={clearErrors}
          setValue={setValue}
        />
        <section className="QM-formRow__soloItem">
          <QMRadioSet
            register={register}
            options={orgChatTargetAgreementOpts}
            legendText={t("new_deal_form.org_chat_target_agreement")}
            name="org_chat_target_agreement"
            errors={getFieldErrors("org_chat_target_agreement")}
          />
        </section>
        <section className="QM-btnsFormRow">
          <QMButton
            viewType="secondary"
            type="button"
            text={t("general.cancel")}
            onClick={() => setIsCancelConfirmVisible(true)}
          />
          <QMButton
            text={t("general.save_proceed")}
            type="submit"
            customClassName="newDeal-submit-btn"
            disabled={!isValid}
          />
        </section>
      </form>
      {showIntegrationLeadWarning === false && (
        <QMToastMessage
          text={t("new_deal_form.integration_lead_warning")}
          viewType="warning"
          iconClassName="icon-attention"
          onClose={() => setShowIntegrationLeadWarning(null)}
          selfCloseDelay={TOAST_CLOSE_DELAY}
        />
      )}

      {isCancelConfirmVisible && (
        <QMModal size="small" customClassName="QM-newDeal_confirmModal">
          <h2>{t("new_deal_form.cancel_confirmation")}</h2>
          <section className="QM-btnsFormRow">
            <QMButton
              viewType="secondary"
              type="button"
              onClick={() => setIsCancelConfirmVisible(false)}
              text={t("general.cancel")}
            />
            <QMButton
              type="button"
              onClick={() => navigate(-1)}
              text={t("general.proceed")}
            />
          </section>
        </QMModal>
      )}
    </section>
  );
};

export default DealAdminNewDealForm;
