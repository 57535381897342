import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import QMButton from "../QMButton";
import QMModal from "../QMModal";
import QMTreeItem from "../QMTreeItem";

import "./styles.scss";

const QMDocsModal = ({
  docsList,
  getDocsList,
  handleClose,
  modalProps,
  handleFileUpload,
  handleFileDelete,
  handleViewFile,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    getDocsList();
  }, []); //eslint-disable-line
  return (
    <QMModal customClassName="QM-docsModal" {...modalProps}>
      <section className="QM-docsModal_header">
        <h4>{t("documents.required_documents")}</h4>
        <section className="QM-docsModal_header_part">
          <span className="QM-docWidget_warning">
            {t("documents.only_pdf_accepted")}
          </span>
          <QMButton
            viewType="transparent"
            iconClassName="icon-x"
            onClick={handleClose}
          />
        </section>
      </section>
      <section className="QM-docsModal_body">
        {docsList && (
          <QMTreeItem
            {...docsList}
            handleFileUpload={handleFileUpload}
            handleFileDelete={handleFileDelete}
            handleViewFile={handleViewFile}
          />
        )}
      </section>
    </QMModal>
  );
};

QMDocsModal.propTypes = {
  docsList: PropTypes.any,
  getDocsList: PropTypes.func,
  handleClose: PropTypes.func,
  modalProps: PropTypes.shape({
    closeModal: PropTypes.func,
    size: PropTypes.oneOf(["big", "medium", "small"]),
  }),
  handleFileUpload: PropTypes.func,
  handleFileDelete: PropTypes.func,
  handleViewFile: PropTypes.func,
};
QMDocsModal.defaultProps = {
  docsList: {},
  getDocsList: () => {},
  handleClose: () => {},
  modalProps: {},
  handleFileUpload: () => {},
  handleFileDelete: () => {},
  handleViewFile: () => {},
};
export default QMDocsModal;
