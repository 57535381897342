import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import { getDeals } from "../../../../api/dealsApi";
import { setIsCopiedToClipboard } from "../../../../redux/reducers/common-reducer";
import {
  setDealsError,
  setIsLoadingDeals,
} from "../../../../redux/reducers/deals-reducer";
import {
  dealsErrorSelector,
  dealsLoadingSelector,
  dealsSelector,
} from "../../../../redux/selectors/broker-selectors";
import { copiedToClipboardSelector } from "../../../../redux/selectors/common-selectors";
import QMGlobalLoader from "../../../common/QMLoader";
import QMToastMessage from "../../common/QMToastMessage";
// import DealsSidebar from "./DealsSidebar";
import DealsEmpty from "./DealsEmpty";
import DealsTable from "./DealsTable";

import "./styles.scss";

const DealsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector(dealsLoadingSelector);
  const deals = useSelector(dealsSelector);
  const isCopied = useSelector(copiedToClipboardSelector);
  const errors = useSelector(dealsErrorSelector);
  const { dealId } = useParams();

  const handleCopyToastClose = () => dispatch(setIsCopiedToClipboard(false));
  const handleErrorToastClose = () => dispatch(setDealsError(null));

  useEffect(() => {
    getDeals(dispatch).catch((err) => {
      console.error("uncaught error occured", err);
      dispatch(
        setDealsError({
          message: t("errors.server_general"),
        })
      );
      dispatch(setIsLoadingDeals(false));
    });
  }, [dispatch, t]);

  const getContent = () => {
    if (!isLoading && !deals?.length && !errors) {
      return <DealsEmpty />;
    } else if (!isLoading && deals?.length) {
      return (
        <section className="QM-deals_container">
          <section>
            <DealsTable data={deals} />
          </section>
          {/* <DealsSidebar /> */}
          {isCopied && (
            <QMToastMessage
              viewType="success"
              text={t("general.clipboard_success")}
              iconClassName="icon-copy"
              onClose={handleCopyToastClose}
            />
          )}
        </section>
      );
    } else if (isLoading) {
      return <QMGlobalLoader />;
    }
  };
  return (
    <>
      {dealId ? (
        <Outlet />
      ) : (
        <>
          {getContent()}
          {errors && (
            <QMToastMessage
              viewType="error"
              text={errors.message}
              iconClassName="icon-attention"
              onClose={handleErrorToastClose}
            />
          )}
        </>
      )}
    </>
  );
};

export default DealsPage;
