import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import UnderwritingList from "./UnderwritingList";
import { SELF_CLOSE_DELAY } from "../../../../helpers/broker-helpers";
import { setDealSaveSuccess } from "../../../../redux/reducers/dealPage-reducer";
import QMToastMessage from "../../common/QMToastMessage";
import { dealSaveSuccessSelector } from "../../../../redux/selectors/deal-selectors";

import "./styles.scss";

const UnderwritingContainer = ({ data }) => {
  const isSaved = useSelector(dealSaveSuccessSelector);
  const dispatch = useDispatch();

  const { t } = useTranslation();
  return (
    <section className="underwriting-tab">
      <UnderwritingList data={data} />
      {isSaved && (
        <QMToastMessage
          viewType="success"
          text={t("general.data_saved")}
          iconClassName="icon-check-circle"
          onClose={() => {
            dispatch(setDealSaveSuccess(false));
          }}
          selfCloseDelay={SELF_CLOSE_DELAY}
        />
      )}
    </section>
  );
};
export default UnderwritingContainer;
