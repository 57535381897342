import React from 'react';
import classNames from 'classnames';

import './styles.scss';

const AuthInput = ({
  register,
  name,
  withIconBtn,
  iconBtnHandler,
  iconClassName,
  iconBtnTitle,
  title,
  errors,
  ...props
}) => {
  return (
    <li className='authInput-wrapper'>
      <label className='authInput-label'>
        <p className='authInput-title'>{title}</p>
        <input 
          className={classNames(
            'authInput-input',
            {'authInput-input__error': errors}
          )}
          {...register(name)}
          {...props}
        />
        {withIconBtn && (
          <button
            type="button"
            className={classNames(
              "iconOnly",
              "buttonAboveInput",
              iconClassName
            )}
            aria-label={iconBtnTitle}
            title={iconBtnTitle}
            onClick={iconBtnHandler}
          >
            <span>{iconBtnTitle}</span>
          </button>
        )}
        {errors && <p className='authInput-error'>{errors.message}</p>}
      </label>
    </li>
  );
}

export default AuthInput;