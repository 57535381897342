import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import QMTile from "../../common/QMTile";
import { getExposure } from "../../../../api/exposureApi";
import {
  buyerExposureSelector,
  exposureSelector,
  exposureTypesSelector,
  sellerExposureSelector,
  targetExposureSelector,
} from "../../../../redux/selectors/exposure-selectors";

import "./styles.scss";
import { formatCurrency } from "../../../../helpers/broker-helpers";

const ExposurePage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { dealId } = useParams();
  const exposureData = useSelector(exposureSelector);
  const exposureTypes = useSelector(exposureTypesSelector);
  const buyerExposure = useSelector(buyerExposureSelector);
  const sellerExposure = useSelector(sellerExposureSelector);
  const targetExposure = useSelector(targetExposureSelector);

  useEffect(() => {
    getExposure(dispatch, dealId);
  }, [dispatch, dealId]);

  const formatCell = (type, data) => {
    return type === "sales_revenue" ? formatCurrency(data) : data;
  };

  return (
    <section className="exposure-tab">
      <QMTile>
        {exposureData && (
          <table className="exposure-table">
            <thead>
              <tr>
                <th></th>
                <th>{t("general.buyer")}</th>
                <th>
                  {exposureData?.target_is_seller
                    ? t("general.target")
                    : t("general.seller")}
                </th>
                {!exposureData?.target_is_seller && (
                  <th>{t("general.target")}</th>
                )}
              </tr>
            </thead>
            <tbody>
              {exposureTypes &&
                exposureTypes.map((type) => {
                  return (
                    <tr key={type.code}>
                      <td className="QM-table_cell__headlike">{type.name}</td>
                      <td>
                        <p>{formatCell(type.code, buyerExposure[type.code])}</p>
                      </td>
                      <td>
                        <p>
                          {formatCell(type.code, sellerExposure[type.code])}
                        </p>
                      </td>
                      {!exposureData?.target_is_seller && (
                        <td>
                          <p>
                            {formatCell(type.code, targetExposure[type.code])}
                          </p>
                        </td>
                      )}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        )}
      </QMTile>
    </section>
  );
};

export default ExposurePage;
