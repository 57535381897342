import React from "react";
import { useTranslation } from "react-i18next";

import "./styles.scss";

const DueDilligenceTabItem = ({
  company_name,
  due_diligence_type,
  report,
  className,
}) => {
  const { t } = useTranslation();
  return (
    <li className={className}>
      <span>{company_name}</span>
      <p>{`${t("rwi_application.due_dilligence")} ${due_diligence_type}`}</p>
      <p>
        {`${t("general.report")} ${
          report ? <span className="icon-check"></span> : t("general.na")
        }`}
      </p>
    </li>
  );
};

export default DueDilligenceTabItem;
