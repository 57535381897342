import React from "react";
import search from "../../../assets/imgs/search.png";
import markets from "../../../assets/imgs/markets.png";

const Markets = () => {
  return (
    <div>
      <img src={search} alt="search"></img>
      <img
        style={{ position: "relative", left: "13px" }}
        alt="markets"
        src={markets}
      ></img>
    </div>
  );
};

export default Markets;
