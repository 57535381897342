import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import QMAvatar from "../../../../../common/QMAvatar/QMAvatar";

import "./styles.scss";

const AssigneeSearchResultItem = ({
  setSearchInput,
  setSearchResult,
  setFilteredResults,
  setShowResults,
  item,
}) => {
  const { t } = useTranslation();
  const handleValueLength = (value) => {
    const attorney_name = value?.attorney_name || "";
    const attorney_email = value?.attorney_email || "";
    const result = attorney_name.concat(`(${attorney_email})`);

    return result;
  };

  const handleSelect = (e) => {
    e.stopPropagation();
    if (item?.attorney_email) {
      setShowResults(false);
      setSearchInput(handleValueLength(item));
      setSearchResult(item);
      setFilteredResults([]);
    }
  };
  return (
    <li
      onClick={handleSelect}
      className={classNames("QM-globalSearch_resultList_item", {
        "QM-globalSearch_resultList_item__disabled": !item?.attorney_email,
      })}
    >
      <QMAvatar name={item?.attorney_name} />
      <div>
        <span
          className={classNames("QM-globalSearch_resultList_contact-name", {
            "QM-globalSearch_resultList_contact-name__disabled":
              !item?.attorney_email,
          })}
        >
          {item?.attorney_name || ""}
        </span>
        <p
          className={classNames("QM-globalSearch_resultList_contact-email", {
            "QM-globalSearch_resultList_contact-err": !item?.attorney_email,
          })}
        >
          {item?.attorney_email || (
            <>
              <span className="icon-attention" />
              {t("assign_lawyer.no_email_err")}
            </>
          )}
        </p>
      </div>
    </li>
  );
};

AssigneeSearchResultItem.propTypes = {
  resultItem: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    address: PropTypes.string,
  }),
};
export default AssigneeSearchResultItem;
