const jurisdictions = [
  {
    "label": "Alaska",
    "value": "AK"
  },
  {
    "label": "Alabama",
    "value": "AL"
  },
  {
    "label": "American Samoa",
    "value": "AS"
  },
  {
    "label": "Arkansas",
    "value": "AR"
  },
  {
    "label": "Arizona",
    "value": "AZ"
  },
  {
    "label": "California",
    "value": "CA"
  },
  {
    "label": "Colorado",
    "value": "CO"
  },
  {
    "label": "Connecticut",
    "value": "CT"
  },
  {
    "label": "District Of Columbia",
    "value": "DC"
  },
  {
    "label": "Delaware",
    "value": "DE"
  },
  // {
  //   "label": "Federated States Of Micronesia",
  //   "value": "FM"
  // },
  {
    "label": "Florida",
    "value": "FL"
  },
  {
    "label": "Georgia",
    "value": "GA"
  },
  {
    "label": "Guam",
    "value": "GU"
  },
  {
    "label": "Hawaii",
    "value": "HI"
  },
  {
    "label": "Iowa",
    "value": "IA"
  },
  {
    "label": "Idaho",
    "value": "ID"
  },
  {
    "label": "Illinois",
    "value": "IL"
  },
  {
    "label": "Indiana",
    "value": "IN"
  },
  {
    "label": "Kansas",
    "value": "KS"
  },
  {
    "label": "Kentucky",
    "value": "KY"
  },
  {
    "label": "Louisiana",
    "value": "LA"
  },
  {
    "label": "Massachusetts",
    "value": "MA"
  },
  {
    "label": "Maryland",
    "value": "MD"
  },
  {
    "label": "Maine",
    "value": "ME"
  },
  {
    "label": "Michigan",
    "value": "MI"
  },
  // {
  //   "label": "Marshall Islands",
  //   "value": "MH"
  // },
  {
    "label": "Minnesota",
    "value": "MN"
  },
  {
    "label": "Missouri",
    "value": "MO"
  },
  {
    "label": "Northern Mariana Islands",
    "value": "MP"
  },
  {
    "label": "Mississippi",
    "value": "MS"
  },
  {
    "label": "Montana",
    "value": "MT"
  },
  {
    "label": "NA",
    "value": "NA"
  },
  {
    "label": "North Carolina",
    "value": "NC"
  },
  {
    "label": "North Dakota",
    "value": "ND"
  },
  {
    "label": "Nebraska",
    "value": "NE"
  },
  {
    "label": "New Hampshire",
    "value": "NH"
  },
  {
    "label": "New Jersey",
    "value": "NJ"
  },
  {
    "label": "New Mexico",
    "value": "NM"
  },
  {
    "label": "Nevada",
    "value": "NV"
  },
  {
    "label": "New York",
    "value": "NY"
  },
  {
    "label": "Ohio",
    "value": "OH"
  },
  {
    "label": "Oklahoma",
    "value": "OK"
  },
  {
    "label": "Oregon",
    "value": "OR"
  },
  {
    "label": "Pennsylvania",
    "value": "PA"
  },
  {
    "label": "Puerto Rico",
    "value": "PR"
  },
  // {
  //   "label": "Palau",
  //   "value": "PW"
  // },
  {
    "label": "Rhode Island",
    "value": "RI"
  },
  {
    "label": "South Carolina",
    "value": "SC"
  },
  {
    "label": "South Dakota",
    "value": "SD"
  },
  {
    "label": "Tennessee",
    "value": "TN"
  },
  {
    "label": "Texas",
    "value": "TX"
  },
  {
    "label": "Utah",
    "value": "UT"
  },
  {
    "label": "Virginia",
    "value": "VA"
  },
  {
    "label": "Virgin Islands",
    "value": "VI"
  },
  {
    "label": "Vermont",
    "value": "VT"
  },
  {
    "label": "Washington",
    "value": "WA"
  },
  {
    "label": "Wisconsin",
    "value": "WI"
  },
  {
    "label": "West Virginia",
    "value": "WV"
  },
  {
    "label": "Wyoming",
    "value": "WY"
  }
]

export default jurisdictions