const TOKEN_KEY = "REACT_TOKEN_AUTH_KEY";

const roles = {
  RW_BROKER: "r_w_broker",
  UNDERWRITER: "underwriter",
  DEAL_ADMIN: "deal_admin",
  LAWYER: "lawyer",
  PARTNER: "partner",
};

const saveTokenData = (token) => {
  localStorage.setItem(TOKEN_KEY, token);
};

const getTokenData = () => {
  const tokenData = localStorage.getItem(TOKEN_KEY);
  return JSON.parse(tokenData);
};

const removeTokenData = () => {
  localStorage.removeItem(TOKEN_KEY);
};

const decodeToken = (token) => {
  const parts = token.split(".");
  if (parts.length !== 3) {
    throw new Error("Invalid token");
  }
  const decoded = JSON.parse(window.atob(parts[1]));
  return decoded.exp;
};

const isTokenExpired = (exp) => {
  const now = Date.now() / 1000;
  return exp < now;
};

const getRedirectPath = (user) => {
  if (user) {
    switch (user?.role) {
      case roles.UNDERWRITER:
        return "/admin"; // will be switched after implementation
      case roles.RW_BROKER:
        return "/broker";
      case roles.DEAL_ADMIN:
        return "/admin";
      case roles.PARTNER:
        return "/partner";
      default:
        return "/";
    }
  }
};

export {
  TOKEN_KEY,
  roles,
  saveTokenData,
  getTokenData,
  removeTokenData,
  decodeToken,
  isTokenExpired,
  getRedirectPath,
};
