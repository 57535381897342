import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { putInsuranceComplianceTabInfo } from "../../../../../api/insuranceTabApi";
import {
  insuranceComplianceTabInfoSelector,
  insuranceLoadingSelector,
} from "../../../../../redux/selectors/insurance-tab-selector";
import QMGlobalLoader from "../../../../common/QMLoader";
import QMSaveLayout from "../../../common/QMSaveLayout";
import compliance from "./compliaceConfig";
import { insuranceComplianceValidationSchema } from "./complianceValidationRules";
import InsuranceComplianceItem from "./InsuranceComplianceItem";
import useLeavePageBlocker from "../../../../../helpers/useLeavePageBlocker";

import "./styles.scss";

const InsuranceCompliance = ({ setIsDirty }) => {
  const { t } = useTranslation();
  const isLoading = useSelector(insuranceLoadingSelector);
  const previousInsuranceCompliance = useSelector(
    insuranceComplianceTabInfoSelector
  );
  const { dealId } = useParams();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(insuranceComplianceValidationSchema),
    mode: "onChange",
  });

  useEffect(() => {
    if (
      previousInsuranceCompliance &&
      Object.keys(previousInsuranceCompliance).length
    ) {
      previousInsuranceCompliance.forEach((key) => {
        setValue(`${key.code}.buyer_requirement`, key.buyer_requirement);
        setValue(`${key.code}.limit_per_claim`, key.limit_per_claim);
        setValue(`${key.code}.aggregate`, key.aggregate);
      });
    }
  }, [previousInsuranceCompliance, setValue]);

  const onSubmit = (data) => {
    putInsuranceComplianceTabInfo(dispatch, dealId, data);
  };

  useEffect(() => setIsDirty(isDirty), [isDirty, setIsDirty]);

  useLeavePageBlocker(isDirty);

  const getContent = () => {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <section className="insurance-compliance">
          <section className="insurance-compliance__header">
            <strong></strong>
            <strong>{t("insurance.buyer_requirement")}</strong>
            <strong>{t("insurance.limit_per_claim-occurance")}</strong>
            <strong>{t("insurance.aggregate")}</strong>
          </section>
          {compliance.map((item) => {
            return (
              <InsuranceComplianceItem
                key={item.question_id}
                register={register}
                control={control}
                {...item}
                errors={errors}
                setValue={setValue}
                watch={watch}
              />
            );
          })}
        </section>
        {isDirty && <QMSaveLayout />}

        {isLoading && <QMGlobalLoader />}
      </form>
    );
  };
  return <>{getContent()}</>;
};

export default InsuranceCompliance;
