import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import uniqid from "uniqid";
import Tippy from "@tippyjs/react";

import { getAssignLawyerList } from "../../../../../api/assignUserApi";
import {
  lawyerAcceptedListSelector,
  lawyerPendingListSelector,
} from "../../../../../redux/selectors/assign-lawyer-selectors";

import QMAvatar from "../../../common/QMAvatar/QMAvatar";

import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import "./styles.scss";

const AssignUserBlock = ({ setShowAssignModal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let { dealId } = useParams();
  const maxLength = 5;

  const pendingAssignees = useSelector(lawyerPendingListSelector) || [];
  const acceptedAssignees = useSelector(lawyerAcceptedListSelector) || [];
  const assignees = [...acceptedAssignees, ...pendingAssignees];
  const croppedAssignees = assignees.slice(0, maxLength);
  useEffect(() => {
    getAssignLawyerList(dispatch, dealId);
  }, [dispatch, dealId]);

  return (
    <section className="deal-info__assign-user">
      {croppedAssignees.map((assignee) => (
        <Tippy
          theme="light"
          interactive
          key={uniqid("assignee-")}
          content={
            <section className="deal-info__assign-user__avatarTooltip">
              <p className="QM-kvBlock">
                <strong>{t("assign_lawyer.assignee")}</strong>
                <span>{assignee?.attorney_name}</span>
              </p>
              <p className="QM-kvBlock">
                <strong>{t("general.status")}</strong>
                <span className="capitalized">
                  {assignee?.assignment_status}
                </span>
              </p>
            </section>
          }
        >
          <div className="deal-info__assign-user__avatarWrapper">
            <QMAvatar viewType="secondary" name={assignee.attorney_name} />
          </div>
        </Tippy>
      ))}
      {assignees.length > maxLength && (
        <div className="deal-info__assign-user__avatarWrapper">
          <QMAvatar
            viewType="secondary"
            name={`+ ${assignees.length - maxLength}`}
          />
        </div>
      )}
      <button
        className="deal-info__assign-user_btn"
        onClick={() => setShowAssignModal(true)}
      />
    </section>
  );
};

export default AssignUserBlock;
