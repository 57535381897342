import React, { useEffect } from 'react';
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import QMEDatePicker from '../common/QMEDatePicker';
// import QMEstimateButton from '../common/QMEstimateButton';
import QMEInput from '../common/QMInput';
import QMECurrencyInput from '../common/QMInput/QMECurrencyInput';
import {
  QMEMultiSelect,
  // QMESearchSelect
} from '../common/QMEReactSelectComponents';
import QMERadioSet from '../common/QMERadioSet';
import {
  LocationControl,
  // LocationPlaceholder,
  // CompanySearchControl,
  // WebsiteSearchControl
  } from './SelectComponents';

  import {ReactComponent as GlobeIcon} from '../../../assets/imgs/globe.svg';

import {
  // setIsCompaniesLoading,
  // setCompanies,
  // setIsWebsitesLoading,
  // setWebsites,
  setIsEstimationLoading,
  setEstimationBundles,
  resetBundles
} from '../../../redux/reducers/estimator-reducer';

import { parseQueryDate, DATE_FORMAT } from '../../../helpers';
import countries from '../../../helpers/countries';
import { getEstimation, formatRequestQueryData } from '../../../api/estimatorApi';

import formConfig, {defaultValues} from './formConfig';
import validationSchema, { ERROR_MESSAGE_REQUIRED } from './validationConfig';

import './styles.scss';

const countriesOptions = countries.map(country => ({
  label: country.name,
  value: country.code
}));

const baseRadioOptions = [
  {
    value: "us_style",
    label: "US-Style",
    tooltip: (
      <ul>
        <li>better coverage</li>
        <li>lower limits</li>
        <li>higher premium</li>
      </ul>
      )
  },
  {
    value: "non_us_style",
    label: "Non US-Style",
    tooltip: (
      <ul>
        <li>limited coverage</li>
        <li>higher limits</li>
        <li>lower premium</li>
      </ul>
    )
  },
  {
    value: "cheaper",
    label: "Cheaper",
    tooltip: "you will be compromising either on limits or retention"
  }
];

// REPLACE AFTER DUNS INTEGRATION
// const sampleCompanies = [
//   {
//     label: 'Company 1',
//     value: 'Company 1'
//   },
//   {
//     label: 'My Awesome Company',
//     value: 'My Awesome Company'
//   },
//   {
//     label: 'Indeed Cool Company',
//     value: 'Indeed Cool Company'
//   }
// ];

// const sampleWebsites = [
//   {
//     label: 'www.coolcompany1.com',
//     value: 'www.coolcompany1.com'
//   },
//   {
//     label: 'www.awesomeco.com',
//     value: 'www.awesomeco.com'
//   },
//   {
//     label: 'www.indeedcoolcompany.com',
//     value: 'www.indeedcoolcompany.com'
//   }
// ];

const EstimatorForm = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const formKeys = Object.keys(defaultValues);

  const bundles = useSelector(state => state.estimator.estimation.bundles);
  const activeBundle = useSelector(state => state.estimator.estimation.activeBundle);
  // ADD AFTER DUNS INTEGRATION
  // const companies = useSelector(state => state.estimator.companiesList);
  // const isLoadingCompanies = useSelector(state => state.estimator.isCompaniesLoading);
  // const websites = useSelector(state => state.estimator.websitesList);
  // const isLoadingWebsites = useSelector(state => state.estimator.isWebsitesLoading);

  const {
    register,
    handleSubmit,
    setError,
    getValues,
    setValue,
    clearErrors,
    trigger,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: {
      ...defaultValues,
    },
    mode: 'onBlur',
    resolver: yupResolver(validationSchema)
  });
  const { t } = useTranslation();

  const getEstimationResult = data => {
    dispatch(setIsEstimationLoading(true));

    getEstimation({ ...data})
    .then(({status, data}) => {
      if (status === 400) {
        const fieldErrors = data.errors[0].detail;
        Object.keys(fieldErrors).forEach(key => {
          setError(key, { type: 'api', message: fieldErrors[key][0].toLowerCase() })
        })
      } else {
        dispatch(setEstimationBundles(data))
      }
      dispatch(setIsEstimationLoading(false))
    })
    .catch(err => console.error(err))
  }

  const onSubmit = (data) => {
    getEstimationResult(data);

    const formattedData = formatRequestQueryData(data);
    setSearchParams(formattedData)
  }

  const onError = (err) => {
    let dateValue = getValues("estimated_closing_date");
    if(!dateValue && err.estimated_closing_date) {
      setError("estimated_closing_date", {
        type: "required",
        message: ERROR_MESSAGE_REQUIRED,
      })
    }
  }

  useEffect(() => {
    const validEntries = [...searchParams].filter(
      ([key]) => formKeys.indexOf(key) >= 0
    );
    const queryObj = Object.fromEntries(validEntries);
    if(queryObj.estimated_closing_date) {
      queryObj.estimated_closing_date = parseQueryDate(queryObj.estimated_closing_date)
    }
    const queryKeys = Object.keys(queryObj);
    if(queryKeys.length) {
      queryKeys.forEach(
        field => {
          // let values;

          // if(field === 'location_of_assets') {
          //   const assetsCodes = queryObj[field].split(",");
          //   values = [];

          //   assetsCodes.forEach(el => {
          //     const valueObj = countriesOptions.find(
          //       country => country.value.toLowerCase() === el
          //     );
          //     values.push(valueObj)
          //   })
          // } else {
            // values = queryObj[field]
        // }
          setValue(
            field,
            queryObj[field],
            {
              shouldValidate: true,
              shouldDirty: true,
              shouldTouch: true,
            }
          )
        }
      );
        
      async function validateAndGetData () {
        const result = await trigger();
        if (result) {
          const formData = getValues();
          getEstimationResult(formData);
        }
      }
      validateAndGetData();

      return () => {
        dispatch(resetBundles())
      }
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    if(bundles?.length) {
      setValue("insurance_location_coverage_type", bundles[activeBundle].bundle_type)
    }
  }, [activeBundle]) // eslint-disable-line
  // ADD AFTER DUNS INTEGRATION
  // useEffect(() => {
  //   dispatch(setIsCompaniesLoading(true));
  //   return new Promise((resolve) => {
  //     setTimeout(() => {
  //       resolve(sampleCompanies);
  //     }, 1000);
  //   }).then((companies) => {
  //     dispatch(setCompanies(companies));
  //     dispatch(setIsCompaniesLoading(false));
  //   })
  // }, [dispatch])

  // useEffect(() => {
  //   dispatch(setIsWebsitesLoading(true));
  //   return new Promise((resolve) => {
  //     setTimeout(() => {
  //       resolve(sampleWebsites);
  //     }, 1000);
  //   }).then((companies) => {
  //     dispatch(setWebsites(companies));
  //     dispatch(setIsWebsitesLoading(false));
  //   })
  // }, [dispatch])

  return (
    <form 
      className='QM_E-form'
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <section className=' QM_E-form-fields_wrapper QM_E-form-fields_wrapper-top'>
      {formConfig
        .filter(field => field.theme === 'dark')
        .map((entry, index) =>{
          if(entry.fieldType === 'select') {
            return (
              <QMEInput 
                {...register(entry.name)}
                name={entry.name}
                label={t(`estimator.form.${entry.name}`)}
                theme={entry.theme}
                tooltip={entry.tooltip}
                placeholder={entry.placeholder}
                errors={errors[entry.name]}
                formatType={entry.formatType}
                width={entry.width}
                key={`qme-top${index}`}
                Icon={entry.name === 'web_site' && GlobeIcon}
              />
            // REPLACE AFTER DUNS INTEGRATION
            //   <Controller 
            //     render={({field}) => (
            //       <QMESearchSelect
            //         {...field}
            //         placeholder={entry.placeholder}
            //         theme={entry.theme}
            //         label={t(`estimator.form.${entry.name}`)}
            //         name={entry.name}
            //         errors={errors[entry.name]}
            //         width={entry.width}
            //         options={entry.name === 'company_name' ? companies : websites}
            //         isLoading={entry.name === 'company_name' ? isLoadingCompanies : isLoadingWebsites}
            //         customComponents={{
            //           Control: entry.name === 'company_name' ?
            //             CompanySearchControl :
            //             WebsiteSearchControl
            //         }}
            //       />
            //     )}
            //   control={control}
            //   name={entry.name}
            //   key={`qme-top${index}`}
            // />
          )
          }
          if(entry.fieldType === 'input') {
            return entry.formatType === 'currency'
            ? (
              <Controller
              render={({field}) => (
                <QMECurrencyInput
                {...field}
                name={entry.name}
                label={t(`estimator.form.${entry.name}`)}
                theme={entry.theme}
                tooltip={entry.tooltip}
                placeholder={entry.placeholder}
                errors={errors[entry.name]}
                formatType={entry.formatType}
                width={entry.width}
                mandatory={entry.mandatory}
                />
              )}
                control={control}
                name={entry.name}
                key={`qme-top${index}`}
              />
            )
            : (
              <QMEInput 
                {...register(entry.name)}
                name={entry.name}
                label={t(`estimator.form.${entry.name}`)}
                theme={entry.theme}
                tooltip={entry.tooltip}
                placeholder={entry.placeholder}
                errors={errors[entry.name]}
                formatType={entry.formatType}
                width={entry.width}
                key={`qme-top${index}`}
                mandatory={entry.mandatory}
              />
            )
          }
          if(entry.fieldType === "date") {
            return (
              <Controller 
                render={({field}) => (
                  <QMEDatePicker
                    {...field}
                    placeholder={entry.placeholder}
                    theme={entry.theme}
                    label={t(`estimator.form.${entry.name}`)}
                    errors={errors[entry.name]}
                    width={entry.width}
                    format={DATE_FORMAT}
                    clearErrors={clearErrors}
                    setError={setError}
                    mandatory={entry.mandatory}
                    getValues={getValues}
                  />
                )}
                control={control}
                name={entry.name}
                key={`qme-top${index}`}
              />
            )
          }
          if(entry.fieldType === 'radio') {
            return (
              <QMERadioSet 
                register={register}
                name={entry.name}
                theme={entry.theme}
                errors={errors[entry.name]}
                width={entry.width}
                options={baseRadioOptions}
                key={`qme-top${index}`}
              />
            )
          }
          return <></>
        })}
      </section>
      <section className='QM_E-form-fields_wrapper QM_E-form-fields_wrapper-bottom'>
      {formConfig
        .filter(field => field.theme === 'light')
        .map((entry, index) =>{
        if(entry.fieldType === 'input') {
          return entry.formatType === 'currency'
            ? (
              <Controller
              render={({field}) => (
                <QMECurrencyInput
                {...field}
                name={entry.name}
                label={t(`estimator.form.${entry.name}`)}
                theme={entry.theme}
                tooltip={entry.tooltip}
                placeholder={entry.placeholder}
                errors={errors[entry.name]}
                formatType={entry.formatType}
                width={entry.width}
                mandatory={entry.mandatory}
                />
              )}
                control={control}
                name={entry.name}
                key={`qme-top${index}`}
              />
            )
            : (
              <QMEInput 
                {...register(entry.name)}
                name={entry.name}
                label={t(`estimator.form.${entry.name}`)}
                theme={entry.theme}
                tooltip={entry.tooltip}
                placeholder={entry.placeholder}
                errors={errors[entry.name]}
                formatType={entry.formatType}
                width={entry.width}
                mandatory={entry.mandatory}
                key={`qme-top${index}`}
              />
            )
        }

        if(entry.fieldType === 'multiselect') {
          return (
            <Controller 
              render={({field}) => (
                <QMEMultiSelect
                  {...field}
                  placeholder={entry.placeholder}
                  theme={entry.theme}
                  label={t(`estimator.form.${entry.name}`)}
                  tooltip={entry.tooltip}
                  errors={errors[entry.name]}
                  width={entry.width}
                  options={countriesOptions}
                  customComponents={{Control: LocationControl}}
                  // customComponents={{Placeholder: LocationPlaceholder}}
                />
              )}
            control={control}
            name={entry.name}
            key={`qme-top${index}`}
          />
        )
        }
        return <></>
      })}
      </section>

      <button className="QM_E-btn_estimate" type='submit'>Estimate</button>
    </form>
  );
}

export default EstimatorForm;
