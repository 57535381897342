import React from 'react';

const QMDialogList = ({title, children}) => {
  return (
    <section className='QM-actionDialog_section'>
      <h2 className='QM-actionDialog_title'>{title}</h2>
      <ul className='QM-actionDialog_list'>
        {children}
      </ul>
    </section>
  );
}

export default QMDialogList;