import { authFetch } from "./fetchConfig";
import { setExposureData } from "../redux/reducers/exposure-reducer";
import {
  setDealError,
  setIsLoadingDeal,
} from "../redux/reducers/dealPage-reducer";

const getExposure = async (dispatch, dealId) => {
  dispatch(setIsLoadingDeal(true));
  const response = await authFetch(
    `${process.env.REACT_APP_BASE_API_URL}/deals/${dealId}/exposure`
  );
  const responseData = await response.json();
  if (response.status === 200) {
    dispatch(setExposureData(responseData));
  } else {
    console.error("something went wrong: ", responseData);
    dispatch(setDealError(responseData?.errors[0]?.message));
  }
  dispatch(setIsLoadingDeal(false));
};

export { getExposure };
