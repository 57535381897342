import React from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

import QMTabs from "../../common/QMTabs";
import QMTile from "../../common/QMTile";

const MainTile = () => {
  const { t } = useTranslation();
  const applicationRoutes = [
    {
      to: "contacts",
      title: t("general.contacts"),
    },
    {
      to: "deal-info",
      title: t("rwi_application.deal_info"),
    },
    {
      to: "past-activities",
      title: t("rwi_application.past_activities"),
    },
    {
      to: "insurance",
      title: t("general.insurance"),
    },
    /*  {
      to: "due-diligence",
      title: t("rwi_application.due_dilligence"),
    }, */
    {
      to: "timing",
      title: t("rwi_application.timing"),
    },
  ];

  return (
    <QMTile>
      <QMTabs routes={applicationRoutes} />
      <Outlet />
    </QMTile>
  );
};

export default MainTile;
