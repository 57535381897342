import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoute = ({
  redirectTo,
  redirectCondition,
  children,
  shouldSaveQuery,
}) => {
  const location = useLocation();
  const query = location?.search ? `${location.search}` : "";
  const link = shouldSaveQuery ? `${redirectTo}${query}` : redirectTo;
  return redirectCondition ? (
    <Navigate to={link} state={{ from: location }} replace />
  ) : (
    children
  );
};

export default ProtectedRoute;
