import React from "react";

import QMButton from "../../common/QMButton";
import QMModal from "../../common/QMModal";

import "./styles.scss";

const UnderwritingModal = ({
  title,
  header_text,
  body_text,
  footer_text,
  cancelButtonText,
  submitButtonText,
  setShowUnderwritingModal,
  sendDataFromTable,
  selectedCarriers,
  selectedCarrier,
}) => {
  return (
    <QMModal
      closeModal={setShowUnderwritingModal}
      customClassName="underwritingModal"
    >
      <section className="deal-page-modal">
        <header>
          <h2 className="deal-page-modal__title">{title}</h2>
        </header>
        <section className="deal-page-modal__body">
          <p className="deal-page-modal__text">{header_text}</p>
          <p className="deal-page-modal__text">{body_text}</p>
          <p className="deal-page-modal__text">{footer_text}</p>
        </section>
        <footer className="deal-page-modal__footer">
          <QMButton
            text={cancelButtonText}
            viewType="secondary"
            type="cancel"
            customClassName="tabs-submit__btn"
            onClick={() => {
              setShowUnderwritingModal(false);
            }}
          />
          <QMButton
            text={submitButtonText}
            type="submit"
            customClassName="tabs-submit__btn"
            onClick={() =>
              selectedCarrier
                ? sendDataFromTable(selectedCarrier)
                : sendDataFromTable(selectedCarriers)
            }
          />
        </footer>
      </section>
    </QMModal>
  );
};

export default UnderwritingModal;
