import React from "react";
import NumberFormat from "react-number-format";
import ReactDatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { format } from "date-fns";

import QMETooltip from "../../estimator/common/QMETooltip";
import {
  landingValidationSchema,
  ERROR_MESSAGE_REQUIRED,
} from "../../estimator/EstimatorForm/validationConfig";
import { DATE_FORMAT, QUERY_DATE_FORMAT } from "../../../helpers";

import "./styles.scss";

const EstimatorSection = ({ inputRef }) => {
  let navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setError,
    getValues,
    clearErrors,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      company_name: "",
      web_site: "https://",
      deal_size: "",
      desired_limits: "",
      revenue: "",
      estimated_closing_date: null,
    },
    mode: "onBlur",
    resolver: yupResolver(landingValidationSchema),
  });

  const setDateRequiredErr = () => {
    let value = getValues("estimated_closing_date");
    if (!value) {
      setError("estimated_closing_date", {
        type: "required",
        message: ERROR_MESSAGE_REQUIRED,
      });
    }
  };

  const onSubmit = (data) => {
    const { estimated_closing_date } = data;
    let formattedDate = format(estimated_closing_date, QUERY_DATE_FORMAT);

    const queryObj = {
      ...data,
      estimated_closing_date: formattedDate,
    };
    const searchParams = new URLSearchParams(queryObj).toString();
    navigate(`/estimator?${searchParams}`);
  };

  const onError = (err) => {
    let dateValue = getValues("estimated_closing_date");
    if (!dateValue && err.estimated_closing_date) {
      setError("estimated_closing_date", {
        type: "required",
        message: ERROR_MESSAGE_REQUIRED,
      });
    }
  };

  return (
    <section
      id="FormEstimatorShort"
      className="custom__commonSection landing__FormEstimatorShort"
    >
      <div className="custom__commonWidth">
        <h2>
          We make transactional insurance easy, straightforward and predictable
        </h2>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <legend>Estimate your M&amp;A Insurance Costs</legend>
          <ul>
            <li>
              <label>
                <span className="icon-search">Company Name</span>
                <input
                  {...register("company_name")}
                  type="text"
                  placeholder="Enter company name"
                  name="company_name"
                  id="ShortEstimateName"
                />
                {errors.company_name && (
                  <QMETooltip
                    text={errors.company_name.message}
                    messageType="error"
                  />
                )}
              </label>
            </li>
            <li>
              <label>
                <span className="icon-globe">
                  Website (starts with https://)
                </span>
                <input
                  {...register("web_site")}
                  placeholder="https://example.com"
                  name="web_site"
                />
                {errors.web_site && (
                  <QMETooltip
                    text={errors.web_site.message}
                    messageType="error"
                  />
                )}
              </label>
            </li>
            <li>
              <label>
                <span className="icon-dollar-sign">Deal Size</span>
                <Controller
                  name="deal_size"
                  control={control}
                  render={({ field }) => (
                    <NumberFormat
                      {...field}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale
                      allowNegative={false}
                      placeholder="e.g., 250,000,000"
                      name="deal_size"
                      id="ShortEstimateDealSize"
                    />
                  )}
                />
                {errors.deal_size && (
                  <QMETooltip
                    text={errors.deal_size.message}
                    messageType="error"
                  />
                )}
              </label>
            </li>
            <li>
              <label>
                <span className="icon-dollar-sign">Desired limits</span>
                <Controller
                  name="desired_limits"
                  control={control}
                  render={({ field }) => (
                    <NumberFormat
                      {...field}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale
                      allowNegative={false}
                      placeholder="e.g., 250,000,000"
                      name="desired_limits"
                      id="ShortEstimateDesiredLimits"
                    />
                  )}
                />
                {errors.desired_limits && (
                  <QMETooltip
                    text={errors.desired_limits.message}
                    messageType="error"
                  />
                )}
              </label>
            </li>
            <li>
              <label>
                <span className="icon-dollar-sign">Revenue</span>
                <Controller
                  name="revenue"
                  control={control}
                  render={({ field }) => (
                    <NumberFormat
                      {...field}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale
                      allowNegative={false}
                      placeholder="e.g., 250,000,000"
                      name="revenue"
                      id="ShortEstimateRevenue"
                    />
                  )}
                />
                {errors.revenue && (
                  <QMETooltip
                    text={errors.revenue.message}
                    messageType="error"
                  />
                )}
              </label>
            </li>
            <li>
              <label>
                <span className="icon-calendar">Estimated Closing Date</span>
                <Controller
                  name="estimated_closing_date"
                  control={control}
                  render={({ field }) => (
                    <ReactDatePicker
                      onChange={(e) => {
                        clearErrors("estimated_closing_date");
                        field.onChange(e);
                        setDateRequiredErr();
                      }}
                      onBlur={(e) => {
                        setDateRequiredErr();
                      }}
                      selected={field.value}
                      minDate={new Date()}
                      showPopperArrow={false}
                      dateFormat={DATE_FORMAT}
                      placeholderText={DATE_FORMAT.toLowerCase()}
                      closeOnScroll
                      shouldCloseOnSelect
                    />
                  )}
                />
                {errors.estimated_closing_date && (
                  <QMETooltip
                    text={errors.estimated_closing_date.message}
                    messageType="error"
                  />
                )}
              </label>
            </li>
          </ul>
          <button type="submit" className="custom__button">
            Estimate
          </button>
        </form>
      </div>
    </section>
  );
};

export default EstimatorSection;
