import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { getRiskProfileLitigationsDocs } from "../../../../api/risk-profileApi";
import { riskProfileLitigationsDocsSelector } from "../../../../redux/selectors/riskProfile-selector";

import QMDocsModal from "../../common/QMDocsModal/QMDocsModal";

const RiskProfileLitigationsDocsModal = ({
  handleClose,
  dealIdForLitigationsDocs,
  setDealIdForLitigationsDocs,
}) => {
  const dispatch = useDispatch();

  const litigationsDocsList = useSelector(riskProfileLitigationsDocsSelector);
  const getLitigationsDocsList = () =>
    getRiskProfileLitigationsDocs(dispatch, dealIdForLitigationsDocs);

  return (
    <QMDocsModal
      docsList={litigationsDocsList}
      getDocsList={getLitigationsDocsList}
      handleClose={handleClose}
      modalProps={{ closeModal: setDealIdForLitigationsDocs }}
    />
  );
};

export default RiskProfileLitigationsDocsModal;
