import React, { forwardRef } from 'react';
import classNames from 'classnames';
import Select from 'react-select';

import QMETooltip from '../QMETooltip';
import { LocationMultiValueRemove } from '../../EstimatorForm/SelectComponents';
import reactSelectStylesConfig from './stylesConfig';


const QMEMultiSelect = forwardRef(({
  theme,
  label,
  placeholder,
  tooltip,
  errors,
  width,
  customComponents,
  ...props
}, ref) => {

  

  const selectComponents = {
    IndicatorsContainer: () => null,
    MultiValueRemove: LocationMultiValueRemove,
    ...customComponents,
  }

  return (
    <section className='QM_E-input_wrapper'>
      <label 
        className={
          classNames(
            'QM_E-input_label',
            `QM_E-input_label__${theme}`
          )
        }
      >
        {label}
        {tooltip && <QMETooltip text={tooltip} theme={theme} />}
      </label>
      <Select
        ref={ref}
        placeholder={placeholder}
        isMulti
        isSearchable
        width={width || '150px'}
        styles={reactSelectStylesConfig(width)}
        components={selectComponents}
        // menuPlacement="top"
        {...props}
      />
      {errors && (<p className='QM_E-input_erroMessage'>{errors.message || 'error occured'}</p>)}
    </section>
  );
})

export default QMEMultiSelect;