import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import QMGlobalLoader from "../../../common/QMLoader";
import { getUnderwritingInfo } from "../../../../api/underwritingApi";
import {
  underwritingCarriersSelector,
  underwritingLoadingSelector,
} from "../../../../redux/selectors/underwriting-selectors";
import UnderwritingContainer from "./UnderwritingContainer";

import "./styles.scss";

const Underwriting = () => {
  const isLoading = useSelector(underwritingLoadingSelector);
  const underwritingInfo = useSelector(underwritingCarriersSelector);
  const dispatch = useDispatch();
  let { dealId } = useParams();

  useEffect(() => {
    getUnderwritingInfo(dispatch, dealId);
  }, [dispatch, dealId]);

  const getContent = () => {
    if (isLoading) {
      return <QMGlobalLoader />;
    } else if (!isLoading && underwritingInfo?.length) {
      return <UnderwritingContainer data={underwritingInfo} />;
    }
  };

  return <>{getContent()}</>;
};
export default Underwriting;
