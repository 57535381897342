import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  // riskProfileAssetsSelector,
  riskProfileDescriptionSelector,
  riskProfileGeneralSelector,
  riskProfileInsuranceSelector,
  riskProfileLitigationsSelector,
  riskProfileOutstandingSelector,
  riskProfileStakeholdersSelector,
} from "../../../../redux/selectors/riskProfile-selector";
// import RiskProfileDealAssets from "./RiskProfileDealAssets";
import RiskProfileDescription from "./RiskProfileDescription";
import RiskProfileGeneral from "./RiskProfileGeneral";
import RiskProfileLitigations from "./RiskProfileLitigations";
import RiskProfileStakeholders from "./RiskProfileStakeholders";
import RiskProfileTable from "./RiskProfileTable";

import "./styles.scss";

const RiskProfileContainer = () => {
  const { t } = useTranslation();
  const riskProfileInsuranceData = useSelector(riskProfileInsuranceSelector);
  const riskProfileOutstandingData = useSelector(
    riskProfileOutstandingSelector
  );
  const riskProfileGeneralData = useSelector(riskProfileGeneralSelector);
  const RiskProfileDescriptionData = useSelector(
    riskProfileDescriptionSelector
  );
  const riskProfileStakeholdersData = useSelector(
    riskProfileStakeholdersSelector
  );
  // const riskProfileAssetsData = useSelector(riskProfileAssetsSelector);
  const riskProfileLitigationsData = useSelector(
    riskProfileLitigationsSelector
  );

  return (
    <section className="risk-profile">
      <RiskProfileDescription
        data={RiskProfileDescriptionData}
        header={t("risk_profile.deal_description_header")}
      />
      {/* <section className="risk-profile__plate"> */}
      <RiskProfileGeneral
        data={riskProfileGeneralData}
        header={t("risk_profile.deal_general_header")}
      />
      {/* <RiskProfileDealAssets
          data={riskProfileAssetsData}
          header={t("risk_profile.deal_assets_header")}
        /> */}
      {/* </section> */}
      <section className="risk-profile__plate">
        <RiskProfileTable
          data={riskProfileInsuranceData}
          header={t("risk_profile.insurance_compliance_header")}
        />
        <RiskProfileTable
          data={riskProfileOutstandingData}
          header={t("risk_profile.outstanding_data_header")}
        />
      </section>
      <RiskProfileLitigations
        data={riskProfileLitigationsData}
        header={t("risk_profile.litigations_header")}
      />
      <RiskProfileStakeholders
        data={riskProfileStakeholdersData}
        header={t("risk_profile.stakeholders_header")}
      />
    </section>
  );
};
export default RiskProfileContainer;
