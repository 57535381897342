import React from "react";
import QMButton from "../QMButton";
import useOuterClick from "../../../../helpers/useOuterClick";

import "./styles.scss";

const QMDialogWrapper = ({ children, onClose }) => {
  const innerRef = useOuterClick((e) => onClose());
  return (
    <section className="QM-actionDialog" ref={innerRef}>
      <QMButton
        iconClassName="icon-x"
        customClassName="QM-actionDialog_closeBtn"
        onClick={onClose}
        viewType="transparent"
      />
      {children}
    </section>
  );
};

export default QMDialogWrapper;
