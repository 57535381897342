import classNames from "classnames";
import React from "react";

import "./styles.scss";

const QMInputWrapper = ({
  children,
  label,
  isRequired,
  errors,
  iconClassName,
  customClassName,
  maxLength,
  withCount,
  ...props
}) => {
  return (
    <section className={classNames("QM-input_wrapper", customClassName)}>
      {iconClassName && <span className={iconClassName} />}
      <label className="QM-input_label">
        <div>
          {label}
          {isRequired && <span className="QM-input_requiredMark">*</span>}
        </div>
        {withCount && (
          <span>{`${props.value ? props.value.length : 0}/${maxLength}`}</span>
        )}
      </label>
      {children}
      {errors && <p className="QM-input_errorMessage">{errors.message}</p>}
    </section>
  );
};

export default QMInputWrapper;
