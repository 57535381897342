import React, { forwardRef } from "react";
import ReactDatePicker from "react-datepicker";
import classNames from "classnames";

import QMInputWrapper from "./QMInputWrapper";
import { ERROR_MESSAGE_REQUIRED } from "../../../../helpers/validationConsts";

const QMDateInput = forwardRef(
  (
    {
      label,
      name,
      errors,
      format,
      clearErrors,
      setError,
      isRequired,
      placeholder,
      value,
      onChange,
      onBlur,
      getValues,
      iconClassName,
      minDate,
      maxDate,
      ...props
    },
    ref
  ) => {
    const setRequiredError = () => {
      let value = getValues(name);
      if (!value) {
        setError(name, {
          type: "required",
          message: ERROR_MESSAGE_REQUIRED,
        });
      }
    };

    const handleDateChange = (date) => {
      clearErrors(name);
      onChange(date);
      isRequired && setRequiredError();
    };

    const handleBlur = () => {
      isRequired && setRequiredError();
    };

    return (
      <QMInputWrapper
        label={label}
        isRequired={isRequired}
        errors={errors}
        iconClassName={iconClassName}
      >
        <ReactDatePicker
          className={classNames("QM-input QM-datepicker", {
            "QM-input__error": !!errors,
          })}
          placeholderText={placeholder}
          selected={value}
          onChange={handleDateChange}
          onBlur={(e) => handleBlur(e)}
          dateFormat={format}
          minDate={minDate}
          maxDate={maxDate}
          showPopperArrow={false}
          // popperPlacement="right"
          ref={ref}
          {...props}
        />
      </QMInputWrapper>
    );
  }
);

export default QMDateInput;
