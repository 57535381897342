import * as yup from "yup";

import * as validation from "../../../../../helpers/validationConsts";

const insuranceValidationSchema = yup.object({
  seller_indemnity_1: yup.bool().nullable(),
  seller_indemnity_percentage_1: yup
    .string()
    .nullable()
    .when("seller_indemnity_1", {
      is: true,
      then: yup
        .string()
        .nullable()
        .matches(validation.NUMBER_REGEXP, {
          message: validation.ERROR_MESSAGE_NUMBERS,
          excludeEmptyString: true,
        })
        .test(
          "seller_indemnity_percentage_1",
          validation.ERROR_MESSAGE_MAX_NUMBER,
          (value) => Number(value) <= validation.MAX_INSURANCE_NUMBER
        )
        .typeError(validation.ERROR_MESSAGE_REQUIRED),
    }),
  no_seller_indemnity_1: yup.bool().nullable(),
  no_seller_indemnity_percentage_1: yup
    .string()
    .nullable()
    .when("no_seller_indemnity_1", {
      is: true,
      then: yup
        .string()
        .nullable()
        .matches(validation.NUMBER_REGEXP, {
          message: validation.ERROR_MESSAGE_NUMBERS,
          excludeEmptyString: true,
        })
        .test(
          "no_seller_indemnity_percentage_1",
          validation.ERROR_MESSAGE_MAX_NUMBER,
          (value) => Number(value) <= validation.MAX_INSURANCE_NUMBER
        )
        .typeError(validation.ERROR_MESSAGE_REQUIRED),
    }),
  seller_indemnity_2: yup.bool().nullable(),
  seller_indemnity_percentage_2: yup
    .string()
    .nullable()
    .when("seller_indemnity_2", {
      is: true,
      then: yup
        .string()
        .nullable()
        .matches(validation.NUMBER_REGEXP, {
          message: validation.ERROR_MESSAGE_NUMBERS,
          excludeEmptyString: true,
        })
        .test(
          "seller_indemnity_percentage_2",
          validation.ERROR_MESSAGE_MAX_NUMBER,
          (value) => Number(value) <= validation.MAX_INSURANCE_NUMBER
        )
        .typeError(validation.ERROR_MESSAGE_REQUIRED),
    }),
  no_seller_indemnity_2: yup.bool().nullable(),
  no_seller_indemnity_percentage_2: yup
    .string()
    .nullable()
    .when("no_seller_indemnity_2", {
      is: true,
      then: yup
        .string()
        .nullable()
        .matches(validation.NUMBER_REGEXP, {
          message: validation.ERROR_MESSAGE_NUMBERS,
          excludeEmptyString: true,
        })
        .test(
          "no_seller_indemnity_percentage_2",
          validation.ERROR_MESSAGE_MAX_NUMBER,
          (value) => Number(value) <= validation.MAX_INSURANCE_NUMBER
        )
        .typeError(validation.ERROR_MESSAGE_REQUIRED),
    }),
  seller_indemnity_3: yup.bool().nullable(),
  seller_indemnity_percentage_3: yup
    .string()
    .nullable()
    .when("seller_indemnity_3", {
      is: true,
      then: yup
        .string()
        .nullable()
        .matches(validation.NUMBER_REGEXP, {
          message: validation.ERROR_MESSAGE_NUMBERS,
          excludeEmptyString: true,
        })
        .test(
          "seller_indemnity_percentage_3",
          validation.ERROR_MESSAGE_MAX_NUMBER,
          (value) => Number(value) <= validation.MAX_INSURANCE_NUMBER
        )
        .typeError(validation.ERROR_MESSAGE_REQUIRED),
    }),
  no_seller_indemnity_3: yup.bool().nullable(),
  no_seller_indemnity_percentage_3: yup
    .string()
    .nullable()
    .when("no_seller_indemnity_3", {
      is: true,
      then: yup
        .string()
        .nullable()
        .matches(validation.NUMBER_REGEXP, {
          message: validation.ERROR_MESSAGE_NUMBERS,
          excludeEmptyString: true,
        })
        .test(
          "no_seller_indemnity_percentage_3",
          validation.ERROR_MESSAGE_MAX_NUMBER,
          (value) => Number(value) <= validation.MAX_INSURANCE_NUMBER
        )
        .typeError(validation.ERROR_MESSAGE_REQUIRED),
    }),
  seller_indemnity_4: yup.bool().nullable(),
  seller_indemnity_percentage_4: yup
    .string()
    .nullable()
    .when("seller_indemnity_4", {
      is: true,
      then: yup
        .string()
        .nullable()
        .matches(validation.NUMBER_REGEXP, {
          message: validation.ERROR_MESSAGE_NUMBERS,
          excludeEmptyString: true,
        })
        .test(
          "seller_indemnity_percentage_4",
          validation.ERROR_MESSAGE_MAX_NUMBER,
          (value) => Number(value) <= validation.MAX_INSURANCE_NUMBER
        )
        .typeError(validation.ERROR_MESSAGE_REQUIRED),
    }),
  no_seller_indemnity_4: yup.bool().nullable(),
  no_seller_indemnity_percentage_4: yup
    .string()
    .nullable()
    .when("no_seller_indemnity_4", {
      is: true,
      then: yup
        .string()
        .nullable()
        .matches(validation.NUMBER_REGEXP, {
          message: validation.ERROR_MESSAGE_NUMBERS,
          excludeEmptyString: true,
        })
        .test(
          "no_seller_indemnity_percentage_4",
          validation.ERROR_MESSAGE_MAX_NUMBER,
          (value) => Number(value) <= validation.MAX_INSURANCE_NUMBER
        )
        .typeError(validation.ERROR_MESSAGE_REQUIRED),
    }),
  coverage_duration: yup.object({
    insurance_policy: yup
      .string()
      .typeError(validation.ERROR_MESSAGE_REQUIRED)
      .matches(validation.NUMBER_REGEXP, validation.ERROR_MESSAGE_NUMBERS)
      .test(
        "insurance_policy",
        validation.ERROR_MESSAGE_MAX_NUMBER,
        (value) => Number(value) <= validation.MAX_INSURANCE_NUMBER
      )
      .nullable()
      .transform((value) => (!!value ? value : null)),
    general_representations: yup
      .string()
      .typeError(validation.ERROR_MESSAGE_REQUIRED)
      .matches(validation.NUMBER_REGEXP, validation.ERROR_MESSAGE_NUMBERS)
      .test(
        "general_representations",
        validation.ERROR_MESSAGE_MAX_NUMBER,
        (value) => Number(value) <= validation.MAX_INSURANCE_NUMBER
      )
      .nullable()
      .transform((value) => (!!value ? value : null)),
    fundamental_representations: yup
      .string()
      .typeError(validation.ERROR_MESSAGE_REQUIRED)
      .matches(validation.NUMBER_REGEXP, validation.ERROR_MESSAGE_NUMBERS)
      .test(
        "fundamental_representations",
        validation.ERROR_MESSAGE_MAX_NUMBER,
        (value) => Number(value) <= validation.MAX_INSURANCE_NUMBER
      )
      .nullable()
      .transform((value) => (!!value ? value : null)),
    tax_representations: yup
      .string()
      .typeError(validation.ERROR_MESSAGE_REQUIRED)
      .matches(validation.NUMBER_REGEXP, validation.ERROR_MESSAGE_NUMBERS)
      .test(
        "tax_representations",
        validation.ERROR_MESSAGE_MAX_NUMBER,
        (value) => Number(value) <= validation.MAX_INSURANCE_NUMBER
      )
      .nullable()
      .transform((value) => (!!value ? value : null)),
  }),

  policy_enhancements: yup.string().nullable(),
});

export { insuranceValidationSchema };
