import { format } from 'date-fns';

import { post, getFile } from './fetchConfig'
import {
  REQUEST_DATE_FORMAT,
  QUERY_DATE_FORMAT,
  extractCurrency,
  // extractNumber
} from '../helpers';

const ESTIMATE_URL = `${process.env.REACT_APP_BASE_API_URL}/companies/estimator`;

const formatEstimateRequestData = (data) => {
  // const locationsData = data.location_of_assets.map(country => country.value.toLowerCase())
  const requestData = {
    company_name: data.company_name,
    web_site: data.web_site,
    deal_size: extractCurrency(data.deal_size),
    estimated_closing_date: data.estimated_closing_date && format(data.estimated_closing_date, REQUEST_DATE_FORMAT),
    desired_limits: extractCurrency(data.desired_limits),
    insurance_location_coverage_type: data.insurance_location_coverage_type,
    revenue: extractCurrency(data.revenue),
    // common_shareholders: data.common_shareholders && extractNumber(data.common_shareholders),
    // preferred_shareholders: data.preferred_shareholders && extractNumber(data.preferred_shareholders),
    // product_sales: data.product_sales && extractCurrency(data.product_sales),
    // voting_board_members: data.voting_board_members && extractNumber(data.voting_board_members),
    // officers: data.officers && extractNumber(data.officers),
    // total_assets: data.total_assets && extractCurrency(data.total_assets),
    // global_employees: data.global_employees && extractNumber(data.global_employees),
    // tax_liabilities: data.tax_liabilities && extractCurrency(data.tax_liabilities),
    // background_checks: data.background_checks && extractNumber(data.background_checks),
    // current_total_liabilities: data.current_total_liabilities && extractCurrency(data.current_total_liabilities),
    // external_audits: data.external_audits && extractNumber(data.external_audits),
    // internal_audits: data.internal_audits && extractNumber(data.internal_audits),
    // owned_properties: data.owned_properties && extractNumber(data.owned_properties),
    // leased_properties: data.leased_properties && extractNumber(data.leased_properties),
    // insurance_claims: data.insurance_claims && extractNumber(data.insurance_claims),
    // location_of_assets: locationsData,
  };
  return Object.fromEntries(Object.entries(requestData)
    .filter(([key, val]) => val !== undefined && val !== ''))
}
const formatRequestQueryData = (data) => {
  const formatted = formatEstimateRequestData(data);
  if(data.estimated_closing_date) {
    formatted.estimated_closing_date = format(data.estimated_closing_date, QUERY_DATE_FORMAT);
  }
  // if(data.location_of_assets) {
  //   formatted.location_of_assets = formatted.location_of_assets.join(',')
  // }
  return formatted;
}
const getEstimation = (data) => {
  const formattedData = formatEstimateRequestData(data)
  return post(ESTIMATE_URL, formattedData)
}

const getEstimationPdf = (record_id) => {
  return getFile(`${ESTIMATE_URL}?record_id=${record_id}`)
}
export {
  getEstimation,
  getEstimationPdf,
  formatEstimateRequestData,
  formatRequestQueryData
}
