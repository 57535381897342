import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  timingLoadingSelector,
  timingTabInfoSelector,
} from "../../../../../redux/selectors/timing-tab-selectors";
import { getTimingTabInfo } from "../../../../../api/timingTabApi";
import TimingForm from "./TimingForm";
import QMGlobalLoader from "../../../../common/QMLoader";

import "./styles.scss";

const TimingTab = () => {
  const isLoading = useSelector(timingLoadingSelector);
  const timingTabData = useSelector(timingTabInfoSelector);

  const { dealId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    getTimingTabInfo(dealId, dispatch);
  }, [dealId, dispatch]);

  return (
    <>
      {isLoading && <QMGlobalLoader />}
      <TimingForm
        timingTabData={timingTabData}
        dealId={dealId}
        isLoading={isLoading}
      />
    </>
  );
};

export default TimingTab;
