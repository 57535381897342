import { authFetch } from "./fetchConfig";
import {
  reformatUnderwriting,
  sortUnderwritingList,
} from "../helpers/deal-helpers";
import {
  setDealError,
  setDealSaveSuccess,
} from "../redux/reducers/dealPage-reducer";
import {
  setIsLoadingUnderwriting,
  setUnderwritingInfo,
  setUnderwritingCarriersInfo,
} from "../redux/reducers/underwriting-reducer";

const getUnderwritingInfo = async (dispatch, dealId) => {
  dispatch(setIsLoadingUnderwriting(true));
  const response = await authFetch(
    `${process.env.REACT_APP_BASE_API_URL}/deals/${dealId}/underwriters`
  );
  const responseData = await response.json();

  if (response.status === 200) {
    const sortedCarriers = sortUnderwritingList(responseData.carriers);
    dispatch(setUnderwritingInfo(responseData.total));
    dispatch(setUnderwritingCarriersInfo(sortedCarriers));
  } else {
    dispatch(setDealError(responseData?.errors[0]?.message));
  }
  dispatch(setIsLoadingUnderwriting(false));
};

const postUnderWritingInfo = async (dispatch, dealId, data) => {
  dispatch(setIsLoadingUnderwriting(true));

  const dataToSend = reformatUnderwriting(data);

  const response = await authFetch(
    `${process.env.REACT_APP_BASE_API_URL}/deals/${dealId}/submission`,
    {
      method: "POST",
      body: JSON.stringify(dataToSend),
      headers: {
        "Content-type": "application/json",
      },
    }
  );

  if (!response.ok) {
    const err = await response.json();
    dispatch(setDealError(err?.errors[0]?.message));
  }

  if (response.ok) {
    dispatch(setIsLoadingUnderwriting(false));
    getUnderwritingInfo(dispatch, dealId);
    dispatch(setDealSaveSuccess(true));
  }
  dispatch(setIsLoadingUnderwriting(false));
};

export { getUnderwritingInfo, postUnderWritingInfo };
