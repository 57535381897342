import { useCallback, useEffect, useState } from "react";
import { searchDNBCompany } from "../../../../api/dealsApi";
import { debounce } from "../../../../helpers";

const useDNBSearch = ({ searchString, delay = 300 }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [shouldFetch, setShouldFetch] = useState(true);

  const clearSuggestions = () => setSuggestions([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getSuggestions = useCallback(
    debounce(
      () => searchDNBCompany(searchString, setIsLoading, setSuggestions),
      delay
    ),
    [searchString]
  );

  useEffect(() => {
    if (shouldFetch) {
      if (searchString && searchString.length > 2) {
        getSuggestions();
      } else {
        clearSuggestions();
      }
    }
  }, [searchString, shouldFetch, getSuggestions]);
  return {
    suggestions,
    clearSuggestions,
    isLoading,
    setShouldFetch,
  };
};

export default useDNBSearch;
