import React from "react";
import { useTranslation } from "react-i18next";

import QMTile from "../../common/QMTile";
import RiskProfileTableItem from "./RiskProfileTableItem";

import "./styles.scss";

const RiskProfileTable = ({ header, data }) => {
  const { t } = useTranslation();

  return (
    <QMTile>
      <h2 className="risk-profile__header">{header}</h2>
      <section className="risk-profile__general-body">
        <div className="risk-profile__table-title">
          <span>{t("risk_profile.data")}</span>
          <span>{t("risk_profile.fields")}</span>
          <span>{t("risk_profile.folders")}</span>
        </div>
        <ul className="risk-profile__table-list">
          {data?.map((item, index) => {
            return (
              <RiskProfileTableItem
                key={index}
                name={item.name}
                field_value={item.fields_completion}
                folder_value={item.folders_completion}
              />
            );
          })}
        </ul>
      </section>
    </QMTile>
  );
};
export default RiskProfileTable;
