import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";

import { QMSelectInput, QMTextInput } from "../../../common/QMInputs";
const DealAdminTargetInfoBlock = ({
  register,
  control,
  watch,
  getFieldErrors,
  setValue,
}) => {
  const { t } = useTranslation();
  const sellerTargetWatch = watch("seller_target");

  const targetOpts = [
    { value: "seller", label: t("general.seller") },
    { value: "subsidiary", label: t("general.subsidiary") },
  ];

  const handleTargetChange = (val, field) => {
    const updatedVal = val === null ? val : val.value;
    field.onChange(updatedVal);
  };

  useEffect(() => {
    if (sellerTargetWatch === "seller") {
      setValue("seller_subsidiary_company_name", "");
      setValue("seller_subsidiary_company_website", "");
    }
  }, [sellerTargetWatch, setValue]);

  return (
    <>
      <Controller
        render={({ field }) => (
          <QMSelectInput
            {...field}
            placeholder={t("general.choose_answer")}
            label={t("general.target")}
            errors={getFieldErrors("seller_target")}
            options={targetOpts}
            isSearchable={false}
            value={targetOpts.find((c) => c.value === field.value)}
            onChange={(val) => handleTargetChange(val, field)}
            isClearable
            isRequired
          />
        )}
        control={control}
        name="seller_target"
      />
      <QMTextInput
        {...register("seller_subsidiary_company_name")}
        label={t("new_deal_form.target_company_label")}
        placeholder={t("new_deal_form.target_company_placeholder")}
        errors={getFieldErrors("seller_subsidiary_company_name")}
        disabled={sellerTargetWatch !== "subsidiary"}
        isRequired={sellerTargetWatch === "subsidiary"}
      />
      <QMTextInput
        {...register("seller_subsidiary_company_website")}
        label={t("new_deal_form.target_website_label")}
        placeholder={t("general.website_placeholder")}
        errors={getFieldErrors("seller_subsidiary_company_website")}
        disabled={sellerTargetWatch !== "subsidiary"}
        isRequired={sellerTargetWatch === "subsidiary"}
      />
    </>
  );
};

export default DealAdminTargetInfoBlock;
