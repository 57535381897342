import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";

import { QMSelectInput, QMTextInput } from "../../../common/QMInputs";
import QMDNBSearch from "../../../common/QMDNBSearch/QMDNBSearch";

const BuyerInfoBlock = ({
  register,
  control,
  watch,
  trigger,
  getFieldErrors,
  boolOpts,
  setValue,
  predictionSetter,
  selectedPrediction,
  optPropPredictionSetter,
  optPropPrediction,
}) => {
  const { t } = useTranslation();
  const buyerSubsidiaryWatch = watch("buyer_is_subsidiary");

  useEffect(() => {
    if (!buyerSubsidiaryWatch) {
      setValue("buyer_parent_company_name", "");
      setValue("buyer_parent_company_website", "");
    }
  }, [buyerSubsidiaryWatch, setValue]);

  const handleSubsidiaryChange = (val, field) => {
    const updatedVal = val === null ? val : val.value;
    field.onChange(updatedVal);
  };

  return (
    <section className="QM-formBlock">
      <section className="QM-formRow">
        <QMDNBSearch
          register={register}
          setValue={setValue}
          watch={watch}
          trigger={trigger}
          name="buyer_name"
          selectedPrediction={selectedPrediction}
          predictionSetter={predictionSetter}
          inputProps={{
            label: t("general.buyer"),
            placeholder: t("new_deal_form.buyer_name_placeholder"),
            errors: getFieldErrors("buyer_name"),
            isRequired: true,
          }}
        />
        <QMTextInput
          {...register("buyer_website")}
          label={t("new_deal_form.buyer_website_label")}
          placeholder={t("general.website_placeholder")}
          errors={getFieldErrors("buyer_website")}
          isRequired
        />
      </section>
      <section className="QM-formRow">
        <Controller
          render={({ field }) => (
            <QMSelectInput
              {...field}
              placeholder={t("general.choose_answer")}
              label={t("general.subsidiary")}
              errors={getFieldErrors("buyer_is_subsidiary")}
              options={boolOpts}
              isSearchable={false}
              isClearable
              value={boolOpts.find((c) => c.value === field.value)}
              onChange={(val) => handleSubsidiaryChange(val, field)}
              isRequired
            />
          )}
          control={control}
          name="buyer_is_subsidiary"
        />
        <QMDNBSearch
          register={register}
          setValue={setValue}
          watch={watch}
          trigger={trigger}
          name="buyer_parent_company_name"
          selectedPrediction={optPropPrediction}
          predictionSetter={optPropPredictionSetter}
          inputProps={{
            label: t("new_deal_form.parent_company_label"),
            placeholder: t("new_deal_form.parent_company_placeholder"),
            errors: getFieldErrors("buyer_parent_company_name"),
            isRequired: buyerSubsidiaryWatch,
            disabled: buyerSubsidiaryWatch !== true,
          }}
        />
        <QMTextInput
          {...register("buyer_parent_company_website")}
          label={t("new_deal_form.parent_website_label")}
          placeholder={t("general.website_placeholder")}
          errors={getFieldErrors("buyer_parent_company_website")}
          disabled={buyerSubsidiaryWatch !== true}
          isRequired={buyerSubsidiaryWatch}
        />
      </section>
    </section>
  );
};

export default BuyerInfoBlock;
