import React, { forwardRef } from 'react';
import classNames from 'classnames';

import QMETooltip from '../QMETooltip';

import './styles.scss';

const QMEInput = forwardRef(({
  label,
  placeholder,
  errors,
  formatType,
  theme,
  tooltip,
  width,
  mandatory,
  Icon,
  ...props
}, ref) => {
  return (
    <section className='QM_E-input_wrapper'>
      {Icon && <Icon />}
      <label 
        className={
          classNames(
            'QM_E-input_label',
            `QM_E-input_label__${theme}`
          )
        }
      >
        {mandatory && <span className='QM_E-input_requiredMark'>*</span>}
        {label}
        {tooltip && <QMETooltip text={tooltip} theme={theme} messageType='info' />}
      </label>
      <input
        ref={ref}
        className={classNames(
          'QM_E-input', {
            'QM_E-input__error': !!errors,
            'QM_E-input__withIcon': !!Icon
          }
        )}
        placeholder={placeholder}
        style={{maxWidth: width || 'unset'}}
        {...props}
      />
      {errors && (<QMETooltip text={errors.message} theme={theme} messageType='error' />)}
    </section>
  );
})

export default QMEInput;