import * as yup from "yup";

import jurisdictions from "../../../../helpers/jurisdictions";
import * as validationConst from "../../../../helpers/validationConsts";
import {
  ERROR_EMAIL_FORMAT,
  ERROR_REQUIRED,
} from "../../../auth/pages/validationConsts";

const checkMinValue = (value) => {
  if (value !== null && value !== undefined) {
    return (
      Number(value.replace(validationConst.MONEY_REMOVE_SIGNS, "")) >=
      validationConst.MIN_DEAL_SIZE
    );
  }
  return value;
};

const checkMaxValue = (value) => {
  if (value !== null && value !== undefined) {
    return (
      Number(value.replace(validationConst.MONEY_REMOVE_SIGNS, "")) <=
      validationConst.MAX_DEAL_SIZE
    );
  }
  return value;
};

const validationSchema = yup
  .object({
    buyer_name: yup
      .string()
      .required(validationConst.ERROR_MESSAGE_REQUIRED)
      // .max(validationConst.MAX_NAME, validationConst.ERROR_MESSAGE_MAX_NAME)
      .min(validationConst.MIN_NAME, validationConst.ERROR_MESSAGE_MIN_NAME),
    // .matches(validationConst.LETTERS_NUMBERS_REGEXP, {
    //   message: validationConst.ERROR_MESSAGE_LETTERS_NUMBERS,
    // }),
    buyer_website: yup
      .string()
      .required(validationConst.ERROR_MESSAGE_REQUIRED)
      .matches(validationConst.URL_REGEXP, {
        message: validationConst.ERROR_MESSAGE_URL_FORMAT,
      }),
    buyer_is_subsidiary: yup
      .bool()
      .nullable()
      .required(validationConst.ERROR_MESSAGE_REQUIRED),
    buyer_parent_company_name: yup.string().when("buyer_is_subsidiary", {
      is: true,
      then: yup
        .string()
        .required(validationConst.ERROR_MESSAGE_REQUIRED)
        // .max(validationConst.MAX_NAME, validationConst.ERROR_MESSAGE_MAX_NAME)
        .min(validationConst.MIN_NAME, validationConst.ERROR_MESSAGE_MIN_NAME),
      // .matches(validationConst.LETTERS_NUMBERS_REGEXP, {
      //   message: validationConst.ERROR_MESSAGE_LETTERS_NUMBERS,
      // }),
    }),
    buyer_parent_company_website: yup.string().when("buyer_is_subsidiary", {
      is: true,
      then: yup
        .string()
        .required(validationConst.ERROR_MESSAGE_REQUIRED)
        .matches(validationConst.URL_REGEXP, {
          message: validationConst.ERROR_MESSAGE_URL_FORMAT,
        }),
    }),
    seller_name: yup
      .string()
      .required(validationConst.ERROR_MESSAGE_REQUIRED)
      // .max(validationConst.MAX_NAME, validationConst.ERROR_MESSAGE_MAX_NAME)
      .min(validationConst.MIN_NAME, validationConst.ERROR_MESSAGE_MIN_NAME),
    // .matches(validationConst.LETTERS_NUMBERS_REGEXP, {
    //   message: validationConst.ERROR_MESSAGE_LETTERS_NUMBERS,
    // }),
    seller_website: yup
      .string()
      .required(validationConst.ERROR_MESSAGE_REQUIRED)
      .matches(validationConst.URL_REGEXP, {
        message: validationConst.ERROR_MESSAGE_URL_FORMAT,
      }),
    seller_target: yup
      .string()
      .nullable()
      .required(validationConst.ERROR_MESSAGE_REQUIRED)
      .oneOf(["subsidiary", "seller"]),
    seller_subsidiary_company_name: yup.string().when("seller_target", {
      is: "subsidiary",
      then: yup
        .string()
        .required(validationConst.ERROR_MESSAGE_REQUIRED)
        // .max(validationConst.MAX_NAME, validationConst.ERROR_MESSAGE_MAX_NAME)
        .min(validationConst.MIN_NAME, validationConst.ERROR_MESSAGE_MIN_NAME),
      // .matches(validationConst.LETTERS_NUMBERS_REGEXP, {
      //   message: validationConst.ERROR_MESSAGE_LETTERS_NUMBERS,
      // }),
    }),
    seller_subsidiary_company_website: yup.string().when("seller_target", {
      is: "subsidiary",
      then: yup
        .string()
        .required(validationConst.ERROR_MESSAGE_REQUIRED)
        .matches(validationConst.URL_REGEXP, {
          message: validationConst.ERROR_MESSAGE_URL_FORMAT,
        }),
    }),
    applicant: yup
      .string()
      .nullable()
      .required(validationConst.ERROR_MESSAGE_REQUIRED)
      .oneOf(["buyer", "seller"]),
    closing_date: yup.date().required(validationConst.ERROR_MESSAGE_REQUIRED),
    // .max(
    //   validationConst.MAX_DEAL_CLOSING_DATE,
    //   validationConst.ERROR_MESSAGE_MAX_DATE
    // ),
    deal_size: yup
      .string()
      .required(validationConst.ERROR_MESSAGE_REQUIRED)
      .matches(
        validationConst.MONEY_REGEXP,
        validationConst.ERROR_MESSAGE_MONEY_FORMAT
      )
      .test("deal_size", validationConst.ERROR_MESSAGE_DEAL_SIZE, (value) => {
        if (!!value) {
          return checkMinValue(value);
        }
        return true;
      })
      .test(
        "deal_size",
        validationConst.ERROR_MESSAGE_MAX_DEAL_SIZE,
        (value) => {
          if (!!value) {
            return checkMaxValue(value);
          }
          return true;
        }
      ),
    jurisdiction: yup
      .object()
      .required(validationConst.ERROR_MESSAGE_REQUIRED)
      .nullable()
      .oneOf(jurisdictions),
    deal_name: yup
      .string()
      .required(validationConst.ERROR_MESSAGE_REQUIRED)
      .max(
        validationConst.MAX_DEAL_NAME,
        validationConst.ERROR_MESSAGE_MAX_DEAL_NAME
      )
      .min(validationConst.MIN_NAME, validationConst.ERROR_MESSAGE_MIN_NAME),
    had_coverage_in_past: yup
      .string()
      .nullable()
      .optional()
      .oneOf(["yes", "no", null]),
    integration_lead_present: yup.bool().nullable(),
    integration_lead_first_name: yup.string().when("integration_lead_present", {
      is: true,
      then: yup
        .string()
        .required(validationConst.ERROR_MESSAGE_REQUIRED)
        .max(validationConst.MAX_NAME, validationConst.ERROR_MESSAGE_MAX_NAME)
        .min(validationConst.MIN_NAME, validationConst.ERROR_MESSAGE_MIN_NAME)
        .matches(validationConst.LETTERS_NUMBERS_REGEXP, {
          message: validationConst.ERROR_MESSAGE_LETTERS_NUMBERS,
        }),
    }),
    integration_lead_last_name: yup.string().when("integration_lead_present", {
      is: true,
      then: yup
        .string()
        .required(validationConst.ERROR_MESSAGE_REQUIRED)
        .max(validationConst.MAX_NAME, validationConst.ERROR_MESSAGE_MAX_NAME)
        .min(validationConst.MIN_NAME, validationConst.ERROR_MESSAGE_MIN_NAME)
        .matches(validationConst.LETTERS_NUMBERS_REGEXP, {
          message: validationConst.ERROR_MESSAGE_LETTERS_NUMBERS,
        }),
    }),
    integration_lead_email: yup.string().when("integration_lead_present", {
      is: true,
      then: yup.string().required(ERROR_REQUIRED).email(ERROR_EMAIL_FORMAT),
    }),
    external_data_room_count: yup
      .string()
      .nullable()
      .oneOf(["0", "1", "2", null]),
    external_data_room_url_first: yup
      .string()
      .nullable()
      .when("external_data_room_count", {
        is: "1",
        then: yup
          .string()
          .required(validationConst.ERROR_MESSAGE_REQUIRED)
          .matches(validationConst.URL_REGEXP, {
            message: validationConst.ERROR_MESSAGE_URL_FORMAT,
          }),
      })
      .when("external_data_room_count", {
        is: "2",
        then: yup
          .string()
          .required(validationConst.ERROR_MESSAGE_REQUIRED)
          .matches(validationConst.URL_REGEXP, {
            message: validationConst.ERROR_MESSAGE_URL_FORMAT,
          }),
      }),
    external_data_room_url_second: yup
      .string()
      .nullable()
      .when("external_data_room_count", {
        is: "2",
        then: yup
          .string()
          .required(validationConst.ERROR_MESSAGE_REQUIRED)
          .matches(validationConst.URL_REGEXP, {
            message: validationConst.ERROR_MESSAGE_URL_FORMAT,
          }),
      }),
    organization_chart_file_present: yup.bool().nullable(),
    organization_chart_file: yup
      .mixed()
      .when("organization_chart_file_present", {
        is: true,
        then: yup.mixed().required(validationConst.ERROR_MESSAGE_REQUIRED),
      }),
    sales_purchase_agreement_file_present: yup.bool().nullable(),
    sales_purchase_agreement_file: yup
      .mixed()
      .when("sales_purchase_agreement_file_present", {
        is: true,
        then: yup.mixed().required(validationConst.ERROR_MESSAGE_REQUIRED),
      }),
  })
  .required();

export default validationSchema;
