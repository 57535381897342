import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import QMGlobalLoader from '../common/QMLoader';
import QMHeader from '../landing/QMHeader';
import EstimatorChart from './EstimatorChart';
import EstimatorForm from './EstimatorForm';
import EstimatorHeader from './EstimatorHeader';

import './styles.scss';

const Estimator = () => {
  const isEstimationLoading = useSelector(state => state.estimator.isEstimationLoading);
  const isPdfLoading = useSelector(state => state.estimator.isPdfLoading);

  useEffect(() => {
    if (isEstimationLoading || isPdfLoading) {
      document.querySelector('html').classList.add('scroll-lock');
    } else {
      document.querySelector('html').classList.remove('scroll-lock');
    }
  }, [isEstimationLoading, isPdfLoading])

  return (
      <section className='QM_E-wrapper'>
        <section className='QM_E-centering'>
          <QMHeader navClassName='QM_E-centering' />
          <EstimatorHeader />
          <section className='QM_E-content'>
            <EstimatorForm />
            <EstimatorChart />
          </section>
        </section>
        {
          (isEstimationLoading || isPdfLoading)
          && 
          <QMGlobalLoader />
        }
      </section>
  )
}

export default Estimator;
