import React from "react";
import { useTranslation } from "react-i18next";
import QMButton from "../../../common/QMButton";

const RemoveUserConfirmModal = ({ userData, handleClose, handleConfirm }) => {
  const { t } = useTranslation();

  return (
    <section className="assign-lawyer-manage-access__wrapper">
      <section className="assign-lawyer-manage-access">
        <header className="assign-lawyer-manage-access__header assign-lawyer-revoke-access__header">
          <h2 className="deal-page-modal__title">
            {`${t("assign_lawyer.remove_assignee")} ${
              userData?.attorney_name || userData?.attorney_email
            }`}
          </h2>
          <QMButton
            viewType="transparent"
            iconClassName={`icon-x`}
            customClassName={`QM-btn__close-text`}
            onClick={(e) => {
              e.stopPropagation();
              handleClose();
            }}
          />
        </header>
        <section className="deal-page-modal__body">
          <p className="deal-page-modal__text">
            {t("assign_lawyer.remove_assignee_confirm_text1")}
          </p>
          <p className="deal-page-modal__text">
            {t("assign_lawyer.remove_assignee_confirm_text2")}
          </p>
        </section>
        <footer className="deal-page-modal__footer">
          <QMButton
            text={t("general.cancel")}
            viewType="secondary"
            type="cancel"
            customClassName="tabs-submit__btn"
            onClick={handleClose}
          />
          <QMButton
            text={t("general.confirm")}
            type="button"
            customClassName="tabs-submit__btn"
            onClick={handleConfirm}
          />
        </footer>
      </section>
    </section>
  );
};

export default RemoveUserConfirmModal;
