import React, { forwardRef } from "react";
import NumberFormat from "react-number-format";
import classNames from "classnames";

import QMInputWrapper from "./QMInputWrapper";

const QMCurrencyInput = forwardRef(
  (
    { label, isRequired, errors, iconClassName, placeholder, ...props },
    ref
  ) => {
    return (
      <QMInputWrapper
        label={label}
        isRequired={isRequired}
        errors={errors}
        iconClassName={iconClassName}
      >
        <NumberFormat
          ref={ref}
          className={classNames("QM-input", {
            "QM-input__error": !!errors,
          })}
          placeholder={placeholder}
          thousandSeparator={true}
          prefix={"$"}
          //decimalScale={2}
          fixedDecimalScale
          allowNegative={false}
          {...props}
        />
      </QMInputWrapper>
    );
  }
);

export default QMCurrencyInput;
