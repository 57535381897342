import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  exposureData: null,
};

export const exposureReducer = createSlice({
  name: "exposure",
  initialState,
  reducers: {
    setExposureData: (state, action) => {
      state.exposureData = action.payload;
    },
  },
});

export const { setExposureData } = exposureReducer.actions;

export default exposureReducer.reducer;
