import React, { forwardRef } from "react";
import classNames from "classnames";
import QMInputWrapper from "./QMInputWrapper";

const QMTextInput = forwardRef(
  (
    {
      label,
      isRequired,
      errors,
      iconClassName,
      placeholder,
      wrapperClassName,
      ...props
    },
    ref
  ) => {
    return (
      <QMInputWrapper
        label={label}
        isRequired={isRequired}
        errors={errors}
        iconClassName={iconClassName}
        customClassName={wrapperClassName}
      >
        <input
          ref={ref}
          className={classNames("QM-input", {
            "QM-input__error": !!errors,
            "QM-input__withIcon": !!iconClassName,
          })}
          placeholder={placeholder}
          {...props}
        />
      </QMInputWrapper>
    );
  }
);

export default QMTextInput;
