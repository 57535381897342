import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dealId: null,
  isLoadingDueDilligence: false,
  dueDilligenceInfo: {},
  dueDilligenceError: null,
};

export const dueDilligenceTabReducer = createSlice({
  name: "dueDilligenceTab",
  initialState,
  reducers: {
    setDealId: (state, action) => {
      state.dealId = action.payload;
    },
    setIsLoadingDueDilligence: (state, action) => {
      state.isLoadingDueDilligence = action.payload;
    },
    setDueDilligenceInfo: (state, action) => {
      state.dueDilligenceInfo = action.payload;
    },
    setDueDilligenceError: (state, action) => {
      state.dueDilligenceError = action.payload;
    },
  },
});
export const {
  setDealId,
  setIsLoadingDueDilligence,
  setDueDilligenceInfo,
  setDueDilligenceError,
} = dueDilligenceTabReducer.actions;

export default dueDilligenceTabReducer.reducer;
