import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import QMGlobalLoader from "../../../common/QMLoader";
import RiskProfileContainer from "./RiskProfileContainer";
import { getRiskProfileInfo } from "../../../../api/risk-profileApi";
import { riskProfileLoadingSelector } from "../../../../redux/selectors/riskProfile-selector";

import "./styles.scss";
import { setDealError } from "../../../../redux/reducers/dealPage-reducer";
import { setIsLoadingRiskProfile } from "../../../../redux/reducers/riskProfile-reducer";
import { useTranslation } from "react-i18next";

const RiskProfile = () => {
  const { t } = useTranslation(); 
  const isLoading = useSelector(riskProfileLoadingSelector);
  const dispatch = useDispatch();
  let { dealId } = useParams();

  useEffect(() => {
    getRiskProfileInfo(dispatch, dealId).catch(err => {
      dispatch(setDealError(t("errors.server_general")));
      dispatch(setIsLoadingRiskProfile(false));
    });
  }, [dispatch, dealId, t]);

  return (
    <>
      <RiskProfileContainer />
      {isLoading && <QMGlobalLoader />}
    </>
  );
};
export default RiskProfile;
