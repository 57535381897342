import React, { useEffect } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { QMTextInput, QMPhoneNumberInput } from "../../../common/QMInputs";
import QMCheckbox from "../../../common/QMCheckbox";
import { getTokenData } from "../../../../../helpers/auth-helpers";
import * as validation from "./validationRules";

import "./styles.scss";

const FieldsetPCBroker = ({
  legendTitle,
  register,
  control,
  watch,
  groupName,
  errors,
  legendText,
  setValue,
}) => {
  const { t } = useTranslation();
  const notSamePerson = watch(`${groupName}.r_w_broker_not_equal`);
  const companyName = watch(`${groupName}.company_name`);
  const contactName = watch(`${groupName}.contact_name`);
  const contactPhone = watch(`${groupName}.contact_phone`);
  const contactEmail = watch(`${groupName}.contact_email`);
  const userData = getTokenData();

  useEffect(() => {
    if (notSamePerson) {
      companyName === userData?.company_name &&
        setValue(`${groupName}.company_name`, "");
      contactName === `${userData?.first_name} ${userData?.last_name}` &&
        setValue(`${groupName}.contact_name`, "");
      contactPhone === userData?.phone_number &&
        setValue(`${groupName}.contact_phone`, "");
      contactEmail === userData?.email &&
        setValue(`${groupName}.contact_email`, "");
    } else {
      setValue(`${groupName}.company_name`, userData?.company_name);
      setValue(
        `${groupName}.contact_name`,
        `${userData?.first_name} ${userData?.last_name}`
      );
      setValue(`${groupName}.contact_phone`, userData?.phone_number || "");
      setValue(`${groupName}.contact_email`, userData?.email);
    }
  }, [notSamePerson, groupName, setValue]); // eslint-disable-line
  return (
    <fieldset className="QM-formBlock">
      <legend>
        <p>{legendTitle}</p>
      </legend>
      <div className="QM-formBlock_p-c-broker-block">
        <legend className="QM-formBlock_p-c-broker">{legendText}</legend>
        <div className="QM-formBlock_checkbox">
          <QMCheckbox {...register(`${groupName}.r_w_broker_not_equal`)} />
          <span> {t("general.yes")}</span>
        </div>
      </div>

      <div className="QM-formBlock_row">
        <QMTextInput
          {...register(
            `${groupName}.company_name`,
            validation.companyValidation
          )}
          label={t("contact.company_name")}
          placeholder={t("contact.company_name_placeholder")}
          errors={errors?.[groupName]?.company_name}
          disabled={!notSamePerson}
        />
        <QMTextInput
          {...register(`${groupName}.contact_name`, validation.nameValidation)}
          label={t("contact.contact_name")}
          placeholder={t("contact.contact_name_placeholder")}
          errors={errors?.[groupName]?.contact_name}
          disabled={!notSamePerson}
        />
      </div>
      <div className="QM-formBlock_row">
        <Controller
          render={({ field }) => (
            <QMPhoneNumberInput
              {...field}
              label={t("contact.contact_phone")}
              placeholder={t("general.phone_placeholder")}
              errors={errors?.[groupName]?.contact_phone}
              disabled={!notSamePerson}
            />
          )}
          control={control}
          name={`${groupName}.contact_phone`}
          rules={validation.phoneValidation}
        />
        <QMTextInput
          {...register(
            `${groupName}.contact_email`,
            validation.emailValidation
          )}
          label={t("contact.contact_email")}
          placeholder={t("general.email_placeholder")}
          errors={errors?.[groupName]?.contact_email}
          disabled={!notSamePerson}
        />
      </div>
    </fieldset>
  );
};

export default FieldsetPCBroker;
