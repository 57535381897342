import React from "react";
import { components } from "react-select";

export const Option = (props) => {
  const color = {
    backgroundColor: props.data.color,
    width: "12px",
    height: "12px",
    display: "inline-block",
    borderRadius: "50%",
    marginRight: "10px",
    paddingTop: "4px",
  };

  return (
    <div>
      <components.Option {...props}>
        <span style={color}></span>
        <span>{props.label}</span>
      </components.Option>
    </div>
  );
};
