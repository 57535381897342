import React from "react";
import PropTypes from "prop-types";
import Tippy from "@tippyjs/react";
import classNames from "classnames";

import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import "./styles.scss";

import { InfoIcon, AlertIcon } from "../../../../assets/imgs";

const QMToolTip = ({ text, theme, messageType }) => {
  const getIcon = () => {
    switch (messageType) {
      case "info":
        return (
          <InfoIcon
            className={classNames(
              "QM_tooltip_trigger",
              `QM_tooltip_trigger__${theme}`,
              `QM_tooltip_trigger__${messageType}`
            )}
          />
        );
      case "error":
        return (
          <AlertIcon
            className={classNames(
              "QM_tooltip_trigger",
              `QM_tooltip_trigger__${messageType}`
            )}
          />
        );
      default:
        return null;
    }
  };
  return (
    <Tippy
      content={
        <section
          className={classNames(
            "QM_tooltip_content",
            `QM_tooltip_content__${messageType}`
          )}
        >
          {text}
        </section>
      }
      theme="light"
      interactive
      placement={messageType === "error" ? "top-end" : "top"}
    >
      {getIcon()}
    </Tippy>
  );
};

QMToolTip.propTypes = {
  text: PropTypes.node.isRequired,
  theme: PropTypes.oneOf(["dark", "light"]),
  messageType: PropTypes.oneOf(["info", "error", "warning"]),
};

QMToolTip.defaultProps = {
  // text: '',
  theme: "light",
  messageType: "info",
};

export default QMToolTip;
