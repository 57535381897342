import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import AuthInput from "../common/AuthInput/AuthInput";
import AuthPageWrapper from "../common/AuthPageWrapper";
import {
  ERROR_NO_INVITATION_TOKEN,
  ERROR_NO_RESET_TOKEN,
  ERROR_PASSWORD_CONFIRM_NOT_MATCH,
  ERROR_PASSWORD_FORMAT,
  ERROR_REQUIRED,
  REG_EXP_PASSWORD,
} from "./validationConsts";
import { createNewPassword, signIn } from "../../../api/authApi";
import { saveTokenData } from "../../../helpers/auth-helpers";

const validationSchema = yup
  .object({
    password: yup
      .string()
      .required(ERROR_REQUIRED)
      .min(8)
      .matches(REG_EXP_PASSWORD, ERROR_PASSWORD_FORMAT),
    confirm_password: yup
      .string()
      .required(ERROR_REQUIRED)
      .oneOf([yup.ref("password")], ERROR_PASSWORD_CONFIRM_NOT_MATCH),
  })
  .required();

const SetPassword = ({ isRecovery }) => {
  const navigate = useNavigate();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isPassword2Visible, setIsPassword2Visible] = useState(false);

  const [searchParams] = useSearchParams();
  const queryObj = Object.fromEntries([...searchParams]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    defaultValues: {
      password: "",
      confirm_password: "",
    },
  });

  const onSubmit = (data) => {
    if (
      (isRecovery && (!queryObj.email || !queryObj.reset_token)) ||
      (!isRecovery && (!queryObj.email || !queryObj.invitation_token))
    ) {
      console.log("missing vital data");
      setError("confirm_password", {
        message: isRecovery ? ERROR_NO_RESET_TOKEN : ERROR_NO_INVITATION_TOKEN,
      });
      return;
    }
    const reqData = {
      new_password: data.password,
      email: queryObj.email,
      token: isRecovery ? queryObj.reset_token : queryObj.invitation_token,
    };

    createNewPassword(reqData)
      .then((res) => {
        if (res.status === 200) {
          return res;
        } else {
          return res.json();
        }
      })
      .then((respData) => {
        if (respData?.errors) {
          setError("password", { message: respData.errors[0].message });
          throw new Error("Password creation failed");
        } else {
          return signIn({ email: queryObj.email, password: data.password });
        }
      })
      .then((res) => {
        if (res.status === 200) {
          saveTokenData(JSON.stringify(res.data));
          // TODO: navigate to portal according to role
          navigate("/broker");
        } else {
          setError("password", { message: res.data.errors[0].message });
        }
      })
      .catch((err) => console.error(err));
  };

  return (
    <AuthPageWrapper>
      <form onSubmit={handleSubmit(onSubmit)} className="user-auth__form">
        <h1>{isRecovery ? "New Password" : "Set Password"}</h1>
        <ul>
          <AuthInput
            register={register}
            name="password"
            type={isPasswordVisible ? "text" : "password"}
            withIconBtn
            iconBtnHandler={() => {
              setIsPasswordVisible(!isPasswordVisible);
            }}
            iconClassName={isPasswordVisible ? "icon-eye-off" : "icon-eye"}
            iconBtnTitle="Show password"
            title={isRecovery ? "New password" : "Password"}
            errors={errors.password}
          />
          <AuthInput
            register={register}
            name="confirm_password"
            type={isPassword2Visible ? "text" : "password"}
            withIconBtn
            iconBtnHandler={() => {
              setIsPassword2Visible(!isPassword2Visible);
            }}
            iconClassName={isPassword2Visible ? "icon-eye-off" : "icon-eye"}
            iconBtnTitle="Show password"
            title="Confirm password"
            errors={errors.confirm_password}
          />
        </ul>
        <button type="submit" className="user-auth__btn">
          Submit
        </button>
      </form>
    </AuthPageWrapper>
  );
};

export default SetPassword;
