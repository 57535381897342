import { useEffect } from "react";

import { decodeToken, getTokenData } from "./auth-helpers";

const useTokenRefresh = (refreshFunc, expirationTime = 600) => {
  useEffect(() => {
    // let timerId;

    // const tokenData = getTokenData();
    // const token = tokenData?.access_token;
    // console.log("token in hook", token);
    // if (token) {
    //   const exp = decodeToken(token);
    //   const timeToExpiration = exp * 1000 - Date.now() - expirationTime * 1000;
    //   if (timeToExpiration < 0) {
    //     refreshFunc();
    //   } else {
    //     timerId = setTimeout(() => {
    //       refreshFunc();
    //     }, timeToExpiration);
    //   }
    // }

    // return () => {
    //   clearTimeout(timerId);
    // };
    // console.log("outer refresh token useEffect");
    let intervalId;
    const manageRefresh = () => {
      // console.log("running token refresh check...");
      const tokenData = getTokenData();
      const token = tokenData?.access_token;

      if (token) {
        const exp = decodeToken(token);
        const expiresIn = exp - Date.now() / 1000;
        // console.log("Expires in", expiresIn);
        if (expiresIn < 120) {
          refreshFunc();
        }
      }
    };
    manageRefresh();
    intervalId = setInterval(manageRefresh, 120000);
    return () => clearInterval(intervalId);
  }, [refreshFunc, expirationTime]);
};

export default useTokenRefresh;
