import React from 'react';
import QMLogo from '../../../common/QMLogo';

import './styles.scss'

const AuthPageWrapper = ({children}) => {
  return (
    <main className="user-auth">
      <QMLogo />
      <section className='user-auth__form-wrapper'>
        {children}
      </section>
    </main>
  );
}

export default AuthPageWrapper;