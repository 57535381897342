import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Routes,
  Route,
  useLocation,
  // Navigate
} from "react-router-dom";

import LandingPage from "./components/landing/LandingPage";
import Estimator from "./components/estimator/Estimator";
// import BrokersList from './components/brokersList/BrokersList';
// import UWPortal from './components/dashboards/UWPortal/UWPortal';

// import UWRiskDashboard from './components/dashboards/UWPortal/UWRiskDashboard';
// import UWOverview from './components/dashboards/UWPortal/UWOverview';
// import UWProbabilityOfIncidents from './components/dashboards/UWPortal/UWProbabilityOfIncidents';

// import UWCompare from './components/dashboards/UWPortal/UWCompare';
// import UWSearches from './components/dashboards/UWPortal/UWSearches';

import { LogIn, PasswordRecovery, SetPassword } from "./components/auth";
import BrokerPortal from "./components/dashboards/BrokerPortal/BrokerPortal";
import DealsAdminPortal from "./components/dashboards/DealsAdminPortal/DealsAdminPortal";
import LawyerPortal from "./components/dashboards/LawyerPortal/LawyerPortal";
// import DealPage from "./components/dashboards/DealPage/DealPage";

import ScrollToTop from "./helpers/ScrollToTopWrapper";
import ProtectedRoute from "./helpers/ProtectedRoute";
import { getRedirectPath, getTokenData, roles } from "./helpers/auth-helpers";
import useTokenRefresh from "./helpers/useTokenRefresh";
import { setDealsError } from "./redux/reducers/deals-reducer";
import { refreshToken } from "./api/authApi";

import "./App.scss";

function App() {
  let location = useLocation();
  const dispatch = useDispatch();
  const [logged, setIsLogged] = useState(null);

  useTokenRefresh(refreshToken);

  const user = getTokenData();

  useEffect(() => {
    dispatch(setDealsError(null));
  }, [dispatch, location.pathname]);

  useEffect(() => {
    (async () => {
      const session = await getTokenData();
      if (session) {
        setIsLogged(true);
      } else {
        setIsLogged(false);
      }
    })();
  }, [location.pathname]);

  if (logged === null) {
    return null;
  }

  return (
    <section className="qm-app">
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/estimator" element={<Estimator />} />
          <Route
            path="/sign-in"
            element={
              <ProtectedRoute
                redirectTo={getRedirectPath(user)}
                redirectCondition={logged && user}
                shouldSaveQuery={user?.role === roles.PARTNER}
              >
                <LogIn />
              </ProtectedRoute>
            }
          />
          <Route
            path="/password-recovery"
            element={
              <ProtectedRoute
                redirectTo={getRedirectPath(user)}
                redirectCondition={logged && user}
              >
                <PasswordRecovery />
              </ProtectedRoute>
            }
          />
          <Route
            path="/auth/set-password"
            element={
              <ProtectedRoute
                redirectTo={getRedirectPath(user)}
                redirectCondition={logged && user}
              >
                <SetPassword />
              </ProtectedRoute>
            }
          />
          <Route
            path="/auth/new-password"
            element={
              <ProtectedRoute
                redirectTo={getRedirectPath(user)}
                redirectCondition={logged && user}
              >
                <SetPassword isRecovery />
              </ProtectedRoute>
            }
          />
          {/* TODO: Uncomment when functionality is ready */}
          {/* <Route path='/find-broker' element={<BrokersList />}/>
            <Route path='/uw-portal' element={<UWPortal />}>
              <Route index element={<Navigate to='risk-dashboard' />} />
              <Route path='risk-dashboard' element={<UWRiskDashboard />}>
                <Route index element={<UWOverview />}/>
                <Route path='exposure-signals' element={<p>Exposure Signals</p>} />
                <Route path='incidents-probability' element={<UWProbabilityOfIncidents/>} />
                <Route path='loss-analysis' element={<p>Loss Analysis</p>} />
                <Route path='assets' element={<p>Company's Assets</p>} />
              </Route>
              <Route path='compare' element={<UWCompare /> }/>
              <Route path='searches' element={<UWSearches /> }/>
            </Route> */}
          <Route
            path="/broker/*"
            element={
              <ProtectedRoute
                redirectTo="/sign-in"
                redirectCondition={!logged || user?.role !== roles.RW_BROKER}
              >
                <BrokerPortal />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/*"
            element={
              <ProtectedRoute redirectTo="/sign-in" redirectCondition={!logged}>
                <DealsAdminPortal />
              </ProtectedRoute>
            }
          />
          <Route
            path="/partner/*"
            element={
              <ProtectedRoute
                redirectTo="/sign-in"
                redirectCondition={!logged || user?.role !== roles.PARTNER}
                shouldSaveQuery
              >
                <LawyerPortal />
              </ProtectedRoute>
            }
          />
          {/* <Route
              path="/deals/:dealId/*"
              element={
                <ProtectedRoute
                  redirectTo="/sign-in"
                  redirectCondition={!logged}
                >
                  <DealPage />
                </ProtectedRoute>
              }
            /> */}
        </Routes>
      </ScrollToTop>
    </section>
  );
}

export default App;
