import React, { useState } from "react";
import PropTypes from "prop-types";
import Tippy from "@tippyjs/react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { reformatTimestamp } from "../../../../helpers";
import QMButton from "../QMButton";
import QMModal from "../QMModal";

import "./styles.scss";
import QMKeyValueTextBlock from "../QMKeyValueTextBlock/QMKeyValueTextBlock";

const QMFileItem = ({
  document_id,
  original_filename,
  created_at,
  file_size,
  handleFileDelete,
  handleViewFile,
  isDeletePresent,
}) => {
  const { t } = useTranslation();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const handleDelete = () => {
    setIsDeleteModalOpen(false);
    handleFileDelete(document_id);
  };
  const handleView = () => handleViewFile(document_id);
  const createdDate = reformatTimestamp(created_at);

  const checkFileNameLength = (file) => {
    if (file?.length <= 15) {
      return "QM-treeItem_row_small QM-fileItem_row";
    }
    if (file?.length <= 55) {
      return "QM-treeItem_row_big QM-fileItem_row";
    }
    return "QM-treeItem_row QM-fileItem_row";
  };

  return (
    <section className="QM-treeItem QM-fileItem">
      <Tippy
        content={
          <section
            className={classNames(
              "QM_tooltip_content",
              `QM_tooltip_content__info`
            )}
          >
            <QMKeyValueTextBlock
              keyName="File name:"
              value={original_filename}
            />
            <QMKeyValueTextBlock keyName="Size:" value={file_size} />
            <QMKeyValueTextBlock
              keyName="Uploaded:"
              value={`${createdDate.date} ${createdDate.time}`}
            />
          </section>
        }
        theme="light"
        interactive
        placement="top"
      >
        <section className={checkFileNameLength(original_filename)}>
          <section
            className="QM-treeItem_row_main QM-fileItem_row_main"
            onClick={handleView}
          >
            <span className="icon-file-text" />
            <p>{original_filename}</p>
          </section>
          <section className="QM-fileItem_row_actionsContainer">
            {isDeletePresent && (
              <QMButton
                viewType="transparent"
                iconClassName="icon-trash"
                onClick={() => setIsDeleteModalOpen(true)}
              />
            )}
          </section>
        </section>
      </Tippy>
      {isDeleteModalOpen && (
        <QMModal size="small" customClassName="fileDelete-modal">
          <h2>{`${t("general.delete")} ${original_filename}?`}</h2>
          <p>{t("documents.delete_file_explanation")}</p>
          <section className="fileDelete-modal_btnsContainer">
            <QMButton
              viewType="secondary"
              onClick={() => setIsDeleteModalOpen(false)}
              text={t("general.cancel")}
            />
            <QMButton
              onClick={handleDelete}
              text={t("documents.delete_file_confirm")}
            />
          </section>
        </QMModal>
      )}
    </section>
  );
};

QMFileItem.propTypes = {
  document_id: PropTypes.string.isRequired,
  original_filename: PropTypes.string.isRequired,
  created_at: PropTypes.string.isRequired,
  file_size: PropTypes.string.isRequired,
  handleFileDelete: PropTypes.func,
  handleViewFile: PropTypes.func,
  isDeletePresent: PropTypes.bool,
};

QMFileItem.defaultProps = {
  handleFileDelete: () => {},
  handleViewFile: () => {},
  isDeletePresent: true,
};
export default QMFileItem;
