import { authFetch, post } from "./fetchConfig";
import {
  getTokenData,
  removeTokenData,
  saveTokenData,
} from "../helpers/auth-helpers";

const signIn = (data) => {
  return post(`${process.env.REACT_APP_BASE_API_URL}/users/login`, data);
};

const createNewPassword = async (data) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_API_URL}/users/reset-password`,
    {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(data),
    }
  );
  return response;
};

const requestPasswordReset = (data) => {
  return post(
    `${process.env.REACT_APP_BASE_API_URL}/users/request-reset-password`,
    data
  );
};

const logOut = () => {
  const { access_token } = getTokenData();
  authFetch(`${process.env.REACT_APP_BASE_API_URL}/users/logout`, {
    method: "POST",
    headers: { "Content-type": "application/json" },
    body: JSON.stringify({ Authorization: access_token }),
  })
    .then((r) => {
      if (!r.ok) {
        return r.json();
      }
    })
    .then(() => {
      removeTokenData();
    })
    .catch((err) => console.error(err));
};

const refreshToken = async () => {
  const session = getTokenData();
  if (session?.refresh_token) {
    return fetch(`${process.env.REACT_APP_BASE_API_URL}/users/refresh-token`, {
      method: "POST",
      body: JSON.stringify({ Authorization: session.refresh_token }),
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${session.refresh_token}`,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        // console.log("refresh response parsed", res);
        if (res.access_token) {
          saveTokenData(JSON.stringify(res));
        } else {
          removeTokenData();
        }
      })
      .catch(() => {
        removeTokenData();
      });
  } else {
    removeTokenData();
  }
};

export {
  signIn,
  logOut,
  createNewPassword,
  requestPasswordReset,
  refreshToken,
};
