import React from "react";
import { useTranslation } from "react-i18next";

import QMModal from "../../../../common/QMModal";
import QMButton from "../../../../common/QMButton";

const NoAccessModal = ({ dealId, dealName, setModalOpen, openAcceptModal }) => {
  const { t } = useTranslation();
  const handleClose = (e) => {
    e.preventDefault();
    setModalOpen(false);
  };

  const handleAccept = (e) => {
    e.preventDefault();
    openAcceptModal(true);
    setModalOpen(false);
  };
  return (
    <QMModal customClassName="QM-manageInvitationModal">
      <header>
        <h2>{`${t("general.no_access")}`}</h2>
        <QMButton
          viewType="transparent"
          iconClassName="icon-x"
          onClick={handleClose}
        />
      </header>
      <section className="QM-manageInvitationModal_body">
        <p className="QM-manageInvitationModal_body_text">
          {t("partner.no_access_notice")}
        </p>
      </section>
      <footer>
        <QMButton
          viewType="secondary"
          onClick={handleClose}
          type="button"
          text={t("general.cancel")}
        />
        <QMButton onClick={handleAccept} text={t("general.accept")} />
      </footer>
    </QMModal>
  );
};

export default NoAccessModal;
