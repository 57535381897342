import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import './styles.scss';


const QMLogo = ({ darkMode }) => {
  const logoClasses = classNames({
    'QM-logo': true,
    'QM-logo__dark': darkMode
  })
  return (
      <NavLink 
        to="/" 
        id="logo"
        className={logoClasses}
        title="QuantuMerge"
      >
        QuantuMerge
      </NavLink>
  )
};

QMLogo.propTypes = {
  darkMode: PropTypes.bool,
};

QMLogo.defaultProps = {
  darkMode: false,
};

export default QMLogo;
