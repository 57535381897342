const backgroundGenerator = (value) => {
  if (value === "Active deal") {
    return "#00A6A9";
  }
  if (value === "Inactive deal") {
    return "#C9D1D9";
  }
};

const backgroundActiveGenerator = (value) => {
  if (value === "Active deal") {
    return "#007375";
  }
  if (value === "Inactive deal") {
    return "#818E9C";
  }
};

const reactSelectStylesDealConfig = () => ({
  control: (provided, state) => {
    const label = state.selectProps?.value?.label;

    return {
      ...provided,
      width: "150px",
      backgroundColor: state.selectProps.menuIsOpen
        ? backgroundActiveGenerator(label)
        : backgroundGenerator(label),
      borderRadius: "4px",
      cursor: "pointer",
      boxShadow: "none",
      paddingLeft: "10px",
      outline: "none",
      marginTop: "5px",
      ":hover": {
        backgroundColor: backgroundActiveGenerator(label),
      },
    };
  },
  input: (provided) => ({
    ...provided,
    color: "#1A1A36",
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "20px",
    cursor: "pointer",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#FFFFFF",
    display: "block",
    fontSize: "20px",
    paddingRight: "10px",
    paddingTop: "11px",
    transition: "all 0.2s ease",
    transform:
      state.selectProps.menuIsOpen && "rotate(180deg) translate(5px, 2px)",
    ":hover": {
      color: "#FFFFFF",
    },
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    color: "#1A1A36",
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: "300",
    fontStyle: "italic",
    lineHeight: "19px",
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: "200px",
    paddingTop: 0,
    paddingBottom: 0,

    "::-webkit-scrollbar": {
      width: "6px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#DDF1FF",
      borderRadius: "8px",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#A1B4C6",
      borderRadius: "8px",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#3b6185",
    },
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: "0",
  }),

  option: (provided, state) => ({
    ...provided,
    color: "#1A1A36",
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "20px",
    paddingTop: "10px",
    cursor: "pointer",
    height: "40px",

    backgroundColor: state.isSelected ? "#A6E0F2" : "#FFF",
    ":hover": {
      backgroundColor: state.isSelected ? "#A6E0F2" : "#E9F7FC",
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#FFFFFF",
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "20px",
    display: "flex",
    "> svg": {
      marginRight: "8px",
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#FFFFFF",
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "19px",
    overflow: "visible",
  }),
});

export default reactSelectStylesDealConfig;
