import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isEstimationLoading: false,
  isCompaniesLoading: false,
  isWebsitesLoading: false,
  isPdfLoading: false,
  pdfError: null,
  companiesList: [],
  websitesList: [],
  estimation: {
    activeBundle: 0,
    firstBundle: 0,
    lastBundle: 0,
    record_id: null,
    underlying_insurances: [],
    bundles: []
  },
}


export const estimatorReducer = createSlice({
  name: 'estimator',
  initialState,
  reducers: {
    setIsCompaniesLoading: (state, action) => {
      state.isCompaniesLoading = action.payload
    },
    setCompanies: (state, action) => {
      state.companiesList = action.payload
    },
    addCompany: (state, action) => {
      state.companiesList = [...state.companiesList, action.payload]
    },

    setIsWebsitesLoading: (state, action) => {
      state.isWebsitesLoading = action.payload
    },
    setWebsites: (state, action) => {
      state.websitesList = action.payload
    },
    addWebsite: (state, action) => {
      state.websitesList = [...state.websitesList, action.payload]
    },

    setIsEstimationLoading: (state, action) => {
      state.isEstimationLoading = action.payload
    },
    setEstimationBundles: (state, action) => {
      const { bundles, underlying_insurances, record_id} = action.payload;
      let notActiveIndexes = bundles.map((bundle, index) => {
        if(bundle.current) return undefined;
        return index
      }).filter(item => item === 0 || item);

      state.estimation = {
        activeBundle: bundles.findIndex(el => Boolean(el.current)),
        firstBundle: notActiveIndexes[0],
        lastBundle: notActiveIndexes[notActiveIndexes.length-1],
        record_id,
        bundles,
        underlying_insurances
      }
    },
    setBundlesOrder: (state, action) => {
      let {activeBundle, lastBundle, firstBundle} = state.estimation;

      if(action.payload === lastBundle) {
        state.estimation.lastBundle = firstBundle;
        state.estimation.firstBundle = activeBundle;
      } else if (action.payload === firstBundle) {
        state.estimation.firstBundle = lastBundle;
        state.estimation.lastBundle = activeBundle;
      }

      state.estimation.activeBundle = action.payload;
    },
    resetBundles: (state) => {
      state.estimation = initialState.estimation;
    },
    setIsPdfLoading: (state, action) => {
      state.isPdfLoading = action.payload
    },
    setPdfError: (state, action) => {
      state.pdfError = action.payload
    },
  }
})

export const {
  setIsCompaniesLoading,
  setCompanies,
  addCompany,

  setIsWebsitesLoading,
  setWebsites,
  addWebsite,

  setIsEstimationLoading,
  setEstimationBundles,
  setBundlesOrder,
  resetBundles,

  setIsPdfLoading,
  setPdfError,
} = estimatorReducer.actions;

export default estimatorReducer.reducer;
