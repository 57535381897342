import React from "react";
import { useTranslation } from "react-i18next";

import { QMTextInput } from "../../../common/QMInputs";

import "./styles.scss";

const InsuranceRWDuration = ({
  retention_number,
  register,
  getFieldErrors,
  insurance_policy,
  general_representations,
  fundamental_representations,
  tax_representations,
  errors,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <h2 className="rw-insurance__duration-title">
        {t("insurance.duration_representations_warranties")}
        {retention_number}
      </h2>
      <section className="rw-insurance__duration-list">
        <QMTextInput
          {...register(`coverage_duration.${insurance_policy}`)}
          label={t("insurance.rw_insurance_policy_coverage")}
          placeholder={t("insurance.rw_insurance_policy_coverage_placeholder")}
          errors={errors?.coverage_duration?.insurance_policy}
        />
        <p className="rw-insurance__duration-text">
          {t("insurance.rw_insurance_policy_coverage_desc")}
        </p>
      </section>
      <section className="rw-insurance__duration-list">
        <QMTextInput
          {...register(`coverage_duration.${general_representations}`)}
          label={t("insurance.general_representations")}
          placeholder={t("insurance.general_representations_placeholder")}
          errors={errors?.coverage_duration?.general_representations}
        />
        <p className="rw-insurance__duration-text">
          {t("insurance.general_representations_desc")}
        </p>
      </section>
      <section className="rw-insurance__duration-list">
        <QMTextInput
          {...register(`coverage_duration.${fundamental_representations}`)}
          label={t("insurance.fundamental_representations")}
          placeholder={t("insurance.fundamental_representations_placeholder")}
          errors={errors?.coverage_duration?.fundamental_representations}
        />
        <p className="rw-insurance__duration-text">
          {t("insurance.fundamental_representations_desc")}
        </p>
      </section>
      <section className="rw-insurance__duration-list">
        <QMTextInput
          {...register(`coverage_duration.${tax_representations}`)}
          label={t("insurance.tax_representations")}
          placeholder={t("insurance.tax_representations_placeholder")}
          errors={errors?.coverage_duration?.tax_representations}
        />
      </section>
    </>
  );
};

export default InsuranceRWDuration;
