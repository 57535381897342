import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";

import { QMSelectInput, QMTextInput } from "../../../common/QMInputs";

const DealAdminSubsidiaryInfoBlock = ({
  register,
  control,
  watch,
  getFieldErrors,
  boolOpts,
  setValue,
}) => {
  const { t } = useTranslation();
  const buyerSubsidiaryWatch = watch("buyer_is_subsidiary");

  const handleSubsidiaryChange = (val, field) => {
    const updatedVal = val === null ? val : val.value;
    field.onChange(updatedVal);
  };

  useEffect(() => {
    if (!buyerSubsidiaryWatch) {
      setValue("buyer_parent_company_name", "");
      setValue("buyer_parent_company_website", "");
    }
  }, [buyerSubsidiaryWatch, setValue]);

  return (
    <>
      <Controller
        render={({ field }) => (
          <QMSelectInput
            {...field}
            placeholder={t("general.choose_answer")}
            label={t("general.subsidiary")}
            errors={getFieldErrors("buyer_is_subsidiary")}
            options={boolOpts}
            isSearchable={false}
            value={boolOpts.find((c) => c.value === field.value)}
            onChange={(val) => handleSubsidiaryChange(val, field)}
            isRequired
            isClearable
          />
        )}
        control={control}
        name="buyer_is_subsidiary"
      />
      <QMTextInput
        {...register("buyer_parent_company_name")}
        label={t("new_deal_form.parent_company_label")}
        placeholder={t("new_deal_form.parent_company_placeholder")}
        errors={getFieldErrors("buyer_parent_company_name")}
        disabled={buyerSubsidiaryWatch !== true}
        isRequired={buyerSubsidiaryWatch}
      />
      <QMTextInput
        {...register("buyer_parent_company_website")}
        label={t("new_deal_form.parent_website_label")}
        placeholder={t("general.website_placeholder")}
        errors={getFieldErrors("buyer_parent_company_website")}
        disabled={buyerSubsidiaryWatch !== true}
        isRequired={buyerSubsidiaryWatch}
      />
    </>
  );
};

export default DealAdminSubsidiaryInfoBlock;
