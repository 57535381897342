export const insuranceTabInfoSelector = (state) =>
  state.insuranceTab.insuranceInfo;
export const insuranceTabRetentionSelector = (state) =>
  state.insuranceTab.insuranceInfo.retention_options;
export const insuranceTabDurationSelector = (state) =>
  state.insuranceTab.insuranceInfo.coverage_duration;
export const insuranceLoadingSelector = (state) =>
  state.insuranceTab.isLoadingInsurance;
export const insuranceComplianceTabInfoSelector = (state) =>
  state.insuranceTab.insuranceComplianceInfo;
