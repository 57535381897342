import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
// import { useDispatch } from "react-redux";
import { useTable, useRowSelect } from "react-table";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

import { formatPropName } from "../../../../helpers";
import {
  formatCurrency,
  reformatDealDate,
} from "../../../../helpers/broker-helpers";
import QMToolTipName from "../../common/QMToolTipName";
// import { setSelectedDeal } from "../../../../redux/reducers/deals-reducer";

const DealsTable = ({ data }) => {
  const { t } = useTranslation();
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const [rowWithInnerActive, setRowWithInnerActive] = useState(null);
  const handleMouseDown = (e, id) => {
    e.stopPropagation();
    setRowWithInnerActive(id);
  };
  const handleMouseUp = (e) => {
    e.stopPropagation();
    setRowWithInnerActive(null);
  };

  /*  const handleDealName = (value) => {
    if (value?.length > 30 || value?.length > 20) {
      return (
        <>
          <strong className="QM-table__deal-name_new-line">
            {value?.substring(0, 11)}
          </strong>
          <strong className="QM-table__deal-name_new-line">
            {value?.substring(11, 21)}
          </strong>
          <strong className="QM-table__deal-name_new-line">
            {value?.substring(21, value?.length)}
          </strong>
        </>
      );
    }

    if (value?.length > 10) {
      return (
        <>
          <strong className="QM-table__deal-name_new-line">
            {value?.substring(0, 11)}
          </strong>
          <strong className="QM-table__deal-name_new-line">
            {value?.substring(11, value?.length)}
          </strong>
        </>
      );
    } else {
      return <strong className="QM-table__deal-name">{value}</strong>;
    }
  };
 */
  const handleNamesLength = (value) => {
    const maxLength = 11;
    if (value?.length > maxLength) {
      return (
        <span>
          <QMToolTipName maxLength={maxLength} text={value} />
        </span>
      );
    }
    return value;
  };

  const columns = useMemo(
    () => [
      {
        Header: t("general.deal_name"),
        accessor: "name",
        Cell: ({ value, row }) => (
          <button
            className="QM-table_cell__clickable QM-btn__transparent"
            onClick={(e) => {
              e.stopPropagation();
              navigate(`${row.id}`);
            }}
            onMouseDown={(e) => handleMouseDown(e, row.id)}
            onMouseUp={handleMouseUp}
          >
            {handleNamesLength(value)}
            <span className="icon-external-link" />
          </button>
        ),
      },
      {
        Header: t("general.buyer"),
        accessor: "buyer.name",
        Cell: ({ value, row }) => (
          //TODO: change to NavLink after behavior is defined
          <p>
            {handleNamesLength(value)}
            <small>{handleNamesLength(row?.original?.buyer?.website)}</small>
          </p>
        ),
      },
      {
        Header: t("general.seller"),
        accessor: "seller.name",
        Cell: ({ value, row }) => (
          //TODO: change to NavLink after behavior is defined
          <p>
            {handleNamesLength(value)}
            <small>{handleNamesLength(row?.original?.seller?.website)}</small>
          </p>
        ),
      },
      {
        Header: t("general.deal_size"),
        accessor: "deal_size",
        Cell: ({ value }) => <p>{formatCurrency(value)}</p>,
      },
      {
        Header: t("general.closing_date"),
        accessor: "closing_date",
        Cell: ({ value }) => <p>{value ? reformatDealDate(value) : value}</p>,
      },
      {
        Header: t("general.status"),
        accessor: "status",
        Cell: ({ value }) => <p>{formatPropName(value)}</p>,
      },
    ],
    [navigate, t]
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // state: { selectedRowIds },
    // toggleAllRowsSelected,
  } = useTable(
    {
      columns,
      data,
      getRowId: useCallback((row) => row.id, []),
    },
    useRowSelect
  );

  const handleRowClick = (row) => {
    // toggleAllRowsSelected(false);
    // row.toggleRowSelected(true);
    // dispatch(setSelectedDeal(row.id));
    navigate(`${row.id}`);
  };

  const getRowSelectedStyle = (row) => {
    return classNames({
      "QM-table_row__selected": row.isSelected,
      "QM-table_row__hasInnerActive": rowWithInnerActive === row.id,
    });
  };

  return (
    <section className="QM-table_scroller">
      <table {...getTableProps()} className="QM-table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                onClick={() => handleRowClick(row)}
                className={getRowSelectedStyle(row)}
              >
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </section>
  );
};

export default DealsTable;
