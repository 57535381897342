import React from "react";

import faq13a from "../../../assets/imgs/faq13a.png";
import faq13b from "../../../assets/imgs/faq13b.png";
import faq13c from "../../../assets/imgs/faq13c.png";
import faq13d from "../../../assets/imgs/faq13d.png";
import QMButton from "../common/QMButton";

import "./styles.scss";

const FaqHowToAddContacts = () => {
  return (
    <section className="faq-page__item">
      <div className="faq-page__content">
        <div className="faq-page__functions-overview-title">
          <p>Every project has two type of contacts: companies and careers.</p>
          <p>
            Companies — are deal participants, such a seller, buyer, subsidiary,
            target.
          </p>
          <p>Carriers — are contacts you will submit a R&amp;W application.</p>
        </div>
        <div className="faq-page__contacts">
          <p>
            To create a new project you need to provide Buyer's, Seller's,
            Subsidiary's and Target's contacts (Name, address, website etc). You
            can do it before launch the project by adding all parties to the{" "}
            <span className="faq-page__secondary-text">
              Contacts → Companies
            </span>{" "}
            menu (1.3.a).
          </p>
          <figure>
            <img src={faq13a} alt="contacts companies"></img>
            <figcaption className="faq-page__caption">1.3.a</figcaption>
          </figure>
        </div>
        <div className="faq-page__contacts">
          <p>
            То create new contact, press{" "}
            <QMButton text="New Action" customClassName="QM-faq-btn__primary" />{" "}
            button on the navigation bar and choose{" "}
            <span className="faq-page__secondary-text"> Company</span>. Provide
            data and press{" "}
            <QMButton text="Save" customClassName="QM-faq-btn__primary" />{" "}
            button.
          </p>
        </div>
        <div className="faq-page__contacts">
          <p>
            Otherwise, you can add a new company directly during creation of a
            new deal on the{" "}
            <span className="faq-page__secondary-text">Add new deal</span>{" "}
            window. Start typing a company name for a buyer, seller, subsidiary
            or target and choose a company. If there are no companies, press{" "}
            <QMButton
              text="Add new contact"
              customClassName="QM-faq-btn__secondary"
              iconClassName="icon-plus"
            />{" "}
            button. System will save it to the{" "}
            <span className="faq-page__secondary-text">Contacts </span> menu.
          </p>
          <figure>
            <img src={faq13b} alt="contacts companies"></img>
            <figcaption className="faq-page__caption">1.3.b</figcaption>
          </figure>
        </div>
        <div className="faq-page__contacts">
          <p>
            Now you can launch new project. Press{" "}
            <QMButton text="New Action" customClassName="QM-faq-btn__primary" />{" "}
            button and choose Deal. Start typing a company name, and you will
            find the company you need on the dropdown list (1.3.c). Choose a
            company to add it as a participant of the project.
          </p>
          <figure>
            <img src={faq13c} alt="contacts companies"></img>
            <figcaption className="faq-page__caption">1.3.c</figcaption>
          </figure>
        </div>
        <div className="faq-page__contacts">
          <p>
            To submit a R&amp;W application, you need to have a list of carriers
            you want to apply. You can add them before or after launch the
            project. To add carrier press{" "}
            <QMButton text="New Action" customClassName="QM-faq-btn__primary" />{" "}
            , provide data and press{" "}
            <QMButton text="Save" customClassName="QM-faq-btn__primary" /> .
            List of carriers you can find on{" "}
            <span className="faq-page__secondary-text">
              Contacts → Carriers
            </span>{" "}
            menu (1.3.d).
          </p>
          <figure>
            <img src={faq13d} alt="contacts companies"></img>
            <figcaption className="faq-page__caption">1.3.d</figcaption>
          </figure>
        </div>
      </div>
    </section>
  );
};

export default FaqHowToAddContacts;
