import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";

import { getDeals } from "../../../../api/dealsApi";
import {
  setDealsError,
  setIsLoadingDeals,
} from "../../../../redux/reducers/deals-reducer";
import {
  dealsErrorSelector,
  dealsLoadingSelector,
  dealsSelector,
} from "../../../../redux/selectors/broker-selectors";

import QMGlobalLoader from "../../../common/QMLoader";
import QMToastMessage from "../../common/QMToastMessage";

import EmptyState from "./EmptyState";
import DealsTable from "./DealsTable";

const LawyerDealsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector(dealsLoadingSelector);
  const deals = useSelector(dealsSelector);
  const errors = useSelector(dealsErrorSelector);
  const { dealId } = useParams();

  const handleErrorToastClose = () => dispatch(setDealsError(null));

  useEffect(() => {
    getDeals(dispatch).catch((err) => {
      console.error("uncaught error occured", err);
      dispatch(
        setDealsError({
          message: t("errors.server_general"),
        })
      );
      dispatch(setIsLoadingDeals(false));
    });
  }, [dispatch, t]);

  const getContent = () => {
    if (!isLoading && !deals?.length && !errors) {
      return <EmptyState />;
    } else if (!isLoading && deals?.length) {
      return (
        <section className="QM-deals_container">
          <section>
            <DealsTable data={deals} />
          </section>
        </section>
      );
    } else if (isLoading) {
      return <QMGlobalLoader />;
    }
  };
  return (
    <>
      {dealId ? (
        <Outlet />
      ) : (
        <>
          {getContent()}
          {errors && (
            <QMToastMessage
              viewType="error"
              text={errors.message}
              iconClassName="icon-attention"
              onClose={handleErrorToastClose}
            />
          )}
        </>
      )}
    </>
  );
};

export default LawyerDealsPage;
