import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoadingRiskProfile: false,
  riskProfileInfo: {},
  riskProfileLitigationsDocs: {},
};

export const riskProfileReducer = createSlice({
  name: "riskProfile",
  initialState,
  reducers: {
    setIsLoadingRiskProfile: (state, action) => {
      state.isLoadingRiskProfile = action.payload;
    },
    setRiskProfileInfo: (state, action) => {
      state.riskProfileInfo = action.payload;
    },
    setRiskProfileLitigationsDocs: (state, action) => {
      state.riskProfileLitigationsDocs = action.payload;
    },
  },
});

export const {
  setIsLoadingRiskProfile,
  setRiskProfileInfo,
  setRiskProfileLitigationsDocs,
} = riskProfileReducer.actions;

export default riskProfileReducer.reducer;
