import React, { useEffect } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { QMSelectInput, QMTextInput } from "../../../common/QMInputs";

import "./styles.scss";

const InsuranceFormItem = ({
  retention_number,
  control,
  register,
  getFieldErrors,
  seller_indemnity,
  seller_indemnity_percentage,
  no_seller_indemnity,
  no_seller_indemnity_percentage,
  disableSellerIndemnityPercentage,
  disableNoSellerIndemnityPercentage,
  setValue,
}) => {
  const { t } = useTranslation();

  const sellerIndemnityOpt = [
    { value: true, label: t("general.yes") },
    { value: false, label: t("general.no") },
  ];

  const handleSelectChange = (val, field) => {
    const updatedVal = val === null ? val : val.value;
    field.onChange(updatedVal);
  };

  useEffect(() => {
    if (!disableSellerIndemnityPercentage) {
      setValue(seller_indemnity_percentage, null);
    }
    if (!disableNoSellerIndemnityPercentage) {
      setValue(no_seller_indemnity_percentage, null);
    }
  }, [
    disableSellerIndemnityPercentage,
    disableNoSellerIndemnityPercentage,
    no_seller_indemnity_percentage,
    seller_indemnity_percentage,
    setValue,
  ]);

  return (
    <>
      <h2 className="rw-insurance__retention-title">
        {t("insurance.retention_option")} {retention_number}
      </h2>
      <section className="rw-insurance__retention-list">
        <section className="rw-insurance__retention-item">
          <Controller
            render={({ field }) => (
              <QMSelectInput
                {...field}
                placeholder={t("insurance.seller_indemnity_placeholder")}
                label={t("insurance.seller_indemnity")}
                errors={getFieldErrors(seller_indemnity)}
                options={sellerIndemnityOpt}
                value={sellerIndemnityOpt.find((c) => c.value === field.value)}
                onChange={(val) => handleSelectChange(val, field)}
                isClearable
              />
            )}
            control={control}
            name={seller_indemnity}
          />
          <QMTextInput
            {...register(seller_indemnity_percentage)}
            label={t("insurance.percent_if_enterprise_value_dropping_to")}
            placeholder={t("insurance.percent_enterpice_placeholder")}
            errors={getFieldErrors(seller_indemnity_percentage)}
            disabled={disableSellerIndemnityPercentage !== true}
          />
        </section>
        <p className="rw-insurance__retention-text">
          {t("insurance.seller_idemnity_desc")}
        </p>
      </section>
      <section className="rw-insurance__retention-list">
        <section className="rw-insurance__retention-item">
          <Controller
            render={({ field }) => (
              <QMSelectInput
                {...field}
                placeholder={t("insurance.seller_indemnity_placeholder")}
                label={t("insurance.no_seller_indemnity")}
                errors={getFieldErrors(no_seller_indemnity)}
                options={sellerIndemnityOpt}
                value={sellerIndemnityOpt.find((c) => c.value === field.value)}
                onChange={(val) => handleSelectChange(val, field)}
                isClearable
              />
            )}
            control={control}
            name={no_seller_indemnity}
          />
          <QMTextInput
            {...register(no_seller_indemnity_percentage)}
            label={t("insurance.percent_enterprise_value_after_12_months")}
            placeholder={t("insurance.percent_enterpice_placeholder")}
            errors={getFieldErrors(no_seller_indemnity_percentage)}
            disabled={disableNoSellerIndemnityPercentage !== true}
          />
        </section>
        <p className="rw-insurance__retention-text">
          {t("insurance.no_seller_indemnity_desc")}
        </p>
      </section>
    </>
  );
};

export default InsuranceFormItem;
