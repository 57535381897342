import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import QMCheckbox from "../../../common/QMCheckbox";
import { QMTextarea } from "../../../common/QMInputs";

import "./styles.scss";

const QuestionSet = ({
  register,
  unregister,
  setValue,
  watch,
  // hasTarget,
  canHaveWarning,
  name,
  hasDefaultTextarea,
  hasTextareaDefaultNote,
}) => {
  const { t } = useTranslation();

  const buyerWatch = watch(`${name}.buyer`);
  const targetWatch = watch(`${name}.target`);
  const naWatch = watch(`${name}.not_applicable`);

  const hasWarning = () =>
    canHaveWarning &&
    [buyerWatch, targetWatch, naWatch].every((watch) => !watch);

  const getTextArea = () => {
    if (hasDefaultTextarea || buyerWatch || targetWatch) {
      const getNote = () => {
        if (hasDefaultTextarea && hasTextareaDefaultNote) {
          return t(`past_activities.questions.${name}.textarea_note`);
        } else if (buyerWatch || targetWatch) {
          // const participants = [
          //   { participant: "buyer", selected: buyerWatch },
          //   { participant: "seller", selected: sellerWatch },
          //   { participant: "target", selected: targetWatch },
          // ];
          // TEMP until new logic is defined
          // ===============================
          // const instructions = participants
          //   .filter((p) => p.selected)
          //   .map((filteredParticipant) => {
          //     const participantStr = t(
          //       `general.${filteredParticipant.participant}`
          //     );
          // const folderNameStr = t(
          //   `past_activities.questions.${name}.folder_name`
          // );
          // return `${participantStr}/${folderNameStr}`;
          //   return `${participantStr}`;
          // })
          // .join(", ");
          return `${t(
            "past_activities.upload_instructions"
          )} ${"corresponding folder in document widget to the right"}`;
        } else {
          return "";
        }
      };
      return (
        <QMTextarea
          {...register(`${name}.explanation`)}
          wrapperClassName={
            hasDefaultTextarea ? "textArea" : "textArea textArea__dynamic"
          }
          label={
            hasDefaultTextarea
              ? ""
              : t("past_activities.textarea_default_label")
          }
          note={getNote()}
          placeholder={t("general.please_type_text")}
          rows={4}
        />
      );
    } else {
      return <></>;
    }
  };

  useEffect(() => {
    if (naWatch) {
      setValue(`${name}.buyer`, false);
      setValue(`${name}.target`, false);
      !hasDefaultTextarea && unregister(`${name}.explanation`);
    }
  }, [naWatch, setValue, unregister, hasDefaultTextarea, name]);

  useEffect(() => {
    if (buyerWatch || targetWatch) {
      setValue(`${name}.not_applicable`, false);
    }
  }, [buyerWatch, targetWatch, setValue, name]);

  useEffect(() => {
    if (!buyerWatch && !targetWatch) {
      !hasDefaultTextarea && unregister(`${name}.explanation`);
      hasDefaultTextarea && setValue(`${name}.explanation`, "");
    }
  }, [buyerWatch, targetWatch, setValue, unregister, hasDefaultTextarea, name]);
  return (
    <fieldset className="questionBlock">
      <section className={classNames("questionRow")}>
        <p>{t(`past_activities.questions.${name}.question_text`)}</p>
        <QMCheckbox
          {...register(`${name}.buyer`)}
          customClassName={hasWarning() ? "QM-checkbox__warning" : ""}
        />
        <QMCheckbox
          {...register(`${name}.target`)}
          customClassName={hasWarning() ? "QM-checkbox__warning" : ""}
        />
        <QMCheckbox
          {...register(`${name}.not_applicable`)}
          customClassName={hasWarning() ? "QM-checkbox__warning" : ""}
        />
      </section>
      {getTextArea()}
    </fieldset>
  );
};

export default QuestionSet;
