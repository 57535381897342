import React, { forwardRef } from "react";
import classNames from "classnames";
import TextareaAutosize from "react-autosize-textarea";
import QMInputWrapper from "./QMInputWrapper";

import "./styles.scss";

const QMTextarea = forwardRef(
  (
    {
      label,
      isRequired,
      errors,
      iconClassName,
      placeholder,
      wrapperClassName,
      note,
      maxLength,
      withCount,
      ...props
    },
    ref
  ) => {
    return (
      <QMInputWrapper
        label={label}
        isRequired={isRequired}
        errors={errors}
        iconClassName={iconClassName}
        customClassName={wrapperClassName}
        maxLength={maxLength}
        withCount={withCount}
        {...props}
      >
        <TextareaAutosize
          ref={ref}
          style={{ resize: "none" }}
          className={classNames("QM-input", "QM-textarea", {
            "QM-input__error": !!errors,
            "QM-textarea__error": !!errors,
            "QM-input__withIcon": !!iconClassName,
            "QM-textarea__withIcon": !!iconClassName,
          })}
          maxLength={maxLength}
          placeholder={placeholder}
          {...props}
        />
        {note && <small className="QM-textarea_note">{note}</small>}
      </QMInputWrapper>
    );
  }
);

export default QMTextarea;
