import React from 'react';
import QMETooltip from '../QMETooltip';

import { useSelector, useDispatch } from 'react-redux';

import { setBundlesOrder } from '../../../../redux/reducers/estimator-reducer'
import './styles.scss';

const QMERadioSet = ({options, name, width, register}) => {
  const bundles = useSelector(state => state.estimator.estimation.bundles);
  const dispatch = useDispatch();

  const handleBundleClick = (index) => {
    if(bundles?.length) {
      dispatch(setBundlesOrder(index))
    }
  }
  return (
    <section
      className='QM_E-radio_wrapper'
      style={{width: width || 'unset'}}
    >
      { options.map((opt, i) => (
        <label className='QM_E-radio_label' key={i}>
          <input 
            onClick={() => {
              handleBundleClick(i)
            }}
            {...register(name)}
            type='radio'
            name={name}
            value={opt.value}
            className='QM_E-radio_input'
          />
          <p className='QM_E-radio_content'>
            {opt.label}
            {opt.tooltip && <QMETooltip theme="dark" text={opt.tooltip}/>}
          </p>
        </label>
      ))}

    </section>
  );
}

export default QMERadioSet;