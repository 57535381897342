import React from 'react';

import './styles.scss';

const AuthModal = ({ setIsOpen, children}) => {
  return (
    <section className='authModal-curtain'>
      <dialog className="authModal icon-loader">
        <button
          type="button"
          className="icon-x iconOnly"
          aria-label="Close"
          title="Close"
          onClick={ () => setIsOpen(false) }
          autoFocus
        >
          <span>Close</span>
        </button>
        {children}
      </dialog>
    </section>
  );
}

export default AuthModal;