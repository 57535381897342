import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useRowSelect, useTable } from "react-table";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useState } from "react";

import QMTile from "../../common/QMTile";
import UnderwritingHeader from "./UnderwritingHeader";
import QMButton from "../../common/QMButton";
import QMCheckbox from "../../common/QMCheckbox";
import { postUnderWritingInfo } from "../../../../api/underwritingApi";
import UnderwritingModal from "./UnderwritingModal";

import "./styles.scss";
import { reformatSubmitDate } from "../../../../helpers/broker-helpers";
import DocsModal from "./DocsModal";

const UnderwritingList = ({ data }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { dealId } = useParams();
  const [showUnderwritingModal, setShowUnderwritingModal] = useState(false);
  const [selectedCarrier, setSelectedCarrier] = useState(null);
  const [dealIdForDocs, setDealIdForDocs] = useState(null);

  const sendDataFromTable = useCallback(
    (data) => {
      postUnderWritingInfo(dispatch, dealId, data);
    },
    [dispatch, dealId]
  );

  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef();
      const resolvedRef = ref || defaultRef;

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
          ? indeterminate
          : undefined;
      }, [resolvedRef, indeterminate]);

      return <QMCheckbox ref={resolvedRef} name={rest.name} {...rest} />;
    }
  );

  const columns = useMemo(
    () => [
      {
        Header: t("underwriting.carrier_name"),
        accessor: "name",
        Cell: ({ value, row }) => <strong>{value}</strong>,
      },
      {
        Header: t("underwriting.contact"),
        accessor: "contact",
        Cell: ({ value, row }) => <strong>{value}</strong>,
      },
      {
        Header: t("underwriting.email"),
        accessor: "email",
        Cell: ({ value }) => (
          <strong style={{ textTransform: "lowercase" }}>{value}</strong>
        ),
      },
      {
        Header: t("underwriting.uw_fee"),
        accessor: "underwriters[0].fee",
        Cell: ({ value }) => <strong>{value}</strong>,
      },
      {
        Header: t("underwriting.status"),
        accessor: "underwriters[0].status",
        Cell: ({ value }) => <strong>{value !== null ? value : "-"}</strong>,
      },
      {
        Header: t("underwriting.date_sent"),
        accessor: "underwriters[0].date_sent",
        Cell: ({ value }) => (
          <strong>{value !== null ? reformatSubmitDate(value) : "-"}</strong>
        ),
      },
      {
        Header: t("deal.deal_room"),
        id: "openFiles",
        Cell: ({ row }) => (
          <QMButton
            viewType="transparent"
            iconClassName="icon-folder-open"
            customClassName="QM-table_openFilesBtn"
            onClick={() => setDealIdForDocs(dealId)} // temp: waiting for BE
            text={t("general.open")}
          />
        ),
      },
      {
        Header: "",
        id: "submitDeal",
        Cell: ({ row }) => (
          <QMButton
            viewType="transparent"
            text={t("underwriting.submit")}
            customClassName="QM-table_openLinkBtn"
            onClick={(e) => {
              e.stopPropagation();
              setShowUnderwritingModal(true);
              setSelectedCarrier(row.original.underwriters[0].id);
            }}
            disabled={row.original.underwriters[0].status ? true : false}
          />
        ),
      },
    ],
    [t, dealId]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      getRowId: useCallback((row) => row.underwriters[0].id, []),
    },
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selectAll",
          Header: ({ toggleRowSelected, isAllRowsSelected, rows }) => {
            const modifiedOnChange = (event) => {
              rows.forEach((row) => {
                !row.original.underwriters[0].status &&
                  toggleRowSelected(row.id, event.currentTarget.checked);
              });
            };

            let selectableRowsInCurrentPage = 0;
            let selectedRowsInCurrentPage = 0;
            rows.forEach((row) => {
              row.isSelected && selectedRowsInCurrentPage++;
              !row.original.underwriters[0].status &&
                selectableRowsInCurrentPage++;
            });

            const disabled = selectableRowsInCurrentPage === 0;
            const checked =
              (isAllRowsSelected ||
                selectableRowsInCurrentPage === selectedRowsInCurrentPage) &&
              !disabled;

            return (
              <div>
                <IndeterminateCheckbox
                  onChange={modifiedOnChange}
                  checked={checked}
                  disabled={disabled}
                  name="select_all"
                />
              </div>
            );
          },
          Cell: ({ row }) =>
            !row.original.underwriters[0].status && (
              <div>
                <IndeterminateCheckbox
                  {...row.getToggleRowSelectedProps()}
                  name="select_carrier"
                />
              </div>
            ),
        },
        ...columns,
      ]);
    }
  );

  const getRowSelectedStyle = (row) => {
    return classNames({
      "QM-table_row__selected": row.isSelected,
    });
  };

  return (
    <>
      <UnderwritingHeader
        selectedCarriers={Object.keys(selectedRowIds)}
        setShowUnderwritingModal={setShowUnderwritingModal}
      />
      <QMTile>
        <section className="QM-table_scroller">
          <table {...getTableProps()} className="QM-table">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr
                    className={getRowSelectedStyle(row)}
                    {...row.getRowProps()}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </section>
      </QMTile>
      {showUnderwritingModal && (
        <UnderwritingModal
          title={t("underwriting.underwriting_modal_header")}
          header_text={t("underwriting.underwriting_modal_body_header-text")}
          body_text={t("underwriting.underwriting_modal_body_body-text")}
          footer_text={t("underwriting.underwriting_modal_body_footer-text")}
          cancelButtonText={t("general.cancel_button")}
          submitButtonText={t("underwriting.underwriting_modal_submit-btn")}
          sendDataFromTable={sendDataFromTable}
          setShowUnderwritingModal={setShowUnderwritingModal}
          selectedCarriers={Object.keys(selectedRowIds)}
          selectedCarrier={selectedCarrier}
        />
      )}
      {Boolean(dealIdForDocs) && (
        <DocsModal
          handleClose={() => setDealIdForDocs(null)}
          dealIdForDocs={dealIdForDocs}
        />
      )}
    </>
  );
};
export default UnderwritingList;
