const reactSelectStylesConfig = (width) =>({
  control: (provided) => ({
    ...provided,
    width: width,
    backgroundColor: '#F1F9FF',
    border: '1px solid #F1F9FF',
    borderRadius: '8px',
    minHeight: '47px',
    paddingLeft: '16px',
    cursor: 'pointer',
  }),
  input: (provided) => ({
    ...provided,
    color: '#333333',
    fontFamily: 'OpenSans',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '19px',
    cursor: 'pointer',
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    color: '#333333',
    fontFamily: 'OpenSans',
    fontSize: '14px',
    fontWeight: '300',
    fontStyle: 'italic',
    lineHeight: '19px',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '200px',
    "::-webkit-scrollbar": {
      width: "6px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#DDF1FF",
      borderRadius: '8px'
    },
    "::-webkit-scrollbar-thumb": {
      background: "#A1B4C6",
      borderRadius: '8px'
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#3b6185"
    }
  }),
  option: (provided) => ({
    ...provided,
    color: '#333333',
    fontFamily: 'OpenSans',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '19px',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#A1B4C6',
    fontFamily: 'OpenSans',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '19px',
    display: 'flex',
    '> svg': {
      marginRight: '8px',
    }
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#333333',
    fontFamily: 'OpenSans',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '19px',
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#DDF1FF',
    border: '1px solid #C4E6FF',
    borderRadius: '8px',
    padding: '6px 10px',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: '#24477D',
    fontFamily: 'OpenSans',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '19px',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    cursor: 'pointer',
    ":hover": {
      backgroundColor: 'none',
      color: 'none'
    },
    ":hover > svg": {
      stroke: '#3d77aa',
    }
  })
})

export default reactSelectStylesConfig;
