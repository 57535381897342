import React from "react";
import { useSelector } from "react-redux";

import { selectedDealSelector } from "../../../../redux/selectors/broker-selectors";
import DealAdminDocuments from "./DealAdminDocuments";
import DealAdminEmptyState from "./DealAdminEmptyState";

import "./styles.scss";

const DealAdminSidebar = () => {
  const dealId = useSelector(selectedDealSelector);
  return (
    <aside className="QM-deal-admin_sidebar">
      {!dealId ? (
        <DealAdminEmptyState />
      ) : (
        <>
          <DealAdminDocuments dealId={dealId} />
        </>
      )}
    </aside>
  );
};

export default DealAdminSidebar;
