import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoadingTimingTab: false,
  timingTabInfo: {},
};

export const timingTabReducer = createSlice({
  name: "timingTab",
  initialState,
  reducers: {
    setIsLoadingTiming: (state, action) => {
      state.isLoadingTimingTab = action.payload;
    },
    setTimingTabInfo: (state, action) => {
      state.timingTabInfo = action.payload;
    },
  },
});

export const { setIsLoadingTiming, setTimingTabInfo } =
  timingTabReducer.actions;

export default timingTabReducer.reducer;
