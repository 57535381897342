import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import uniqid from "uniqid";

import "./styles.scss";
import classNames from "classnames";

const QMCheckbox = forwardRef(
  ({ label, isRequired, errors, customClassName, name, ...props }, ref) => {
    const fieldId = uniqid(`${name}-`);
    return (
      <section className="QM-checkbox_wrapper">
        <input
          ref={ref}
          type="checkbox"
          className="QM-checkbox"
          name={name}
          id={fieldId}
          {...props}
        />
        <label
          className={classNames("QM-checkbox_label", customClassName)}
          htmlFor={fieldId}
        >
          {label && <span>{label}</span>}
        </label>
      </section>
    );
  }
);

QMCheckbox.propTypes = {
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  name: PropTypes.string.isRequired,
  customClassName: PropTypes.string,
};

QMCheckbox.defaultProps = {
  label: "",
  isRequired: false,
  customClassName: "",
};

export default QMCheckbox;
