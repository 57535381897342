import React from "react";

import QMTile from "../../common/QMTile";

import "./styles.scss";

const RiskProfileDescription = ({ header, data }) => {
  return (
    <QMTile>
      <h2 className="risk-profile__description-header">{header}</h2>
      <section className="risk-profile__description-body">
        <p>{data}</p>
      </section>
    </QMTile>
  );
};
export default RiskProfileDescription;
