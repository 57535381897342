import React from 'react';

import './styles.scss';

const QMTextTabs = ({children}) => {
  return (
    <nav className='QM-textTabs_nav'>
      {children}
    </nav>
  );
}

export default QMTextTabs;