import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isCopiedToClipboard: false,
}

export const commonReducer = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setIsCopiedToClipboard: (state, action) => {
      state.isCopiedToClipboard = action.payload
    },
  }
});
export const {
  setIsCopiedToClipboard,
} = commonReducer.actions;

export default commonReducer.reducer;