import React from "react";
import { useTranslation } from "react-i18next";

import QMButton from "../QMButton";

import "./styles.scss";

const QMSaveLayout = () => {
  const { t } = useTranslation();
  return (
    <div className="tabs-submit">
      <QMButton
        text={t("general.save_changes")}
        type="submit"
        customClassName="tabs-submit__btn"
      />
    </div>
  );
};

export default QMSaveLayout;
