import { authFetch } from "./fetchConfig";

import { setDealError } from "../redux/reducers/dealPage-reducer";
import {
  setIsLoadingRiskProfile,
  setRiskProfileInfo,
  setRiskProfileLitigationsDocs,
} from "../redux/reducers/riskProfile-reducer";

const getRiskProfileInfo = async (dispatch, dealId) => {
  dispatch(setIsLoadingRiskProfile(true));
  const response = await authFetch(
    `${process.env.REACT_APP_BASE_API_URL}/deals/${dealId}/risk_profile/risk_summary`
  );
  const responseData = await response.json();

  if (response.status === 200) {
    dispatch(setRiskProfileInfo(responseData.risk_summary));
  } else {
    dispatch(setDealError(responseData?.errors[0]?.message));
  }
  dispatch(setIsLoadingRiskProfile(false));
};

const getRiskProfileLitigationsDocs = async (dispatch, dealId) => {
  dispatch(setIsLoadingRiskProfile(true));

  const response = await authFetch(
    `${process.env.REACT_APP_BASE_API_URL}/deals/${dealId}/risk_profile/past_activities_documents`
  );

  const responseData = await response.json();

  if (response.status === 200) {
    const resKeys = Object.keys(responseData);
    dispatch(setRiskProfileLitigationsDocs(responseData[resKeys[0]]));
  } else {
    dispatch(setRiskProfileLitigationsDocs({}));
    dispatch(setDealError(responseData));
  }

  dispatch(setIsLoadingRiskProfile(false));
};

export { getRiskProfileInfo, getRiskProfileLitigationsDocs };
