import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import QMGlobalLoader from "../../../common/QMLoader";
import QMToastMessage from "../../common/QMToastMessage";
import DealAdminNewDealForm from "./DealAdminNewDealForm";

import dealAdminValidationSchema from "./dealAdminValidationSchema";
import { createNewDealAdminDeal } from "../../../../api/dealsApi";
import useLeavePageBlocker from "../../../../helpers/useLeavePageBlocker";
import { TOAST_CLOSE_DELAY } from "../../../../helpers";

const DealAdminNewDealPage = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setError,
    getValues,
    setValue,
    clearErrors,
    watch,
    control,
    formState: {
      errors,
      touchedFields,
      isValid,
      isDirty,
      isSubmitting,
      isSubmitted,
    },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(dealAdminValidationSchema),
  });
  const [isLoading, setIsLoading] = useState(false);
  const [generalError, setGeneralError] = useState(null);
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);

  const handleCreateSuccess = (dealId) => {
    navigate(`/admin/portfolio/deals/${dealId}`);
  };

  const onSubmit = (data) => {
    createNewDealAdminDeal(
      data,
      setIsLoading,
      setGeneralError,
      handleCreateSuccess
    );
  };

  const leaveBlockerCondition = isDirty && !isSubmitting && !isSubmitted;

  useLeavePageBlocker(leaveBlockerCondition);

  return (
    <>
      <DealAdminNewDealForm
        register={register}
        handleSubmit={handleSubmit(onSubmit)}
        setError={setError}
        getValues={getValues}
        setValue={setValue}
        clearErrors={clearErrors}
        watch={watch}
        control={control}
        errors={errors}
        touchedFields={touchedFields}
        isValid={isValid}
        isConfirmVisible={isConfirmVisible}
        setIsConfirmVisible={setIsConfirmVisible}
      />
      {isLoading && <QMGlobalLoader />}
      {generalError && (
        <QMToastMessage
          text={generalError.message}
          viewType="error"
          iconClassName="icon-attention"
          onClose={() => setGeneralError(null)}
          selfCloseDelay={TOAST_CLOSE_DELAY}
        />
      )}
    </>
  );
};

export default DealAdminNewDealPage;
