import React, { useState, useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import BrokerNavMenu from "./BrokerNavMenu";
import BrokerActionMenu from "./BrokerActionMenu";
import QMSidebar from "../common/QMSidebar";
import QMButton from "../common/QMButton/QMButton";
import DealsPage from "./DealsPage";

import DealPage from "../DealPage/DealPage";

import NewDeal from "./NewDealPage";

import "./styles.scss";
import Faq from "../Faq";
import Markets from "../Markets";

const BrokerPortal = () => {
  const { pathname } = useLocation();
  const [isActionMenuOpen, setIsActionMenuOpen] = useState(false);

  useEffect(() => {
    document.body.classList.add("no-scroll");

    return () => document.body.classList.remove("no-scroll");
  }, []);

  useEffect(() => {
    if (isActionMenuOpen) {
      setIsActionMenuOpen(false);
    }
  }, [pathname]); // eslint-disable-line
  return (
    <main className="QM-main QM-brokerPortal">
      <QMSidebar>
        <QMButton
          text="New Action"
          customClassName="QM-newAction-btn"
          onClick={() => setIsActionMenuOpen(true)}
        />
        <BrokerNavMenu />
      </QMSidebar>
      <article className="QM-main_content">
        <Routes>
          <Route index element={<Navigate to="portfolio" />} />
          <Route path="portfolio" exact strict>
            <Route index element={<Navigate to="deals" />} />
            <Route path="deals" element={<DealsPage />} exact strict>
              <Route path=":dealId/*" element={<DealPage />} exact strict />
            </Route>
            <Route path="companies" element={<p>Companies</p>} />
          </Route>
          <Route path="markets" element={<Markets />} />
          <Route path="faq" element={<Faq />} exact strict />
          <Route path="new-deal" element={<NewDeal />} exact strict />
        </Routes>
      </article>
      {isActionMenuOpen && (
        <BrokerActionMenu onClose={() => setIsActionMenuOpen(false)} />
      )}
    </main>
  );
};

export default BrokerPortal;
