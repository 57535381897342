import * as yup from "yup";

import * as validationConst from "../../../../../helpers/validationConsts";

const timingValidationSchema = yup.object({
  closing_date: yup
    .date()
    /*  .min(
      validationConst.MIN_DEAL_CLOSING_DATE,
      validationConst.ERROR_MESSAGE_MIN_DATE2
    )
    .max(
      validationConst.MAX_DEAL_CLOSING_DATE,
      validationConst.ERROR_MESSAGE_MAX_DATE
    ) */
    .nullable()
    .required(validationConst.ERROR_MESSAGE_REQUIRED),
  policy_insurance_date: yup
    .date()
    /* .min(
      validationConst.MIN_DEAL_CLOSING_DATE,
      validationConst.ERROR_MESSAGE_MIN_DATE2
    )
    .max(
      validationConst.MAX_DEAL_CLOSING_DATE,
      validationConst.ERROR_MESSAGE_MAX_DATE
    ) */
    .nullable(),
  signing_date: yup
    .date()
    /* .min(
      validationConst.MIN_DEAL_CLOSING_DATE,
      validationConst.ERROR_MESSAGE_MIN_DATE2
    )
    .max(
      validationConst.MAX_DEAL_CLOSING_DATE,
      validationConst.ERROR_MESSAGE_MAX_DATE
    ) */
    .nullable(),
});

export default timingValidationSchema;
