import React from 'react';
import PropTypes from 'prop-types';
import QMLogo from '../../../common/QMLogo';
// import QMNavMenuItem from '../QMNavMenuItem';
import QMUserBlock from '../QMUserBlock/QMUserBlock';

import './styles.scss';

const QMSidebar = ({ darkMode, children }) => {

	return (
    <aside className='QM-sidebar'>
      <nav>
        <QMLogo darkMode={darkMode} />
        { children }
      </nav>
      <div>
				{/* <nav>
					<ul>
            <QMNavMenuItem
              to="/estimator"
              iconClassName="icon-calculator"
              title='Estimator'
            />
					</ul>
				</nav> */}
				<QMUserBlock />
			</div>
    </aside>
	)
};

QMSidebar.propTypes = {
  darkMode: PropTypes.bool,
  children: PropTypes.node,
};

QMSidebar.defaultProps = {
	darkMode: false,
  children: null,
};

export default QMSidebar;