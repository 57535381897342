import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { dueDilligenceTabInfoSelector } from "../../../../../redux/selectors/due-dilligence-tab-selectors";
import DueDilligenceTabList from "./DueDilligenceTabList";

import "./styles.scss";

const DueDilligenceTabOverlay = () => {
  const { t } = useTranslation();
  const dueDillignceInfo = useSelector(dueDilligenceTabInfoSelector);

  return (
    <>
      <p className="due-dilligence-tab__description">
        {t("due_diligence.due_diligence_desc")}
      </p>
      <DueDilligenceTabList
        dueDilligenceArray={dueDillignceInfo?.buyer}
        title={t("general.buyer")}
      />
      <DueDilligenceTabList
        dueDilligenceArray={dueDillignceInfo?.seller}
        title={t("general.seller")}
      />
      <DueDilligenceTabList
        dueDilligenceArray={dueDillignceInfo?.target}
        title={t("general.target")}
      />
    </>
  );
};

export default DueDilligenceTabOverlay;
