import React, { useEffect } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import QMCheckbox from "../../../common/QMCheckbox";
import { QMCurrencyInput } from "../../../common/QMInputs";

import "./styles.scss";

const InsuranceComplianceItem = ({
  register,
  code,
  control,
  errors,
  watch,
  setValue,
}) => {
  const { t } = useTranslation();

  const insurance_compliance_buyer_requirement = watch(
    `${code}.buyer_requirement`
  );
  useEffect(() => {
    if (!insurance_compliance_buyer_requirement) {
      setValue(`${code}.limit_per_claim`, "");
      setValue(`${code}.aggregate`, "");
    }
  }, [insurance_compliance_buyer_requirement, setValue, code]);
  return (
    <fieldset className="insurance-compliance__form">
      <p {...register(`${code}.code`, { value: code })}>
        {t(`insurance.compliance.${code}`)}
      </p>
      <QMCheckbox {...register(`${code}.buyer_requirement`)} />
      <Controller
        render={({ field }) => (
          <QMCurrencyInput
            {...field}
            placeholder={t("insurance.clain-occurance_aggregate_placeholder")}
            errors={errors?.[code]?.limit_per_claim}
            disabled={insurance_compliance_buyer_requirement !== true}
          />
        )}
        control={control}
        name={`${code}.limit_per_claim`}
      />
      <Controller
        render={({ field }) => (
          <QMCurrencyInput
            {...field}
            placeholder={t("insurance.clain-occurance_aggregate_placeholder")}
            errors={errors?.[code]?.aggregate}
            disabled={insurance_compliance_buyer_requirement !== true}
          />
        )}
        control={control}
        name={`${code}.aggregate`}
      />
    </fieldset>
  );
};

export default InsuranceComplianceItem;
