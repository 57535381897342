import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import {
  dealInfoTabLoadingSelector,
  dealInfoTabSelector,
} from "../../../../../redux/selectors/deal-info-tab-selector";
import { getDealInfoTabInfo } from "../../../../../api/dealInfoTabApi";
import QMGlobalLoader from "../../../../common/QMLoader";
import QMToastMessage from "../../../common/QMToastMessage";
import DealInfoOverlay from "./DealInfoOverlay";
import { setDealSaveSuccess } from "../../../../../redux/reducers/dealPage-reducer";
import { dealSaveSuccessSelector } from "../../../../../redux/selectors/deal-selectors";
import { SELF_CLOSE_DELAY } from "../../../../../helpers/broker-helpers";

import "./styles.scss";

const DealInfoTab = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const id = useParams();
  const isLoading = useSelector(dealInfoTabLoadingSelector);
  const dealInfoTabInfo = useSelector(dealInfoTabSelector);
  const isSaved = useSelector(dealSaveSuccessSelector);

  useEffect(() => {
    getDealInfoTabInfo(dispatch, id.dealId);
  }, [dispatch, id.dealId]);

  const getContent = () => {
    return (
      <section className="deal-info-tab">
        {isLoading && <QMGlobalLoader />}
        <DealInfoOverlay id={id.dealId} dealInfoTabInfo={dealInfoTabInfo} />

        {isSaved && (
          <QMToastMessage
            viewType="success"
            text={t("general.data_saved")}
            iconClassName="icon-check-circle"
            onClose={() => {
              dispatch(setDealSaveSuccess(false));
            }}
            selfCloseDelay={SELF_CLOSE_DELAY}
          />
        )}
      </section>
    );
  };

  return <>{getContent()}</>;
};

export default DealInfoTab;
