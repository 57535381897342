import React from "react";
import { useTranslation } from "react-i18next";

import QMNavMenuItem from "../../common/QMNavMenuItem";

const PortfolioMenu = () => {
  const { t } = useTranslation();
  return (
    <ul className="broker-portfolioMenu">
      <QMNavMenuItem
        title={t("general.deals")}
        to="/partner/portfolio/deals"
        iconClassName="icon-book"
        isInner
        end
      />
    </ul>
  );
};

const LawyerNavMenu = () => {
  const { t } = useTranslation();
  return (
    <ul className="broker-sidebarMenu">
      <QMNavMenuItem
        title={t("general.portfolio")}
        to="/partner/portfolio"
        iconClassName="icon-portfolio"
        hasInnerMenu
        innerContent={<PortfolioMenu />}
        end
      />
    </ul>
  );
};

export default LawyerNavMenu;
