import { authFetch } from "./fetchConfig";
import { reformatTimingInfoTab } from "../helpers/deal-helpers";

import {
  setDealError,
  setDealSaveSuccess,
  setIsForbidden,
} from "../redux/reducers/dealPage-reducer";
import {
  setIsLoadingTiming,
  setTimingTabInfo,
} from "../redux/reducers/timingTab-reducer";
import { getDealInfo } from "./dealApi";
import { ERROR_MESSAGE_EDITING_NOT_ALLOWED } from "../helpers/validationConsts";

const getTimingTabInfo = async (dealId, dispatch) => {
  dispatch(setIsLoadingTiming(true));
  const response = await authFetch(
    `${process.env.REACT_APP_BASE_API_URL}/deals/${dealId}/rwi_application/timing`
  );
  const responseData = await response.json();
  if (response.status === 200) {
    dispatch(setTimingTabInfo(responseData.timing));
  } else if (response.status === 403) {
    dispatch(setIsForbidden(true));
  } else {
    dispatch(setDealError(responseData?.errors[0]?.message));
  }
  dispatch(setIsLoadingTiming(false));
};

const updateTimingTab = async (dispatch, dealId, data, setErrorsToInput) => {
  dispatch(setIsLoadingTiming(true));

  const formattedData = reformatTimingInfoTab(data);

  const response = await authFetch(
    `${process.env.REACT_APP_BASE_API_URL}/deals/${dealId}/rwi_application/timing`,
    {
      method: "PUT",
      mode: "cors",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(formattedData),
    }
  );

  if (response.ok) {
    dispatch(setTimingTabInfo(data));
    await getDealInfo(dispatch, dealId);
    dispatch(setDealSaveSuccess(true));
  }

  if (!response.ok) {
    if (response.status === 403) {
      dispatch(setDealError(ERROR_MESSAGE_EDITING_NOT_ALLOWED));
    } else {
      const err = await response.json();
      dispatch(setDealError(err?.errors[0]?.message));

      if (err?.errors[0]?.detail) {
        const errEntries = Object.entries(err?.errors[0]?.detail);

        errEntries.forEach(([key, value]) => {
          setErrorsToInput(key, { type: "api", message: value[0] });
        });
      }
    }
  }
  dispatch(setIsLoadingTiming(false));
};

export { getTimingTabInfo, updateTimingTab };
