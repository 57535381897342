const questions = [
  {
    number_code: "1.1",
    name: "intellectual_property_litigation",
    hasTextareaDefaultNote: false,
    hasDefaultTextarea: false,
  },
  {
    number_code: "1.2",
    name: "securities_law_violation",
    hasTextareaDefaultNote: false,
    hasDefaultTextarea: false,
  },
  {
    number_code: "1.3",
    name: "derivative_lawsuits",
    hasTextareaDefaultNote: false,
    hasDefaultTextarea: false,
  },
  {
    number_code: "1.4",
    name: "contractual_representation_breach",
    hasTextareaDefaultNote: false,
    hasDefaultTextarea: false,
  },
  {
    number_code: "1.5",
    name: "governmental_involving_dispute",
    hasTextareaDefaultNote: false,
    hasDefaultTextarea: false,
  },
  {
    number_code: "1.6",
    name: "bankruptcy_proceeding",
    hasTextareaDefaultNote: false,
    hasDefaultTextarea: false,
  },
  {
    number_code: "1.7",
    name: "securities_offerings_description",
    hasTextareaDefaultNote: false,
    hasDefaultTextarea: true,
  },
  {
    number_code: "1.8",
    name: "target_privately_held_description",
    hasTextareaDefaultNote: false,
    hasDefaultTextarea: true,
  },
  {
    number_code: "1.9",
    name: "corporate_restructurings_description",
    hasTextareaDefaultNote: false,
    hasDefaultTextarea: true,
  },
  {
    number_code: "1.10",
    name: "change_accountants",
    hasTextareaDefaultNote: false,
    hasDefaultTextarea: false,
  },
  {
    number_code: "1.11",
    name: "change_accounting_method",
    hasTextareaDefaultNote: false,
    hasDefaultTextarea: false,
  },
];

export default questions;
