import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTable, useRowSelect } from "react-table";
import { useNavigate, useSearchParams } from "react-router-dom";
import classNames from "classnames";

import { formatPropName } from "../../../../../helpers";
import {
  formatCurrency,
  // reformatDealDate,
} from "../../../../../helpers/broker-helpers";
import QMToolTipName from "../../../common/QMToolTipName";
import AcceptDeclineBlock from "./AcceptDecline/AcceptDeclineBlock";

import "./styles.scss";
import NoAccessModal from "./AcceptDecline/NoAccessModal";
import AcceptConfirmationModal from "./AcceptDecline/AcceptConfirmationModal";

const DealsTable = ({ data }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const queryObj = Object.fromEntries([...searchParams]);
  const [rowWithInnerActive, setRowWithInnerActive] = useState(null);
  const [showNoAccessModal, setShowNoAccessModal] = useState(false);
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [currentDeal, setCurrentDeal] = useState(null);

  const handleMouseDown = (e, id) => {
    e.stopPropagation();
    setRowWithInnerActive(id);
  };
  const handleMouseUp = (e) => {
    e.stopPropagation();
    setRowWithInnerActive(null);
  };

  const handleClick = useCallback(
    (row) => {
      if (row.original.partner_assignment_status === "accepted") {
        navigate(`${row.id}`);
      } else {
        setCurrentDeal({
          dealId: row.id,
          dealName: row.original.name,
        });
        setShowNoAccessModal(true);
      }
    },
    [navigate]
  );

  const handleNamesLength = (value) => {
    const maxLength = 11;
    if (value?.length > maxLength) {
      return (
        <span>
          <QMToolTipName maxLength={maxLength} text={value} />
        </span>
      );
    }
    return value;
  };

  const columns = useMemo(
    () => [
      {
        Header: t("general.deal_name"),
        accessor: "name",
        Cell: ({ value, row }) => (
          <button
            className="QM-table_cell__clickable QM-btn__transparent"
            onClick={(e) => {
              e.stopPropagation();
              handleClick(row);
            }}
            onMouseDown={(e) => handleMouseDown(e, row.id)}
            onMouseUp={handleMouseUp}
          >
            {handleNamesLength(value)}
            <span className="icon-external-link" />
          </button>
        ),
      },
      // {
      //   Header: t("general.closing_date"),
      //   accessor: "closing_date",
      //   Cell: ({ value }) => <p>{value ? reformatDealDate(value) : value}</p>,
      // },
      {
        Header: t("general.brokerage"), // should be R&W Broker (company)
        accessor: "brokerage.company_name",
        Cell: ({ value }) => <p>{formatPropName(value)}</p>,
      },
      {
        Header: t("general.buyer"),
        accessor: "buyer.name",
        Cell: ({ value, row }) =>
          //TODO: change to NavLink after behavior is defined
          row.original.partner_assignment_status === "accepted" ? (
            <p>
              {handleNamesLength(value)}
              <small>{handleNamesLength(row?.original?.buyer?.website)}</small>
            </p>
          ) : (
            <p></p>
          ),
      },
      {
        Header: t("general.target"), // should be TARGET
        accessor: "seller.name",
        Cell: ({ value, row }) =>
          //TODO: change to NavLink after behavior is defined
          row.original.partner_assignment_status === "accepted" ? (
            <p>
              {handleNamesLength(value)}
              <small>{handleNamesLength(row?.original?.seller?.website)}</small>
            </p>
          ) : (
            <p></p>
          ),
      },
      {
        Header: t("general.deal_size"),
        accessor: "deal_size",
        Cell: ({ value, row }) =>
          row.original.partner_assignment_status === "accepted" ? (
            <p>{formatCurrency(value)}</p>
          ) : (
            <p></p>
          ),
      },
      {
        Header: t("risk_profile.deal_general_qscore"),
        accessor: "q_score.score",
        Cell: ({ value, row }) =>
          row.original.partner_assignment_status === "accepted" ? (
            <p>{value >= 0 ? value : "No data"}</p>
          ) : (
            <p></p>
          ),
      },
      {
        Header: t("general.status"),
        accessor: "partner_assignment_status",
        Cell: ({ value, row }) => {
          return value === "pending" ? (
            <AcceptDeclineBlock deal={row.original} />
          ) : (
            <p>{formatPropName(value)}</p>
          );
        },
      },
    ],
    [t, handleClick]
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // state: { selectedRowIds },
    // toggleAllRowsSelected,
  } = useTable(
    {
      columns,
      data,
      getRowId: useCallback((row) => row.id, []),
    },
    useRowSelect
  );

  const getRowSelectedStyle = (row) => {
    return classNames({
      "QM-table_row__selected": row.id === queryObj?.dealId,
      "QM-table_row__hasInnerActive": rowWithInnerActive === row.id,
    });
  };

  return (
    <section className="QM-table_scroller">
      <table {...getTableProps()} className="QM-table QM-table_partnerDeals">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                onClick={() => handleClick(row)}
                className={getRowSelectedStyle(row)}
              >
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {showNoAccessModal && (
        <NoAccessModal
          dealId={currentDeal?.dealId}
          dealName={currentDeal.dealName}
          setModalOpen={setShowNoAccessModal}
          openAcceptModal={setShowAcceptModal}
        />
      )}
      {showAcceptModal && (
        <AcceptConfirmationModal
          dealId={currentDeal?.dealId}
          dealName={currentDeal?.dealName}
          setModalOpen={setShowAcceptModal}
        />
      )}
    </section>
  );
};

export default DealsTable;
