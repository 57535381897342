import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import QMButton from "../../../common/QMButton";
import { QMPhoneNumberInput, QMTextInput } from "../../../common/QMInputs";
import QMSaveLayout from "../../../common/QMSaveLayout";
import FieldsetNewDueDilligence from "./FieldsetNewDueDilligence";
import FieldsetBasic from "./FieldsetBasic";
import FieldsetPCBroker from "./FieldsetPCBroker";
import QMCheckbox from "../../../common/QMCheckbox";

import * as validation from "./validationRules";

import "./styles.scss";

const emptyDueDiligence = {
  type: "",
  company_name: "",
  contact_name: "",
  contact_phone: "",
  contact_email: "",
};

const ContactsForm = ({
  title,
  watch,
  register,
  control,
  errors,
  isDirty,
  handleSubmit,
  isSubmitSuccessful,
  setValue,
  dueDiligence,
  append,
  remove,
}) => {
  const { t } = useTranslation();

  const boolOpts = [
    { value: true, label: t("general.yes") },
    { value: false, label: t("general.no") },
  ];

  const isPrimaryApplicable = watch(`primary.is_applicable`);

  return (
    <form className="QM-dealContactsForm" onSubmit={handleSubmit}>
      <fieldset className="QM-formBlock">
        <legend>
          <p>{t(`contact.has_primary_${title}`)}</p>
          <div className="QM-formBlock_checkbox">
            <QMCheckbox {...register("primary.is_applicable")} />
            <span>{t("general.yes")}</span>
          </div>
        </legend>
        <div className="QM-formBlock_row">
          <QMTextInput
            {...register("primary.contact_name", validation.nameValidation)}
            label={t("contact.contact_name")}
            placeholder={t("contact.contact_name_placeholder")}
            errors={errors?.primary?.contact_name}
            disabled={!isPrimaryApplicable}
          />
          <Controller
            render={({ field }) => (
              <QMPhoneNumberInput
                {...field}
                label={t("contact.contact_phone")}
                placeholder={t("general.phone_placeholder")}
                errors={errors?.primary?.contact_phone}
                disabled={!isPrimaryApplicable}
              />
            )}
            control={control}
            name="primary.contact_phone"
            rules={validation.phoneValidation}
          />
        </div>
        <div className="QM-formBlock_row">
          <QMTextInput
            {...register("primary.contact_email", validation.emailValidation)}
            label={t("contact.contact_email")}
            placeholder={t("general.email_placeholder")}
            errors={errors?.primary?.contact_email}
            disabled={!isPrimaryApplicable}
          />
        </div>
      </fieldset>
      <FieldsetBasic
        legendTitle={t(`contact.has_legal_${title}`)}
        register={register}
        control={control}
        watch={watch}
        groupName="legal"
        errors={errors}
        boolOpts={boolOpts}
        setValue={setValue}
      />
      <FieldsetPCBroker
        legendTitle={t(`contact.p_c_broker_${title}`)}
        register={register}
        control={control}
        watch={watch}
        groupName="p_c_broker"
        errors={errors}
        setValue={setValue}
        legendText={t("contact.is_p_c_broker_not_i")}
      />
      <fieldset className="QM-formBlock">
        <legend>
          <p>{t("deal.due_dilligence_providers")}</p>
        </legend>
        <QMTextInput
          {...register(
            "due_diligence_providers_count",
            validation.countValidation
          )}
          label={t("deal.how_many_due_dilligence_providers")}
          placeholder={t("general.enter_number")}
          errors={errors?.due_diligence_providers_count}
          wrapperClassName="QM-dueDilligenceCount"
        />
      </fieldset>
      {dueDiligence.map((dueDiligenceItem, index) => (
        <FieldsetNewDueDilligence
          register={register}
          watch={watch}
          control={control}
          groupName={`due_diligence_providers.${index}`}
          errors={errors}
          key={dueDiligenceItem.id}
          handleRemove={() => remove(index)}
          setValue={setValue}
        />
      ))}
      <div className="QM-btnRow">
        <QMButton
          viewType="secondary"
          iconClassName="icon-plus"
          type="button"
          text={t("deal.add_due_dilligence_contact")}
          onClick={() => {
            append(emptyDueDiligence);
          }}
        />
      </div>
      {isDirty && !isSubmitSuccessful && <QMSaveLayout />}
    </form>
  );
};

export default ContactsForm;
