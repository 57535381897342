import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isNewDealOpen: false,
}

export const brokerActionsReducer = createSlice({
  name: 'brokerActionsMenu',
  initialState,
  reducers: {
    setIsNewDealOpen: (state, action) => {
      state.isNewDealOpen = action.payload
    },
  }
});
export const {
  setIsNewDealOpen,
} = brokerActionsReducer.actions;

export default brokerActionsReducer.reducer;