import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoadingUnderwriting: false,
  underwritingInfo: {},
  underwritingCarriersInfo: [],
};

export const underwritingReducer = createSlice({
  name: "underwriting",
  initialState,
  reducers: {
    setIsLoadingUnderwriting: (state, action) => {
      state.isLoadingUnderwriting = action.payload;
    },
    setUnderwritingInfo: (state, action) => {
      state.underwritingInfo = action.payload;
    },
    setUnderwritingCarriersInfo: (state, action) => {
      state.underwritingCarriersInfo = action.payload;
    },
  },
});

export const {
  setIsLoadingUnderwriting,
  setUnderwritingInfo,
  setUnderwritingCarriersInfo,
} = underwritingReducer.actions;

export default underwritingReducer.reducer;
