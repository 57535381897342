import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import QMModal from "../../../../common/QMModal";
import QMButton from "../../../../common/QMButton";
import QMCheckbox from "../../../../common/QMCheckbox";

import acceptValidationSchema from "./acceptValidationSchema";
import { manageInvitation } from "../../../../../../api/partnerInvitationApi";
import { getDeals } from "../../../../../../api/dealsApi";

import "./styles.scss";

const AcceptConfirmationModal = ({ dealId, dealName, setModalOpen }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setModalOpen(false);
  };
  const handleAcceptSuccess = async () => {
    navigate(`${dealId}`);
    await getDeals(dispatch);
    setModalOpen(false);
  };

  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    resolver: yupResolver(acceptValidationSchema),
    mode: "onChange",
  });
  const onSubmit = (data) => {
    manageInvitation(dispatch, dealId, "accepted", handleAcceptSuccess);
  };
  return (
    <QMModal
      customClassName="QM-manageInvitationModal QM-acceptDealModal"
      size="medium"
      onClick={(e) => e.stopPropagation()}
    >
      <header>
        <h2>{`${t("partner.accept_deal")} ${dealName}`}</h2>
        <QMButton
          viewType="transparent"
          iconClassName="icon-x"
          onClick={handleClose}
        />
      </header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <section className="QM-manageInvitationModal_body">
          <QMCheckbox
            {...register("confirm_legal_right")}
            label={t("partner.confirm_1")}
          />
          <QMCheckbox
            {...register("confirm_licenses")}
            label={t("partner.confirm_2")}
          />
          <QMCheckbox
            {...register("declare_foregoing_true")}
            label={t("partner.confirm_3")}
          />
        </section>
        <footer>
          <QMButton
            viewType="secondary"
            onClick={handleClose}
            type="button"
            text={t("general.cancel")}
          />
          <QMButton
            onClick={() => {}}
            type="submit"
            text={t("general.accept")}
            disabled={!isValid}
          />
        </footer>
      </form>
    </QMModal>
  );
};

export default AcceptConfirmationModal;
