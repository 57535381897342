import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import AssigneeSearchResultItem from "./AssigneeSearchResultItem";
import useOuterClick from "../../../../../../helpers/useOuterClick";

import "./styles.scss";

const AssigneeSearch = ({
  isLoading,
  label,
  data,
  setSearchResult,
  searchInput,
  setSearchInput,
  ...props
}) => {
  const labelClasses = classNames("icon-search", "QM-globalSearch_label", {
    "QM-globalSearch_label__isLoading": isLoading,
  });
  const [showResults, setShowResults] = useState(false);
  const innerRef = useOuterClick((e) => setShowResults(false));
  const { t } = useTranslation();
  const [filteredResults, setFilteredResults] = useState([]);

  const searchItems = (searchValue) => {
    setSearchInput(searchValue);
    if (searchValue.length) {
      const filteredData = data.filter(({ attorney_name, attorney_email }) => {
        const nameMatch = attorney_name
          ? attorney_name.toLowerCase().startsWith(searchValue.toLowerCase())
          : false;
        const emailMatch = attorney_email
          ? attorney_email.toLowerCase().startsWith(searchValue.toLowerCase())
          : false;
        return nameMatch || emailMatch;
      });
      setFilteredResults(filteredData);
    } else {
      setFilteredResults([]);
    }
  };

  const handleSearchChange = (e) => {
    e.preventDefault();
    searchItems(e.target.value);

    if (!e.target.value) {
      setSearchResult({});
      setFilteredResults(data);
    }
  };

  return (
    <section className="QM-globalSearch_form" ref={innerRef}>
      <label htmlFor="CompanySearch__Field" className={labelClasses}>
        <input
          type="text"
          name="CompanySearch__Field"
          id="CompanySearch__Field"
          className="QM-globalSearch_field"
          placeholder={t("assign_lawyer.search_placeholder")}
          // autoFocus={true}
          onChange={(e) => handleSearchChange(e)}
          onFocus={() => {
            setShowResults(true);
            !searchInput && setFilteredResults(data);
          }}
          value={searchInput}
          {...props}
        />
      </label>
      {showResults ? (
        <ul id="CompanySearch__Results" className="QM-globalSearch_resultList">
          {filteredResults.length ? (
            filteredResults.map((item, index) => (
              <AssigneeSearchResultItem
                key={index}
                setSearchInput={setSearchInput}
                setSearchResult={setSearchResult}
                setFilteredResults={setFilteredResults}
                setShowResults={setShowResults}
                item={item}
              />
            ))
          ) : (
            <p className="QM-globalSearch_emptyResult">No matches found</p>
          )}
        </ul>
      ) : (
        <></>
      )}
    </section>
  );
};

AssigneeSearch.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
};

AssigneeSearch.defaultProps = {
  onClick: () => {},
};

export default AssigneeSearch;
