import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";

import { QMSelectInput, QMTextInput } from "../../../common/QMInputs";

const DataRoomInfoBlock = ({
  register,
  control,
  watch,
  getFieldErrors,
  setValue,
  clearErrors,
}) => {
  const { t } = useTranslation();
  const externalDataRoomAccessWatch = watch("external_data_room_count");
  const externalDataRoomAccessOpts = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
  ];

  const handleExternalDataRoomCountChange = (val, field) => {
    if (val === null) {
      clearErrors("external_data_room_url_first", null);
      clearErrors("external_data_room_url_second", null);
      setValue("external_data_room_url_first", "");
      setValue("external_data_room_url_second", "");
    }
    const updatedVal = val === null ? val : val.value;
    field.onChange(updatedVal);
  };

  useEffect(() => {
    if (externalDataRoomAccessWatch === 1) {
      setValue("external_data_room_url_second", null);
    }
  }, [externalDataRoomAccessWatch, setValue]);

  return (
    <section className="QM-formBlock">
      <section className="QM-formRow">
        <Controller
          render={({ field }) => (
            <QMSelectInput
              {...field}
              placeholder={t("general.choose_answer")}
              label={t("new_deal_form.external_data_room_access_label")}
              errors={getFieldErrors("external_data_room_count")}
              options={externalDataRoomAccessOpts}
              value={externalDataRoomAccessOpts.find(
                (c) => c.value === field.value
              )}
              ref={field.ref}
              isClearable
              onChange={(val) => {
                handleExternalDataRoomCountChange(val, field);
              }}
            />
          )}
          control={control}
          name="external_data_room_count"
        />
        <QMTextInput
          {...register("external_data_room_url_first")}
          label={t("new_deal_form.secure_url_to_data_room_1")}
          placeholder={t("general.url_placeholder")}
          errors={getFieldErrors("external_data_room_url_first")}
          disabled={
            externalDataRoomAccessWatch !== 1 &&
            externalDataRoomAccessWatch !== 2
          }
          isRequired={
            externalDataRoomAccessWatch === 1 ||
            externalDataRoomAccessWatch === 2
          }
        />
        <QMTextInput
          {...register("external_data_room_url_second")}
          label={t("new_deal_form.secure_url_to_data_room_2")}
          placeholder={t("general.url_placeholder")}
          errors={getFieldErrors("external_data_room_url_second")}
          disabled={externalDataRoomAccessWatch !== 2}
          isRequired={externalDataRoomAccessWatch === 2}
        />
      </section>
    </section>
  );
};

export default DataRoomInfoBlock;
