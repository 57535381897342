import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import sampleAvatar from "../../../../assets/imgs/avatar.jpg";

import QMDropdownMenu, { QMDropDownMenuItem } from "../QMDropdownMenu";
import {
  getTokenData,
  removeTokenData,
} from "../../../../helpers/auth-helpers";

import "./styles.scss";

const QMUserBlock = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [userData, setUserData] = useState({});
  const navigate = useNavigate();

  const menuConfig = [
    {
      text: "Profile",
      iconClassName: "icon-user",
      onClick: () => {
        navigate("/broker");
      },
    },
    {
      text: "Settings",
      iconClassName: "icon-settings",
      onClick: () => {
        navigate("/broker");
      },
    },
    {
      text: "Sign out",
      iconClassName: "icon-sign_out",
      onClick: () => {
        removeTokenData();
        navigate("/sign-in", { replace: true });
      },
    },
  ];
  useEffect(() => {
    const user = getTokenData();
    setUserData(user);
  }, []);
  return (
    <div className="QM-userBlock">
      <NavLink to="/user" className="QM-userBlock_summary">
        <img
          src={sampleAvatar}
          width="46"
          height="46"
          alt="user avatar"
          className="QM-userBlock_avatar"
        />
        <span>
          <strong
            title={`${userData?.first_name} ${userData?.last_name}`}
          >{`${userData?.first_name} ${userData?.last_name}`}</strong>
          <small title={userData?.email}>{userData?.email}</small>
        </span>
      </NavLink>
      <button
        className={classNames(
          "QM-userBlock_btn",
          isMenuOpen ? "icon-chevron-down" : "icon-chevron-up"
        )}
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      />
      {isMenuOpen && (
        <QMDropdownMenu
          closeMenu={() => setIsMenuOpen(false)}
          customClassName="QM-userBlock_dropdown"
        >
          {menuConfig.map((item, i) => (
            <QMDropDownMenuItem key={i} {...item} />
          ))}
        </QMDropdownMenu>
      )}
    </div>
  );
};

export default QMUserBlock;
