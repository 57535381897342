import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import QMButton from "../../common/QMButton";
import QMRadioSet from "../../common/QMRadioSet/QMRadioSet";
import QMToastMessage from "../../common/QMToastMessage";
import QMModal from "../../common/QMModal";

import BuyerInfoBlock from "./FormBlocks/BuyerInfoBlock";
import SellerInfoBlock from "./FormBlocks/SellerInfoBlock";
import GeneralInfoBlock from "./FormBlocks/GeneralInfoBlock";
import IntegrationLeadInfoBlock from "./FormBlocks/IntegrationLeadInfoBlock";
import DataRoomInfoBlock from "./FormBlocks/DataRoomInfoBlock";
import OrgChartBlock from "./FormBlocks/OrgChartBlock";
import SpaBlock from "./FormBlocks/SpaBlock";

import "./styles.scss";
import { SELF_CLOSE_DELAY } from "../../../../helpers/broker-helpers";

const NewDealForm = ({
  register,
  handleSubmit,
  onUnderwritingSubmit,
  setError,
  getValues,
  setValue,
  clearErrors,
  watch,
  trigger,
  control,
  errors,
  touchedFields,
  isValid,
  isConfirmVisible,
  setIsConfirmVisible,
  buyerPrediction,
  buyerPredictionSetter,
  buyerParentPrediction,
  buyerParentPredictionSetter,
  sellerPrediction,
  sellerPredictionSetter,
  targetPrediction,
  targetPredictionSetter,
  setGeneralError,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const boolOpts = [
    { value: true, label: t("general.yes") },
    { value: false, label: t("general.no") },
  ];

  const prevCoverageOpts = [
    { value: "yes", label: t("general.yes") },
    { value: "no", label: t("general.no") },
  ];
  const [showIntegrationLeadWarning, setShowIntegrationLeadWarning] =
    useState(null);
  const [showOrgChartWarning, setShowOrgChartWarning] = useState(null);
  const [showSpaWarning, setShowSpaWarning] = useState(null);
  const [isCancelConfirmVisible, setIsCancelConfirmVisible] = useState(false);

  const getFieldErrors = (fieldName) => {
    return errors[fieldName] && touchedFields[fieldName]
      ? errors[fieldName]
      : undefined;
  };

  return (
    <section className="QM-newDeal">
      <h2>{t("broker_portal.new_deal")}</h2>
      <form onSubmit={handleSubmit}>
        <BuyerInfoBlock
          register={register}
          control={control}
          watch={watch}
          trigger={trigger}
          getFieldErrors={getFieldErrors}
          boolOpts={boolOpts}
          setValue={setValue}
          predictionSetter={buyerPredictionSetter}
          selectedPrediction={buyerPrediction}
          optPropPredictionSetter={buyerParentPredictionSetter}
          optPropPrediction={buyerParentPrediction}
        />
        <SellerInfoBlock
          register={register}
          control={control}
          watch={watch}
          trigger={trigger}
          getFieldErrors={getFieldErrors}
          setValue={setValue}
          predictionSetter={sellerPredictionSetter}
          selectedPrediction={sellerPrediction}
          optPropPredictionSetter={targetPredictionSetter}
          optPropPrediction={targetPrediction}
        />
        <GeneralInfoBlock
          register={register}
          control={control}
          watch={watch}
          getFieldErrors={getFieldErrors}
          clearErrors={clearErrors}
          setError={setError}
          getValues={getValues}
        />
        <IntegrationLeadInfoBlock
          register={register}
          control={control}
          watch={watch}
          getFieldErrors={getFieldErrors}
          boolOpts={boolOpts}
          setShowIntegrationLeadWarning={setShowIntegrationLeadWarning}
          setValue={setValue}
        />
        <DataRoomInfoBlock
          register={register}
          control={control}
          watch={watch}
          getFieldErrors={getFieldErrors}
          setValue={setValue}
          clearErrors={clearErrors}
        />
        <OrgChartBlock
          control={control}
          getFieldErrors={getFieldErrors}
          setValue={setValue}
          boolOpts={boolOpts}
          setShowOrgChartWarning={setShowOrgChartWarning}
          showOrgChartWarning={showOrgChartWarning}
          watch={watch}
          trigger={trigger}
          setGeneralError={setGeneralError}
        />
        <SpaBlock
          control={control}
          getFieldErrors={getFieldErrors}
          setValue={setValue}
          boolOpts={boolOpts}
          setShowSpaWarning={setShowSpaWarning}
          showSpaWarning={showSpaWarning}
          watch={watch}
          trigger={trigger}
          setGeneralError={setGeneralError}
        />
        <section className="QM-formRow__soloItem">
          <QMRadioSet
            register={register}
            options={prevCoverageOpts}
            legendText={t("new_deal_form.had_coverage_in_past")}
            name="had_coverage_in_past"
            errors={getFieldErrors("had_coverage_in_past")}
          />
        </section>
        <section className="QM-btnsFormRow">
          <QMButton
            viewType="secondary"
            text={t("general.cancel")}
            type="button"
            onClick={() => setIsCancelConfirmVisible(true)}
          />
          {/* Temporary hiding till the functionality is ready */}
          {/* <QMButton
            text={t("new_deal_form.non_binding_indication_now")}
            customClassName="newDeal-submit-btn"
            disabled={!isValid}
            type="button"
            onClick={() => setIsConfirmVisible(true)}
          /> */}
          <QMButton
            text={t("general.save_proceed")}
            type="submit"
            customClassName="newDeal-submit-btn"
            disabled={!isValid}
          />
        </section>
      </form>
      {showIntegrationLeadWarning === false && (
        <QMToastMessage
          text={t("new_deal_form.integration_lead_warning")}
          viewType="warning"
          iconClassName="icon-attention"
          onClose={() => setShowIntegrationLeadWarning(null)}
          selfCloseDelay={SELF_CLOSE_DELAY}
        />
      )}
      {showSpaWarning === false && (
        <QMToastMessage
          text={t("new_deal_form.org_chart_warning")}
          viewType="warning"
          iconClassName="icon-attention"
          onClose={() => setShowSpaWarning(null)}
          selfCloseDelay={SELF_CLOSE_DELAY}
        />
      )}
      {showOrgChartWarning === false && (
        <QMToastMessage
          text={t("new_deal_form.org_chart_warning")}
          viewType="warning"
          iconClassName="icon-attention"
          onClose={() => setShowOrgChartWarning(null)}
          selfCloseDelay={SELF_CLOSE_DELAY}
        />
      )}
      {isConfirmVisible && (
        <QMModal size="small" customClassName="QM-newDeal_confirmModal">
          <h2>{t("new_deal_form.non_binding_indication_confirm1")}</h2>
          <p>{t("new_deal_form.non_binding_indication_confirm2")}</p>
          <section className="QM-btnsFormRow">
            <QMButton
              viewType="secondary"
              onClick={() => setIsConfirmVisible(false)}
              text={t("general.cancel")}
            />
            <QMButton
              onClick={onUnderwritingSubmit}
              text={t("general.submit")}
            />
          </section>
        </QMModal>
      )}
      {isCancelConfirmVisible && (
        <QMModal size="small" customClassName="QM-newDeal_confirmModal">
          <h2>{t("new_deal_form.cancel_confirmation")}</h2>
          <section className="QM-btnsFormRow">
            <QMButton
              viewType="secondary"
              onClick={() => setIsCancelConfirmVisible(false)}
              type="button"
              text={t("general.cancel")}
            />
            <QMButton
              onClick={() => navigate(-1)}
              text={t("general.proceed")}
              type="button"
            />
          </section>
        </QMModal>
      )}
    </section>
  );
};

export default NewDealForm;
