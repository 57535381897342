import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import QMButton from "../QMButton";

import "./styles.scss";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const QMPdfViewer = ({ file, onClose }) => {
  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  return (
    <section className="QM-pdfViewer">
      <section className="QM-pdfViewer_content">
        <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
          ))}
        </Document>
      </section>
      <QMButton
        viewType="transparent"
        iconClassName="icon-x"
        customClassName="QM-pdfViewer_closeBtn"
        onClick={onClose}
      />
    </section>
  );
};

export default QMPdfViewer;
