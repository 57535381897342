import React from "react";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";

import {
  QMCurrencyInput,
  QMDateInput,
  QMSelectInput,
  QMTextInput,
} from "../../../common/QMInputs";

import { DEAL_DATE_FORMAT } from "../../../../../helpers/broker-helpers";
import jurisdictions from "../../../../../helpers/jurisdictions";
// import { TODAY } from "../../../../../helpers/validationConsts";

const GeneralInfoBlock = ({
  register,
  control,
  watch,
  getFieldErrors,
  clearErrors,
  setError,
  getValues,
}) => {
  const { t } = useTranslation();
  const applicantOpts = [
    { value: "seller", label: t("general.seller") },
    { value: "buyer", label: t("general.buyer") },
  ];

  const handleSelectChange = (val, field) => {
    const updatedVal = val === null ? val : val.value;
    field.onChange(updatedVal);
  };
  return (
    <section className="QM-formBlock">
      <section className="QM-formRow">
        <Controller
          render={({ field }) => (
            <QMSelectInput
              {...field}
              placeholder={t("new_deal_form.applicant_placeholder")}
              label={t("general.applicant")}
              errors={getFieldErrors("applicant")}
              options={applicantOpts}
              isSearchable={false}
              value={applicantOpts.find((c) => c.value === field.value)}
              onChange={(val) => handleSelectChange(val, field)}
              isRequired
              isClearable
            />
          )}
          control={control}
          name="applicant"
        />
        <Controller
          render={({ field }) => (
            <QMSelectInput
              {...field}
              placeholder={t("new_deal_form.jurisdiction_placeholder")}
              label={t("new_deal_form.jurisdiction_label")}
              errors={getFieldErrors("jurisdiction")}
              options={jurisdictions}
              isSearchable
              isRequired
              isClearable
            />
          )}
          control={control}
          name="jurisdiction"
        />
      </section>
      <section className="QM-formRow">
        <Controller
          render={({ field }) => (
            <QMCurrencyInput
              {...field}
              label={t("general.deal_size")}
              placeholder={t("general.currency_placeholder")}
              errors={getFieldErrors("deal_size")}
              isRequired
            />
          )}
          control={control}
          name="deal_size"
        />
        <Controller
          render={({ field }) => (
            <QMDateInput
              {...field}
              placeholder={t("general.select_date")}
              label={t("general.est_closing_date")}
              errors={getFieldErrors("closing_date")}
              format={DEAL_DATE_FORMAT}
              // minDate={TODAY}
              clearErrors={clearErrors}
              setError={setError}
              getValues={getValues}
              isRequired
            />
          )}
          control={control}
          name="closing_date"
        />
        <QMTextInput
          {...register("deal_name")}
          label={t("new_deal_form.deal_title_label")}
          placeholder={t("new_deal_form.deal_title_placeholder")}
          errors={getFieldErrors("deal_name")}
          isRequired
        />
      </section>
    </section>
  );
};

export default GeneralInfoBlock;
