import React from "react";
import { useTranslation } from "react-i18next";

import QMTile from "../../common/QMTile";
import RiskProfileStakeholdersitem from "./RiskProfileStakeholdersItem";

import "./styles.scss";

const RiskProfileStakeholders = ({ header, data }) => {
  const { t } = useTranslation();
  return (
    <QMTile>
      <h2 className="risk-profile__header">{header}</h2>
      <section className="risk-profile__general-body">
        {data?.length ? (
          <ul className="risk-profile__stakeholders-list">
            {data?.map((item, index) => {
              return (
                <RiskProfileStakeholdersitem
                  key={index}
                  name={item.name}
                  type={item.contact_type}
                  email={item.email}
                  phone={item.phone_number}
                  company={item.company_name}
                />
              );
            })}
          </ul>
        ) : (
          <div className="risk-profile__stakeholders-no-data">
            {t("general.no_data")}
          </div>
        )}
      </section>
    </QMTile>
  );
};
export default RiskProfileStakeholders;
