import * as yup from "yup";

import * as validation from "../../../../../helpers/validationConsts";

const checkMinValue = (value) => {
  if (value !== null && value !== undefined) {
    return (
      Number(value.replace(validation.MONEY_REMOVE_SIGNS, "")) >=
      validation.MAX_MONEY_INSURANCE
    );
  }
  return value;
};

const checkLimits = (limit, aggregate) => {
  if (
    limit !== null &&
    limit !== undefined &&
    limit !== "" &&
    aggregate !== null &&
    aggregate !== undefined &&
    aggregate !== ""
  ) {
    return (
      Number(limit.replace(validation.MONEY_REMOVE_SIGNS, "")) <
      Number(aggregate.replace(validation.MONEY_REMOVE_SIGNS, ""))
    );
  }
  return false;
};

const insuranceComplianceValidationSchema = yup.object({
  commercial_liability: yup.object({
    limit_per_claim: yup
      .string()
      .transform((value) => (!!value ? value : null))
      .nullable(true)
      .optional()
      .test(
        "commercial_liability.limit_per_claim",
        validation.ERROR_MESSAGE_MAX_MONEY_INSURANCE,
        (value) => {
          if (!!value) {
            return checkMinValue(value);
          }
          return true;
        }
      )
      .matches(
        validation.MONEY_WHOLE_THOUSANDS_REGEXP,
        validation.ERROR_MESSAGE_MONEY_WHOLE_THOUSANDS
      )
      .typeError(validation.ERROR_MESSAGE_REQUIRED),
    aggregate: yup
      .string()
      .transform((value) => (!!value ? value : null))
      .nullable(true)
      .optional()
      .test(
        "commercial_liability.aggregate",
        validation.ERROR_MESSAGE_MAX_MONEY_INSURANCE,
        (value) => {
          if (!!value) {
            return checkMinValue(value);
          }
          return true;
        }
      )
      .test(
        "commercial_liability.aggregate",
        validation.ERROR_MESSAGE_LIMIT,
        (value, ctx) => {
          if (!!ctx.parent.limit_per_claim && !!value) {
            return checkLimits(ctx.parent.limit_per_claim, value);
          }
          return true;
        }
      )
      .matches(
        validation.MONEY_WHOLE_THOUSANDS_REGEXP,
        validation.ERROR_MESSAGE_MONEY_WHOLE_THOUSANDS
      )
      .typeError(validation.ERROR_MESSAGE_REQUIRED),
  }),
  employment_practice_liability: yup.object({
    limit_per_claim: yup
      .string()
      .transform((value) => (!!value ? value : null))
      .nullable(true)
      .test(
        "employment_practice_liability.limit_per_claim",
        validation.ERROR_MESSAGE_MAX_MONEY_INSURANCE,
        (value) => {
          if (!!value) {
            return checkMinValue(value);
          }
          return true;
        }
      )
      .matches(
        validation.MONEY_WHOLE_THOUSANDS_REGEXP,
        validation.ERROR_MESSAGE_MONEY_WHOLE_THOUSANDS
      )
      .typeError(validation.ERROR_MESSAGE_REQUIRED),
    aggregate: yup
      .string()
      .transform((value) => (!!value ? value : null))
      .nullable(true)
      .test(
        "employment_practice_liability.aggregate",
        validation.ERROR_MESSAGE_MAX_MONEY_INSURANCE,
        (value) => {
          if (!!value) {
            return checkMinValue(value);
          }
          return true;
        }
      )
      .test(
        "employment_practice_liability.aggregate",
        validation.ERROR_MESSAGE_LIMIT,
        (value, ctx) => {
          if (!!ctx.parent.limit_per_claim && !!value) {
            return checkLimits(ctx.parent.limit_per_claim, value);
          }
          return true;
        }
      )
      .matches(
        validation.MONEY_WHOLE_THOUSANDS_REGEXP,
        validation.ERROR_MESSAGE_MONEY_WHOLE_THOUSANDS
      )
      .typeError(validation.ERROR_MESSAGE_REQUIRED),
  }),
  cyber_liability: yup.object({
    limit_per_claim: yup
      .string()
      .transform((value) => (!!value ? value : null))
      .nullable(true)
      .test(
        "cyber_liability.limit_per_claim",
        validation.ERROR_MESSAGE_MAX_MONEY_INSURANCE,
        (value) => {
          if (!!value) {
            return checkMinValue(value);
          }
          return true;
        }
      )
      .matches(
        validation.MONEY_WHOLE_THOUSANDS_REGEXP,
        validation.ERROR_MESSAGE_MONEY_WHOLE_THOUSANDS
      )
      .typeError(validation.ERROR_MESSAGE_REQUIRED),
    aggregate: yup
      .string()
      .transform((value) => (!!value ? value : null))
      .nullable(true)
      .test(
        "cyber_liability.aggregate",
        validation.ERROR_MESSAGE_MAX_MONEY_INSURANCE,
        (value) => {
          if (!!value) {
            return checkMinValue(value);
          }
          return true;
        }
      )
      .test(
        "cyber_liability.aggregate",
        validation.ERROR_MESSAGE_LIMIT,
        (value, ctx) => {
          if (!!ctx.parent.limit_per_claim && !!value) {
            return checkLimits(ctx.parent.limit_per_claim, value);
          }
          return true;
        }
      )
      .matches(
        validation.MONEY_WHOLE_THOUSANDS_REGEXP,
        validation.ERROR_MESSAGE_MONEY_WHOLE_THOUSANDS
      )
      .typeError(validation.ERROR_MESSAGE_REQUIRED),
  }),
  fiduciary_liability: yup.object({
    limit_per_claim: yup
      .string()
      .transform((value) => (!!value ? value : null))
      .nullable(true)
      .test(
        "fiduciary_liability.limit_per_claim",
        validation.ERROR_MESSAGE_MAX_MONEY_INSURANCE,
        (value) => {
          if (!!value) {
            return checkMinValue(value);
          }
          return true;
        }
      )
      .matches(
        validation.MONEY_WHOLE_THOUSANDS_REGEXP,
        validation.ERROR_MESSAGE_MONEY_WHOLE_THOUSANDS
      )
      .typeError(validation.ERROR_MESSAGE_REQUIRED),
    aggregate: yup
      .string()
      .transform((value) => (!!value ? value : null))
      .nullable(true)
      .test(
        "fiduciary_liability.aggregate",
        validation.ERROR_MESSAGE_MAX_MONEY_INSURANCE,
        (value) => {
          if (!!value) {
            return checkMinValue(value);
          }
          return true;
        }
      )
      .test(
        "fiduciary_liability.aggregate",
        validation.ERROR_MESSAGE_LIMIT,
        (value, ctx) => {
          if (!!ctx.parent.limit_per_claim && !!value) {
            return checkLimits(ctx.parent.limit_per_claim, value);
          }
          return true;
        }
      )
      .matches(
        validation.MONEY_WHOLE_THOUSANDS_REGEXP,
        validation.ERROR_MESSAGE_MONEY_WHOLE_THOUSANDS
      )
      .typeError(validation.ERROR_MESSAGE_REQUIRED),
  }),
  contingent_liability: yup.object({
    limit_per_claim: yup
      .string()
      .transform((value) => (!!value ? value : null))
      .nullable(true)
      .test(
        "contingent_liability.limit_per_claim",
        validation.ERROR_MESSAGE_MAX_MONEY_INSURANCE,
        (value) => {
          if (!!value) {
            return checkMinValue(value);
          }
          return true;
        }
      )
      .matches(
        validation.MONEY_WHOLE_THOUSANDS_REGEXP,
        validation.ERROR_MESSAGE_MONEY_WHOLE_THOUSANDS
      )
      .typeError(validation.ERROR_MESSAGE_REQUIRED),
    aggregate: yup
      .string()
      .transform((value) => (!!value ? value : null))
      .nullable(true)
      .test(
        "contingent_liability.aggregate",
        validation.ERROR_MESSAGE_MAX_MONEY_INSURANCE,
        (value) => {
          if (!!value) {
            return checkMinValue(value);
          }
          return true;
        }
      )
      .test(
        "contingent_liability.aggregate",
        validation.ERROR_MESSAGE_LIMIT,
        (value, ctx) => {
          if (!!ctx.parent.limit_per_claim && !!value) {
            return checkLimits(ctx.parent.limit_per_claim, value);
          }
          return true;
        }
      )
      .matches(
        validation.MONEY_WHOLE_THOUSANDS_REGEXP,
        validation.ERROR_MESSAGE_MONEY_WHOLE_THOUSANDS
      )
      .typeError(validation.ERROR_MESSAGE_REQUIRED),
  }),
  workers_compensation: yup.object({
    limit_per_claim: yup
      .string()
      .transform((value) => (!!value ? value : null))
      .nullable(true)
      .test(
        "workers_compensation.limit_per_claim",
        validation.ERROR_MESSAGE_MAX_MONEY_INSURANCE,
        (value) => {
          if (!!value) {
            return checkMinValue(value);
          }
          return true;
        }
      )
      .matches(
        validation.MONEY_WHOLE_THOUSANDS_REGEXP,
        validation.ERROR_MESSAGE_MONEY_WHOLE_THOUSANDS
      )
      .typeError(validation.ERROR_MESSAGE_REQUIRED),
    aggregate: yup
      .string()
      .transform((value) => (!!value ? value : null))
      .nullable(true)
      .test(
        "workers_compensation.aggregate",
        validation.ERROR_MESSAGE_MAX_MONEY_INSURANCE,
        (value) => {
          if (!!value) {
            return checkMinValue(value);
          }
          return true;
        }
      )
      .test(
        "workers_compensation.aggregate",
        validation.ERROR_MESSAGE_LIMIT,
        (value, ctx) => {
          if (!!ctx.parent.limit_per_claim && !!value) {
            return checkLimits(ctx.parent.limit_per_claim, value);
          }
          return true;
        }
      )
      .matches(
        validation.MONEY_WHOLE_THOUSANDS_REGEXP,
        validation.ERROR_MESSAGE_MONEY_WHOLE_THOUSANDS
      )
      .typeError(validation.ERROR_MESSAGE_REQUIRED),
  }),
  representations_and_warranties: yup.object({
    limit_per_claim: yup
      .string()
      .transform((value) => (!!value ? value : null))
      .nullable(true)
      .test(
        "representations_and_warranties.limit_per_claim",
        validation.ERROR_MESSAGE_MAX_MONEY_INSURANCE,
        (value) => {
          if (!!value) {
            return checkMinValue(value);
          }
          return true;
        }
      )
      .matches(
        validation.MONEY_WHOLE_THOUSANDS_REGEXP,
        validation.ERROR_MESSAGE_MONEY_WHOLE_THOUSANDS
      )
      .typeError(validation.ERROR_MESSAGE_REQUIRED),
    aggregate: yup
      .string()
      .transform((value) => (!!value ? value : null))
      .nullable(true)
      .test(
        "representations_and_warranties.aggregate",
        validation.ERROR_MESSAGE_MAX_MONEY_INSURANCE,
        (value) => {
          if (!!value) {
            return checkMinValue(value);
          }
          return true;
        }
      )
      .test(
        "representations_and_warranties.aggregate",
        validation.ERROR_MESSAGE_LIMIT,
        (value, ctx) => {
          if (!!ctx.parent.limit_per_claim && !!value) {
            return checkLimits(ctx.parent.limit_per_claim, value);
          }
          return true;
        }
      )
      .matches(
        validation.MONEY_WHOLE_THOUSANDS_REGEXP,
        validation.ERROR_MESSAGE_MONEY_WHOLE_THOUSANDS
      )
      .typeError(validation.ERROR_MESSAGE_REQUIRED),
  }),
  technology_errors_omissions: yup.object({
    limit_per_claim: yup
      .string()
      .transform((value) => (!!value ? value : null))
      .nullable(true)
      .test(
        "technology_errors_omissions.limit_per_claim",
        validation.ERROR_MESSAGE_MAX_MONEY_INSURANCE,
        (value) => {
          if (!!value) {
            return checkMinValue(value);
          }
          return true;
        }
      )
      .matches(
        validation.MONEY_WHOLE_THOUSANDS_REGEXP,
        validation.ERROR_MESSAGE_MONEY_WHOLE_THOUSANDS
      )
      .typeError(validation.ERROR_MESSAGE_REQUIRED),
    aggregate: yup
      .string()
      .transform((value) => (!!value ? value : null))
      .nullable(true)
      .test(
        "technology_errors_omissions.aggregate",
        validation.ERROR_MESSAGE_MAX_MONEY_INSURANCE,
        (value) => {
          if (!!value) {
            return checkMinValue(value);
          }
          return true;
        }
      )
      .test(
        "technology_errors_omissions.aggregate",
        validation.ERROR_MESSAGE_LIMIT,
        (value, ctx) => {
          if (!!ctx.parent.limit_per_claim && !!value) {
            return checkLimits(ctx.parent.limit_per_claim, value);
          }
          return true;
        }
      )
      .matches(
        validation.MONEY_WHOLE_THOUSANDS_REGEXP,
        validation.ERROR_MESSAGE_MONEY_WHOLE_THOUSANDS
      )
      .typeError(validation.ERROR_MESSAGE_REQUIRED),
  }),
  tax_liability: yup.object({
    limit_per_claim: yup
      .string()
      .transform((value) => (!!value ? value : null))
      .nullable(true)
      .test(
        "tax_liability.limit_per_claim",
        validation.ERROR_MESSAGE_MAX_MONEY_INSURANCE,
        (value) => {
          if (!!value) {
            return checkMinValue(value);
          }
          return true;
        }
      )
      .matches(
        validation.MONEY_WHOLE_THOUSANDS_REGEXP,
        validation.ERROR_MESSAGE_MONEY_WHOLE_THOUSANDS
      )
      .typeError(validation.ERROR_MESSAGE_REQUIRED),
    aggregate: yup
      .string()
      .transform((value) => (!!value ? value : null))
      .nullable(true)
      .test(
        "tax_liability.aggregate",
        validation.ERROR_MESSAGE_MAX_MONEY_INSURANCE,
        (value) => {
          if (!!value) {
            return checkMinValue(value);
          }
          return true;
        }
      )
      .test(
        "tax_liability.aggregate",
        validation.ERROR_MESSAGE_LIMIT,
        (value, ctx) => {
          if (!!ctx.parent.limit_per_claim && !!value) {
            return checkLimits(ctx.parent.limit_per_claim, value);
          }
          return true;
        }
      )
      .matches(
        validation.MONEY_WHOLE_THOUSANDS_REGEXP,
        validation.ERROR_MESSAGE_MONEY_WHOLE_THOUSANDS
      )
      .typeError(validation.ERROR_MESSAGE_REQUIRED),
  }),
  crime_liability: yup.object({
    limit_per_claim: yup
      .string()
      .transform((value) => (!!value ? value : null))
      .nullable(true)
      .test(
        "crime_liability.limit_per_claim",
        validation.ERROR_MESSAGE_MAX_MONEY_INSURANCE,
        (value) => {
          if (!!value) {
            return checkMinValue(value);
          }
          return true;
        }
      )
      .matches(
        validation.MONEY_WHOLE_THOUSANDS_REGEXP,
        validation.ERROR_MESSAGE_MONEY_WHOLE_THOUSANDS
      )
      .typeError(validation.ERROR_MESSAGE_REQUIRED),
    aggregate: yup
      .string()
      .transform((value) => (!!value ? value : null))
      .nullable(true)
      .test(
        "crime_liability.aggregate",
        validation.ERROR_MESSAGE_MAX_MONEY_INSURANCE,
        (value) => {
          if (!!value) {
            return checkMinValue(value);
          }
          return true;
        }
      )
      .test(
        "crime_liability.aggregate",
        validation.ERROR_MESSAGE_LIMIT,
        (value, ctx) => {
          if (!!ctx.parent.limit_per_claim && !!value) {
            return checkLimits(ctx.parent.limit_per_claim, value);
          }
          return true;
        }
      )
      .matches(
        validation.MONEY_WHOLE_THOUSANDS_REGEXP,
        validation.ERROR_MESSAGE_MONEY_WHOLE_THOUSANDS
      )
      .typeError(validation.ERROR_MESSAGE_REQUIRED),
  }),
  product_liability: yup.object({
    limit_per_claim: yup
      .string()
      .transform((value) => (!!value ? value : null))
      .nullable(true)
      .test(
        "product_liability.limit_per_claim",
        validation.ERROR_MESSAGE_MAX_MONEY_INSURANCE,
        (value) => {
          if (!!value) {
            return checkMinValue(value);
          }
          return true;
        }
      )
      .matches(
        validation.MONEY_WHOLE_THOUSANDS_REGEXP,
        validation.ERROR_MESSAGE_MONEY_WHOLE_THOUSANDS
      )
      .typeError(validation.ERROR_MESSAGE_REQUIRED),
    aggregate: yup
      .string()
      .transform((value) => (!!value ? value : null))
      .nullable(true)
      .test(
        "product_liability.aggregate",
        validation.ERROR_MESSAGE_MAX_MONEY_INSURANCE,
        (value) => {
          if (!!value) {
            return checkMinValue(value);
          }
          return true;
        }
      )
      .test(
        "product_liability.aggregate",
        validation.ERROR_MESSAGE_LIMIT,
        (value, ctx) => {
          if (!!ctx.parent.limit_per_claim && !!value) {
            return checkLimits(ctx.parent.limit_per_claim, value);
          }
          return true;
        }
      )
      .matches(
        validation.MONEY_WHOLE_THOUSANDS_REGEXP,
        validation.ERROR_MESSAGE_MONEY_WHOLE_THOUSANDS
      )
      .typeError(validation.ERROR_MESSAGE_REQUIRED),
  }),
  directors_officers: yup.object({
    limit_per_claim: yup
      .string()
      .transform((value) => (!!value ? value : null))
      .nullable(true)
      .test(
        "directors_officers.limit_per_claim",
        validation.ERROR_MESSAGE_MAX_MONEY_INSURANCE,
        (value) => {
          if (!!value) {
            return checkMinValue(value);
          }
          return true;
        }
      )
      .matches(
        validation.MONEY_WHOLE_THOUSANDS_REGEXP,
        validation.ERROR_MESSAGE_MONEY_WHOLE_THOUSANDS
      )
      .typeError(validation.ERROR_MESSAGE_REQUIRED),
    aggregate: yup
      .string()
      .transform((value) => (!!value ? value : null))
      .nullable(true)
      .test(
        "directors_officers.aggregate",
        validation.ERROR_MESSAGE_MAX_MONEY_INSURANCE,
        (value) => {
          if (!!value) {
            return checkMinValue(value);
          }
          return true;
        }
      )
      .test(
        "directors_officers.aggregate",
        validation.ERROR_MESSAGE_LIMIT,
        (value, ctx) => {
          if (!!ctx.parent.limit_per_claim && !!value) {
            return checkLimits(ctx.parent.limit_per_claim, value);
          }
          return true;
        }
      )
      .matches(
        validation.MONEY_WHOLE_THOUSANDS_REGEXP,
        validation.ERROR_MESSAGE_MONEY_WHOLE_THOUSANDS
      )
      .typeError(validation.ERROR_MESSAGE_REQUIRED),
  }),
});

export { insuranceComplianceValidationSchema };
