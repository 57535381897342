import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";

import { getDealInfo } from "../../../api/dealApi";
import {
  setDealError,
  setIsForbidden,
  setIsLoadingDeal,
} from "../../../redux/reducers/dealPage-reducer";
import {
  dealErrorSelector,
  dealForbiddenSelector,
  dealLoadingSelector,
} from "../../../redux/selectors/deal-selectors";

import QMGlobalLoader from "../../common/QMLoader";
import QMTile from "../common/QMTile";
import DealPageHeader from "./DealPageHeader";
import QMTabs from "../common/QMTabs";
import QMToastMessage from "../common/QMToastMessage";

import RWIApplication from "./RWIApplication";
import ContactsTab from "./RWIApplication/ContactsTab";
import PastActivitiesTab from "./RWIApplication/PastActivitiesTab";
import TimingTab from "./RWIApplication/TimingTab/TimingTab";
import DueDilligenceTab from "./RWIApplication/DueDilligenceTab/DueDilligenceTab";
import DealInfoTab from "./RWIApplication/DealInfoTab";
import InsuranceTab from "./RWIApplication/InsuranceTab/InsuranceTab";
import Underwriting from "./Underwriting";
import Exposure from "./Exposure";
import RiskProfile from "./RiskProfile";
import FilesFolder from "./FilesFolder";

import "./styles.scss";
import { getTokenData, roles } from "../../../helpers/auth-helpers";
// import { getAssignLawyerList } from "../../../api/assignUserApi";

const DealPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector(dealLoadingSelector);
  const isForbidden = useSelector(dealForbiddenSelector);
  const dealError = useSelector(dealErrorSelector);
  const id = useParams();
  const { pathname } = useLocation();
  const { role } = getTokenData();

  const applicationRoutes = [
    {
      to: "rwi-application",
      title: t("deal.rwi_application"),
      acceptedRoles: [
        roles.RW_BROKER,
        roles.UNDERWRITER,
        roles.DEAL_ADMIN,
        roles.PARTNER,
      ],
    },
    {
      to: "risk-profile",
      title: t("deal.risk_profile"),
      acceptedRoles: [
        roles.RW_BROKER,
        roles.UNDERWRITER,
        roles.DEAL_ADMIN,
        roles.PARTNER,
      ],
    },
    {
      to: "exposure",
      title: "Exposure",
      acceptedRoles: [roles.UNDERWRITER, roles.DEAL_ADMIN],
    },
    {
      to: "underwriting",
      title: t("deal.underwriting"),
      acceptedRoles: [roles.RW_BROKER, roles.UNDERWRITER, roles.DEAL_ADMIN],
    },
    {
      to: "files-folder",
      title: t("deal.deal_room"),
      acceptedRoles: [
        roles.RW_BROKER,
        roles.UNDERWRITER,
        roles.DEAL_ADMIN,
        roles.PARTNER,
      ],
    },
  ];
  const getTabs = () => {
    return applicationRoutes.filter((route) =>
      route.acceptedRoles.includes(role)
    );
  };
  // useEffect(() => {
  //   document.body.classList.add('no-scroll');
  //   return () => document.body.classList.remove('no-scroll');
  // }, [])

  useEffect(() => {
    dispatch(setDealError(null));
    dispatch(setIsForbidden(false));
  }, [pathname, dispatch]);

  useEffect(() => {
    getDealInfo(dispatch, id.dealId).catch((err) => {
      console.error("uncaught error occured", err);
      dispatch(setDealError(t("errors.server_general")));
      dispatch(setIsLoadingDeal(false));
    });
  }, [t, dispatch, id.dealId]);

  // useEffect(() => {
  //   getAssignLawyerList(dispatch, id.dealId);
  // }, [dispatch, id.dealId]);

  const getContent = () => {
    return (
      <section
        className={classNames("QM-main QM-dealPage", {
          "no-scroll": isForbidden,
        })}
      >
        {isLoading && <QMGlobalLoader />}
        <QMTile>
          <DealPageHeader id={id?.dealId} />
          <QMTabs routes={getTabs()} />
        </QMTile>
        <Routes>
          <Route index element={<Navigate to="rwi-application" />} />
          <Route
            path="rwi-application"
            element={<RWIApplication />}
            exact
            strict
          >
            <Route index element={<Navigate to="contacts" />} />
            <Route path="contacts" element={<ContactsTab />} exact strict />
            <Route path="deal-info" element={<DealInfoTab />} exact strict />
            <Route
              path="past-activities"
              element={<PastActivitiesTab />}
              exact
              strict
            />
            <Route path="insurance" element={<InsuranceTab />} exact strict />
            <Route
              path="due-diligence"
              element={<DueDilligenceTab />}
              exact
              strict
            />
            <Route path="timing" element={<TimingTab />} exact strict />
          </Route>
          {/* <Route path="loss-analysis" element={<p>Loss analysis</p>} /> */}
          <Route path="risk-profile" element={<RiskProfile />} />
          {role !== roles.RW_BROKER && role !== roles.LAWYER && (
            <Route path="exposure" element={<Exposure />} />
          )}
          <Route path="underwriting" element={<Underwriting />} />
          <Route path="files-folder" element={<FilesFolder />} />
        </Routes>
        {dealError && !isLoading && (
          <QMToastMessage
            viewType="error"
            text={dealError}
            iconClassName="icon-attention"
            onClose={() => dispatch(setDealError(null))}
          />
        )}
      </section>
    );
  };
  return <>{getContent()}</>;
};

export default DealPage;
