import React from "react";
import { useTranslation } from "react-i18next";

import "./styles.scss";

const RiskProfileTableItem = ({ name, field_value, folder_value }) => {
  const { t } = useTranslation();

  const showFieldFolderStatus = (value) => {
    if (value?.code?.toLowerCase() === "complete") {
      return (
        <p className="risk-profile__table-success">
          <span>{t("risk_profile.complete")}</span>
        </p>
      );
    }

    if (value?.code?.toLowerCase() === "incomplete") {
      return (
        <p className="risk-profile__table-warning">
          <span>{t("risk_profile.incomplete")}</span>
        </p>
      );
    }

    if (value?.code?.toLowerCase() === "outstanding") {
      return (
        <p className="risk-profile__table-error">
          <span>{t("general.na")}</span>
        </p>
      );
    }

    if (value?.code === "False") {
      return (
        <p className="risk-profile__table-error">
          <span>{t("risk_profile.outstanding")}</span>
        </p>
      );
    }

    if (value.code === null) {
      return null;
    }
  };

  return (
    <li className="risk-profile__table-list-item">
      <span className="risk-profile__general-item">{name}</span>
      {showFieldFolderStatus(field_value)}
      {showFieldFolderStatus(folder_value)}
    </li>
  );
};
export default RiskProfileTableItem;
