import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  underwritingCarriersSelector,
  underwritingInfoSelector,
} from "../../../../redux/selectors/underwriting-selectors";
import QMButton from "../../common/QMButton";
import QMTile from "../../common/QMTile";

import "./styles.scss";

const UnderwritingHeader = ({ selectedCarriers, setShowUnderwritingModal }) => {
  const underwritingInfo = useSelector(underwritingInfoSelector);
  const underwritingCarriers = useSelector(underwritingCarriersSelector);
  const { t } = useTranslation();

  const countTotalSubmitedFields = (data) => {
    let counter = 0;
    data.forEach((item) => {
      if (item.underwriters[0].status !== null) {
        counter++;
      }
    });
    return counter;
  };

  return (
    <QMTile>
      <section className="underwriting-tab__header">
        <div className="underwriting-tab__header-info">
          <span className="underwriting-tab__header-text">
            {t("underwriting.total_carriers")}:{" "}
            {underwritingInfo ? underwritingInfo : t("general.no_data")}
          </span>
          <span className="underwriting-tab__header-text">
            {t("underwriting.submitted")}:{" "}
            {countTotalSubmitedFields(underwritingCarriers)}
          </span>
        </div>

        {selectedCarriers.length ? (
          <div className="underwriting-tab__header-choosed">
            <span className="underwriting-tab__header-choosed-text">
              {t("underwriting.selected")}: {selectedCarriers.length}{" "}
              {selectedCarriers.length === 1
                ? t("underwriting.carrier")
                : t("underwriting.carriers")}
            </span>
            <QMButton
              text={
                selectedCarriers.length === 1
                  ? t("underwriting.submit")
                  : t("underwriting.submit_all")
              }
              customClassName="QM-table_openLinkBtn"
              onClick={(e) => {
                e.stopPropagation();
                setShowUnderwritingModal(true);
                //sendDataFromTable(selectedCarriers);
              }}
            />
          </div>
        ) : null}
      </section>
    </QMTile>
  );
};
export default UnderwritingHeader;
