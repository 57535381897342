import React from "react";
import { useTranslation } from "react-i18next";

const EmptyState = () => {
  const { t } = useTranslation();
  return (
    <section className="QM-deals__empty">
      <h2>{t("lawyer_portal.no_deals")}</h2>
    </section>
  );
};

export default EmptyState;
