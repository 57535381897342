import React from "react";
import { useTranslation } from "react-i18next";

import QMButton from "../../common/QMButton";
import QMModal from "../../common/QMModal";
import RWIAllFilesTree from "../RWIApplication/RWIAllFilesTree";

import "./styles.scss";

const DocsModal = ({ handleClose }) => {
  const { t } = useTranslation();
  return (
    <QMModal customClassName="QM-docsModal">
      <section className="QM-docsModal_header">
        <h4>{t("documents.required_documents")}</h4>
        <section className="QM-docsModal_header_part">
          <span className="QM-docWidget_warning">
            {t("documents.only_pdf_accepted")}
          </span>
          <QMButton
            viewType="transparent"
            iconClassName="icon-x"
            onClick={handleClose}
          />
        </section>
      </section>
      <section className="QM-docsModal_body">
        <RWIAllFilesTree />
      </section>
    </QMModal>
  );
};

export default DocsModal;
