import React from "react";
import {
  Link,
  // scroller
} from "react-scroll";

import PosterAvif from "../../../assets/landing/poster.avif";
import PosterWebp from "../../../assets/landing/poster.webp";
import PosterJpg from "../../../assets/landing/poster.jpg";
import BgVideo from "../../../assets/landing/bg.mp4";
import "./styles.scss";

const VideoSection = ({ focusInput }) => {
  // NOTE: for option with simple <a>
  // const handleScroll = () => {
  //   scroller.scrollTo("ShortEstimateName", {
  //     spy: true,
  //     smooth: true,
  //     duration: 500,
  //     hashSpy: true,
  //     offset: -200,
  //   });
  //   // focusInput()
  // }
  return (
    <section id="landing__FirstScreen">
      <div className="landing__videoContainer">
        <picture>
          <source srcSet={PosterAvif} type="image/avif" />
          <source srcSet={PosterWebp} type="image/webp" />
          <source srcSet={PosterJpg} />
          <img
            src={PosterJpg}
            width="1903"
            height="758"
            alt="Follow the steps to find out how easy it is to get R&W Insurance"
          />
        </picture>
        <video
          id="comp-k4mkxomb_video"
          className="_3vVMz"
          role="presentation"
          crossOrigin="anonymous"
          playsInline
          preload="auto"
          muted
          loop
          autoPlay
          tabIndex="-1"
          width="100%"
          height="100%"
          src={BgVideo}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "center center",
            opacity: 1,
          }}
        />
      </div>
      <div className="custom__commonWidth">
        <h1>The smartest Reps & Warranties insurance for small transactions</h1>
        <p>
          Transactional and R&W insurance is a critical element of a successful
          M&A transaction. We streamline the process to make it easy, clear, and
          fast for brokers and underwriters.
        </p>
        {/* Leaving as a possible option for now */}
        {/* <p><a onClick={handleScroll} href="#ShortEstimateName" className="custom__button">Estimate M&A Insurance Costs</a></p>						 */}
        <p>
          <Link
            to="FormEstimatorShort"
            className="custom__button"
            spy={true}
            smooth={true}
            duration={500}
            hashSpy
            // onClick={() => focusInput()}
          >
            Estimate M&A Insurance Costs
          </Link>
        </p>
      </div>
    </section>
  );
};

export default VideoSection;
