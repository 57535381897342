import React, { useRef } from 'react';

// import QMLogo from '../dashboards/common/QMLogo';
import VideoSection from './VideoSection';
import NumbersSection from './NumbersSection';
import StepsSection from './StepsSection';
import EstimatorSection from './EstimatorSection';
import NeitherSection from './NeitherSection';
import QMHeader from './QMHeader';

import './styles.scss';

const LandingPage = () => {
  const inputToFocus = useRef(null);

  const focusInput = () => inputToFocus.current.focus();
  

  return (
    <section className='QM-landing'>
      <QMHeader />
      <main>
        <article>
          <VideoSection focusInput={focusInput}/>
          <NumbersSection />
          <StepsSection />
          <EstimatorSection inputRef={inputToFocus}/>
          <NeitherSection />
        </article>
      </main>
      <footer>
        <div className="custom__commonWidth">
          <p>©2022 Quantumerge, Inc. <span>All rights reserved</span></p>
          <p><a href="https://www.linkedin.com/company/quantumerge" target="_blank" rel="noreferrer noopener" className="icon-linkedin"><span>Linkedin</span></a></p>
        </div>
      </footer>
    </section>
  );
}

export default LandingPage;