import React from 'react';
import PropTypes from 'prop-types'; 
import classNames from 'classnames';

import './styles.scss';

const QMTextTab = ({
  isSelected,
  onSelect,
  tabName
}) => {
  const classes = classNames(
    'QM-textTab',
    {'QM-textTab__selected': isSelected}
  )
  const handleClick = () => {
    if (isSelected) return;
    onSelect();
  }
  return (
    <button className={classes} onClick={handleClick}>
      {tabName}
    </button>
  );
}

QMTextTab.propTypes = {
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
  tabName: PropTypes.string.isRequired
};

QMTextTab.defaultProps = {
  isSelected: false,
  onSelect: ()=>{},
};
export default QMTextTab;