import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  lawyersListData: {},
  assignUserErr: null,
  assignUserSuccess: null,
};

export const assignLawyerReducer = createSlice({
  name: "assignLawyer",
  initialState,
  reducers: {
    setLawyersListData: (state, action) => {
      state.lawyersListData = action.payload;
    },
    setAssignUserErr: (state, action) => {
      state.assignUserErr = action.payload;
    },
    setAssignUserSuccess: (state, action) => {
      state.assignUserSuccess = action.payload;
    },
  },
});

export const { setLawyersListData, setAssignUserErr, setAssignUserSuccess } =
  assignLawyerReducer.actions;

export default assignLawyerReducer.reducer;
