import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  getInsuranceComplianceTabInfo,
  getInsuranceTabInfo,
} from "../../../../../api/insuranceTabApi";
import { SELF_CLOSE_DELAY } from "../../../../../helpers/broker-helpers";
import { setDealSaveSuccess } from "../../../../../redux/reducers/dealPage-reducer";
import { dealSaveSuccessSelector } from "../../../../../redux/selectors/deal-selectors";
import { insuranceLoadingSelector } from "../../../../../redux/selectors/insurance-tab-selector";
import QMGlobalLoader from "../../../../common/QMLoader";
import { QMTextTab, QMTextTabs } from "../../../common/QMTextTabs";
import QMToastMessage from "../../../common/QMToastMessage";
import InsuranceCompliance from "./InsuranceCompliance";
import InsuranceRWContainer from "./InsuranceRWContainer";

const InsuranceTab = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(insuranceLoadingSelector);
  let { dealId } = useParams();
  const { t } = useTranslation();
  const [openSubTab, setOpenSubTab] = useState("rw_insurance");
  const [isDirty, setIsDirty] = useState(false);

  const isSaved = useSelector(dealSaveSuccessSelector);

  const handleTabChange = (tabName) => {
    const shouldLeave =
      !isDirty || window.confirm(t("general.leave_page_warning"));
    if (shouldLeave) setOpenSubTab(tabName);
  };

  useEffect(() => {
    getInsuranceTabInfo(dispatch, dealId);
    getInsuranceComplianceTabInfo(dispatch, dealId);
  }, [dispatch, dealId, openSubTab]);

  const getContent = () => {
    return (
      <>
        <QMTextTabs>
          <QMTextTab
            tabName={t("insurance.rw_insurance_tab_name")}
            isSelected={openSubTab === "rw_insurance"}
            onSelect={() => handleTabChange("rw_insurance")}
          />
          <QMTextTab
            tabName={t("insurance.compliance_insurance_tab_name")}
            isSelected={openSubTab === "compliance_insurance"}
            onSelect={() => handleTabChange("compliance_insurance")}
          />
        </QMTextTabs>
        {isLoading ? (
          <QMGlobalLoader />
        ) : (
          <>
            {openSubTab === "rw_insurance" && (
              <InsuranceRWContainer
                currentTab={openSubTab}
                setIsDirty={setIsDirty}
              />
            )}
            {openSubTab === "compliance_insurance" && (
              <InsuranceCompliance
                currentTab={openSubTab}
                setIsDirty={setIsDirty}
              />
            )}
          </>
        )}
        {isSaved && (
          <QMToastMessage
            viewType="success"
            text={t("general.data_saved")}
            iconClassName="icon-check-circle"
            onClose={() => {
              dispatch(setDealSaveSuccess(false));
            }}
            selfCloseDelay={SELF_CLOSE_DELAY}
          />
        )}
      </>
    );
  };
  return <>{getContent()}</>;
};

export default InsuranceTab;
