import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import './styles.scss';

const QMNavMenuItem = ({
  title,
  iconClassName,
  to,
  hasInnerMenu,
  innerContent,
  isInner,
  ...props
}) => {
  const linkClasses = (isActive) => classNames({
    'QM-navMenuItem_link': true,
    'QM-navMenuItem_link__inner': isInner,
    'active': isActive,
  }, iconClassName);

  const [isOpen, setIsOpen] = useState(true);

  return (
    <li className='QM-navMenuItem'>
      <NavLink
        to={to}
        className={({isActive}) => linkClasses(isActive)}
        {...props}
      >
        { title }
      </NavLink>
      { hasInnerMenu && (
        <details open className='QM-navMenuItem_details'>
          <summary 
            className={classNames(
              "QM-navMenuItem_details_trigger",
              isOpen ? "icon-triangle-up" : "icon-triangle-down")}
            aria-label="Press to show details"
            onClick={() => setIsOpen(!isOpen)}
          >
            Press to show details
          </summary>
          { innerContent }
        </details>
      )}
    </li>
  );
}

QMNavMenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  iconClassName: PropTypes.string,
  to: PropTypes.string.isRequired,
  hasInnerMenu: PropTypes.bool,
  innerContent: PropTypes.node,
  isInner: PropTypes.bool,
};

QMNavMenuItem.defaultProps = {
  hasInnerMenu: false,
  innerContent: null,
  iconClassName: '',
  isInner: false,
}

export default QMNavMenuItem;