import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getDealContacts } from "../../../../../api/dealApi";
import { setDealContacts } from "../../../../../redux/reducers/dealPage-reducer";
import {
  dealInfoSelector,
  dealSellerSelector,
} from "../../../../../redux/selectors/deal-selectors";
import { QMTextTab, QMTextTabs } from "../../../common/QMTextTabs";
import ContactsFormContainer from "./ContactsFormContainer";

const ContactsTab = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let { dealId } = useParams();
  const dealInfo = useSelector(dealInfoSelector);
  const sellerInfo = useSelector(dealSellerSelector);
  const [openSubTab, setOpenSubTab] = useState("buyer");
  const [isDirty, setIsDirty] = useState(false);
  const handleTabChange = (tabName) => {
    dispatch(setDealContacts({}));
    const shouldLeave =
      !isDirty || window.confirm(t("general.leave_page_warning"));
    if (shouldLeave) setOpenSubTab(tabName);
  };
  useEffect(() => {
    getDealContacts(dispatch, dealId);
  }, [dispatch, dealId, openSubTab]);
  return (
    <>
      <QMTextTabs>
        <QMTextTab
          tabName={t("general.buyer")}
          isSelected={openSubTab === "buyer"}
          onSelect={() => handleTabChange("buyer")}
        />
        <QMTextTab
          tabName={
            sellerInfo?.target === "seller"
              ? t("general.seller_target")
              : t("general.seller")
          }
          isSelected={openSubTab === "seller"}
          onSelect={() => handleTabChange("seller")}
        />
        {sellerInfo?.target === "subsidiary" && (
          <QMTextTab
            tabName={t("general.target")}
            isSelected={openSubTab === "target"}
            onSelect={() => handleTabChange("target")}
          />
        )}
      </QMTextTabs>
      {openSubTab === "buyer" && (
        <ContactsFormContainer
          currentTab={openSubTab}
          isApplicant={dealInfo.applicant === "buyer"}
          setIsDirty={setIsDirty}
        />
      )}
      {openSubTab === "seller" && (
        <ContactsFormContainer
          currentTab={openSubTab}
          isApplicant={dealInfo.applicant === "seller"}
          setIsDirty={setIsDirty}
        />
      )}
      {openSubTab === "target" && (
        <ContactsFormContainer
          currentTab={openSubTab}
          setIsDirty={setIsDirty}
        />
      )}
    </>
  );
};

export default ContactsTab;
