import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import QMButton from "../../../common/QMButton";

import "./styles.scss";

const DealsEmpty = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <section className="QM-deals__empty">
      <h2>{t("broker_portal.add_first_deal")}</h2>
      <QMButton
        text={t("broker_portal.add_new_deal")}
        onClick={() => {
          navigate("/broker/new-deal");
        }}
      />
    </section>
  );
};

export default DealsEmpty;
