import React from "react";

import QMTile from "../../common/QMTile";
import RiskProfileGeneralItem from "./RiskProfileGeneralItem";

import "./styles.scss";

const RiskProfileGeneral = ({ header, data }) => {
  return (
    <QMTile>
      <h2 className="risk-profile__header">{header}</h2>
      <section className="risk-profile__general-body">
        <ul className="risk-profile__general-list">
          {data?.map((item, index) => {
            return (
              <RiskProfileGeneralItem
                key={index}
                name={item.name}
                value={item.value}
                item={item}
              />
            );
          })}
        </ul>
      </section>
    </QMTile>
  );
};
export default RiskProfileGeneral;
