import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoadingDealInfo: false,
  dealInfoData: {},
};

export const dealInfoReducer = createSlice({
  name: "dealInfoTab",
  initialState,
  reducers: {
    setIsLoadingDealInfo: (state, action) => {
      state.isLoadingDealInfo = action.payload;
    },
    setDealInfoData: (state, action) => {
      state.dealInfoData = action.payload;
    },
  },
});

export const { setIsLoadingDealInfo, setDealInfoData } =
  dealInfoReducer.actions;

export default dealInfoReducer.reducer;
