import React from 'react';

const QMDialogListAction = ({text, onClick }) => {
  return (
    <li className='QM-actionDialog_list_action' onClick={onClick}>
      {text}
    </li>
  );
}

export default QMDialogListAction;