import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  QMTextInput,
  QMSelectInput,
  QMPhoneNumberInput,
} from "../../../common/QMInputs";
import QMCheckbox from "../../../common/QMCheckbox";
import * as validation from "./validationRules";

import "./styles.scss";

const FieldsetBasic = ({
  legendTitle,
  register,
  control,
  watch,
  groupName,
  errors,
  withBuyerConfirm,
  noLegendRadio,
  customRadio,
  allDisabled,
  legendText,
  setValue,
}) => {
  const { t } = useTranslation();
  const isApplicable = watch(`${groupName}.is_applicable`);

  const boolOpts = [
    { value: true, label: t("general.yes") },
    { value: false, label: t("general.no") },
  ];

  return (
    <fieldset className="QM-formBlock">
      <legend>
        <p>{legendTitle}</p>
        {!noLegendRadio && (
          <div className="QM-formBlock_checkbox">
            <QMCheckbox {...register(`${groupName}.is_applicable`)} />
            <span>{t("general.yes")}</span>
          </div>
        )}
      </legend>
      {Boolean(customRadio) && (
        <div className="QM-formBlock_p-c-broker-block">
          <legend className="QM-formBlock_p-c-broker">{legendText}</legend>
          <div className="QM-formBlock_checkbox">
            {customRadio}
            <span> {t("general.yes")}</span>
          </div>
        </div>
      )}
      {withBuyerConfirm && (
        <div className="QM-formBlock_row">
          <Controller
            render={({ field }) => (
              <QMSelectInput
                {...field}
                placeholder={t("general.choose_answer")}
                label={t("contact.same_as_buyer_question")}
                errors={errors?.[groupName]?.type}
                options={boolOpts}
                isSearchable={false}
                value={boolOpts.find((c) => c.value === field.value)}
                onChange={(val) => field.onChange(val.value)}
                isRequired
              />
            )}
            control={control}
            name={`${groupName}.type`}
          />
        </div>
      )}
      <div className="QM-formBlock_row">
        <QMTextInput
          {...register(
            `${groupName}.company_name`,
            validation.companyValidation
          )}
          label={t("contact.company_name")}
          placeholder={t("contact.company_name_placeholder")}
          errors={errors?.[groupName]?.company_name}
          disabled={!isApplicable && !allDisabled}
        />
        <QMTextInput
          {...register(`${groupName}.contact_name`, validation.nameValidation)}
          label={t("contact.contact_name")}
          placeholder={t("contact.contact_name_placeholder")}
          errors={errors?.[groupName]?.contact_name}
          disabled={!isApplicable && !allDisabled}
        />
      </div>
      <div className="QM-formBlock_row">
        <Controller
          render={({ field }) => (
            <QMPhoneNumberInput
              {...field}
              label={t("contact.contact_phone")}
              placeholder={t("general.phone_placeholder")}
              errors={errors?.[groupName]?.contact_phone}
              disabled={!isApplicable && !allDisabled}
            />
          )}
          control={control}
          name={`${groupName}.contact_phone`}
          rules={validation.phoneValidation}
        />
        <QMTextInput
          {...register(
            `${groupName}.contact_email`,
            validation.emailValidation
          )}
          label={t("contact.contact_email")}
          placeholder={t("general.email_placeholder")}
          errors={errors?.[groupName]?.contact_email}
          disabled={!isApplicable && !allDisabled}
        />
      </div>
    </fieldset>
  );
};

export default FieldsetBasic;
