import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import useOuterClick from '../../../../helpers/useOuterClick';

const QMDropdownMenu = ({children, closeMenu, customClassName, ...props}) => {
  const innerRef = useOuterClick((e) => closeMenu());

  return (
    <ul className={classNames('QM-dropdownMenu', customClassName)} ref={innerRef} {...props}>
      {children}
    </ul>
  );
}

QMDropdownMenu.propTypes = {
  children: PropTypes.node,
  closeMenu: PropTypes.func,
  customClassName: PropTypes.string,
}
QMDropdownMenu.defaultProps = {
  children: "",
  closeMenu: ()=>{},
  customClassName: ""
}
export default QMDropdownMenu;