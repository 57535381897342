import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";

const AssignLawyerAccessLevelItem = ({ text, setValue, code, closeMenu }) => {
  const handleValueChange = (value) => {
    setValue(`${code}_level`, value, { shouldDirty: true, shouldTouch: true });
  };
  return (
    <li
      className="QM-dropdownMenu_item"
      onClick={(e) => {
        e.stopPropagation();
        handleValueChange(text);
        closeMenu();
      }}
    >
      <span>{text}</span>
    </li>
  );
};

AssignLawyerAccessLevelItem.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
};

AssignLawyerAccessLevelItem.defaultProps = {
  text: "",
  onClick: () => {},
};

export default AssignLawyerAccessLevelItem;
