export const dealsSelector = state => state.deals.dealsList;
export const dealsLoadingSelector = state => state.deals.isLoadingDeals;
export const dealsErrorSelector = state => state.deals.dealsError;
export const selectedDealSelector = state => state.deals.selectedDeal;
export const selectedDealContactsSelector = state => state.deals.selectedDealContacts;
export const sidebarTabLoadingSelector = state => state.deals.isLoadingSidebarTab;

export const selectedDealRequiredDocumentsSelector = state => state.deals.requiredDocumentsList;
export const selectedDealProvidedDocumentsSelector = state => state.deals.providedDocumentsList;
export const selectedDealSubmissionSelector = state => state.deals.submissionData;

// New Action menu
export const newDealOpenSelector = state => state.brokerActionsMenu.isNewDealOpen;
