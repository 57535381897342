import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";

const QMDropDownMenuItem = ({ text, iconClassName, closeMenu, onClick }) => {
  return (
    <li
      className="QM-dropdownMenu_item"
      onClick={onClick ? onClick : closeMenu}
    >
      {iconClassName && <span className={iconClassName} />}
      <span>{text}</span>
    </li>
  );
};

QMDropDownMenuItem.propTypes = {
  text: PropTypes.string,
  iconClassName: PropTypes.string,
  // closeMenu,
  onClick: PropTypes.func,
};

QMDropDownMenuItem.defaultProps = {
  text: "",
  iconClassName: "",
  // closeMenu,
  onClick: () => {},
};

export default QMDropDownMenuItem;
