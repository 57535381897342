import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoadingInsurance: false,
  insuranceInfo: {},
  insuranceComplianceInfo: [],
};

export const insuranceTabReducer = createSlice({
  name: "insuranceTab",
  initialState,
  reducers: {
    setIsLoadingInsurance: (state, action) => {
      state.isLoadingInsurance = action.payload;
    },
    setInsuranceInfo: (state, action) => {
      state.insuranceInfo = action.payload;
    },
    setInsuranceComplianceInfo: (state, action) => {
      state.insuranceComplianceInfo = action.payload;
    },
  },
});

export const {
  setIsLoadingInsurance,
  setInsuranceInfo,
  setInsuranceComplianceInfo,
} = insuranceTabReducer.actions;

export default insuranceTabReducer.reducer;
