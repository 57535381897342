import React from "react";

import QMButton from "../common/QMButton";
import faq15a from "../../../assets/imgs/faq15a.png";
import faq15b from "../../../assets/imgs/faq15b.png";
import faq15c from "../../../assets/imgs/faq15c.png";
import faq15d from "../../../assets/imgs/faq15d.png";
import faq15e from "../../../assets/imgs/faq15e.png";
import faqActive from "../../../assets/imgs/faqActive.png";
import faqInactive from "../../../assets/imgs/faqInactive.png";
import "./styles.scss";

const FaqHowManyStatuses = () => {
  return (
    <section className="faq-page__item">
      <div className="faq-page__content">
        <div className="faq-page__functions-overview-title">
          <p>
            The system has two types of statuses: deal status (1.5.a, 1.5.d) and
            submission status (1.5.c, 1.5.d). Deal status
          </p>
          <p className="faq-page__status-title">Deal status</p>
          <figure className="faq-img__figure">
            <img
              className="faq-img__full-size"
              src={faq15a}
              alt="contacts companies"
            ></img>
            <figcaption className="faq-page__caption">1.5.a</figcaption>
          </figure>
        </div>
        <div className="faq-page__functions-overview-title">
          <p>
            <img
              className="faq-page__status-img"
              src={faqActive}
              alt="active deal"
            ></img>{" "}
            This status appears once the deal was created. You can submit
            application and upload documents. Underwriters will receive
            notification any time a new document added after submission.
          </p>
          <p>
            {" "}
            <img
              className="faq-page__status-img"
              src={faqInactive}
              alt="inactive deal"
            ></img>{" "}
            You can change deal status to the inactive deal in case deal not
            relevant any more. In this case, upload files and submission
            function will be disabled. If you're going to change active status
            to inactive deal status after you have submitted, the system will
            notify all underwriters you have been submitted the application that
            this deal is irrelevant no longe. You can review all deals status on
            the menu <span className="faq-page__secondary-text">Deals</span>.
            Green — active deal, grey — inactive deal.
          </p>
          <figure className="faq-img__figure">
            <img
              className="faq-img__full-size"
              src={faq15b}
              alt="contacts companies"
            ></img>
            <figcaption className="faq-page__caption">1.5.b</figcaption>
          </figure>
        </div>
        <div className="faq-page__contacts">
          <p>
            You are able to change statuses any time. Just press status button
            and choosing status from drop-down list (1.5.c).
          </p>
          <figure>
            <img src={faq15c} alt="contacts companies"></img>
            <figcaption className="faq-page__caption faq-page__caption-status">
              1.5.c
            </figcaption>
          </figure>
        </div>
        <div className="faq-page__functions-overview-title">
          <p className="faq-page__status-title">Submission status</p>
        </div>
        <div className="faq-page__functions-overview-title">
          <p>
            Submission status R&W application has two submission statuses:
            submitted and non-submitted. Those statuses related to the carriers
            and represent have you submitted to the specific carrier or not.
            According to this,{" "}
            <span className="faq-page__secondary-text"> Underwriting</span>{" "}
            (1.5.c) has two type of buttons{" "}
            <QMButton text="Submit" customClassName="QM-faq-btn__submit" /> and{" "}
            <QMButton
              text="Submited"
              customClassName="QM-faq-btn__submit-inactive"
            />
          </p>
          <figure className="faq-img__figure">
            <img
              className="faq-img__full-size"
              src={faq15d}
              alt="contacts companies"
            ></img>
            <figcaption className="faq-page__caption">1.5.d</figcaption>
          </figure>
        </div>
        <div className="faq-page__functions-overview-title">
          <p>
            Go to the{" "}
            <span className="faq-page__secondary-text">
              Menu Deals → Status column
            </span>{" "}
            (1.5.d) to get acquainted if a specific deal has been submitted at
            least to the one carrier or not. This column might have two options:
            empty field (any submission) or submitted.
          </p>
          <figure className="faq-img__figure">
            <img
              className="faq-img__full-size"
              src={faq15e}
              alt="contacts companies"
            ></img>
            <figcaption className="faq-page__caption">1.5.e</figcaption>
          </figure>
        </div>
      </div>
    </section>
  );
};

export default FaqHowManyStatuses;
