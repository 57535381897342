import React from "react";
import QMButton from "../../common/QMButton";
import QMModal from "../../common/QMModal";

import "./styles.scss";

const DealPageModal = ({
  title,
  text,
  subText,
  submitButtonText,
  cancelButtonText,
  setInactiveStatus,
  reset,
}) => {
  return (
    <QMModal
      closeModal={() => {
        setInactiveStatus(false);
        reset();
      }}
    >
      <section className="deal-page-modal">
        <header>
          <h2 className="deal-page-modal__title">{title}</h2>
        </header>
        <section className="deal-page-modal__body">
          <p className="deal-page-modal__text">{text}</p>
          <p className="deal-page-modal__subtext">{subText}</p>
        </section>
        <footer className="deal-page-modal__footer">
          <QMButton
            text={cancelButtonText}
            viewType="secondary"
            type="cancel"
            customClassName="tabs-submit__btn"
            onClick={() => {
              setInactiveStatus(false);
              reset();
            }}
          />
          <QMButton
            text={submitButtonText}
            type="submit"
            customClassName="tabs-submit__btn"
          />
        </footer>
      </section>
    </QMModal>
  );
};

export default DealPageModal;
