const groupAssignLawyerProps = (obj) => {
  const entries = Object.entries(obj);
  console.log(entries);
  const access = entries
    .filter(([key, val]) => !key.endsWith("_level") && !!val)
    .map(([key]) => ({ [key]: key }));

  const levels = entries.filter(([key]) => key.endsWith("_level"));

  levels.forEach(([key, val]) => {
    const keyFirstPart = key.slice(0, -6);
    const idx = access.findIndex((obj) => Object.keys(obj)[0] === keyFirstPart);
    if (idx >= 0) {
      access[idx] = {
        ...access[idx],
        actions: val,
      };
    }
  });

  return access;
};

const setAccessLevelsValues = (policies, setValue) => {
  if (policies && policies.length) {
    policies.forEach((policy) => {
      if (policy.actions) {
        setValue(policy.code, true);
        const level = policy.actions.includes("update") ? "Edit" : "View";
        setValue(`${policy.code}_level`, level);
      }
      if (policy.policies && policy.policies.length) {
        setAccessLevelsValues(policy.policies, setValue);
      }
    });
  }
};
const reformatAssignLawyerData = (obj) => {
  const combinedData = [];
  let objectToSend = {};

  obj.forEach((item) => {
    if (
      item.deal_info ||
      item.insurance ||
      item.past_activities ||
      item.due_diligence ||
      item.contacts ||
      item.rwi_application
    ) {
      objectToSend = {
        entity_code: "rwi_application",
        has_children: true,
        policies: [],
      };
    } else {
      combinedData.push(item);
    }
  });

  obj.forEach((item) => {
    Object.entries(item).forEach(([key, val]) => {
      if (val.toLowerCase() === "view") {
        item[key] = ["read"];
      }
      if (val.toLowerCase() === "edit") {
        item[key] = ["read", "update"];
      }
    });

    Object.keys(item).map((key) => {
      switch (key) {
        case "deal_info":
          item["entity_code"] = item[key];
          delete item["deal_info"];
          break;
        case "contacts":
          item["entity_code"] = item[key];
          delete item["contacts"];
          break;
        case "insurance":
          item["entity_code"] = item[key];
          delete item["insurance"];
          break;
        case "rwi_application":
          item["entity_code"] = item[key];
          delete item["rwi_application"];
          break;
        case "past_activities":
          item["entity_code"] = item[key];
          delete item["past_activities"];
          break;
        case "due_diligence":
          item["entity_code"] = item[key];
          delete item["due_diligence"];
          break;
        case "underwriting_requests":
          item["entity_code"] = item[key];
          item["has_children"] = false;
          delete item["underwriting_requests"];
          break;
        case "deal_room":
          item["entity_code"] = item[key];
          item["has_children"] = false;
          delete item["deal_room"];
          break;
        default:
          return item;
      }
      return item;
    });

    if (
      item.actions &&
      item.entity_code !== "deal_room" &&
      item.entity_code !== "underwriting_requests" &&
      item.entity_code !== "rwi_application"
    ) {
      objectToSend.policies.push(item);
    }
  });
  if (Object.keys(objectToSend).length > 1) {
    combinedData.push(objectToSend);
  }

  return combinedData;
};

const dataToSend = (array, lawyerInfo) => {
  const { attorney_email, attorney_role_code } = lawyerInfo;
  const objectToSend = {
    attorney_email: attorney_email,
    attorney_role_code: attorney_role_code,
    policies: array,
  };

  return objectToSend;
};

export {
  groupAssignLawyerProps,
  setAccessLevelsValues,
  reformatAssignLawyerData,
  dataToSend,
};
