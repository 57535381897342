import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import Tippy from "@tippyjs/react";
import classNames from "classnames";

import { QMSelectInput } from "../../../common/QMInputs";
import QMButton from "../../../common/QMButton";
import { defaultAcceptedFileTypes } from "../../../../../helpers/docs-helpers";

const OrgChartBlock = ({
  control,
  getFieldErrors,
  setValue,
  boolOpts,
  setShowOrgChartWarning,
  showOrgChartWarning,
  trigger,
  watch,
  setGeneralError,
}) => {
  const { t } = useTranslation();
  const [fileData, setFileData] = useState(null);
  const organizationChartFileWatch = watch("organization_chart_file_present");

  const {
    getRootProps,
    getInputProps,
    open,
    acceptedFiles,
    isDragActive,
    isDragReject,
  } = useDropzone({
    noKeyboard: true,
    noClick: true,
    onDrop: (files) => {
      setValue("organization_chart_file", files);
      trigger("organization_chart_file");
    },
    accept: defaultAcceptedFileTypes,
  });

  const dropzoneClasses = classNames("new_deal_file_dropzone_wrapper", {
    org_chart_dropzone_wrapper__dragActive: isDragActive,
  });

  const handleSelectChange = (val, field) => {
    const updatedVal = val === null ? val : val.value;
    field.onChange(updatedVal);
    setShowOrgChartWarning(updatedVal);
  };
  const getCutFilename = (filename) => {
    if (filename) {
      return filename.length > 35 ? `${filename.slice(0, 35)}...` : filename;
    }
    return;
  };
  const getTooltipContent = (filename) => {
    return filename && filename.length > 35 ? (
      <section className="QM_tooltip_content QM_tooltip_content__info filename_tooltip">
        {filename}
      </section>
    ) : (
      <></>
    );
  };
  useEffect(() => {
    setFileData(acceptedFiles[0]);
  }, [acceptedFiles]);

  useEffect(() => {
    if (!organizationChartFileWatch) {
      setFileData(null);
      setValue("organization_chart_file", null);
      trigger("organization_chart_file");
    }
  }, [organizationChartFileWatch, setValue, trigger]);

  useEffect(() => {
    if (isDragReject) {
      setGeneralError({ message: "Current file format is not allowed" });
    }
  }, [isDragReject, setGeneralError]);

  return (
    <section className="QM-formBlock">
      <section className="QM-formRow">
        <Controller
          render={({ field }) => (
            <QMSelectInput
              {...field}
              placeholder={t("general.choose_answer")}
              label={t("new_deal_form.org_chart")}
              errors={getFieldErrors("organization_chart_file_present")}
              options={boolOpts}
              isSearchable
              value={boolOpts.find((c) => c.value === field.value)}
              onChange={(val) => handleSelectChange(val, field)}
              isClearable
            />
          )}
          control={control}
          name="organization_chart_file_present"
        />
        {showOrgChartWarning && !fileData?.path && (
          <div className="new_deal_file_dropzone">
            <label className="QM-input_label">
              <div>
                <span>{t("new_deal_form.org_chart_label")}</span>
                <span className="QM-input_requiredMark">*</span>
              </div>
            </label>
            <div
              className="new_deal_file_dropzone_wrapper"
              {...getRootProps({ className: dropzoneClasses })}
            >
              <input {...getInputProps()} name="organization_chart_file" />
              <QMButton
                viewType="transparent"
                iconClassName="icon-upload-cloud"
                onClick={open}
              />
            </div>
          </div>
        )}
        <div className="new_deal_file_dropzone_files">
          {fileData?.path?.length && fileData?.path?.length > 35 ? (
            <Tippy
              content={getTooltipContent(fileData?.path)}
              theme="light"
              interactive
            >
              <p>{getCutFilename(fileData?.path)}</p>
            </Tippy>
          ) : (
            <p>{fileData?.path}</p>
          )}
          {fileData && (
            <QMButton
              onClick={() => {
                setFileData(null);
                setValue("organization_chart_file", null);
              }}
              viewType="transparent"
              className="icon-trash new_deal_file_dropzone_delete"
            />
          )}
        </div>
      </section>
    </section>
  );
};

export default OrgChartBlock;
