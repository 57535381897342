import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";

import { QMSelectInput, QMTextInput } from "../../../common/QMInputs";
import QMDNBSearch from "../../../common/QMDNBSearch/QMDNBSearch";

const SellerInfoBlock = ({
  register,
  control,
  watch,
  trigger,
  getFieldErrors,
  setValue,
  predictionSetter,
  selectedPrediction,
  optPropPredictionSetter,
  optPropPrediction,
}) => {
  const { t } = useTranslation();
  const sellerTargetWatch = watch("seller_target");

  const targetOpts = [
    { value: "seller", label: t("general.seller") },
    { value: "subsidiary", label: t("general.subsidiary") },
  ];

  const handleTargetChange = (val, field) => {
    const updatedVal = val === null ? val : val.value;
    field.onChange(updatedVal);
  };

  useEffect(() => {
    if (sellerTargetWatch === "seller") {
      setValue("seller_subsidiary_company_name", "");
      setValue("seller_subsidiary_company_website", "");
    }
  }, [sellerTargetWatch, setValue]);

  return (
    <section className="QM-formBlock">
      <section className="QM-formRow">
        <QMDNBSearch
          register={register}
          setValue={setValue}
          watch={watch}
          trigger={trigger}
          name="seller_name"
          selectedPrediction={selectedPrediction}
          predictionSetter={predictionSetter}
          inputProps={{
            label: t("general.seller"),
            placeholder: t("new_deal_form.seller_name_placeholder"),
            errors: getFieldErrors("seller_name"),
            isRequired: true,
          }}
        />
        <QMTextInput
          {...register("seller_website")}
          label={t("new_deal_form.seller_website_label")}
          placeholder={t("general.website_placeholder")}
          errors={getFieldErrors("seller_website")}
          isRequired
        />
      </section>
      <section className="QM-formRow">
        <Controller
          render={({ field }) => (
            <QMSelectInput
              {...field}
              placeholder={t("general.choose_answer")}
              label={t("general.target")}
              errors={getFieldErrors("seller_target")}
              options={targetOpts}
              isSearchable={false}
              value={targetOpts.find((c) => c.value === field.value)}
              onChange={(val) => handleTargetChange(val, field)}
              isRequired
              isClearable
            />
          )}
          control={control}
          name="seller_target"
        />
        <QMDNBSearch
          register={register}
          setValue={setValue}
          watch={watch}
          trigger={trigger}
          name="seller_subsidiary_company_name"
          selectedPrediction={optPropPrediction}
          predictionSetter={optPropPredictionSetter}
          inputProps={{
            label: t("new_deal_form.target_company_label"),
            placeholder: t("new_deal_form.target_company_placeholder"),
            errors: getFieldErrors("seller_subsidiary_company_name"),
            isRequired: sellerTargetWatch === "subsidiary",
            disabled: sellerTargetWatch !== "subsidiary",
          }}
        />
        <QMTextInput
          {...register("seller_subsidiary_company_website")}
          label={t("new_deal_form.target_website_label")}
          placeholder={t("general.website_placeholder")}
          errors={getFieldErrors("seller_subsidiary_company_website")}
          disabled={sellerTargetWatch !== "subsidiary"}
          isRequired={sellerTargetWatch === "subsidiary"}
        />
      </section>
    </section>
  );
};

export default SellerInfoBlock;
