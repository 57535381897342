import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import QMButton from "../common/QMButton";
import QMSidebar from "../common/QMSidebar";
import DealPage from "../DealPage/DealPage";
import Faq from "../Faq";
import Markets from "../Markets";
import DealAdminActionMenu from "./DealAdminActionMenu";
import DealAdminNavMenu from "./DealAdminNavMenu";
import DealAdminNewDealPage from "./DealAdminNewDeal";
import DealsAdminPage from "./DealsAdminPage";

const DealsAdminPortal = () => {
  const [isActionMenuOpen, setIsActionMenuOpen] = useState(false);

  useEffect(() => {
    document.body.classList.add("no-scroll");

    return () => document.body.classList.remove("no-scroll");
  }, []);

  return (
    <main className="QM-main QM-brokerPortal">
      <QMSidebar>
        <QMButton
          text="New Action"
          customClassName="QM-newAction-btn"
          onClick={() => setIsActionMenuOpen(true)}
        />
        <DealAdminNavMenu />
      </QMSidebar>
      <article className="QM-main_content">
        <Routes>
          <Route index element={<Navigate to="portfolio" />} />
          <Route path="portfolio" exact strict>
            <Route index element={<Navigate to="deals" />} />
            <Route path="deals" element={<DealsAdminPage />} exact strict>
              <Route path=":dealId/*" element={<DealPage />} exact strict />
            </Route>
            <Route path="companies" element={<p>Companies</p>} />
          </Route>
          <Route path="markets" element={<Markets />} />
          <Route path="faq" element={<Faq />} exact strict />
          <Route
            path="new-deal"
            element={<DealAdminNewDealPage />}
            exact
            strict
          />
        </Routes>
      </article>
      {isActionMenuOpen && (
        <DealAdminActionMenu onClose={() => setIsActionMenuOpen(false)} />
      )}
    </main>
  );
};

export default DealsAdminPortal;
