import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './styles.scss';

const QMEButton = ({onClick, btnStyle, children, ...props}) => {
  return (
    <button
      className={classNames({
        'QM_E-btn': true,
        'QM_E-btn__primary': btnStyle === 'primary',
        'QM_E-btn__secondary': btnStyle === 'secondary'
      })}
      type="submit"
      onClick={onClick}
      {...props}
    >
      { children }
    </button>
  );
}

QMEButton.propTypes = {
  onClick: PropTypes.func,
  btnStyle: PropTypes.oneOf(['primary', 'secondary']),
  children: PropTypes.node.isRequired,
}

QMEButton.defaultProps = {
  onClick: () => {},
  btnStyle: 'primary',
}

export default QMEButton;
