import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useTable, useRowSelect } from "react-table";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

import { formatPropName } from "../../../../helpers";
import { formatCurrency } from "../../../../helpers/broker-helpers";
import { setSelectedDeal } from "../../../../redux/reducers/deals-reducer";
import QMToolTipName from "../../common/QMToolTipName";

const DealsAdminTable = ({ data }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [rowWithInnerActive, setRowWithInnerActive] = useState(null);
  const handleMouseDown = (e, id) => {
    e.stopPropagation();
    setRowWithInnerActive(id);
  };
  const handleMouseUp = (e) => {
    e.stopPropagation();
    setRowWithInnerActive(null);
  };

  const handleNamesLength = (value) => {
    const maxLength = 11;
    if (value?.length > maxLength) {
      return (
        <span>
          <QMToolTipName maxLength={maxLength} text={value} />
        </span>
      );
    }
    return value;
  };

  const columns = useMemo(
    () => [
      {
        Header: t("general.deal_name"),
        accessor: "name",
        Cell: ({ value, row }) => (
          <button
            className="QM-table_cell__clickable QM-btn__transparent"
            onClick={(e) => {
              e.stopPropagation();
              navigate(`${row.id}`);
            }}
            onMouseDown={(e) => handleMouseDown(e, row.id)}
            onMouseUp={handleMouseUp}
          >
            {handleNamesLength(value)}
            <span className="icon-external-link" />
          </button>
        ),
      },
      {
        Header: t("general.brokerage"),
        accessor: "brokerage.name",
        Cell: ({ value, row }) => (
          <p>
            <strong>{value ? value : t("general.na")}</strong>
            <small>
              {row?.original?.brokerage?.address
                ? handleNamesLength(row.original.brokerage.address)
                : t("general.na")}
            </small>
          </p>
        ),
      },
      {
        Header: t("general.law_firm"),
        accessor: "lawfirm.name",
        Cell: ({ value, row }) => (
          <p>
            <strong>{value ? value : t("general.na")}</strong>
            <small>
              {row?.original?.brokerage?.address
                ? handleNamesLength(row.original.brokerage.address)
                : t("general.na")}
            </small>
          </p>
        ),
      },
      {
        Header: t("general.deal_size"),
        accessor: "deal_size",
        Cell: ({ value }) => <p>{formatCurrency(value)}</p>,
      },
      {
        Header: t("risk_profile.deal_general_qscore"),
        accessor: "q_score.score",
        Cell: ({ value }) => <p>{value ? value : t("general.na")}</p>,
      },
      {
        Header: t("general.status"),
        accessor: "status",
        Cell: ({ value }) => <p>{formatPropName(value)}</p>,
      },
    ],
    [navigate, t]
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    toggleAllRowsSelected,
  } = useTable(
    {
      columns,
      data,
      getRowId: useCallback((row) => row.id, []),
    },
    useRowSelect
  );

  const handleRowClick = (row) => {
    toggleAllRowsSelected(false);
    row.toggleRowSelected(true);
    dispatch(setSelectedDeal(row.id));
  };

  const getRowSelectedStyle = (row) => {
    return classNames({
      "QM-table_row__selected": row.isSelected,
      "QM-table_row__hasInnerActive": rowWithInnerActive === row.id,
    });
  };

  return (
    <section className="QM-table_scroller">
      <table {...getTableProps()} className="QM-table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                onClick={() => handleRowClick(row)}
                className={getRowSelectedStyle(row)}
              >
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </section>
  );
};

export default DealsAdminTable;
