const ERROR_REQUIRED = "this field is mandatory";
const ERROR_EMAIL_FORMAT = "please provide valid email";
const ERROR_PASSWORD_FORMAT = "password should contain at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character (@$!%*?&)";
const ERROR_PASSWORD_CONFIRM_NOT_MATCH = "password doesn't match";

const ERROR_NO_RESET_TOKEN = "please request password recovery again, seems like you don't have corresponding token";
const ERROR_NO_INVITATION_TOKEN = "please, request invitation again, seems like you don't have corresponding token";

const REG_EXP_PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

export {
  ERROR_REQUIRED,
  ERROR_EMAIL_FORMAT,
  ERROR_PASSWORD_FORMAT,
  ERROR_PASSWORD_CONFIRM_NOT_MATCH,

  ERROR_NO_RESET_TOKEN,
  ERROR_NO_INVITATION_TOKEN,

  REG_EXP_PASSWORD
}
