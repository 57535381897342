const compliance = [
  {
    question_id: "1",
    code: "commercial_liability",
  },
  {
    question_id: "2",
    code: "employment_practice_liability",
  },
  {
    question_id: "3",
    code: "cyber_liability",
  },
  {
    question_id: "4",
    code: "fiduciary_liability",
  },
  {
    question_id: "5",
    code: "contingent_liability",
  },
  {
    question_id: "6",
    code: "workers_compensation",
  },
  {
    question_id: "7",
    code: "representations_and_warranties",
  },
  {
    question_id: "8",
    code: "technology_errors_omissions",
  },
  {
    question_id: "9",
    code: "tax_liability",
  },
  {
    question_id: "10",
    code: "crime_liability",
  },
  {
    question_id: "11",
    code: "product_liability",
  },
  {
    question_id: "12",
    code: "directors_officers",
  },
];

export default compliance;
