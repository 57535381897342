import React from "react";
import PropTypes from "prop-types";
import uniqid from "uniqid";
import classNames from "classnames";

import "./styles.scss";

const QMRadioSet = ({
  options,
  register,
  name,
  legendText,
  layoutType,
  errors,
}) => {
  const setClasses = classNames("QM-radioSet", `QM-radioSet__${layoutType}`);

  return (
    <fieldset className={setClasses}>
      {legendText && <p className="QM-radioSet_legend">{legendText}</p>}

      {options.map((opt) => (
        <label className="QM-radioSet_label" key={uniqid(`${name}-`)}>
          <input
            {...register(name)}
            className="QM-radioSet_input"
            type="radio"
            name={name}
            value={opt.value}
          />
          <span className="QM-radioSet_labelText">{opt.label}</span>
          {errors && <p className="QM-input_errorMessage">{errors.message}</p>}
        </label>
      ))}
    </fieldset>
  );
};

QMRadioSet.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
      label: PropTypes.string,
    })
  ),
  register: PropTypes.any,
  name: PropTypes.string.isRequired,
  legendText: PropTypes.string,
  layoutType: PropTypes.oneOf(["horizontal", "vertical"]),
};

QMRadioSet.defaultProps = {
  options: [],
  register: () => ({}),
  legendText: "",
  layoutType: "horizontal",
};

export default QMRadioSet;
