import React from 'react';
import { components } from 'react-select';

import { ReactComponent as LocationIcon } from '../../../assets/imgs/map-pin.svg';
import {ReactComponent as CloseIcon} from '../../../assets/imgs/x-square.svg';
import {ReactComponent as SearchIcon} from '../../../assets/imgs/search.svg';
import {ReactComponent as GlobeIcon} from '../../../assets/imgs/globe.svg';

const LocationMultiValueRemove = (props) => {
  return (
      <components.MultiValueRemove {...props}>
        <CloseIcon />
      </components.MultiValueRemove>
  );
};

const LocationControl = ({children, hasValue, ...props }) => {
  // console.log('control props', props)
  return (
  <components.Control {...props}>
    {!hasValue && <LocationIcon />}
    {children}
  </components.Control>
)};

const LocationPlaceholder = ({children, ...props }) => (
  <components.Placeholder {...props}>
    <LocationIcon />
    {children}
  </components.Placeholder>
)

const CompanySearchControl = ({children, ...props }) => (
  <components.Control {...props}>
    <SearchIcon />
    {children}
  </components.Control>
);

const WebsiteSearchControl = ({children, ...props }) => (
  <components.Control {...props}>
    <GlobeIcon />
    {children}
  </components.Control>
);

export {
  LocationMultiValueRemove,
  LocationControl,
  LocationPlaceholder,
  CompanySearchControl,
  WebsiteSearchControl
}
