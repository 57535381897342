import classNames from "classnames";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import { accessLevelSelector } from "../../../../../redux/selectors/assign-lawyer-selectors";
import QMDropdownMenu from "../../../common/QMDropdownMenu";
import AssignLawyerAccessLevelItem from "./AssignLawyerAcessLevelItem";

import "./styles.scss";

const AssignLawyerAccessLevelList = ({ code, register, setValue, watch }) => {
  const [showAccessLevel, setShowAccessLevel] = useState(false);
  const accessLevelData = useSelector(accessLevelSelector);
  const checkboxVal = watch(code);
  const level = watch(`${code}_level`);
  return (
    <>
      <section
        className={classNames("assign-lawyer-manage-access__dropdown", {
          "assign-lawyer-manage-access__dropdown--disabled": !checkboxVal,
        })}
        onClick={() => {
          if (checkboxVal) {
            setShowAccessLevel(!showAccessLevel);
          }
        }}
      >
        <span
          className={classNames("assign-lawyer-manage-access__level", {
            "assign-lawyer-manage-access__level--disabled": !checkboxVal,
          })}
          {...register(`${code}_level`)}
        >
          {level}
        </span>
        <span
          className={classNames(
            "QM-userBlock_btn",
            showAccessLevel ? "icon-triangle-up" : "icon-triangle-down"
          )}
        />
      </section>
      {showAccessLevel && (
        <QMDropdownMenu
          closeMenu={() => setShowAccessLevel(false)}
          customClassName="QM-userBlock_dropdown"
        >
          {accessLevelData.map((item) => (
            <AssignLawyerAccessLevelItem
              key={item?.code}
              text={item?.title}
              setValue={setValue}
              code={code}
              closeMenu={() => setShowAccessLevel(false)}
            />
          ))}
        </QMDropdownMenu>
      )}
    </>
  );
};

export default AssignLawyerAccessLevelList;
