import React from 'react';

import QMLoader from './QMLoader';

import './styles.scss';
const QMGlobalLoader = () => {
  return (
    <section className='QM-loader-wrapper'>
      <QMLoader />
    </section>
  );
}

export default QMGlobalLoader;