import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  formatCurrency,
  reformatDealDate,
  capitalizeFirstLetter,
} from "../../../../helpers/broker-helpers";
import jurisdiction from "../../../../helpers/jurisdictions";
import { getTokenData, roles } from "../../../../helpers/auth-helpers";

import {
  dealBuyerSelector,
  dealSellerSelector,
  dealInfoSelector,
} from "../../../../redux/selectors/deal-selectors";
import {
  setDealError,
  setDealSaveSuccess,
} from "../../../../redux/reducers/dealPage-reducer";

import QMButton from "../../common/QMButton";
import QMKeyValueTextBlock from "../../common/QMKeyValueTextBlock/QMKeyValueTextBlock";
import QMToolTipName from "../../common/QMToolTipName";
import AssignLawyerModal from "./AssignLawyerModal/AssignLawyerModal";
import AssignUserBlock from "./AssignUserBlock/AssignUserBlock";
import StatusChangeForm from "./StatusChangeForm/StatusChangeForm";

import "./styles.scss";

const DealPageHeader = ({ id }) => {
  const { t } = useTranslation();
  const dealBuyer = useSelector(dealBuyerSelector);
  const dealSeller = useSelector(dealSellerSelector);
  const dealInfo = useSelector(dealInfoSelector);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const user = getTokenData();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getFullStateName = (value) => {
    let result = "";
    jurisdiction.find((item) => {
      if (item.value === value.toUpperCase()) {
        result = item.label;
      }
      return result;
    });
    return result;
  };

  const moveToDealTable = () => {
    if (user?.role === roles.RW_BROKER) {
      navigate(`/broker/portfolio/deals`);
    } else if (user?.role === roles.PARTNER) {
      navigate(`/partner/portfolio/deals`);
    } else if (
      user?.role === roles.DEAL_ADMIN ||
      user?.role === roles.UNDERWRITER
    ) {
      navigate(`/admin/portfolio/deals`);
    }
    dispatch(setDealError(null));
    dispatch(setDealSaveSuccess(false));
  };

  const handleNamesLength = (value) => {
    const maxLength = 13;
    if (value?.length > maxLength) {
      return (
        <span>
          <QMToolTipName maxLength={maxLength} text={value} />
        </span>
      );
    }
    return value;
  };

  return (
    <section className="deal-info">
      <section className="deal-info__header">
        <section className="deal-info__title">
          <h2 className="deal-info__project-name">
            {dealInfo?.name ? dealInfo?.name : t("general.no_data")}
          </h2>
          <StatusChangeForm id={id} />
          {user?.role !== roles.PARTNER && (
            <AssignUserBlock setShowAssignModal={setShowAssignModal} />
          )}
        </section>
        <section className="deal-info__close">
          <QMButton
            viewType="transparent"
            text={t("general.close")}
            iconClassName={`icon-x`}
            customClassName={`QM-btn__close-text`}
            iconReverse
            onClick={moveToDealTable}
          />
        </section>
      </section>
      <section className="deal-info">
        <div className="deal-info__body">
          <div className="deal-info__q-score">
            <span className="deal-info__q-score-name">
              {t("risk_profile.deal_general_qscore")}
            </span>
            {dealInfo?.q_score ? (
              <p className="deal-info__q-score-score">
                {dealInfo?.q_score?.score >= 0
                  ? dealInfo?.q_score?.score
                  : dealInfo?.q_score?.description}
              </p>
            ) : (
              <p className="deal-info__q-score-no-score">
                {t("general.no_data")}
              </p>
            )}
          </div>
          <div className="deal-info__table">
            <ul className="deal-info__content">
              <QMKeyValueTextBlock
                keyName={t("general.buyer")}
                value={handleNamesLength(dealBuyer?.name)}
              />
              <QMKeyValueTextBlock
                keyName={t("general.website")}
                value={handleNamesLength(dealBuyer?.website)}
              />
              <QMKeyValueTextBlock
                keyName={t("general.subsidiary")}
                value={
                  dealBuyer?.is_subsidiary ? t("general.yes") : t("general.no")
                }
              />
              <QMKeyValueTextBlock
                keyName={t("general.parent")}
                value={
                  dealBuyer?.parent_company_name
                    ? handleNamesLength(dealBuyer?.parent_company_name)
                    : t("general.no_data")
                }
              />
              <QMKeyValueTextBlock
                keyName={t("deal.parent_website")}
                value={
                  dealBuyer?.parent_company_website
                    ? handleNamesLength(dealBuyer?.parent_company_website)
                    : t("general.no_data")
                }
              />
            </ul>
            <ul className="deal-info__content">
              <QMKeyValueTextBlock
                keyName={t("general.seller")}
                value={handleNamesLength(dealSeller?.name)}
              />
              <QMKeyValueTextBlock
                keyName={t("general.website")}
                value={handleNamesLength(dealSeller?.website)}
              />
              <QMKeyValueTextBlock
                keyName={t("general.target")}
                value={
                  dealSeller?.target
                    ? handleNamesLength(
                        capitalizeFirstLetter(dealSeller?.target)
                      )
                    : t("general.no_data")
                }
              />
              <QMKeyValueTextBlock
                keyName={t("general.target")}
                value={
                  dealSeller?.subsidiary_company_name
                    ? handleNamesLength(dealSeller?.subsidiary_company_name)
                    : t("general.no_data")
                }
              />
              <QMKeyValueTextBlock
                keyName={t("deal.target_website")}
                value={
                  dealSeller?.subsidiary_company_website
                    ? handleNamesLength(dealSeller?.subsidiary_company_website)
                    : t("general.no_data")
                }
              />
            </ul>
            <ul className="deal-info__content">
              <QMKeyValueTextBlock
                keyName={t("general.est_closing_date")}
                value={
                  dealInfo?.closing_date
                    ? reformatDealDate(dealInfo?.closing_date)
                    : t("general.no_data")
                }
              />
              <QMKeyValueTextBlock
                keyName={t("general.deal_size")}
                value={
                  dealInfo?.deal_size
                    ? formatCurrency(dealInfo?.deal_size)
                    : t("general.no_data")
                }
              />
              <QMKeyValueTextBlock
                keyName={t("new_deal_form.jurisdiction_label")}
                value={
                  dealInfo?.jurisdiction
                    ? getFullStateName(dealInfo.jurisdiction)
                    : t("general.no_data")
                }
              />
            </ul>
          </div>
        </div>
      </section>

      {showAssignModal && (
        <AssignLawyerModal setInactiveStatus={setShowAssignModal} />
      )}
    </section>
  );
};

export default DealPageHeader;
