import React from "react";
import { useTranslation } from "react-i18next";

import { QMTextInput } from "../../../common/QMInputs";

const BrokerageInfoBlock = ({ register, getFieldErrors, title }) => {
  const { t } = useTranslation();

  return (
    <section className="QM-formBlock">
      <h3 className="QM-formBlock__title">{title}</h3>
      <section className="QM-formRow">
        <QMTextInput
          {...register("brokerage")}
          label={t("new_deal_form.brokerage_name")}
          placeholder={t("new_deal_form.brokerage_name_placeholder")}
          errors={getFieldErrors("brokerage")}
          isRequired
        />
      </section>
      <section className="QM-formRow">
        <QMTextInput
          {...register("broker_first_name")}
          label={t("new_deal_form.brokers_first_name")}
          placeholder={t("new_deal_form.brokers_first_name_placeholder")}
          errors={getFieldErrors("broker_first_name")}
          isRequired
        />
        <QMTextInput
          {...register("broker_last_name")}
          label={t("new_deal_form.brokers_last_name")}
          placeholder={t("new_deal_form.brokers_last_name_placeholder")}
          errors={getFieldErrors("broker_last_name")}
          isRequired
        />
        <QMTextInput
          {...register("broker_email")}
          label={t("new_deal_form.brokers_email")}
          placeholder={t("general.email_placeholder")}
          errors={getFieldErrors("broker_email")}
          isRequired
        />
      </section>
    </section>
  );
};

export default BrokerageInfoBlock;
