import React from "react";

import QMButton from "../common/QMButton";
import faq14a from "../../../assets/imgs/faq14a.png";
import faq14b from "../../../assets/imgs/faq14b.png";
import faq14c from "../../../assets/imgs/faq14c.png";
import faq14d from "../../../assets/imgs/faq14d.png";
import "./styles.scss";

const FaqHowToSubmit = () => {
  return (
    <section className="faq-page__item">
      <div className="faq-page__content">
        <div className="faq-page__functions-overview-title">
          <p>
            Go to{" "}
            <span className="faq-page__secondary-text">Navigation menu</span>{" "}
            and press{" "}
            <QMButton text="New Action" customClassName="QM-faq-btn__primary" />{" "}
            button. Provide basic deal info: buyer, seller, subsidiary, target,
            deal size, etc and press{" "}
            <QMButton text="Add deal" customClassName="QM-faq-btn__primary" />{" "}
            button (1.4.a).
          </p>
          <figure className="faq-img__figure">
            <img
              className="faq-img__full-size"
              src={faq14a}
              alt="contacts companies"
            ></img>
            <figcaption className="faq-page__caption">1.4.a</figcaption>
          </figure>
        </div>
        <div className="faq-page__functions-overview-title">
          <p>
            The system saves a new deal, and redirects you to the{" "}
            <span className="faq-page__secondary-text">Deal setup page</span>{" "}
            (1.4.b). Fulfil all necessary fields and upload required documents.
          </p>
          <figure className="faq-img__figure">
            <img
              className="faq-img__full-size"
              src={faq14b}
              alt="contacts companies"
            ></img>
            <figcaption className="faq-page__caption">1.4.b</figcaption>
          </figure>
        </div>
        <div className="faq-page__functions-overview-title">
          <p>
            Then go to the{" "}
            <span className="faq-page__secondary-text">Underwriting tab</span>{" "}
            and press{" "}
            <QMButton text="Submit" customClassName="QM-faq-btn__submit" />{" "}
            button next to the carrier name you want to submit R&W application
            (1.4.с). You can submit to as many carriers you want, but you will
            not able to change your application after you submit it, only add
            additional data.
          </p>
          <figure className="faq-img__figure">
            <img
              className="faq-img__full-size"
              src={faq14c}
              alt="contacts companies"
            ></img>
            <figcaption className="faq-page__caption">1.4.c</figcaption>
          </figure>
        </div>
        <div className="faq-page__functions-overview-title">
          <p>
            Underwriter may ask you to upload additional documents. In this
            case, go to{" "}
            <span className="faq-page__secondary-text">Deal room tab</span> and
            open folder with a Carrier’s name. Upload required files to the{" "}
            <span className="faq-page__secondary-text">
              Additional information (upload files here) folder{" "}
            </span>
            (1.4.d).
          </p>
          <figure className="faq-img__figure">
            <img
              className="faq-img__full-size"
              src={faq14d}
              alt="contacts companies"
            ></img>
            <figcaption className="faq-page__caption">1.4.d</figcaption>
          </figure>
        </div>
        <div className="faq-page__functions-overview-title">
          <p>
            Currently, QUANTUMERGE app 1.0 doesn't have internal messenger. We
            suggest having communication with underwriters via email, but do not
            send any sensitive data via email. Use shared warehouse to provide
            required data.
          </p>
        </div>
      </div>
    </section>
  );
};

export default FaqHowToSubmit;
