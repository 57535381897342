import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getDealAdminDocuments,
  uploadDealAdminDocument,
} from "../../../../api/dealDocsApi";
import { TOAST_CLOSE_DELAY } from "../../../../helpers";
import {
  setDealDocumentsError,
  setDealDocumentsSuccess,
  setIsLoadingDealDocuments,
} from "../../../../redux/reducers/dealDocs-reducer";
import {
  dealDocsErrorSelector,
  dealDocsListSelector,
  dealDocsLoadingSelector,
  dealDocsSuccessSelector,
} from "../../../../redux/selectors/dealDocs-selectors";
import QMGlobalLoader from "../../../common/QMLoader";
import QMToastMessage from "../../common/QMToastMessage";
import QMTreeItem from "../../common/QMTreeItem";

import "./styles.scss";

const DealAdminDocuments = ({ dealId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const docsList = useSelector(dealDocsListSelector)?.directories;
  const docSuccess = useSelector(dealDocsSuccessSelector);
  const dealDocsError = useSelector(dealDocsErrorSelector);
  const isLoading = useSelector(dealDocsLoadingSelector);

  const uploadSuccessMsg = t("documents.upload_success");

  useEffect(() => {
    getDealAdminDocuments(dispatch, dealId);
  }, [dispatch, dealId]);

  const handleFileUpload = (files, id) => {
    if (Array.isArray(files)) {
      files.forEach((file) => {
        uploadDealAdminDocument(
          dispatch,
          dealId,
          file,
          id,
          uploadSuccessMsg,
          true
        ).catch((err) => {
          dispatch(setIsLoadingDealDocuments(false));
          dispatch(
            setDealDocumentsError({ message: t("errors.server_general") })
          );
        });
      });
    } else {
      uploadDealAdminDocument(
        dispatch,
        dealId,
        files,
        id,
        uploadSuccessMsg,
        true
      ).catch((err) => {
        dispatch(setIsLoadingDealDocuments(false));
        dispatch(
          setDealDocumentsError({ message: t("errors.server_general") })
        );
      });
    }
  };

  return (
    <section>
      <section className="QM-deal-admin_sidebar_header">
        <h3 className="icon-folder-open QM-deal-admin_sidebar_header_text">
          {t("documents.csv_documents")}
        </h3>
      </section>
      {docsList &&
        docsList.map((folder) => (
          <QMTreeItem
            key={folder.id}
            {...folder}
            handleFileUpload={(files, id) =>
              handleFileUpload(files, id, folder.name)
            }
            isDeletePresent={false}
            acceptedFileTypes={{ "text/csv": [".csv"] }}
          />
        ))}
      {docSuccess && (
        <QMToastMessage
          text={docSuccess.message}
          iconClassName="icon-check-circle"
          selfCloseDelay={TOAST_CLOSE_DELAY}
          onClose={() => dispatch(setDealDocumentsSuccess(null))}
        />
      )}
      {dealDocsError && (
        <QMToastMessage
          viewType="error"
          iconClassName="icon-attention"
          text={dealDocsError?.errors[0]?.message}
          selfCloseDelay={TOAST_CLOSE_DELAY}
          onClose={() => dispatch(setDealDocumentsError(null))}
        />
      )}
      {isLoading && <QMGlobalLoader />}
    </section>
  );
};

export default DealAdminDocuments;
