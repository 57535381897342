import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import QMTile from "../../common/QMTile";
import RiskProfileLitigationsDocsModal from "./RiskProfileLitigationsDocsModal";
import RiskProfileLitigationsItem from "./RiskProfileLitigationsItem";

import "./styles.scss";

const RiskProfileLitigations = ({ header, data }) => {
  const { t } = useTranslation();
  const [dealIdForLitigationsDocs, setDealIdForLitigationsDocs] =
    useState(null);

  return (
    <QMTile>
      <h2 className="risk-profile__header">{header}</h2>
      <section className="risk-profile__litigations-body">
        <div className="risk-profile__litigations-title">
          <span>{t("risk_profile.activities")}</span>
          <span>{t("general.buyer")}</span>
          <span>{t("general.target")}</span>
        </div>
        <div className="risk-profile__litigations-title">
          <span>{t("risk_profile.activities")}</span>
          <span>{t("general.buyer")}</span>
          <span>{t("general.target")}</span>
        </div>
      </section>
      <ul className="risk-profile__litigations-list">
        {data?.map((item, index) => {
          return (
            <RiskProfileLitigationsItem
              key={index}
              name={item.name}
              buyer={item.buyer}
              seller={item.seller}
              target={item.target}
              description={item.description}
              setDealIdForLitigationsDocs={setDealIdForLitigationsDocs}
            />
          );
        })}
      </ul>
      {Boolean(dealIdForLitigationsDocs) && (
        <RiskProfileLitigationsDocsModal
          handleClose={() => setDealIdForLitigationsDocs(null)}
          dealIdForLitigationsDocs={dealIdForLitigationsDocs}
          setDealIdForLitigationsDocs={setDealIdForLitigationsDocs}
        />
      )}
    </QMTile>
  );
};
export default RiskProfileLitigations;
