import React from "react";

import "./styles.scss";

const FaqWhereToStart = () => {
  return (
    <section className="faq-page__item">
      <div className="faq-page__content">
        <div className="faq-page__functions-overview-title">
          <p>
            The core of QUANTUMERGE app 1.0 is to submit R&amp;W policy. You
            have few ways what to do:
          </p>
          <p>
            Option 1. Add companies and carriers to the contacts, then create a
            new deal.
          </p>
          <p>
            Option 2. Create a new deal with a new contacts, then add carriers.
          </p>
        </div>
        <ul className="faq-page__functions-overview-list">
          <p>A new deal allows you:</p>
          <li className="faq-page__functions-overview-item">
            add carriers and companies to the contacts;
          </li>
          <li className="faq-page__functions-overview-item">create a deal;</li>
        </ul>
      </div>
    </section>
  );
};

export default FaqWhereToStart;
