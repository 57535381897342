import React from "react";
import { useTranslation } from "react-i18next";

import "./styles.scss";

const RiskProfileGeneralItem = ({ name, value, item }) => {
  const { t } = useTranslation();

  const generalItemValue = (value) => {
    if (value === "Processing...") {
      return <span className="risk-profile__processing">{value}</span>;
    }
    return <span>{value}</span>;
  };

  const integrationLeadFullName = (value) => {
    if (value.length === 3) {
      return <span>{t("general.na")}</span>;
    }
    return (
      <p>
        <span className="risk-profile__first-name">{value[0]}</span>{" "}
        <span>{value[1]}</span>
      </p>
    );
  };

  return (
    <li className="risk-profile__general-list-item">
      <span className="risk-profile__general-item">{name}</span>
      {name === "Accountable for integration"
        ? integrationLeadFullName(item.values)
        : generalItemValue(value)}
    </li>
  );
};
export default RiskProfileGeneralItem;
