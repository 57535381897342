const formConfig = [
  {
    name: 'company_name',
    fieldType: 'select',
    placeholder: 'Enter company name',
    theme: 'dark',
    formatType: 'text',
    width: '250px',
  },
  {
    name: 'web_site',
    fieldType: 'select',
    placeholder: 'www.example.com',
    theme: 'dark',
    formatType: 'text',
    width: '250px',
  },
  {
    name: 'deal_size',
    fieldType: 'input',
    placeholder: 'e.g., $250,000,000',
    theme: 'dark',
    formatType: 'currency',
    width: '165px',
    mandatory: true,
  },
  {
    name: 'estimated_closing_date',
    fieldType: 'date',
    placeholder: 'mm/dd/yyyy',
    theme: 'dark',
    formatType: 'date',
    width: '155px',
    mandatory: true,
  },
  {
    name: 'desired_limits',
    fieldType: 'input',
    placeholder: 'e.g., $250,000,000',
    theme: 'dark',
    formatType: 'currency',
    width: '184px',
    mandatory: true,
  },
  {
    name: 'insurance_location_coverage_type',
    fieldType: 'radio',
    placeholder: '',
    theme: 'dark',
    formatType: 'text',
    width: '341px',
  },
  {
    name: 'revenue',
    fieldType: 'input',
    placeholder: 'e.g., $250,000,000',
    theme: 'light',
    formatType: 'currency',
    width: '163px',
    mandatory: true,
  },
  // {
  //   name: 'common_shareholders',
  //   fieldType: 'input',
  //   placeholder: 'Enter a number',
  //   tooltip: 'Common shares are usually distributed among employees and founders. Not all employees are given common shares. Please advise the number of employees, advisors or any other third parties that received equity in the form of common shares',
  //   theme: 'light',
  //   formatType: 'number',
  //   width: '143px',
  // },
  // {
  //   name: 'preferred_shareholders',
  //   fieldType: 'input',
  //   placeholder: 'Enter a number',
  //   tooltip: 'Preferred shares are usually distributed among investors or other parties specified in the preferred shares distribution agreement" and founders. Not all employees are given common shares. Please advise the number of employees, advisors or any other third parties that received equity in the form of common shares',
  //   theme: 'light',
  //   formatType: 'number',
  //   width: '143px',
  // },
  // {
  //   name: 'product_sales',
  //   fieldType: 'input',
  //   placeholder: 'e.g., $250,000,000',
  //   tooltip: 'Product Sales means gross revenue from product sales for the past 12 months or if your reporting period is a fiscal year, in that case for the past fiscal year. If you manufacture a product or software that was sold under Products category - revenue from these transactions will go here',
  //   theme: 'light',
  //   formatType: 'currency',
  //   width: '184px',
  // },
  // {
  //   name: 'voting_board_members',
  //   fieldType: 'input',
  //   placeholder: 'Enter a number',
  //   tooltip: 'Voting board members are all of those board members that can influence the course of a company. Voting board members are usually the top executives of the company and representatives of your investors and financially interested parties. Your board structure is usually found in the articles of incorporation',
  //   theme: 'light',
  //   formatType: 'number',
  //   width: '141px',
  // },
  // {
  //   name: 'officers',
  //   fieldType: 'input',
  //   placeholder: 'Number',
  //   tooltip: 'Officers of the company are all those with the "chief" title, and/or but not necessarily a "director" title. Officers of the company are usually listed on the Dec page of your Directors & Officers insurance liability coverage',
  //   theme: 'light',
  //   formatType: 'number',
  //   width: '101px',
  // },
  // {
  //   name: 'total_assets',
  //   fieldType: 'input',
  //   placeholder: 'e.g., $250,000,000',
  //   tooltip: 'Total assets means the dollar value of all of your assets.Asset means: owned properties, all subsidiaries, dwellings and entities that generate and do not generate revenue',
  //   theme: 'light',
  //   formatType: 'currency',
  //   width: '181px',
  // },
  // {
  //   name: 'global_employees',
  //   fieldType: 'input',
  //   placeholder: 'Number in all offices',
  //   tooltip: 'Employees only across all domestic and global offices, subsidiaries and their global offices. Please do not include independent contractors, or those with 1099 forms. Temporary or seasonal workers should be included in the count',
  //   theme: 'light',
  //   formatType: 'number',
  //   width: '195px',
  // },
  // {
  //   name: 'tax_liabilities',
  //   fieldType: 'input',
  //   placeholder: 'e.g., $250,000,000',
  //   theme: 'light',
  //   formatType: 'currency',
  //   width: '180px',
  // },
  // {
  //   name: 'background_checks',
  //   fieldType: 'input',
  //   placeholder: 'Number of receivers',
  //   tooltip: 'Please provide information about the number of employees that received background checks in your organization',
  //   theme: 'light',
  //   formatType: 'number',
  //   width: '200px',
  // },
  // {
  //   name: 'current_total_liabilities',
  //   fieldType: 'input',
  //   placeholder: 'e.g., $250,000,000',
  //   theme: 'light',
  //   formatType: 'currency',
  //   width: '159px',
  // },
  // {
  //   name: 'external_audits',
  //   fieldType: 'input',
  //   placeholder: 'Number',
  //   tooltip: 'External audits are performed by third parties, independent auditors or auditors from the entities that give you a certain line of authority to act on their behalf',
  //   theme: 'light',
  //   formatType: 'number',
  //   width: '145px',
  // },
  // {
  //   name: 'internal_audits',
  //   fieldType: 'input',
  //   placeholder: 'Number',
  //   tooltip: 'Internal audits are performed by your internal compliance department, officers who are in charge of compliance in your organization',
  //   theme: 'light',
  //   formatType: 'number',
  //   width: '145px',
  // },
  // {
  //   name: 'owned_properties',
  //   fieldType: 'input',
  //   placeholder: 'Number',
  //   tooltip: 'Please provide information about the number of properties owned globally. Property means a geographic location, real estate, a plot of land, dwelling, including properties of every subsidiary of yours',
  //   theme: 'light',
  //   formatType: 'number',
  //   width: '130px',
  // },
  // {
  //   name: 'leased_properties',
  //   fieldType: 'input',
  //   placeholder: 'Number',
  //   tooltip: 'Please provide information about the number of properties leased globally. Property means a geographic location, real estate, a plot of land, dwelling, including properties of every subsidiary of yours that you lease for the purpose of conducting your operations, even if that property doesn\'t generate any revenue',
  //   theme: 'light',
  //   formatType: 'number',
  //   width: '130px',
  // },
  // {
  //   name: 'insurance_claims',
  //   fieldType: 'input',
  //   placeholder: 'Number',
  //   tooltip: 'Please provide the total number of claims that have a unique claims ID or claims number provided by your insurance carrier across all insurance coverages you have or had in the past 5 years',
  //   theme: 'light',
  //   formatType: 'number',
  //   width: '188px',
  // },
  // {
  //   name: 'location_of_assets',
  //   fieldType: 'multiselect',
  //   placeholder: 'Enter location',
  //   tooltip: 'please advise location of all assets, including owned ad leased properties, subsidiaries, their headquarters, owned and leased properties globally. There should be at least 1 location to continue. This location is the state where you are incorporated or operating from',
  //   theme: 'light',
  //   formatType: 'array',
  //   width: '335px',
  // },
];

const defaultValues = {
  // background_checks: "",
  insurance_location_coverage_type: "us_style",
  estimated_closing_date: null,
  // common_shareholders: "",
  company_name: undefined,
  // current_total_liabilities: "",
  deal_size: "",
  desired_limits: "",
  // external_audits: "",
  // global_employees: "",
  // insurance_claims: "",
  // internal_audits: "",
  // leased_properties: "",
  // location_of_assets: [],
  // officers: "",
  // owned_properties: "",
  // preferred_shareholders: "",
  // product_sales: "",
  revenue: "",
  // tax_liabilities: "",
  // total_assets: "",
  // voting_board_members: "",
  web_site: undefined
};

export { 
  formConfig as default,
  defaultValues
}
