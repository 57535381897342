import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  reformatDealStatusLabel,
  reformatDealStatusValue,
} from "../../../../../helpers/deal-helpers";
import { patchDealStatus } from "../../../../../api/dealApi";
import { dealInfoSelector } from "../../../../../redux/selectors/deal-selectors";

import { QMSelectDeal } from "../../../common/QMInputs";
import DealPageModal from "../DealPageModal";

const StatusChangeForm = ({ id }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const dealStatusOptions = [
    { value: "active", label: t("general.active_status"), color: "#00A6A9" },
    {
      value: "inactive",
      label: t("general.inactive_status"),
      color: "#C9D1D9",
    },
  ];

  const [showInactiveStatusModal, setShowInactiveStatusModal] = useState(false);
  const [showActiveStatusModal, setShowActiveStatusModal] = useState(false);
  const dealInfo = useSelector(dealInfoSelector);

  const { handleSubmit, control, watch, reset, setValue } = useForm({
    mode: "onChange",
  });

  const dealStatus = watch(`status`);

  const valueReset = () => {
    reset({
      status: {
        label: reformatDealStatusLabel(dealInfo),
        value: reformatDealStatusValue(dealInfo),
      },
    });
  };

  const onSubmit = async (data) => {
    await patchDealStatus(dispatch, id, data);
    setShowActiveStatusModal(false);
    setShowInactiveStatusModal(false);
  };
  useEffect(() => {
    if (dealInfo.status) {
      setValue("status", {
        label: reformatDealStatusLabel(dealInfo),
        value: reformatDealStatusValue(dealInfo),
      });
    }
  }, [setValue, dealInfo]);

  useEffect(() => {
    if (dealStatus === "inactive" && dealStatus !== dealInfo.status) {
      setShowInactiveStatusModal(true);
    }
    if (dealStatus === "active" && dealStatus !== dealInfo.status) {
      setShowActiveStatusModal(true);
    }
  }, [dealInfo.status, dealStatus]);

  useEffect(() => {
    if (showActiveStatusModal === true || showInactiveStatusModal === true) {
      document.body.style.overflow = "hidden";
    }
    if (showActiveStatusModal === false && showInactiveStatusModal === false) {
      document.body.style.overflow = "";
    }
  }, [showActiveStatusModal, showInactiveStatusModal]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {dealInfo.status ? (
        <Controller
          render={({ field }) => (
            <QMSelectDeal
              {...field}
              isSearchable={false}
              options={dealStatusOptions}
              value={dealStatusOptions.find(
                (c) => c.value === field?.value?.value
              )}
              onChange={(val) => {
                field.onChange(val.value);
              }}
            />
          )}
          name="status"
          control={control}
        />
      ) : (
        <></>
      )}
      {showActiveStatusModal && (
        <DealPageModal
          title={t("general.modal_header_change_status")}
          text={t("general.modal_body_active_status")}
          subText={t("general.modal_body_active_status_confirm")}
          cancelButtonText={t("general.cancel_button")}
          submitButtonText={t("general.submit_button_active_status")}
          setInactiveStatus={setShowActiveStatusModal}
          reset={valueReset}
        />
      )}
      {showInactiveStatusModal && (
        <DealPageModal
          title={t("general.modal_header_change_status")}
          text={t("general.modal_body_inactive_status")}
          subText={t("general.modal_body_inactive_status_confirm")}
          cancelButtonText={t("general.cancel_button")}
          submitButtonText={t("general.submit_button_inactive_status")}
          setInactiveStatus={setShowInactiveStatusModal}
          reset={valueReset}
        />
      )}
    </form>
  );
};

export default StatusChangeForm;
