export const riskProfileLoadingSelector = (state) =>
  state.riskProfile.isLoadingRiskProfile;
export const riskProfileDescriptionSelector = (state) =>
  state.riskProfile.riskProfileInfo.description;
export const riskProfileGeneralSelector = (state) =>
  state.riskProfile.riskProfileInfo.general;
export const riskProfileInsuranceSelector = (state) =>
  state.riskProfile.riskProfileInfo.insurance_compliance;
export const riskProfileOutstandingSelector = (state) =>
  state.riskProfile.riskProfileInfo.outstanding_data;
export const riskProfileLitigationsSelector = (state) =>
  state.riskProfile.riskProfileInfo.litigations;
export const riskProfileLitigationsDocsSelector = (state) =>
  state.riskProfile.riskProfileLitigationsDocs;
export const riskProfileStakeholdersSelector = (state) =>
  state.riskProfile.riskProfileInfo.stakeholders;
export const riskProfileAssetsSelector = (state) =>
  state.riskProfile.riskProfileInfo.assets;
