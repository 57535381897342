import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";

import {
  getDealPastActivities,
  putDealPastActivities,
} from "../../../../../api/dealApi";
import {
  dealPastActivitiesSelector,
  dealSaveSuccessSelector,
} from "../../../../../redux/selectors/deal-selectors";
import { setDealSaveSuccess } from "../../../../../redux/reducers/dealPage-reducer";

import useLeavePageBlocker from "../../../../../helpers/useLeavePageBlocker";
import { TOAST_CLOSE_DELAY } from "../../../../../helpers";

import QMSaveLayout from "../../../common/QMSaveLayout";
import QMToastMessage from "../../../common/QMToastMessage";
import QuestionSet from "./QuestionSet";
import questions from "./questionsConfig";

const PastActivitiesForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let { dealId } = useParams();
  const isSaved = useSelector(dealSaveSuccessSelector);
  const prevPastActivities = useSelector(dealPastActivitiesSelector);
  const [wasPrefilled, setWasPrefilled] = useState(false);
  const {
    register,
    unregister,
    reset,
    handleSubmit,
    // setError,
    setValue,
    getValues,
    watch,
    // control,
    formState: { isDirty, isSubmitSuccessful }, // errors may be added here
  } = useForm({
    mode: "onChange",
  });
  const onSubmit = (data) => {
    putDealPastActivities(dispatch, dealId, data);
  };

  useEffect(() => {
    getDealPastActivities(dispatch, dealId);
  }, [dispatch, dealId]);

  useEffect(() => {
    if (prevPastActivities && Object.keys(prevPastActivities).length) {
      Object.keys(prevPastActivities).forEach((key) =>
        setValue(key, prevPastActivities[key])
      );

      const prefilled = Object.values(prevPastActivities).some(
        (o) => o.buyer || o.target || o.not_applicable
      );
      setWasPrefilled(prefilled);
    }
  }, [prevPastActivities, setValue]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(undefined, { keepValues: true });

      const prefilled = Object.values(getValues()).some(
        (o) => o.buyer || o.target || o.not_applicable
      );
      setWasPrefilled(prefilled);
    }
  }, [isSubmitSuccessful, reset, getValues]);

  useLeavePageBlocker(isDirty);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <section className="questionRow">
        <strong></strong>
        <strong>{t("general.buyer")}</strong>
        <strong>{t("general.target")}</strong>
        <strong>{t("general.na")}</strong>
      </section>
      {questions.map((question) => (
        <QuestionSet
          key={question.number_code}
          register={register}
          unregister={unregister}
          setValue={setValue}
          watch={watch}
          canHaveWarning={wasPrefilled}
          {...question}
        />
      ))}
      {isDirty && !isSubmitSuccessful && <QMSaveLayout />}
      {isSaved && (
        <QMToastMessage
          viewType="success"
          text={t("general.data_saved")}
          iconClassName="icon-check-circle"
          onClose={() => {
            dispatch(setDealSaveSuccess(false));
          }}
          selfCloseDelay={TOAST_CLOSE_DELAY}
        />
      )}
    </form>
  );
};

export default PastActivitiesForm;
