import classNames from "classnames";
import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";

const QMKeyValueTextBlock = ({
  keyName,
  value,
  shouldCopyToClipboard,
  handleCopy,
}) => {
  const handleCopyToClipboard = () => {
    if (shouldCopyToClipboard) {
      navigator.clipboard.writeText(value);
      handleCopy();
    }
  };
  const classes = classNames("QM-kvBlock", {
    "QM-kvBlock__clickable": shouldCopyToClipboard,
  });

  return (
    <li className={classes} onClick={handleCopyToClipboard}>
      <strong>{keyName}</strong>
      <span>{value}</span>
    </li>
  );
};

QMKeyValueTextBlock.propTypes = {
  keyName: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
    PropTypes.object.isRequired,
  ]),
  shouldCopyToClipboard: PropTypes.bool,
  handleCopy: PropTypes.func,
};

QMKeyValueTextBlock.defaultProps = {
  shouldCopyToClipboard: false,
  handleCopy: () => {},
};
export default QMKeyValueTextBlock;
