import React from "react";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { numFormatter } from "../../../../helpers";
import {
  IcebergSmImg,
  ArrowSmImg,
  ChevronRight,
} from "../../../../assets/imgs";
import { setBundlesOrder } from "../../../../redux/reducers/estimator-reducer";

import "./styles.scss";

const QMEBundleCardWrapper = ({ children, classes, ...props }) => {
  return (
    <section className={classNames("QM_E-bundleCard", classes)} {...props}>
      {children}
    </section>
  );
};

const QMEBundleCard = ({ bundle, isActive, isFirst, isLast, index }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleViewClick = () => {
    dispatch(setBundlesOrder(index));
  };

  return (
    <QMEBundleCardWrapper
      classes={classNames({
        "QM_E-bundleCard__active": isActive,
        "QM_E-bundleCard__first": isFirst,
        "QM_E-bundleCard__last": isLast,
      })}
      onClick={!isActive ? handleViewClick : () => {}}
    >
      <section className="QM_E-bundleCard_content">
        <h4 className="QM_E-chart_text QM_E-chart_text__regular-12">
          {t(`estimator.chart.bundles.${bundle.bundle_type}`)}
        </h4>
        <h3 className="QM_E-chart_text QM_E-chart_text__bold-18">
          Estimated Price
        </h3>
        <section className="QM_E-bundleCard_chart">
          <p className="QM_E-chart_text QM_E-chart_text__bold-36 QM_E-chart_text__costContainer">
            {numFormatter(bundle.rwi_expected_cost).map((item, i) => (
              <span key={i}>{item}</span>
            ))}
          </p>
          <ArrowSmImg className="QM_E-bundleCard_chart_arrow" />
          <IcebergSmImg />
        </section>
        {isActive ? (
          <p className="QM_E-chart_text QM_E-chart_text__bold-13">Current</p>
        ) : (
          <button className="QM_E-bundleCard_button" onClick={handleViewClick}>
            <p className="QM_E-chart_text QM_E-chart_text__bold-13">View</p>
            <ChevronRight />
          </button>
        )}
      </section>
    </QMEBundleCardWrapper>
  );
};

export { QMEBundleCard as default, QMEBundleCardWrapper };
