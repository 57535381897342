import React from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import QMLogo from '../../common/QMLogo';
import './styles.scss';

const QMHeader = ({className, navClassName}) => {
  const linkClasses = ({isActive}) => classNames({
    "navLink": true,
    "navLink__active": isActive
  });
  return (
    <header className={classNames('QM-commonHeader', className)}>
        <nav className={classNames("custom__commonWidth", navClassName)}>
          <QMLogo />
          <ul>
          <li><NavLink to="/"  className={linkClasses}>Home</NavLink></li>
          <li><NavLink to="/estimator" className={linkClasses}>Estimator</NavLink></li>
          <li><a href="mailto:partner@quantumerge.com?subject=Partnership%20Inquiry" className="custom__button">Become a Partner</a></li>
          </ul>
        </nav>
      </header>
  );
}

export default QMHeader;