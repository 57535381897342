import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./styles.scss";

const QMButton = ({
  viewType,
  customClassName,
  iconClassName,
  iconReverse,
  text,
  onClick,
  ...props
}) => {
  const buttonClasses = classNames({
    "QM-btn": true,
    "QM-btn__primary": viewType === "primary",
    "QM-btn__secondary": viewType === "secondary",
    "QM-btn__transparent": viewType === "transparent",
    "QM-btn__tile": viewType === "tile",
    "QM-btn__reverse": iconReverse,
    [customClassName]: customClassName,
  });
  return (
    <button className={buttonClasses} onClick={onClick} {...props}>
      {iconClassName && <span className={iconClassName}></span>}
      {text && <span>{text}</span>}
    </button>
  );
};

QMButton.propTypes = {
  viewType: PropTypes.oneOf(["primary", "secondary", "tile", "transparent"]),
  iconClassName: PropTypes.string,
  customClassName: PropTypes.string,
  iconReverse: PropTypes.bool,
  text: PropTypes.string,
  onClick: PropTypes.func,
};

QMButton.defaultProps = {
  viewType: "primary",
  iconClassName: "",
  customClassName: "",
  iconReverse: false,
  text: "",
  onClick: () => {},
};

export default QMButton;
