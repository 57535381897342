import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import QMButton from "../../../common/QMButton";
import QMModal from "../../../common/QMModal";
import {
  QMTextInput,
  QMSelectInput,
  QMPhoneNumberInput,
} from "../../../common/QMInputs";
import * as validation from "./validationRules";

import "./styles.scss";

const FieldsetNewDueDilligence = ({
  register,
  control,
  watch,
  groupName,
  errors,
  handleRemove,
  setValue,
}) => {
  const { t } = useTranslation();
  const dueDiligenceTypes = [
    { value: "legal", label: t("rwi_application.due_diligence_legal") },
    { value: "finance", label: t("rwi_application.due_diligence_finance") },
    { value: "tax", label: t("rwi_application.due_diligence_tax") },
    {
      value: "environmental",
      label: t("rwi_application.due_diligence_environmental"),
    },
  ];
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const type = watch(`${groupName}.type`);
  const errorSelector = (fieldName) => {
    const groupParts = groupName.split(".");
    return errors?.[groupParts[0]]?.[groupParts[1]]?.[fieldName];
  };
  const getTitle = () => {
    return type
      ? dueDiligenceTypes.find((el) => el.value === type).label
      : t("contact.select_due_diligence_type");
  };

  const handleSelectChange = (val, field) => {
    const updatedVal = val === null ? val : val.value;
    field.onChange(updatedVal);
  };
  useEffect(() => {
    if (!type) {
      setValue(`${groupName}.company_name`, "");
      setValue(`${groupName}.contact_name`, "");
      setValue(`${groupName}.contact_phone`, "");
      setValue(`${groupName}.contact_email`, "");
    }
  }, [type, groupName, setValue]);
  return (
    <fieldset className="QM-formBlock">
      <legend className="QM-formBlock_legend__flex">
        <p className="QM-formBlock_type-title">{getTitle()}</p>
        <QMButton
          viewType="transparent"
          iconClassName="icon-trash"
          customClassName="QM-btn__remove"
          onClick={() => setIsDeleteModalOpen(true)}
          title={t("general.delete")}
          type="button"
        />
      </legend>
      <div className="QM-formBlock_row">
        <Controller
          render={({ field }) => (
            <QMSelectInput
              {...field}
              placeholder={t("general.choose_answer")}
              label={t("contact.contact_type")}
              errors={errorSelector("type")}
              options={dueDiligenceTypes}
              isSearchable={false}
              value={dueDiligenceTypes.find((c) => c.value === field.value)}
              onChange={(val) => handleSelectChange(val, field)}
              isRequired
              isClearable
            />
          )}
          control={control}
          name={`${groupName}.type`}
          rules={validation.typeValidation}
        />
      </div>
      <div className="QM-formBlock_row">
        <QMTextInput
          {...register(
            `${groupName}.company_name`,
            validation.companyValidation
          )}
          label={t("contact.company_name")}
          placeholder={t("contact.company_name_placeholder")}
          errors={errorSelector("company_name")}
          disabled={!type}
        />
        <QMTextInput
          {...register(`${groupName}.contact_name`, validation.nameValidation)}
          label={t("contact.contact_name")}
          placeholder={t("contact.contact_name_placeholder")}
          errors={errorSelector("contact_name")}
          disabled={!type}
        />
      </div>
      <div className="QM-formBlock_row">
        <Controller
          render={({ field }) => (
            <QMPhoneNumberInput
              {...field}
              label={t("contact.contact_phone")}
              placeholder={t("general.phone_placeholder")}
              errors={errorSelector("contact_phone")}
              disabled={!type}
            />
          )}
          control={control}
          name={`${groupName}.contact_phone`}
          rules={validation.phoneValidation}
        />
        <QMTextInput
          {...register(
            `${groupName}.contact_email`,
            validation.emailValidation
          )}
          label={t("contact.contact_email")}
          placeholder={t("general.email_placeholder")}
          errors={errorSelector("contact_email")}
          disabled={!type}
        />
      </div>
      {isDeleteModalOpen && (
        <QMModal size="small" customClassName="delete-modal">
          <h2>{t("contact.delete_due_diligence_provider")}</h2>
          <p>{t("contact.delete_due_diligence_provider_confirm")}</p>
          <section className="delete-modal_btnsContainer">
            <QMButton
              viewType="secondary"
              onClick={() => setIsDeleteModalOpen(false)}
              text={t("general.cancel")}
            />
            <QMButton
              onClick={handleRemove}
              text={t("documents.delete_file_confirm")}
            />
          </section>
        </QMModal>
      )}
    </fieldset>
  );
};

export default FieldsetNewDueDilligence;
