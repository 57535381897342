import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";

import QMGlobalLoader from "../../../common/QMLoader";
import QMToastMessage from "../../common/QMToastMessage";
import NewDealForm from "./NewDealForm";

import validationSchema from "./validationSchema";
import { createNewDeal } from "../../../../api/dealsApi";
import useLeavePageBlocker from "../../../../helpers/useLeavePageBlocker";

const NewDealPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setError,
    getValues,
    setValue,
    clearErrors,
    watch,
    trigger,
    control,
    formState: {
      errors,
      touchedFields,
      isValid,
      isDirty,
      isSubmitting,
      isSubmitted,
    },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });
  const [isLoading, setIsLoading] = useState(false);
  const [generalError, setGeneralError] = useState(null);
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);
  const [selectedBuyerPrediction, setSelectedBuyerPrediction] = useState(null);
  const [selectedSellerPrediction, setSelectedSellerPrediction] =
    useState(null);
  const [selectedBuyerParentPrediction, setSelectedBuyerParentPrediction] =
    useState(null);
  const [selectedTargetPrediction, setSelectedTargetPrediction] =
    useState(null);
  const handleCreateSuccess = (dealId) => {
    navigate(`/broker/portfolio/deals/${dealId}`);
  };
  const handleCreateSubmitSuccess = (dealId) => {
    setIsConfirmVisible(false);
    navigate(`/broker/portfolio/deals/${dealId}`);
  };
  const onSubmit = (data) => {
    const dunsData = {
      buyer_duns_number: selectedBuyerPrediction?.duns_number,
      buyer_parent_duns_number: selectedBuyerParentPrediction?.duns_number,
      seller_duns_number: selectedSellerPrediction?.duns_number,
      seller_subsidiary_duns_number: selectedTargetPrediction?.duns_number,
    };
    createNewDeal(
      data,
      dunsData,
      setIsLoading,
      setGeneralError,
      handleCreateSuccess,
      setError
    ).catch((err) => {
      console.error("uncaught error occured", err);
      setGeneralError({
        message: t("errors.server_general"),
      });
      setIsLoading(false);
    });
  };
  const handleCreateSubmitError = (error) => {
    setIsConfirmVisible(false);
    setGeneralError(error);
  };
  const onUnderwritingSubmit = (data) => {
    createNewDeal(
      data,
      setIsLoading,
      handleCreateSubmitError,
      handleCreateSubmitSuccess,
      setError,
      true
    );
  };
  const leaveBlockerCondition = isDirty && !isSubmitting && !isSubmitted;
  useLeavePageBlocker(leaveBlockerCondition);

  return (
    <>
      <NewDealForm
        register={register}
        handleSubmit={handleSubmit(onSubmit)}
        onUnderwritingSubmit={handleSubmit(onUnderwritingSubmit)}
        setError={setError}
        getValues={getValues}
        setValue={setValue}
        clearErrors={clearErrors}
        watch={watch}
        trigger={trigger}
        control={control}
        errors={errors}
        touchedFields={touchedFields}
        isValid={isValid}
        isConfirmVisible={isConfirmVisible}
        setIsConfirmVisible={setIsConfirmVisible}
        buyerPrediction={selectedBuyerPrediction}
        buyerPredictionSetter={setSelectedBuyerPrediction}
        buyerParentPrediction={selectedBuyerParentPrediction}
        buyerParentPredictionSetter={setSelectedBuyerParentPrediction}
        sellerPrediction={selectedSellerPrediction}
        sellerPredictionSetter={setSelectedSellerPrediction}
        targetPrediction={selectedTargetPrediction}
        targetPredictionSetter={setSelectedTargetPrediction}
        setGeneralError={setGeneralError}
      />
      {isLoading && <QMGlobalLoader />}
      {generalError && (
        <QMToastMessage
          text={generalError.message}
          viewType="error"
          iconClassName="icon-attention"
          onClose={() => setGeneralError(null)}
        />
      )}
    </>
  );
};

export default NewDealPage;
