import React from "react";
import Tippy from "@tippyjs/react";

import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";

import "./styles.scss";

const QMToolTipName = ({ text, maxLength }) => {
  return (
    <Tippy
      content={<section className="QM_tooltip_name">{text}</section>}
      theme="light"
      interactive
      placement="top"
    >
      <span className="QM_tooltip_name_text">
        {text.substring(0, maxLength).concat("...")}
      </span>
    </Tippy>
  );
};

export default QMToolTipName;
