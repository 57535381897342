import React from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";

import { updateTimingTab } from "../../../../../api/timingTabApi";
import useLeavePageBlocker from "../../../../../helpers/useLeavePageBlocker";
import { DATE_TIMING_FORMAT } from "../../../../../helpers";
// import { TODAY } from "../../../../../helpers/validationConsts";
import timingValidationSchema from "./timingValidationSchema";
import QMGlobalLoader from "../../../../common/QMLoader";
import QMSaveLayout from "../../../common/QMSaveLayout";
import { QMDateInput } from "../../../common/QMInputs";
import QMToastMessage from "../../../common/QMToastMessage";
import {
  dealErrorSelector,
  dealSaveSuccessSelector,
} from "../../../../../redux/selectors/deal-selectors";
import {
  setDealError,
  setDealSaveSuccess,
} from "../../../../../redux/reducers/dealPage-reducer";
import { SELF_CLOSE_DELAY } from "../../../../../helpers/broker-helpers";

import "./styles.scss";
import { useEffect } from "react";

const TimingForm = ({ timingTabData, dealId, isLoading }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const timingError = useSelector(dealErrorSelector);
  const isSaved = useSelector(dealSaveSuccessSelector);
  const {
    handleSubmit,
    clearErrors,
    getValues,
    setValue,
    setError,
    reset,
    control,
    formState: { errors, isDirty, isSubmitSuccessful },
  } = useForm({
    resolver: yupResolver(timingValidationSchema),
    mode: "onChange",
  });

  const onSubmit = (data) => {
    updateTimingTab(dispatch, dealId, data, setError);
  };

  const getFieldErrors = (fieldName) => {
    return errors[fieldName];
  };
  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(undefined, { keepValues: true });
    }
  }, [isSubmitSuccessful, reset]);
  useEffect(() => {
    if (timingTabData && Object.keys(timingTabData).length) {
      Object.keys(timingTabData).forEach((key) => {
        const val = timingTabData[key] ? new Date(timingTabData[key]) : null;
        setValue(key, val);
      });
    }
  }, [timingTabData, setValue]);

  useLeavePageBlocker(isDirty);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <section className="timing-tab">
        <Controller
          render={({ field }) => (
            <QMDateInput
              {...field}
              label={t("timing.expected_signing_exchange_date")}
              errors={getFieldErrors("signing_date")}
              format={DATE_TIMING_FORMAT}
              clearErrors={clearErrors}
              setError={setError}
              getValues={getValues}
              iconClassName={
                field.value === null
                  ? `icon-calendar icon-calendar__inactive`
                  : `icon-calendar`
              }
              placeholder={t("general.select_date")}
              onChange={(date) => field.onChange(date)}
            />
          )}
          control={control}
          name="signing_date"
        />
        <Controller
          render={({ field }) => (
            <QMDateInput
              {...field}
              label={t("timing.expected_closing_date")}
              errors={getFieldErrors("closing_date")}
              format={DATE_TIMING_FORMAT}
              // minDate={TODAY}
              clearErrors={clearErrors}
              setError={setError}
              getValues={getValues}
              iconClassName={
                field.value === null
                  ? `icon-calendar icon-calendar__inactive`
                  : `icon-calendar`
              }
              placeholder={t("general.select_date")}
              onChange={(date) => field.onChange(date)}
            />
          )}
          control={control}
          name="closing_date"
        />
      </section>
      <section className="timing-tab">
        <Controller
          render={({ field }) => (
            <QMDateInput
              {...field}
              label={t("timing.policy_insurance_date")}
              errors={getFieldErrors("policy_insurance_date")}
              format={DATE_TIMING_FORMAT}
              clearErrors={clearErrors}
              setError={setError}
              getValues={getValues}
              iconClassName={
                field.value === null
                  ? `icon-calendar icon-calendar__inactive`
                  : `icon-calendar`
              }
              placeholder={t("general.select_date")}
              onChange={(date) => field.onChange(date)}
            />
          )}
          control={control}
          name="policy_insurance_date"
        />
      </section>
      <section className="timing-tab-UW-process">
        <strong className="timing-tab-UW-process__title">
          {t("timing.uw_process")}
        </strong>
        <p className="timing-tab-UW-process__text">
          {t("timing.uw_process_desc")}
        </p>
      </section>
      {isDirty && !isSubmitSuccessful && <QMSaveLayout />}
      {isLoading && <QMGlobalLoader />}
      {timingError && (
        <QMToastMessage
          text={timingError.message}
          viewType="error"
          iconClassName="icon-attention"
          onClose={() => dispatch(setDealError(null))}
        />
      )}
      {isSaved && (
        <QMToastMessage
          viewType="success"
          text={t("general.data_saved")}
          iconClassName="icon-check-circle"
          onClose={() => {
            dispatch(setDealSaveSuccess(false));
          }}
          selfCloseDelay={SELF_CLOSE_DELAY}
        />
      )}
    </form>
  );
};

export default TimingForm;
