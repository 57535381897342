import React from "react";

import "./styles.scss";

const FaqFunctionOverview = () => {
  return (
    <section className="faq-page__item">
      <div className="faq-page__content">
        <ul className="faq-page__functions-overview-list">
          <p className="faq-page__functions-overview-title">
            Welcome, to the QUANTUMERGE app 1.0. This platform helps M&amp;A
            brokers proceed through the merge and acquisition process. The main
            functions:
          </p>
          <li className="faq-page__functions-overview-item">
            add carriers and companies to the contacts;
          </li>
          <li className="faq-page__functions-overview-item">create a deal;</li>
          <li className="faq-page__functions-overview-item">
            fill out application form and provide files;
          </li>
          <li className="faq-page__functions-overview-item">
            submit application;
          </li>
          <li className="faq-page__functions-overview-item">reseive policy;</li>
        </ul>
      </div>
    </section>
  );
};

export default FaqFunctionOverview;
