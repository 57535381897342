import { configureStore } from "@reduxjs/toolkit";
import estimatorReducer from "./reducers/estimator-reducer";
import dealsReducer from "./reducers/deals-reducer";
import dealPageReducer from "./reducers/dealPage-reducer";
import commonReducer from "./reducers/common-reducer";
import brokerActionsReducer from "./reducers/brokerActions-reducer";
import dueDilligenceTabReducer from "./reducers/dueDilligenceTab-reducer";
import dealInfoReducer from "./reducers/dealInfoTab-reducer";
import insuranceTabReducer from "./reducers/insuranceTab-reducer";
import underwritingReducer from "./reducers/underwriting-reducer";
import dealDocsReducer from "./reducers/dealDocs-reducer";
import riskProfileReducer from "./reducers/riskProfile-reducer";
import timingTabReducer from "./reducers/timingTab-reducer";
import assignLawyerReducer from "./reducers/assignLawyer-reducer";
import exposureReducer from "./reducers/exposure-reducer";

export const store = configureStore({
  reducer: {
    estimator: estimatorReducer,
    deals: dealsReducer,
    dealPage: dealPageReducer,
    common: commonReducer,
    brokerActionsMenu: brokerActionsReducer,
    dueDilligenceTab: dueDilligenceTabReducer,
    dealInfoTab: dealInfoReducer,
    insuranceTab: insuranceTabReducer,
    underwriting: underwritingReducer,
    dealDocs: dealDocsReducer,
    riskProfile: riskProfileReducer,
    timingTab: timingTabReducer,
    assignLawyer: assignLawyerReducer,
    exposure: exposureReducer,
  },
});
