const reactSelectStylesConfig = (hasError, saleAcquisitionToolTip) => ({
  control: (provided, state) => {
    return {
      ...provided,
      width: "100%",
      backgroundColor: "#FFF",
      border:
        state.isFocused || state.menuIsOpen
          ? "1px solid #1B91B6"
          : hasError
          ? "1px solid #E05A5C"
          : "1px solid #C9D1D9",
      borderRadius: "4px",
      minHeight: "42px",
      cursor: "pointer",
      boxShadow: "none",
      ":hover": {
        border:
          state.isFocused || state.menuIsOpen
            ? "1px solid #1B91B6"
            : "1px solid #C9D1D9",
      },
    };
  },
  input: (provided) => ({
    ...provided,
    color: "#1A1A36",
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "20px",
    cursor: "pointer",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "#1A1A36",
    fontSize: "20px",
    ":hover": {
      color: "#1A1A36",
    },
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    color: "#1A1A36",
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: "300",
    fontStyle: "italic",
    lineHeight: "19px",
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: "200px",
    overflowY: saleAcquisitionToolTip ? "visible" : "auto",
    "::-webkit-scrollbar": {
      width: "6px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#DDF1FF",
      borderRadius: "8px",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#A1B4C6",
      borderRadius: "8px",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#3b6185",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    color: "#1A1A36",
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "20px",
    cursor: "pointer",
    backgroundColor: state.isSelected ? "#A6E0F2" : "#FFF",
    ":hover": {
      backgroundColor: state.isSelected ? "#A6E0F2" : "#E9F7FC",
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#C9D1D9",
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "20px",
    display: "flex",
    "> svg": {
      marginRight: "8px",
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#333333",
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "19px",
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "#DDF1FF",
    fontFamily: "Rubik",
    border: "1px solid #C4E6FF",
    borderRadius: "8px",
    padding: "6px 10px",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: "#24477D",
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "19px",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    cursor: "pointer",
    ":hover": {
      backgroundColor: "none",
      color: "none",
    },
    ":hover > svg": {
      stroke: "#3d77aa",
    },
  }),
});

export default reactSelectStylesConfig;
