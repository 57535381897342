import React from "react";

import "./styles.scss";

const NeitherSection = () => {
  return (
    <section id="landing__Neither" className="custom__commonSection">
      <div className="custom__commonWidth">
        <h3>Is Quantumerge a broker or an insurance provider?</h3>
        <p>
          The answer is: So much more!
          <br />
          We are an insurance company offering representations and warranties
          insurance for small technology acquisitions
        </p>
        <ul>
          <li>
            <h4 className="icon-coffee">Easy</h4>
            <p>
              We simplify your life by providing tools and resources to speed up
              your M&A insurance placement process.
            </p>
          </li>
          <li>
            <h4 className="icon-gauge">Straightforward</h4>
            <p>
              We provide enriched data to the underwriters for the light-speed
              decision making
            </p>
          </li>
          <li>
            <h4 className="icon-shield-check">Predictable</h4>
            <p>
              We equip you with knowledge to lower the risk of the post-M&A
              losses
            </p>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default NeitherSection;
