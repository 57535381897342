import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  dealDocsErrorSelector,
  dealDocsListSelector,
  dealDocsLoadingSelector,
  dealDocsSuccessSelector,
} from "../../../../../redux/selectors/dealDocs-selectors";

import {
  setDealDocumentsError,
  setDealDocumentsSuccess,
  setIsLoadingDealDocuments,
} from "../../../../../redux/reducers/dealDocs-reducer";

import {
  deleteDealDocument,
  getDealDocumentPdf,
  getDealDocuments,
  uploadDealDocument,
} from "../../../../../api/dealDocsApi";

import QMTreeItem from "../../../common/QMTreeItem";
import QMGlobalLoader from "../../../../common/QMLoader";
import QMPdfViewer from "../../../common/QMPdfViewer";
import QMToastMessage from "../../../common/QMToastMessage";
import { TOAST_CLOSE_DELAY } from "../../../../../helpers";

// APPROACH NEEDS IMPROVEMENTS
const apiUrlDictionary = {
  Contacts: "contacts",
  "Deal Info": "deal_info",
  "Past Activities": "past_activities",
  Insurance: "insurance",
  "Due Diligence": "due_diligence",
};
const RWIAllFilesTree = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let { dealId } = useParams();
  const docsList = useSelector(dealDocsListSelector)?.directories;
  const docSuccess = useSelector(dealDocsSuccessSelector);
  const uploadError = useSelector(dealDocsErrorSelector);
  const isLoading = useSelector(dealDocsLoadingSelector);

  const [fileData, setFileData] = useState(null);
  const uploadSuccessMsg = t("documents.upload_success");
  const deleteSuccessMsg = t("documents.delete_success");

  useEffect(() => {
    getDealDocuments(dispatch, dealId);
  }, [dispatch, dealId]);

  const handleFileUpload = (files, id, folderName) => {
    if (Array.isArray(files)) {
      files.forEach((file) => {
        uploadDealDocument(
          dispatch,
          dealId,
          apiUrlDictionary[folderName],
          file,
          id,
          uploadSuccessMsg,
          true
        ).catch((err) => {
          dispatch(setIsLoadingDealDocuments(false));
          dispatch(
            setDealDocumentsError({ message: t("errors.server_general") })
          );
        });
      });
    } else {
      uploadDealDocument(
        dispatch,
        dealId,
        apiUrlDictionary[folderName],
        files,
        id,
        uploadSuccessMsg,
        true
      ).catch((err) => {
        dispatch(setIsLoadingDealDocuments(false));
        dispatch(
          setDealDocumentsError({ message: t("errors.server_general") })
        );
      });
    }
  };
  const handleFileDelete = (documentId) => {
    deleteDealDocument(
      dispatch,
      dealId,
      documentId,
      undefined,
      deleteSuccessMsg
    ).catch((err) => {
      dispatch(setIsLoadingDealDocuments(false));
      dispatch(setDealDocumentsError({ message: t("errors.server_general") }));
    });
  };

  const handleViewFile = (documentId) => {
    getDealDocumentPdf(dispatch, dealId, documentId, setFileData).catch(
      (err) => {
        dispatch(setIsLoadingDealDocuments(false));
        dispatch(
          setDealDocumentsError({ message: t("errors.server_general") })
        );
      }
    );
  };

  const handleFileClose = () => {
    window.URL.revokeObjectURL(fileData.blob);
    setFileData(null);
  };

  return (
    <section>
      {docsList &&
        docsList.map((folder) => (
          <QMTreeItem
            key={folder.id}
            {...folder}
            handleFileUpload={(files, id) =>
              handleFileUpload(files, id, folder.name)
            }
            handleFileDelete={handleFileDelete}
            handleViewFile={handleViewFile}
          />
        ))}
      {docSuccess && (
        <QMToastMessage
          text={docSuccess.message}
          iconClassName="icon-check-circle"
          selfCloseDelay={TOAST_CLOSE_DELAY}
          onClose={() => dispatch(setDealDocumentsSuccess(null))}
        />
      )}
      {uploadError && (
        <QMToastMessage
          viewType="error"
          iconClassName="icon-attention"
          text={uploadError.message}
          selfCloseDelay={TOAST_CLOSE_DELAY}
          onClose={() => dispatch(setDealDocumentsError(null))}
        />
      )}
      {isLoading && <QMGlobalLoader />}
      {fileData?.url && (
        <QMPdfViewer file={fileData.url} onClose={handleFileClose} />
      )}
    </section>
  );
};

export default RWIAllFilesTree;
