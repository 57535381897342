import React from 'react';

import './styles.scss';

const NumbersSection = () => {
  return (
    <section id="landing__Numbers" className="custom__commonSection">
      <div className="custom__commonWidth">
        <h3>The rising number of litigation costs related to M&A transactions</h3>
        <ul>
          <li>
            <h3 className="icon-gavel">36%</h3>
            <p>Suffered <strong>Regulatory disputes</strong> losses (e.g., antitrust, insider trading, etc)</p>
          </li>
          <li>
            <h3 className="icon-calculator">32%</h3>
            <p>Suffered <strong>post-closing disputes</strong> losses (e.g. completion accounts, calculation of adjustments, etc)</p>
          </li>
          <li>
            <h3 className="icon-file-text">36%</h3>
            <p>Suffered losses related to the breaches of <strong>Representations & warranties</strong></p>
          </li>
          <li>
            <h3 className="icon-trending-down">32%</h3>
            <p>Suffered <strong>tax disputes</strong> losses</p>
          </li>
        </ul>
      </div>
    </section>
  );
}

export default NumbersSection;