import React from "react";
import { useSelector } from "react-redux";

import DocsTile from "./DocsTile";
import MainTile from "./MainTile";

import { dealForbiddenSelector } from "../../../../redux/selectors/deal-selectors";

import "./styles.scss";
import QMForbiddenCover from "../../common/QMForbiddenCover/QMForbiddenCover";

const RWIApplication = () => {
  const isForbidden = useSelector(dealForbiddenSelector);
  return (
    <section className="QM-rwiApplication_container">
      <MainTile />
      <DocsTile />
      {isForbidden && <QMForbiddenCover />}
    </section>
  );
};

export default RWIApplication;
