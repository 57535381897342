import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import QMButton from "../../common/QMButton";
import RiskProfileRedFlag from "./RiskProfileRedFlag";

import "./styles.scss";

const RiskProfileLitigationsItem = ({
  name,
  description,
  buyer,
  target,
  setDealIdForLitigationsDocs,
}) => {
  const { t } = useTranslation();
  const { dealId } = useParams();

  return (
    <li className="risk-profile__litigations-list-item">
      <div className="risk-profile__litigations-list-item__container">
        <div className="risk-profile__litigations-list-item__header">
          <span className="risk-profile__litigations-list-item__header-text">
            {name}
          </span>
          <QMButton
            viewType="transparent"
            text={t("risk_profile.open_files")}
            iconClassName={`icon-folder-open`}
            customClassName={`QM-btn-files-text`}
            iconReverse
            onClick={() => setDealIdForLitigationsDocs(dealId)}
          />
        </div>
        <p className="risk-profile__litigations-list-item__description">
          {description}
        </p>
      </div>
      <span className="risk-profile__litigations-list-item__red-flag">
        {buyer && (
          <RiskProfileRedFlag mainColor="#E05A5C" width="22" height="22" />
        )}
      </span>
      <span className="risk-profile__litigations-list-item__red-flag">
        {target && (
          <RiskProfileRedFlag mainColor="#E05A5C" width="22" height="22" />
        )}
      </span>
    </li>
  );
};
export default RiskProfileLitigationsItem;
