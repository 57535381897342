import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import AuthInput from "../common/AuthInput/AuthInput";
import AuthPageWrapper from "../common/AuthPageWrapper";
import { ERROR_EMAIL_FORMAT, ERROR_REQUIRED } from "./validationConsts";
import { signIn } from "../../../api/authApi";
import { getRedirectPath, saveTokenData } from "../../../helpers/auth-helpers";

const validationSchema = yup
  .object({
    email: yup.string().required(ERROR_REQUIRED).email(ERROR_EMAIL_FORMAT),
    password: yup.string().required(ERROR_REQUIRED).min(8),
  })
  .required();

const LogIn = () => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = (data) => {
    signIn(data)
      .then((res) => {
        if (res.status === 200) {
          saveTokenData(JSON.stringify({ ...res.data }));

          navigate(`${getRedirectPath(res.data)}${location?.search}`);
        } else {
          setError("email", { message: res.data.errors[0].message });
          setError("password", { message: res.data.errors[0].message });
        }
      })
      .catch((err) => console.error(err));
  };

  return (
    <AuthPageWrapper>
      <form onSubmit={handleSubmit(onSubmit)} className="user-auth__form">
        <h1>Sign In</h1>
        <ul>
          <AuthInput
            register={register}
            name="email"
            type="email"
            title="Email"
            errors={errors.email}
            autoFocus
          />
          <AuthInput
            register={register}
            name="password"
            type={isPasswordVisible ? "text" : "password"}
            withIconBtn
            iconBtnHandler={() => {
              setIsPasswordVisible(!isPasswordVisible);
            }}
            iconClassName={isPasswordVisible ? "icon-eye-off" : "icon-eye"}
            iconBtnTitle="Show password"
            title="Password"
            errors={errors.password}
          />
          <li>
            <p className="r">
              <NavLink to="/password-recovery">Forgot password?</NavLink>
            </p>
          </li>
        </ul>
        <button type="submit" className="user-auth__btn">
          Sign In
        </button>
      </form>
    </AuthPageWrapper>
  );
};

export default LogIn;
