import React from 'react';
import { useTranslation } from 'react-i18next';

import './styles.scss';

const EstimatorHeader = () => {
  const { t } = useTranslation();

  return (
    <section className='QM_E-header'>
      <h1>{t('estimator.title1')}</h1>
      <p className='QM_E-subtext'>{t('estimator.title2')}</p>
    </section>
  )
};

export default EstimatorHeader;