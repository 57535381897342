import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import uniqid from "uniqid";
import {
  QMDialogWrapper,
  QMDialogList,
  QMDialogListAction,
} from "../../common/QMActionDialog";

const BrokerActionMenu = ({ onClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const actionsConfig = [
    {
      title: t("general.portfolio"),
      items: [
        {
          text: t("broker_portal.action_menu.add_deal"),
          key: uniqid("action-"),
          onClick: () => {
            navigate("new-deal");
            onClose();
          },
        },
        {
          text: t("broker_portal.action_menu.add_company"),
          key: uniqid("action-"),
          onClick: () => {},
        },
      ],
    },
    {
      title: t("general.markets"),
      items: [
        {
          text: t("broker_portal.action_menu.add_carrier"),
          key: uniqid("action-"),
          onClick: () => {},
        },
      ],
    },
  ];
  return (
    <QMDialogWrapper onClose={onClose}>
      {actionsConfig.map((list, i) => (
        <QMDialogList title={list.title} key={i}>
          {list.items.map((action) => (
            <QMDialogListAction {...action} />
          ))}
        </QMDialogList>
      ))}
    </QMDialogWrapper>
  );
};

export default BrokerActionMenu;
