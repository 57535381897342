import React from 'react';
import { useSelector } from 'react-redux';

import QMEBundleCard, {QMEBundleCardWrapper} from '../common/QMEBundleCard';

import './styles.scss';

const EstimatorBundleCarousel = () => {
  const bundles = useSelector(state => state.estimator.estimation.bundles);
  const activeBundle = useSelector(state => state.estimator.estimation.activeBundle);
  const firstBundle = useSelector(state => state.estimator.estimation.firstBundle);
  const lastBundle = useSelector(state => state.estimator.estimation.lastBundle);

  return (
    <section className='QM_E-bundleCarousel'>
      {
        bundles?.length
        ? bundles.map((bundle, index) => (
          <QMEBundleCard
            key={index}
            index={index}
            bundle={bundle}
            isActive={activeBundle === index}
            isFirst={firstBundle === index}
            isLast={lastBundle === index}
          />
        ))
        : (
          <QMEBundleCardWrapper>
            <h3 className='QM_E-chart_text QM_E-chart_text__bold-18'>
              {'Please fill out the questions to estimate insurance for your M&A needs'}</h3>
          </QMEBundleCardWrapper>
        )
      }
    </section>
  );
}

export default EstimatorBundleCarousel;