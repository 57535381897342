import { extractCurrency, formatDate, NEW_DEAL_DATE_REQUEST_FORMAT } from ".";

const filterOutEmptyFields = (obj) => {
  if (
    Object.keys(obj).includes("is_applicable") &&
    obj.is_applicable === false
  ) {
    return { is_applicable: obj.is_applicable };
  }
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([key, val]) => val !== undefined && val !== "" && val !== null
    )
  );
};

const filterOutEmptyObjFields = (obj) =>
  Object.fromEntries(
    Object.entries(filterOutEmptyFields(obj)).filter(
      ([key, val]) =>
        typeof val !== "object" ||
        (typeof val === "object" && Object.keys(val).length)
    )
  );

const reformatContacts = (contacts) => {
  const {
    primary,
    legal,
    p_c_broker,
    section,
    due_diligence_providers_count,
    due_diligence_providers,
  } = contacts;

  const dueDiligenceArr = due_diligence_providers
    .map((dueDilligence) => filterOutEmptyFields(dueDilligence))
    .filter((item) => Object.keys(item).length);
  return filterOutEmptyObjFields({
    primary: filterOutEmptyFields(primary),
    legal: filterOutEmptyFields(legal),
    p_c_broker: filterOutEmptyFields(p_c_broker),
    section,
    due_diligence_providers_count:
      due_diligence_providers_count && parseInt(due_diligence_providers_count),
    due_diligence_providers: dueDiligenceArr,
  });
};

const reformatPastActivitiesResponse = (pastActivities) => {
  return Object.fromEntries(
    Object.entries(pastActivities).map(([name, val]) => {
      return [
        name,
        {
          buyer: Boolean(val.buyer),
          explanation: val.explanation || "",
          explanation_attached: Boolean(val.explanation_attached), // need to decide what to do with it
          not_applicable: Boolean(val.not_applicable),
          seller: Boolean(val.seller),
          target: Boolean(val.target),
        },
      ];
    })
  );
};

const reformatDealInfoTab = (data) => {
  data.sale_agreement_date = data.sale_agreement_date
    ? formatDate(data.sale_agreement_date, NEW_DEAL_DATE_REQUEST_FORMAT)
    : null;
  data.retention = data.retention ? extractCurrency(data.retention) : null;
  data.deal_size = data.deal_size ? extractCurrency(data.deal_size) : null;
  data.integration_lead_email = data.integration_lead_email
    ? data.integration_lead_email
    : null;

  return data;
};

const reformatDealStatusLabel = (data) => {
  switch (data?.status) {
    case "in_progress":
    case "active":
    case "Active":
      return "Active";
    case "inactive":
      return "Inactive";
    default:
      return "Active";
  }
};

const reformatDealStatusValue = (data) => {
  switch (data?.status) {
    case "in_progress":
    case "Active":
      return "active";
    case "Inactive":
    case "inactive":
      return "inactive";
    default:
      return "active";
  }
};

const findRelatedObject = (id, array) => {
  const result = array?.find((item) => item.order_number === id);
  return result || null;
};

const createRWInsuranceObjectToSend = (
  seller_indemnity,
  seller_indemnity_percentage,
  no_seller_indemnity,
  no_seller_indemnity_percentage,
  order_number
) => {
  const objectToSend = {
    seller_indemnity: seller_indemnity,
    seller_indemnity_percentage: seller_indemnity_percentage
      ? seller_indemnity_percentage
      : null,
    no_seller_indemnity: no_seller_indemnity,
    no_seller_indemnity_percentage: no_seller_indemnity_percentage
      ? no_seller_indemnity_percentage
      : null,
  };

  if (Object.values(objectToSend).every((item) => item === null)) {
    objectToSend.order_number = null;
  } else {
    objectToSend.order_number = order_number;
  }

  Object.keys(objectToSend).forEach((key) => {
    if (
      objectToSend[key] === null ||
      objectToSend[key] === undefined ||
      objectToSend[key] === ""
    ) {
      delete objectToSend[key];
    }
  });

  return objectToSend;
};

const reformatRWRetentionArray = (data) => {
  const {
    seller_indemnity_1,
    seller_indemnity_2,
    seller_indemnity_3,
    seller_indemnity_4,
    no_seller_indemnity_1,
    no_seller_indemnity_2,
    no_seller_indemnity_3,
    no_seller_indemnity_4,
    seller_indemnity_percentage_1,
    seller_indemnity_percentage_2,
    seller_indemnity_percentage_3,
    seller_indemnity_percentage_4,
    no_seller_indemnity_percentage_1,
    no_seller_indemnity_percentage_2,
    no_seller_indemnity_percentage_3,
    no_seller_indemnity_percentage_4,
  } = data;
  const retention_options = [];

  const retention_option_one = createRWInsuranceObjectToSend(
    seller_indemnity_1,
    seller_indemnity_percentage_1,
    no_seller_indemnity_1,
    no_seller_indemnity_percentage_1,
    1
  );
  retention_options.push(retention_option_one);

  const retention_option_two = createRWInsuranceObjectToSend(
    seller_indemnity_2,
    seller_indemnity_percentage_2,
    no_seller_indemnity_2,
    no_seller_indemnity_percentage_2,
    2
  );
  retention_options.push(retention_option_two);

  const retention_option_three = createRWInsuranceObjectToSend(
    seller_indemnity_3,
    seller_indemnity_percentage_3,
    no_seller_indemnity_3,
    no_seller_indemnity_percentage_3,
    3
  );
  retention_options.push(retention_option_three);

  const retention_option_four = createRWInsuranceObjectToSend(
    seller_indemnity_4,
    seller_indemnity_percentage_4,
    no_seller_indemnity_4,
    no_seller_indemnity_percentage_4,
    4
  );
  retention_options.push(retention_option_four);

  const filteredRetentionArray = retention_options.filter(
    (value) => Object.keys(value).length !== 0
  );

  return filteredRetentionArray;
};

const reformatInsuranceCoverageDuration = (data) => {
  const {
    general_representations,
    tax_representations,
    insurance_policy,
    fundamental_representations,
  } = data;

  const coverageData = {
    general_representations: general_representations
      ? general_representations
      : null,
    fundamental_representations: fundamental_representations
      ? fundamental_representations
      : null,
    tax_representations: tax_representations ? tax_representations : null,
    insurance_policy: insurance_policy ? insurance_policy : null,
  };

  const filteredData = filterOutEmptyFields(coverageData);

  return filteredData;
};

const reformatRWInsurance = (data) => {
  const dataToSend = {
    retention_options: reformatRWRetentionArray(data),
    coverage_duration: reformatInsuranceCoverageDuration(
      data.coverage_duration
    ),
    policy_enhancements: data.policy_enhancements
      ? data.policy_enhancements
      : null,
  };

  const filteredData = filterOutEmptyFields(dataToSend);

  return filteredData;
};

const reformatInsuranceCompliance = (data) => {
  const data_list = Object.values(data);

  Object.values(data_list).forEach((item) => {
    if (item.aggregate === null || item.aggregate === undefined) {
      delete item.aggregate;
    }
    if (item.limit_per_claim === null || item.limit_per_claim === undefined) {
      delete item.limit_per_claim;
    }
    if (item.aggregate !== null && item.aggregate !== undefined) {
      item.aggregate = extractCurrency(item.aggregate);
    }
    if (item.limit_per_claim !== null && item.limit_per_claim !== undefined) {
      item.limit_per_claim = extractCurrency(item.limit_per_claim);
    }
    if (
      item.buyer_requirement === null ||
      item.buyer_requirement === undefined
    ) {
      delete item.buyer_requirement;
    }
  });

  const reformatDataList = data_list.filter((item) => item.code !== null);

  const dataToSend = {
    compliance_list: reformatDataList,
  };

  return dataToSend;
};

const reformatUnderwriting = (data) => {
  const underwriter_ids = [];

  const dataToSend = {
    underwriter_ids: underwriter_ids,
  };

  if (Array.isArray(data)) {
    dataToSend.underwriter_ids = data;
  } else {
    underwriter_ids.push(data);
    dataToSend.underwriter_ids = underwriter_ids;
  }

  return dataToSend;
};

const sortUnderwritingList = (data) => {
  const unsubmitedUnderwiters = [];
  const submitedUnderwriters = [];

  data.forEach((item) => {
    if (item.underwriters && item.underwriters.length) {
      if (item.underwriters[0].status === null) {
        unsubmitedUnderwiters.push(item);
      } else {
        submitedUnderwriters.push(item);
      }
    }
  });

  const underwritersData = unsubmitedUnderwiters.concat(submitedUnderwriters);

  return underwritersData;
};

const reformatTimingInfoTab = (data) => {
  data.signing_date =
    data.signing_date !== null
      ? formatDate(data.signing_date, NEW_DEAL_DATE_REQUEST_FORMAT)
      : null;
  data.closing_date =
    data.closing_date !== null
      ? formatDate(data.closing_date, NEW_DEAL_DATE_REQUEST_FORMAT)
      : null;
  data.policy_insurance_date =
    data.policy_insurance_date !== null
      ? formatDate(data.policy_insurance_date, NEW_DEAL_DATE_REQUEST_FORMAT)
      : null;

  return data;
};

export {
  reformatContacts,
  reformatDealInfoTab,
  reformatDealStatusLabel,
  reformatPastActivitiesResponse,
  reformatDealStatusValue,
  findRelatedObject,
  reformatRWInsurance,
  reformatInsuranceCompliance,
  reformatUnderwriting,
  sortUnderwritingList,
  reformatTimingInfoTab,
};
