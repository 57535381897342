import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  insuranceLoadingSelector,
  insuranceTabDurationSelector,
  insuranceTabInfoSelector,
  insuranceTabRetentionSelector,
} from "../../../../../redux/selectors/insurance-tab-selector";
import InsuranceRWItem from "./InsuranceRWItem";
import { findRelatedObject } from "../../../../../helpers/deal-helpers";
import InsuranceRWDuration from "./InsuranceRWDuration";
import { QMTextarea } from "../../../common/QMInputs";
import QMSaveLayout from "../../../common/QMSaveLayout";
import QMGlobalLoader from "../../../../common/QMLoader";
import { putInsuranceTabInfo } from "../../../../../api/insuranceTabApi";
import { insuranceValidationSchema } from "./insuranceValidationSchema";
import useLeavePageBlocker from "../../../../../helpers/useLeavePageBlocker";

import "./styles.scss";

//REFACTORING NEEDED

const InsuranceRWContainer = ({ setIsDirty }) => {
  const dispatch = useDispatch();
  const insuranceRetentionInfo = useSelector(insuranceTabRetentionSelector);
  const insuranceInfo = useSelector(insuranceTabInfoSelector);
  const insuranceCoverageDuration = useSelector(insuranceTabDurationSelector);
  const isLoading = useSelector(insuranceLoadingSelector);

  const { dealId } = useParams();

  const RETENTION_OPTION_NUMBER_ONE = 1;
  const RETENTION_OPTION_NUMBER_TWO = 2;
  const RETENTION_OPTION_NUMBER_THREE = 3;
  const RETENTION_OPTION_NUMBER_FOUR = 4;

  const { t } = useTranslation();

  const retentionOptionOne = findRelatedObject(
    RETENTION_OPTION_NUMBER_ONE,
    insuranceRetentionInfo
  );
  const retentionOptionTwo = findRelatedObject(
    RETENTION_OPTION_NUMBER_TWO,
    insuranceRetentionInfo
  );
  const retentionOptionThree = findRelatedObject(
    RETENTION_OPTION_NUMBER_THREE,
    insuranceRetentionInfo
  );
  const retentionOptionFour = findRelatedObject(
    RETENTION_OPTION_NUMBER_FOUR,
    insuranceRetentionInfo
  );

  const {
    handleSubmit,
    control,
    register,
    watch,
    setValue,
    formState: { errors, touchedFields, isDirty },
  } = useForm({
    //REFACTOR default values to setValue
    defaultValues: {
      seller_indemnity_1: retentionOptionOne
        ? retentionOptionOne.seller_indemnity
        : null,
      seller_indemnity_percentage_1: retentionOptionOne
        ? retentionOptionOne.seller_indemnity_percentage
        : null,
      no_seller_indemnity_1: retentionOptionOne
        ? retentionOptionOne.no_seller_indemnity
        : null,
      no_seller_indemnity_percentage_1: retentionOptionOne
        ? retentionOptionOne.no_seller_indemnity_percentage
        : null,

      seller_indemnity_2: retentionOptionTwo
        ? retentionOptionTwo.seller_indemnity
        : null,
      seller_indemnity_percentage_2: retentionOptionTwo
        ? retentionOptionTwo.seller_indemnity_percentage
        : null,
      no_seller_indemnity_2: retentionOptionTwo
        ? retentionOptionTwo.no_seller_indemnity
        : null,
      no_seller_indemnity_percentage_2: retentionOptionTwo
        ? retentionOptionTwo.no_seller_indemnity_percentage
        : null,

      seller_indemnity_3: retentionOptionThree
        ? retentionOptionThree.seller_indemnity
        : null,
      seller_indemnity_percentage_3: retentionOptionThree
        ? retentionOptionThree.seller_indemnity_percentage
        : null,
      no_seller_indemnity_3: retentionOptionThree
        ? retentionOptionThree.no_seller_indemnity
        : null,
      no_seller_indemnity_percentage_3: retentionOptionThree
        ? retentionOptionThree.no_seller_indemnity_percentage
        : null,

      seller_indemnity_4: retentionOptionFour
        ? retentionOptionFour.seller_indemnity
        : null,
      seller_indemnity_percentage_4: retentionOptionFour
        ? retentionOptionFour.seller_indemnity_percentage
        : null,
      no_seller_indemnity_4: retentionOptionFour
        ? retentionOptionFour.no_seller_indemnity
        : null,
      no_seller_indemnity_percentage_4: retentionOptionFour
        ? retentionOptionFour.no_seller_indemnity_percentage
        : null,

      coverage_duration: {
        general_representations:
          insuranceCoverageDuration?.general_representations
            ? insuranceCoverageDuration?.general_representations
            : null,
        fundamental_representations:
          insuranceCoverageDuration?.fundamental_representations
            ? insuranceCoverageDuration?.fundamental_representations
            : null,
        insurance_policy: insuranceCoverageDuration?.insurance_policy
          ? insuranceCoverageDuration?.insurance_policy
          : null,
        tax_representations: insuranceCoverageDuration?.tax_representations
          ? insuranceCoverageDuration?.tax_representations
          : null,
      },
      policy_enhancements: insuranceInfo?.policy_enhancements
        ? insuranceInfo?.policy_enhancements
        : "",
    },
    resolver: yupResolver(insuranceValidationSchema),
    mode: "onChange",
  });

  const seller_indemnity_1 = watch("seller_indemnity_1");
  const seller_indemnity_2 = watch("seller_indemnity_2");
  const seller_indemnity_3 = watch("seller_indemnity_3");
  const seller_indemnity_4 = watch("seller_indemnity_4");

  const no_seller_indemnity_1 = watch("no_seller_indemnity_1");
  const no_seller_indemnity_2 = watch("no_seller_indemnity_2");
  const no_seller_indemnity_3 = watch("no_seller_indemnity_3");
  const no_seller_indemnity_4 = watch("no_seller_indemnity_4");

  const onSubmit = (data) => {
    putInsuranceTabInfo(dispatch, dealId, data);
  };

  const getFieldErrors = (fieldName) => {
    return errors[fieldName] && touchedFields[fieldName]
      ? errors[fieldName]
      : undefined;
  };

  useEffect(() => setIsDirty(isDirty), [isDirty, setIsDirty]);

  useLeavePageBlocker(isDirty);

  const getContent = () => {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <section className="rw-insurance">
          <section className="rw-insurance__form">
            <InsuranceRWItem
              control={control}
              register={register}
              getFieldErrors={getFieldErrors}
              disableSellerIndemnityPercentage={seller_indemnity_1}
              disableNoSellerIndemnityPercentage={no_seller_indemnity_1}
              retention_number={RETENTION_OPTION_NUMBER_ONE}
              seller_indemnity="seller_indemnity_1"
              seller_indemnity_percentage="seller_indemnity_percentage_1"
              no_seller_indemnity="no_seller_indemnity_1"
              no_seller_indemnity_percentage="no_seller_indemnity_percentage_1"
              setValue={setValue}
            />
          </section>
          <section className="rw-insurance__form">
            <InsuranceRWItem
              control={control}
              register={register}
              getFieldErrors={getFieldErrors}
              disableSellerIndemnityPercentage={seller_indemnity_2}
              disableNoSellerIndemnityPercentage={no_seller_indemnity_2}
              retention_number={RETENTION_OPTION_NUMBER_TWO}
              seller_indemnity="seller_indemnity_2"
              seller_indemnity_percentage="seller_indemnity_percentage_2"
              no_seller_indemnity="no_seller_indemnity_2"
              no_seller_indemnity_percentage="no_seller_indemnity_percentage_2"
              setValue={setValue}
            />
          </section>
          <section className="rw-insurance__form">
            <InsuranceRWItem
              control={control}
              register={register}
              getFieldErrors={getFieldErrors}
              watch={watch}
              disableSellerIndemnityPercentage={seller_indemnity_3}
              disableNoSellerIndemnityPercentage={no_seller_indemnity_3}
              retention_number={RETENTION_OPTION_NUMBER_THREE}
              seller_indemnity="seller_indemnity_3"
              seller_indemnity_percentage="seller_indemnity_percentage_3"
              no_seller_indemnity="no_seller_indemnity_3"
              no_seller_indemnity_percentage="no_seller_indemnity_percentage_3"
              setValue={setValue}
            />
          </section>
          <section className="rw-insurance__form">
            <InsuranceRWItem
              control={control}
              className="rw-insurance__form"
              register={register}
              getFieldErrors={getFieldErrors}
              disableSellerIndemnityPercentage={seller_indemnity_4}
              disableNoSellerIndemnityPercentage={no_seller_indemnity_4}
              retention_number={RETENTION_OPTION_NUMBER_FOUR}
              seller_indemnity="seller_indemnity_4"
              seller_indemnity_percentage="seller_indemnity_percentage_4"
              no_seller_indemnity="no_seller_indemnity_4"
              no_seller_indemnity_percentage="no_seller_indemnity_percentage_4"
              setValue={setValue}
            />
          </section>
          <section className="rw-insurance__form">
            <InsuranceRWDuration
              register={register}
              getFieldErrors={getFieldErrors}
              insurance_policy="insurance_policy"
              general_representations="general_representations"
              fundamental_representations="fundamental_representations"
              tax_representations="tax_representations"
              errors={errors}
            />
          </section>
          <section className="rw-insurance__form">
            <h2 className="rw-insurance__exclusions-title">
              {t("insurance.standard_exclusions")}
            </h2>
            <p className="rw-insurance__exclusions-text">
              {t("insurance.standard_exclusions_desc")}
            </p>
          </section>
          <section className="rw-insurance__textarea">
            <Controller
              render={({ field }) => (
                <QMTextarea
                  {...field}
                  label={t("insurance.required_policy_enhancements")}
                  placeholder={t(
                    "insurance.required_policy_enhancements_placeholder"
                  )}
                  errors={getFieldErrors("policy_enhancements")}
                  withCount
                  maxLength={300}
                />
              )}
              control={control}
              name="policy_enhancements"
            />
          </section>
        </section>
        {isDirty && <QMSaveLayout />}

        {isLoading && <QMGlobalLoader />}
      </form>
    );
  };
  return <>{getContent()}</>;
};

export default InsuranceRWContainer;
