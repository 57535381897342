import React from 'react';
import PropTypes from 'prop-types';

const QMLoader = ({size, stroke, color}) => {
  return (
    <svg className="QM-loader" viewBox="0 0 36 36" width={size} height={size} xmlns="http://www.w3.org/2000/svg">
      <path className="QM-loader-bg" stroke="#eeeeee" strokeWidth={stroke} fill="none" d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"/>
      <path className="QM-loader-color" stroke={color} strokeWidth={stroke} strokeDasharray="30, 100" strokeLinecap="round" fill="none"
          d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831" />
    </svg>
  );
}
QMLoader.propTypes = {
  size: PropTypes.string,
  stroke: PropTypes.string,
  color: PropTypes.string,
}

QMLoader.defaultProps = {
  size: "100",
  stroke: "3",
  color: "#3d77aa",
}
export default QMLoader;
