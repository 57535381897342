import {
  setDealsError,
  setIsLoadingDeals,
} from "../redux/reducers/deals-reducer";
import { authFetch } from "./fetchConfig";

const manageInvitation = async (
  dispatch,
  dealId,
  acceptanceStatus,
  handleSuccess
) => {
  dispatch(setIsLoadingDeals(true));
  const response = await authFetch(
    `${process.env.REACT_APP_BASE_API_URL}/deals/${dealId}/acceptance`,
    {
      method: "PATCH",
      body: JSON.stringify({ status: acceptanceStatus }),
      headers: { "Content-type": "application/json" },
    }
  );
  if (response.status === 204) {
    handleSuccess();
  } else {
    const err = await response.json();
    dispatch(setDealsError(err?.error[0]));
  }
  dispatch(setIsLoadingDeals(false));
};

export { manageInvitation };
