import React, { forwardRef } from "react";
import classNames from "classnames";
import PatternFormat from "react-number-format";

import QMInputWrapper from "./QMInputWrapper";

const QMPhoneNumberInput = forwardRef(
  (
    { label, isRequired, errors, iconClassName, placeholder, ...props },
    ref
  ) => {
    return (
      <QMInputWrapper
        label={label}
        isRequired={isRequired}
        errors={errors}
        iconClassName={iconClassName}
      >
        <PatternFormat
          ref={ref}
          className={classNames("QM-input", {
            "QM-input__error": !!errors,
          })}
          placeholder={placeholder}
          allowNegative={false}
          format="+1 (###) ###-####"
          {...props}
        />
      </QMInputWrapper>
    );
  }
);

export default QMPhoneNumberInput;
