import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import QMModal from "../../../../common/QMModal";
import QMButton from "../../../../common/QMButton";

import { manageInvitation } from "../../../../../../api/partnerInvitationApi";
import { getDeals } from "../../../../../../api/dealsApi";

import "./styles.scss";

const DeclineConfirmationModal = ({ dealId, dealName, setModalOpen }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClose = (e) => {
    e.preventDefault();
    setModalOpen(false);
  };

  const handleDeclineSuccess = async () => {
    await getDeals(dispatch);
    setModalOpen(false);
  };
  const handleSubmit = () => {
    manageInvitation(dispatch, dealId, "declined", handleDeclineSuccess);
  };
  return (
    <QMModal customClassName="QM-manageInvitationModal QM-declineDealModal">
      <header>
        <h2>{`${t("partner.decline_deal")} ${dealName}`}</h2>
        <QMButton
          viewType="transparent"
          iconClassName="icon-x"
          onClick={handleClose}
        />
      </header>
      <section className="QM-manageInvitationModal_body">
        <p className="QM-manageInvitationModal_body_text">
          {`${t("partner.decline_confirm_text_1")} ${dealName}. ${t(
            "partner.decline_confirm_text_2"
          )}`}
        </p>
      </section>
      <footer>
        <QMButton
          viewType="secondary"
          onClick={handleClose}
          type="button"
          text={t("general.cancel")}
        />
        <QMButton
          customClassName="QM-btn__decline"
          onClick={handleSubmit}
          text={t("general.decline")}
        />
      </footer>
    </QMModal>
  );
};

export default DeclineConfirmationModal;
