import classNames from 'classnames';
import React from 'react';


import './styles.scss';

const QMTile = ({ children, className }) => {
  return (
    <section className={classNames('QM-tile', className)}>
      {children}
    </section>
  );
}

export default QMTile;