import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import QMButton from "../../../../common/QMButton";

import "./styles.scss";
import AcceptConfirmationModal from "./AcceptConfirmationModal";
import DeclineConfirmationModal from "./DeclineConfirmationModal";

const AcceptDeclineBlock = ({ deal }) => {
  const { t } = useTranslation();
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [isDeclineModalOpen, setIsDeclineModalOpen] = useState(false);
  return (
    <section className="QM-acceptDeclineBlock">
      <QMButton
        text={t("general.accept")}
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          setIsAcceptModalOpen(true);
        }}
      />
      <QMButton
        text={t("general.decline")}
        type="button"
        customClassName="QM-btn__decline"
        onClick={(e) => {
          e.stopPropagation();
          setIsDeclineModalOpen(true);
        }}
      />
      {isAcceptModalOpen && (
        <AcceptConfirmationModal
          dealId={deal.id}
          dealName={deal.name}
          setModalOpen={setIsAcceptModalOpen}
        />
      )}
      {isDeclineModalOpen && (
        <DeclineConfirmationModal
          dealId={deal.id}
          dealName={deal.name}
          setModalOpen={setIsDeclineModalOpen}
        />
      )}
    </section>
  );
};

export default AcceptDeclineBlock;
