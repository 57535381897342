import React from "react";

import { useTranslation } from "react-i18next";

const DealAdminEmptyState = () => {
  const { t } = useTranslation();
  return (
    <section className="QM-deal-admin_sidebar_emptyState">
      <h2 className="QM-deal-admin_sidebar_emptyState_text">
        {t("documents.deal_admin_sidebar_empty")}
      </h2>
    </section>
  );
};

export default DealAdminEmptyState;
