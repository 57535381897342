import { setIsLoadingDeal } from "../redux/reducers/dealPage-reducer";
import {
  setAssignUserErr,
  setAssignUserSuccess,
  setLawyersListData,
} from "../redux/reducers/assignLawyer-reducer";
import { authFetch } from "./fetchConfig";
import {
  dataToSend,
  groupAssignLawyerProps,
  reformatAssignLawyerData,
} from "../helpers/assignLawyer-helpers";

const getAssignLawyerList = async (dispatch, dealId) => {
  dispatch(setIsLoadingDeal(true));
  const response = await authFetch(
    `${process.env.REACT_APP_BASE_API_URL}/deals/${dealId}/assigned_attorneys`
  );
  const responseData = await response.json();
  if (response.status === 200) {
    dispatch(setLawyersListData(responseData));
  } else {
    dispatch(setAssignUserErr(responseData?.errors[0]?.message));
  }
  dispatch(setIsLoadingDeal(false));
};

const postLawyer = async (dispatch, data, lawyerInfo, dealId) => {
  const getGroupOfProps = groupAssignLawyerProps(data);
  const formattAssignLawyerObject = reformatAssignLawyerData(getGroupOfProps);
  const formattedData = dataToSend(formattAssignLawyerObject, lawyerInfo);

  dispatch(setIsLoadingDeal(true));
  const response = await authFetch(
    `${process.env.REACT_APP_BASE_API_URL}/deals/${dealId}/assigned_attorneys`,
    {
      method: "POST",
      body: JSON.stringify(formattedData),
      headers: { "Content-type": "application/json" },
    }
  );
  if (!response.ok) {
    const err = await response.json();
    dispatch(setAssignUserErr(err?.errors[0]?.message));
  } else {
    getAssignLawyerList(dispatch, dealId);
    dispatch(setAssignUserSuccess("Invitation was successfully sent"));
  }
  dispatch(setIsLoadingDeal(false));
};

const getUserPolicies = async (dispatch, policyLink, setDataToForm) => {
  dispatch(setIsLoadingDeal(true));
  const linkPart = policyLink.slice(7);
  const response = await authFetch(
    `${process.env.REACT_APP_BASE_API_URL}${linkPart}`
  );

  const responseData = await response.json();
  if (!response.ok) {
    dispatch(setAssignUserErr(responseData?.errors[0]?.message));
  } else {
    setDataToForm(responseData?.policies);
  }
  dispatch(setIsLoadingDeal(false));
};

const updateUserPolicies = async (dispatch, data, policyLink) => {
  dispatch(setIsLoadingDeal(true));
  const linkPart = policyLink.slice(7);

  const getGroupOfProps = groupAssignLawyerProps(data);
  const formattedAssignLawyerObject = reformatAssignLawyerData(getGroupOfProps);
  const formattedData = { policies: formattedAssignLawyerObject };
  const response = await authFetch(
    `${process.env.REACT_APP_BASE_API_URL}${linkPart}`,
    {
      method: "PUT",
      body: JSON.stringify(formattedData),
      headers: { "Content-type": "application/json" },
    }
  );

  if (!response.ok) {
    const err = await response.json();
    dispatch(setAssignUserErr(err?.errors[0]?.message));
  } else {
    dispatch(setAssignUserSuccess("Policies were successfuly updated"));
  }
  dispatch(setIsLoadingDeal(false));
};

const removeAssignedUser = async (dispatch, policyLink, dealId) => {
  dispatch(setIsLoadingDeal(true));
  const linkPart = policyLink.slice(7);

  const response = await authFetch(
    `${process.env.REACT_APP_BASE_API_URL}${linkPart}`,
    {
      method: "DELETE",
    }
  );

  if (!response.ok) {
    const err = await response.json();
    console.log(err);
    const message = err?.error || err?.errors[0]?.message;
    dispatch(setAssignUserErr(message));
  } else {
    getAssignLawyerList(dispatch, dealId);
    dispatch(setAssignUserSuccess("Assignee was successfuly removed"));
  }
  dispatch(setIsLoadingDeal(false));
};

const resendInvitation = async (dispatch, policyLink, dealId) => {
  dispatch(setIsLoadingDeal(true));
  const policyLinkArr = policyLink.split("/");
  const userId = policyLinkArr[policyLinkArr.length - 2];

  const response = await authFetch(
    `${process.env.REACT_APP_BASE_API_URL}/deals/${dealId}/attorneys/${userId}/resend_invite`,
    {
      method: "POST",
    }
  );

  if (!response.ok) {
    const err = await response.json();
    console.log(err);
    const message = err?.error || err?.errors[0]?.message;
    dispatch(setAssignUserErr(message));
  } else {
    dispatch(setAssignUserSuccess("Invitation was resent"));
  }
  dispatch(setIsLoadingDeal(false));
};
export {
  getAssignLawyerList,
  postLawyer,
  getUserPolicies,
  updateUserPolicies,
  removeAssignedUser,
  resendInvitation,
};
