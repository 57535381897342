import { useTranslation } from "react-i18next";

import contactIcon from "../../../../assets/imgs/contact_icon.svg";

import "./styles.scss";

// change svg to icon font

const RiskProfileStakeholdersItem = ({ name, type, company, phone, email }) => {
  const { t } = useTranslation();

  const handleNameLength = (value) => {
    if (value?.length > 30) {
      return value.substring(0, 20).concat("...");
    }
    return value;
  };

  return (
    <li className="risk-profile__stakeholders-list-item">
      <p className="risk-profile__stakeholders-list-item__title">
        <img
          src={contactIcon}
          alt="contact-icon"
          className="risk-profile__stakeholders-list-item__img"
        ></img>
        <span>{handleNameLength(name)}</span>
      </p>

      <p className="risk-profile__stakeholders-list-item__text">
        {type ? type : t("general.no_data")}
      </p>
      <p className="risk-profile__stakeholders-list-item__text">
        {company ? handleNameLength(company) : t("general.no_data")}
      </p>
      <p className="risk-profile__stakeholders-list-item__text">
        {email ? handleNameLength(email) : t("general.no_data")}
      </p>
      <p className="risk-profile__stakeholders-list-item__text">
        {phone ? phone : t("general.no_data")}
      </p>
    </li>
  );
};
export default RiskProfileStakeholdersItem;
