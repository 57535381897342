import * as yup from "yup";
const today = new Date()

const URL_REGEXP = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/ // eslint-disable-line
const MONEY_REGEXP = /^\$?([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)(\.[0-9][0-9])?$/;
const LETTERS_NUMBERS_REGEXP = /^[a-zA-Z0-9\s-]*$/;
// const NUMBER_REGEXP = /^(?:0|[1-9]{1}[0-9]{0,2}|n\/a|na)$/i;
const ERROR_MESSAGE_REQUIRED = 'This field is mandatory';

const ERROR_MESSAGE_MAX65 = 'Name is too long';
const ERROR_MESSAGE_MAX35 = 'Please enter valid dollar ammount';
// const ERROR_MESSAGE_MAX10 = 'Please enter valid amount';
// const ERROR_MESSAGE_MAX3 = 'Please enter valid amount';
// const ERROR_MESSAGE_MAX2 = 'Max 2 characters are allowed';

// const ERROR_MESSAGE_NUMBERS = 'Incorrect input. If field doesn\'t apply, please enter N/A or "0"';
const ERROR_MESSAGE_LETTERS_NUMBERS = 'Invalid entry. Only letters & numbers are allowed';
const ERROR_MESSAGE_MONEY_FORMAT = 'Please, enter valid amount';
const ERROR_MESSAGE_URL_FORMAT = 'Accepted format: www.example.com';
const ERROR_MESSAGE_MIN_DATE = 'Invalid entry. Please choose the date in the future';
const ERROR_MESSAGE_DATE_FORMAT = (format) => `Accepted entry - ${format.toLowerCase()}`

const validationSchema = yup.object({
  company_name: yup.string().notRequired().max(65, ERROR_MESSAGE_MAX65).matches(LETTERS_NUMBERS_REGEXP, {message: ERROR_MESSAGE_LETTERS_NUMBERS, excludeEmptyString: true}),
  web_site: yup.string().notRequired().max(65, ERROR_MESSAGE_MAX65).matches(URL_REGEXP, {message: ERROR_MESSAGE_URL_FORMAT, excludeEmptyString: true}),
  // REPLACE AFTER DUNS INTEGRATION
    // company_name: yup.object({
    //   value: 
    //     yup.string()
    //     .max(65, ERROR_MESSAGE_MAX65)
    //     .matches(/^[a-zA-Z0-9\s]*$/, ERROR_MESSAGE_LETTERS_NUMBERS)
    //     .required(ERROR_MESSAGE_REQUIRED)
    // }).required(ERROR_MESSAGE_REQUIRED),
    // web_site: yup.object({
    //   value:
    //     yup.string()
    //     .max(65, ERROR_MESSAGE_MAX65)
    //     .url('please enter a valid url')
    //     .required(ERROR_MESSAGE_REQUIRED)
    // }),
  deal_size: yup.string().required(ERROR_MESSAGE_REQUIRED).max(35, ERROR_MESSAGE_MAX35).matches(MONEY_REGEXP, ERROR_MESSAGE_MONEY_FORMAT),
  estimated_closing_date: yup.date().required(ERROR_MESSAGE_REQUIRED).min(today, ERROR_MESSAGE_MIN_DATE),
  desired_limits: yup.string().required(ERROR_MESSAGE_REQUIRED).max(35, ERROR_MESSAGE_MAX35).matches(MONEY_REGEXP, ERROR_MESSAGE_MONEY_FORMAT),
  insurance_location_coverage_type: yup.string().oneOf(['us_style', 'non_us_style', 'cheaper']),
  revenue: yup.string().required(ERROR_MESSAGE_REQUIRED).max(35, ERROR_MESSAGE_MAX35).matches(MONEY_REGEXP, ERROR_MESSAGE_MONEY_FORMAT),
  // common_shareholders: yup.string().notRequired().max(3, ERROR_MESSAGE_MAX3).matches(NUMBER_REGEXP, {message: ERROR_MESSAGE_NUMBERS, excludeEmptyString: true}),
  // preferred_shareholders: yup.string().notRequired().max(3, ERROR_MESSAGE_MAX3).matches(NUMBER_REGEXP, {message: ERROR_MESSAGE_NUMBERS, excludeEmptyString: true}),
  // product_sales: yup.string().notRequired().max(35, ERROR_MESSAGE_MAX35).matches(MONEY_REGEXP, {message: ERROR_MESSAGE_MONEY_FORMAT, excludeEmptyString: true}),
  // voting_board_members: yup.string().notRequired().max(3, ERROR_MESSAGE_MAX3).matches(NUMBER_REGEXP, {message: ERROR_MESSAGE_NUMBERS, excludeEmptyString: true}),
  // officers: yup.string().notRequired().max(3, ERROR_MESSAGE_MAX3).matches(NUMBER_REGEXP, {message: ERROR_MESSAGE_NUMBERS, excludeEmptyString: true }),
  // total_assets: yup.string().notRequired().max(35, ERROR_MESSAGE_MAX35).matches(MONEY_REGEXP, {message: ERROR_MESSAGE_MONEY_FORMAT, excludeEmptyString: true}),
  // global_employees: yup.string().notRequired().max(3, ERROR_MESSAGE_MAX3).matches(NUMBER_REGEXP, {message: ERROR_MESSAGE_NUMBERS, excludeEmptyString: true}),
  // tax_liabilities: yup.string().notRequired().max(35, ERROR_MESSAGE_MAX35).matches(MONEY_REGEXP, {message: ERROR_MESSAGE_MONEY_FORMAT, excludeEmptyString: true}),
  // background_checks: yup.string().notRequired().max(2, ERROR_MESSAGE_MAX2).matches(NUMBER_REGEXP, {message: ERROR_MESSAGE_NUMBERS, excludeEmptyString: true}),
  // current_total_liabilities: yup.string().notRequired().max(35, ERROR_MESSAGE_MAX35).matches(MONEY_REGEXP, {message: ERROR_MESSAGE_MONEY_FORMAT, excludeEmptyString: true}),
  // external_audits: yup.string().notRequired().max(2, ERROR_MESSAGE_MAX2).matches(NUMBER_REGEXP, {message: ERROR_MESSAGE_NUMBERS, excludeEmptyString: true}),
  // internal_audits: yup.string().notRequired().max(2, ERROR_MESSAGE_MAX2).matches(NUMBER_REGEXP, {message: ERROR_MESSAGE_NUMBERS, excludeEmptyString: true}),
  // owned_properties: yup.string().notRequired().max(10, ERROR_MESSAGE_MAX10).matches(NUMBER_REGEXP, {message: ERROR_MESSAGE_NUMBERS, excludeEmptyString: true}),
  // leased_properties: yup.string().notRequired().max(10, ERROR_MESSAGE_MAX10).matches(NUMBER_REGEXP, {message: ERROR_MESSAGE_NUMBERS, excludeEmptyString: true}),
  // insurance_claims: yup.string().notRequired().max(10, ERROR_MESSAGE_MAX10).matches(NUMBER_REGEXP, {message: ERROR_MESSAGE_NUMBERS, excludeEmptyString: true}),
  // location_of_assets:yup.array()
}).required();

const landingValidationSchema = yup.object({
  company_name: yup.string().required(ERROR_MESSAGE_REQUIRED).max(65, ERROR_MESSAGE_MAX65).matches(LETTERS_NUMBERS_REGEXP, ERROR_MESSAGE_LETTERS_NUMBERS),
  web_site: yup.string().required(ERROR_MESSAGE_REQUIRED).max(65, ERROR_MESSAGE_MAX65).matches(URL_REGEXP, ERROR_MESSAGE_URL_FORMAT),
  deal_size: yup.string().required(ERROR_MESSAGE_REQUIRED).max(35, ERROR_MESSAGE_MAX35).matches(MONEY_REGEXP, ERROR_MESSAGE_MONEY_FORMAT),
  revenue: yup.string().required(ERROR_MESSAGE_REQUIRED).max(35, ERROR_MESSAGE_MAX35).matches(MONEY_REGEXP, ERROR_MESSAGE_MONEY_FORMAT),
  estimated_closing_date: yup.date().required(ERROR_MESSAGE_REQUIRED).min(today, ERROR_MESSAGE_MIN_DATE),
  desired_limits: yup.string().required(ERROR_MESSAGE_REQUIRED).max(35, ERROR_MESSAGE_MAX35).matches(MONEY_REGEXP, ERROR_MESSAGE_MONEY_FORMAT),
}).required();

export {
  validationSchema as default,
  landingValidationSchema,
  ERROR_MESSAGE_MIN_DATE,
  ERROR_MESSAGE_DATE_FORMAT,
  ERROR_MESSAGE_REQUIRED
};
