import React, { forwardRef } from 'react';
import ReactDatePicker from "react-datepicker";
import classNames from 'classnames';

import QMETooltip from '../QMETooltip';
import {ReactComponent as CalendarIcon} from '../../../../assets/imgs/calendar.svg';

import "react-datepicker/dist/react-datepicker.css";
import './styles.scss';
import { ERROR_MESSAGE_REQUIRED } from '../../EstimatorForm/validationConfig';

const QMEDatePicker = forwardRef(({
  label,
  theme,
  errors,
  width,
  name,
  format,
  clearErrors,
  setError,
  mandatory,
  placeholder,
  value,
  onChange,
  onBlur,
  getValues,
  ...props
}, ref) => {
  const setRequiredError = () => {
    let value = getValues(name);
    if(!value) {
      setError(name, {
        type: "required",
        message: ERROR_MESSAGE_REQUIRED,
      })
    }
  }
  const handleDateChange = (date) => {
    clearErrors(name);
    onChange(date);
    setRequiredError();
  }

const handleBlur = () => {
  setRequiredError();
}
  return (
    <section className='QM_E-datepicker_wrapper QM_E-input_wrapper'>
      <label 
        className={
          classNames(
            'QM_E-input_label',
            `QM_E-input_label__${theme}`,
            'QM_E-datepicker_label',
            `QM_E-datepicker_label__${theme}`
          )
        }
      >
        {mandatory && <span className='QM_E-input_requiredMark'>*</span>}
        {label}
      </label>
      <CalendarIcon />
      <ReactDatePicker 
        className={
          classNames(
            'QM_E-input QM_E-datepicker', {
              'QM_E-input__error': !!errors,
            }
          )
        }
        width={width}
        placeholderText={placeholder}
        selected={value}
        onChange={handleDateChange}
        onBlur={e => handleBlur(e)}
        dateFormat={format}
        minDate={new Date()}
        showPopperArrow={false}
        popperPlacement="right"
        ref={ref}
        {...props}
      />
      
      {errors && (<QMETooltip text={errors.message} theme={theme} messageType='error' />)}
    </section>
  );
})

export default QMEDatePicker;