import React from "react";
import { useTranslation } from "react-i18next";

import DueDilligenceTabItem from "./DueDilligenceTabItem";

import "./styles.scss";

const DueDilligenceTabList = ({ title, dueDilligenceArray }) => {
  const { t } = useTranslation();
  return (
    <section className="due-dilligence-tab__content">
      <h2 className="due-dilligence-tab__title">{title}</h2>
      <ul className="due-dilligence-tab__list">
        {dueDilligenceArray
          ? dueDilligenceArray?.map((item, index) => {
              return (
                <DueDilligenceTabItem
                  key={index}
                  company_name={item.company_name}
                  due_diligence_type={item.due_diligence_type}
                  report={item.report}
                  className={`due-dilligence-tab__item`}
                />
              );
            })
          : t("general.no_data")}
      </ul>
    </section>
  );
};

export default DueDilligenceTabList;
