import React from "react";

import QMAccordion from "../common/QMAccordion";
import QMTile from "../common/QMTile";
import FaqFunctionOverview from "./FaqFunctionOverview";
import FaqHowManyStatuses from "./FaqHowManyStatuses";
import FaqHowToAddContacts from "./FaqHowToAddContacts";
import FaqHowToSubmit from "./FaqHowToSubmit";
import FaqWhereToStart from "./FaqWhereToStart";

import "./styles.scss";

const Faq = () => {
  return (
    <section className="faq-page">
      <QMTile>
        <h2 className="faq-page__header">FAQ</h2>
        <h3 className="faq-page__description">
          Detailed manual how to use QUANTUMERGE app
        </h3>
        <QMAccordion summaryText={"1.1 Functions overview"}>
          <FaqFunctionOverview />
        </QMAccordion>
        <QMAccordion summaryText={"1.2 Where to start?"}>
          <FaqWhereToStart />
        </QMAccordion>
        <QMAccordion summaryText={"1.3 How to add Contacts?"}>
          <FaqHowToAddContacts />
        </QMAccordion>
        <QMAccordion summaryText={"1.4 How to submit R&W application?"}>
          <FaqHowToSubmit />
        </QMAccordion>
        <QMAccordion
          summaryText={
            "1.5 How many statuses QUANTUMERGE app 1.0 has and what do they mean?"
          }
        >
          <FaqHowManyStatuses />
        </QMAccordion>
      </QMTile>
    </section>
  );
};

export default Faq;
