import React from 'react';

import StepsAvif from '../../../assets/landing/steps-opaque_v2.avif';
import StepsWebp from '../../../assets/landing/steps-opaque_v2.webp';
import StepsPng from '../../../assets/landing/steps-opaque_v2.png';

import './styles.scss';

const StepsSection = () => {
  return (
    <section id="landing__Steps" className="custom__commonSection">
      <div className="custom__commonWidth">
        <h3>Follow the steps to find out how easy it is to get R&W Insurance</h3>
        <picture>
          <source srcSet={StepsAvif} type="image/avif" />
          <source srcSet={StepsWebp} type="image/webp" />
          <source srcSet={StepsPng} />
          <img src={StepsPng} width="1000" height="555" alt="Follow the steps to find out how easy it is to get R&W Insurance" />
        </picture>
      </div>
    </section>	
  );
}

export default StepsSection;