import React, { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import QMSidebar from "../common/QMSidebar";
import LawyerNavMenu from "./LawyerNavMenu";

import DealsPage from "./DealsPage";
import DealPage from "../DealPage/DealPage";

const LawyerPortal = () => {
  useEffect(() => {
    document.body.classList.add("no-scroll");

    return () => document.body.classList.remove("no-scroll");
  }, []);

  const { search } = useLocation();

  return (
    <main className="QM-main QM-brokerPortal">
      <QMSidebar>
        <LawyerNavMenu />
      </QMSidebar>
      <article className="QM-main_content">
        <Routes>
          <Route
            index
            element={
              <Navigate to={{ pathname: "portfolio", search: search }} />
            }
          />
          <Route path="portfolio" exact strict>
            <Route
              index
              element={<Navigate to={{ pathname: "deals", search: search }} />}
            />
            <Route path="deals" element={<DealsPage />} exact strict>
              <Route path=":dealId/*" element={<DealPage />} exact strict />
            </Route>
          </Route>
        </Routes>
      </article>
    </main>
  );
};

export default LawyerPortal;
