import React from "react";
import { useTranslation } from "react-i18next";

import "./styles.scss";

const QMForbiddenCover = () => {
  const { t } = useTranslation();
  return (
    <section className="QM-forbiddenCover">
      <section className="QM-forbiddenCover_content">
        <span className="icon-eye-off" />
        <p>{t("general.not_allowed_notification")}</p>
      </section>
    </section>
  );
};

export default QMForbiddenCover;
