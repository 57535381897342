import React from "react";
import { useTranslation } from "react-i18next";

import "./styles.scss";

const AssignLawyerEmptyList = () => {
  const { t } = useTranslation();

  return (
    <section className="assign-lawyer-modal__empty-list">
      <p>{t("assign_lawyer.empty_list")}</p>
    </section>
  );
};

export default AssignLawyerEmptyList;
