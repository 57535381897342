import React from "react";
import { components } from "react-select";
import QMToolTip from "../QMToolTip";

export const Option = (props) => {
  const tipPosition = {
    position: "absolute",
    paddingTop: "3px",
    right: "20px",
  };
  return (
    <div>
      <components.Option {...props}>
        <span>{props.label}</span>
        <span style={tipPosition}>
          {props.data.content && (
            <QMToolTip theme="dark" text={props.data.content} />
          )}
        </span>
      </components.Option>
    </div>
  );
};
