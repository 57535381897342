import React from "react";
import { useSelector } from "react-redux";

import QMTile from "../../common/QMTile";
import QMForbiddenCover from "../../common/QMForbiddenCover/QMForbiddenCover";
import RWIAllFilesTree from "../RWIApplication/RWIAllFilesTree/RWIAllFilesTree";

import { dealForbiddenSelector } from "../../../../redux/selectors/deal-selectors";

import "./styles.scss";

const FilesFolder = () => {
  const isForbidden = useSelector(dealForbiddenSelector);
  return (
    <section className="grid-2-col QM-filesFolder_container">
      <QMTile>
        <RWIAllFilesTree />
      </QMTile>
      <QMTile>{/* underwriting docs are going here */}</QMTile>
      {isForbidden && <QMForbiddenCover />}
    </section>
  );
};

export default FilesFolder;
