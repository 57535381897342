import React, { useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { putDealContacts } from "../../../../../api/dealApi";
import { TOAST_CLOSE_DELAY } from "../../../../../helpers";
import useLeavePageBlocker from "../../../../../helpers/useLeavePageBlocker";
import {
  setDealContacts,
  setDealSaveSuccess,
} from "../../../../../redux/reducers/dealPage-reducer";
import {
  dealContactsSelector,
  dealSaveSuccessSelector,
  dealSellerSelector,
} from "../../../../../redux/selectors/deal-selectors";
import QMToastMessage from "../../../common/QMToastMessage";

import ContactsForm from "./ContactsForm";

import "./styles.scss";

const ContactsFormContainer = ({ currentTab, setIsDirty, isApplicant }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let { dealId } = useParams();
  const prevContacts = useSelector(dealContactsSelector);
  const sellerInfo = useSelector(dealSellerSelector);
  const prevCurrentContacts = prevContacts?.[currentTab];
  const isSaved = useSelector(dealSaveSuccessSelector);
  const title =
    currentTab === "seller" && sellerInfo?.target === "seller"
      ? "seller_target"
      : currentTab;

  const {
    register,
    reset,
    handleSubmit,
    setError,
    setValue,
    watch,
    control,
    formState: { errors, isDirty, isSubmitSuccessful },
  } = useForm({
    mode: "onChange",
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "due_diligence_providers",
  });
  useEffect(() => {
    if (prevCurrentContacts) {
      prevCurrentContacts.primary &&
        setValue("primary", prevCurrentContacts.primary);
      prevCurrentContacts.legal && setValue("legal", prevCurrentContacts.legal);
      prevCurrentContacts.p_c_broker &&
        setValue("p_c_broker", prevCurrentContacts.p_c_broker);
      prevCurrentContacts.due_diligence_providers_count &&
        setValue(
          "due_diligence_providers_count",
          prevCurrentContacts.due_diligence_providers_count
        );
      prevCurrentContacts.due_diligence_providers &&
        setValue(
          "due_diligence_providers",
          prevCurrentContacts.due_diligence_providers
        );
    }
    return () => {
      dispatch(setDealContacts({}));
    };
  }, [prevCurrentContacts]); // eslint-disable-line

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(undefined, { keepValues: true });
    }
  }, [isSubmitSuccessful, reset]);

  useEffect(() => setIsDirty(isDirty), [isDirty, setIsDirty]);

  const onSubmit = (data) => {
    putDealContacts(
      dispatch,
      dealId,
      { ...data, section: currentTab },
      setError
    );
  };

  useLeavePageBlocker(isDirty);

  return (
    <>
      <ContactsForm
        title={title}
        watch={watch}
        register={register}
        control={control}
        errors={errors}
        isDirty={isDirty}
        handleSubmit={handleSubmit(onSubmit)}
        dueDiligence={fields}
        append={append}
        remove={remove}
        isSubmitSuccessful={isSubmitSuccessful}
        setValue={setValue}
      />
      {isSaved && (
        <QMToastMessage
          viewType="success"
          text={t("general.data_saved")}
          iconClassName="icon-check-circle"
          onClose={() => {
            dispatch(setDealSaveSuccess(false));
          }}
          selfCloseDelay={TOAST_CLOSE_DELAY}
        />
      )}
    </>
  );
};

export default ContactsFormContainer;
