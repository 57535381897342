import React from "react";
import { useTranslation } from "react-i18next";

import { QMTextInput } from "../../../common/QMInputs";
import DealAdminSubsidiaryInfoBlock from "./DealAdminSubsidiaryInfoBlock";
import DealAdminTargetInfoBlock from "./DealAdminTargetInfoBlock";

const BuyerSellerInfoBlock = ({
  register,
  control,
  watch,
  getFieldErrors,
  boolOpts,
  title,
  dealParticipant,
  setValue,
}) => {
  const { t } = useTranslation();

  const checkDealParticipant = (value) => {
    if (value) {
      return value.toLowerCase();
    }
  };

  return (
    <section className="QM-formBlock">
      <h3 className="QM-formBlock__title">{title}</h3>
      <section className="QM-formRow">
        <QMTextInput
          {...register(`${checkDealParticipant(dealParticipant)}_name`)}
          label={t(`general.${checkDealParticipant(dealParticipant)}`)}
          placeholder={t(
            `new_deal_form.${checkDealParticipant(
              dealParticipant
            )}_name_placeholder`
          )}
          errors={getFieldErrors(
            `${checkDealParticipant(dealParticipant)}_name`
          )}
          isRequired
        />
        <QMTextInput
          {...register(`${checkDealParticipant(dealParticipant)}_website`)}
          label={t(
            `new_deal_form.${checkDealParticipant(
              dealParticipant
            )}_website_label`
          )}
          placeholder={t("general.website_placeholder")}
          errors={getFieldErrors(
            `${checkDealParticipant(dealParticipant)}_website`
          )}
          isRequired
        />
        <QMTextInput
          {...register(
            `${checkDealParticipant(dealParticipant)}_law_firm_name`
          )}
          label={t(
            `new_deal_form.${checkDealParticipant(dealParticipant)}_law_firm`
          )}
          placeholder={t(
            `new_deal_form.${checkDealParticipant(dealParticipant)}_law_firm`
          )}
          errors={getFieldErrors(
            `${checkDealParticipant(dealParticipant)}_law_firm_name`
          )}
          isRequired
        />
      </section>
      <section className="QM-formRow">
        {dealParticipant === "Buyer" && (
          <DealAdminSubsidiaryInfoBlock
            register={register}
            control={control}
            watch={watch}
            getFieldErrors={getFieldErrors}
            boolOpts={boolOpts}
            setValue={setValue}
          />
        )}
        {dealParticipant === "Seller" && (
          <DealAdminTargetInfoBlock
            register={register}
            control={control}
            watch={watch}
            getFieldErrors={getFieldErrors}
            setValue={setValue}
          />
        )}
      </section>
    </section>
  );
};

export default BuyerSellerInfoBlock;
