import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoadingDeals: false,
  dealsList: [],
  dealsError: null,
  selectedDeal: null,
  selectedDealContacts: null,
  isLoadingSidebarTab: false,
  requiredDocumentsList: [],
  providedDocumentsList: [],
  submissionData: null,
};

export const dealsReducer = createSlice({
  name: "deals",
  initialState,
  reducers: {
    setIsLoadingDeals: (state, action) => {
      state.isLoadingDeals = action.payload;
    },
    setDealsList: (state, action) => {
      state.dealsList = action.payload;
    },
    setDealsError: (state, action) => {
      state.dealsError = action.payload;
    },
    setSelectedDeal: (state, action) => {
      state.selectedDeal = action.payload;
    },
    setSelectedDealContacts: (state, action) => {
      state.selectedDealContacts = action.payload;
    },
    setIsLoadingSidebarTab: (state, action) => {
      state.isLoadingSidebarTab = action.payload;
    },
    setRequiredDocumentsList: (state, action) => {
      state.requiredDocumentsList = action.payload;
    },
    setProvidedDocumentsList: (state, action) => {
      state.providedDocumentsList = action.payload;
    },
    setSubmissionData: (state, action) => {
      state.submissionData = action.payload;
    },
  },
});
export const {
  setIsLoadingDeals,
  setDealsList,
  setDealsError,
  setSelectedDeal,
  setSelectedDealContacts,
  setIsLoadingSidebarTab,
  setRequiredDocumentsList,
  setProvidedDocumentsList,
  setSubmissionData,
} = dealsReducer.actions;

export default dealsReducer.reducer;
