import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import useOuterClick from "../../../../helpers/useOuterClick";

import "./styles.scss";

const QMModal = ({ children, customClassName, size, closeModal }) => {
  const innerRef = useOuterClick((e) => closeModal(false));
  const modalClasses = classNames(
    "QM-modal_content",
    customClassName,
    `QM-modal_content__${size}`
  );
  return (
    <section className="QM-modal_bg">
      <section className={modalClasses} ref={closeModal ? innerRef : null}>
        {children}
      </section>
    </section>
  );
};

QMModal.propTypes = {
  children: PropTypes.node,
  customClassName: PropTypes.string,
  size: PropTypes.oneOf(["big", "medium", "small"]),
  closeModal: PropTypes.func,
};
QMModal.defaultProps = {
  children: "",
  customClassName: "",
  size: "big",
};

export default QMModal;
