import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import './styles.scss';

const QMTabs = ({ routes }) => {
  return (
    <nav className='QM-tabs'>
      <ul className="QM-tabs_list">
        {routes.map((route, index) => (
          <li className='QM-tabs_list_item' key={index}>
            <NavLink 
              to={route.to}
              className={({isActive}) => classNames({
                'QM-tabs_list_item_link': true,
                'active': isActive,
              })}
              data-title={route.title}
              end={route.end}
            >
              <span>{route.title}</span>
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
}

QMTabs.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape({
    to: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    end: PropTypes.bool,
  })),
};

QMTabs.defaultProps = {
  routes: [],
};

export default QMTabs;