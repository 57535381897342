import React from 'react';
import PropTypes from 'prop-types';
import { CloseIcon } from '../../../../assets/imgs';

import './styles.scss';

const QMEErrorTile = ({errorText, onClose}) => {
  return (
    <section className='QM_E-errorTile'>
      <p className='QM_E-errorTile_text'>{errorText}</p>
      <CloseIcon className='QM_E-errorTile_icon' onClick={onClose} />
    </section>
  );
}

QMEErrorTile.propTypes = {
  errorText: PropTypes.string,
  onClose: PropTypes.func
};

QMEErrorTile.defaultProps = {
  errorText: '',
  onClose: () => {}
};

export default QMEErrorTile;
