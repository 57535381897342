import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./styles.scss";

const QMAvatar = ({ name, viewType }) => {
  const nameArr = name && name.split(" ");
  const initials =
    nameArr && nameArr.length
      ? `${nameArr[0].charAt(0)}${nameArr[nameArr.length - 1].charAt(0)}`
      : "";
  return (
    <section
      className={classNames("QM-avatar_wrapper", {
        "QM-avatar_wrapper__secondary": viewType === "secondary",
      })}
    >
      <span
        className={classNames("QM-avatar_text", {
          "QM-avatar_text__secondary": viewType === "secondary",
        })}
      >
        {initials}
      </span>
    </section>
  );
};

QMAvatar.propTypes = {
  name: PropTypes.string,
  viewType: PropTypes.oneOf(["primary", "secondary"]),
};

QMAvatar.defaultProps = {
  name: "",
  viewType: "primary",
};
export default QMAvatar;
