import React, { forwardRef } from 'react';
import NumberFormat from "react-number-format";
import classNames from 'classnames';

import QMETooltip from '../QMETooltip';

import './styles.scss'

const QMECurrencyInput = forwardRef(({
  label,
  placeholder,
  errors,
  formatType,
  theme,
  tooltip,
  width,
  mandatory,
  ...props
}, ref) => {
  return (
    <section className='QM_E-input_wrapper'>
      <label 
        className={
          classNames(
            'QM_E-input_label',
            `QM_E-input_label__${theme}`
          )
        }
      >
        {mandatory && <span className='QM_E-input_requiredMark'>*</span>}
        {label}
        {tooltip && <QMETooltip text={tooltip} theme={theme} />}
      </label>
      <NumberFormat
        ref={ref}
        className={classNames(
          'QM_E-input', {
          'QM_E-input__error': !!errors
          }
        )}
        placeholder={placeholder}
        style={{maxWidth: width || 'unset'}}
        thousandSeparator={true}
        prefix={"$"}
        decimalScale={2}
        fixedDecimalScale
        allowNegative={false}
        {...props}
      />
      {errors && (<QMETooltip text={errors.message} theme={theme} messageType='error' />)}
    </section>
  );
})

export default QMECurrencyInput;
