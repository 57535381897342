import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoadingDealDocuments: false,
  dealDocumentsList: {},
  dealDocumentsError: null,
  dealDocumentsSuccess: null,
};

export const dealsDocumentsReducer = createSlice({
  name: "dealDocs",
  initialState,
  reducers: {
    setIsLoadingDealDocuments: (state, action) => {
      state.isLoadingDealDocuments = action.payload;
    },
    setDealDocumentsList: (state, action) => {
      state.dealDocumentsList = action.payload;
    },
    setDealDocumentsError: (state, action) => {
      state.dealDocumentsError = action.payload;
    },
    setDealDocumentsSuccess: (state, action) => {
      state.dealDocumentsSuccess = action.payload;
    },
  },
});
export const {
  setIsLoadingDealDocuments,
  setDealDocumentsList,
  setDealDocumentsError,
  setDealDocumentsSuccess,
} = dealsDocumentsReducer.actions;

export default dealsDocumentsReducer.reducer;
