import React from 'react';
import Tippy from '@tippyjs/react';
import {followCursor} from 'tippy.js';
import classNames from 'classnames';

import 'tippy.js/dist/tippy.css'; 
import 'tippy.js/themes/light.css';
import './styles.scss';

import { ReactComponent as InfoIcon } from '../../../../assets/imgs/info.svg'

const QMEHelpTile = ({text, tooltipText, className}) => {
  return (
    <Tippy
      content={(<section className='QM_E-tooltip_content'>{tooltipText}</section>)}
      theme="light"
      followCursor={true}
      plugins={[followCursor]}
    >
    <section className={classNames('QM_E-helpTile', className)}>
      <InfoIcon
        className='QM_E-helpTile_icon'
      />
      <p className='QM_E-helpTile_text'>
        {text}
      </p>
    </section>
    </Tippy>
  );
}

export default QMEHelpTile;