import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { getDueDilligenceTabInfo } from "../../../../../api/dueDilligenceTabApi";
import {
  setDueDilligenceError,
  setIsLoadingDueDilligence,
} from "../../../../../redux/reducers/dueDilligenceTab-reducer";
import {
  dueDilligenceTabErrorSelector,
  dueDilligenceTabLoadingSelector,
} from "../../../../../redux/selectors/due-dilligence-tab-selectors";
import DueDilligenceTabOverlay from "./DueDilligenceTabOverlay";
import QMGlobalLoader from "../../../../common/QMLoader";
import QMToastMessage from "../../../common/QMToastMessage";

import "./styles.scss";

const DueDilligenceTab = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector(dueDilligenceTabLoadingSelector);
  const dueDilligenceError = useSelector(dueDilligenceTabErrorSelector);
  const id = useParams();

  useEffect(() => {
    getDueDilligenceTabInfo(dispatch, id.dealId).catch((err) => {
      dispatch(
        setDueDilligenceError({
          message: t("errors.server_general"),
        })
      );
      dispatch(setIsLoadingDueDilligence(false));
    });
  }, [t, dispatch, id.dealId]);

  const getContent = () => {
    return (
      <section className="due-dilligence-tab">
        {isLoading ? (
          <QMGlobalLoader />
        ) : (
          <DueDilligenceTabOverlay id={id.dealId} />
        )}
        {dueDilligenceError && !isLoading && (
          <QMToastMessage
            viewType="error"
            text={dueDilligenceError}
            iconClassName="icon-attention"
            onClose={() => dispatch(setDueDilligenceError(null))}
          />
        )}
      </section>
    );
  };
  return <>{getContent()}</>;
};

export default DueDilligenceTab;
