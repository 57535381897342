import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";

import {
  IcebergImg,
  // ArrowImg,
  Line1,
  // Line2,
  // Line3,
  Line4,
  Line4Empty,
  Circle1,
  Circle2,
  DownloadIcon,
  // FileSearchIcon,
} from "../../../assets/imgs";
import { numFormatter } from "../../../helpers";
import {
  setIsPdfLoading,
  setPdfError,
} from "../../../redux/reducers/estimator-reducer";
import { getEstimationPdf } from "../../../api/estimatorApi";
import QMEButton from "../common/QMEButton";

import EstimatorBundleCarousel from "../EstimatorBundleCarousel";
import QMEHelpTile from "../common/QMEHelpTile";

import "./styles.scss";
import QMEErrorTile from "../common/QMEErrorTile";

const EstimatorChart = () => {
  const dispatch = useDispatch();
  const activeBundle = useSelector(
    (state) => state.estimator.estimation.activeBundle
  );
  const chartData = useSelector(
    (state) => state.estimator.estimation.bundles[activeBundle]
  );
  const underlyingInsurances = useSelector(
    (state) => state.estimator.estimation.underlying_insurances
  );
  const pdfRecordId = useSelector(
    (state) => state.estimator.estimation.record_id
  );
  const pdfError = useSelector((state) => state.estimator.pdfError);

  const { t } = useTranslation();
  // const navigate = useNavigate();

  const getPdf = () => {
    dispatch(setIsPdfLoading(true));
    getEstimationPdf(pdfRecordId)
      .then((res) => {
        if (res.status === 500) {
          dispatch(setPdfError("Server error. Please try again later"));
        } else if (res.status !== 200) {
          return res.json();
        } else {
          return res.blob();
        }
      })
      .then((file) => {
        if (file?.error) {
          dispatch(setPdfError(file.error));
        } else {
          const url = window.URL.createObjectURL(file);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = "qm-estimation.pdf";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }
        dispatch(setIsPdfLoading(false));
      })
      .catch((err) => {
        dispatch(setPdfError("Something went wrong. Please try again later"));
        console.error(err);
      });
  };
  // const getArrowContent = () => {
  // return
  // Boolean(chartData)
  //   ? (
  //     <section>
  //       <p className='QM_E-chart_text QM_E-chart_text__bold-12 QM_E-chart_arrow_text'>
  //         {t('estimator.chart.estimated_bundle_price')}
  //       </p>
  //       <p className='QM_E-chart_text QM_E-chart_text__bold-24 QM_E-chart_arrow_text '>
  //         {
  //           numFormatter(chartData.bundle_without_excess)
  //           .map((item, i) => <span key={i} >{item}</span>)
  //         }
  //       </p>
  //     </section>
  //   )
  //   : (
  //   return (
  //     <p className="QM_E-chart_text QM_E-chart_text__bold-12 QM_E-chart_arrow_text">
  //       {t("estimator.chart.chart_arrow_title")}
  //     </p>
  //   );
  // };

  const getRWIContent = () => {
    return chartData && chartData.rwi_expected_cost ? (
      <section className="QM_E-chart_dataContainer QM_E-chart_dataContainer1__withData">
        <Circle1 className="QM_E-chart_dataContainer1__withData_circle" />
        <Line1 className="QM_E-chart_dataContainer1__withData_line" />
        <section className="QM_E-chart_dataContainer1__withData_text">
          <p className="QM_E-chart_text QM_E-chart_text__bold-14">
            {t("estimator.chart.rwi_title")}
          </p>
          <p className="QM_E-chart_text QM_E-chart_text__bold-24 ">
            {numFormatter(chartData.rwi_expected_cost).map((item, i) => (
              <span key={i}>{item}</span>
            ))}
          </p>
        </section>
      </section>
    ) : (
      <section className="QM_E-chart_dataContainer QM_E-chart_dataContainer1">
        <Circle1 className="QM_E-chart_dataContainer1_circle" />
        <Line1 className="QM_E-chart_dataContainer1_line" />
        <p className="QM_E-chart_text QM_E-chart_text__bold-14 QM_E-chart_dataContainer1_text">
          {t("estimator.chart.rwi_desc")}
        </p>
      </section>
    );
  };

  return (
    <section className="QM_E-chart_wrapper">
      <section className="QM_E-chart">
        {/* <section className="QM_E-chart_arrow_wrapper">
          {getArrowContent()}
          <ArrowImg className="QM_E-chart_arrow" />
        </section> */}
        <IcebergImg className="QM_E-chart_iceberg" />
        {getRWIContent()}
        {/* {
          // TODO: CHANGE AFTER DESIGN UPDATE
          Boolean(chartData) && (
            <>
              <section className='QM_E-chart_dataContainer QM_E-chart_dataContainer2'>
                <Circle1 className='QM_E-chart_dataContainer2_circle' />
                <Line2 className='QM_E-chart_dataContainer2_line' />
                <p className='QM_E-chart_text QM_E-chart_text__bold-14 QM_E-chart_dataContainer2_text'>
                  {'Excess I'}
                </p>
              </section>
              <section className='QM_E-chart_dataContainer QM_E-chart_dataContainer3'>
              <Circle1 className='QM_E-chart_dataContainer3_circle' />
              <Line3 className='QM_E-chart_dataContainer3_line' />
              <p className='QM_E-chart_text QM_E-chart_text__bold-14 QM_E-chart_dataContainer3_text'>
                {'Excess II'}
              </p>
            </section>
          </>
          )
        } */}
        <section className="QM_E-chart_dataContainer QM_E-chart_dataContainer4">
          <Circle2 className="QM_E-chart_dataContainer4_circle" />
          {Boolean(chartData) ? (
            <Line4 className="QM_E-chart_dataContainer4_line" />
          ) : (
            <Line4Empty className="QM_E-chart_dataContainer4_line" />
          )}

          <section className="QM_E-chart_dataContainer4_text">
            <h3 className="QM_E-chart_text QM_E-chart_text__bold-18">
              {t("estimator.chart.underlying_insurances.title")}
            </h3>
            {Boolean(chartData) && (
              <ul>
                {underlyingInsurances.map((insurance) => (
                  <li
                    className="QM_E-chart_text QM_E-chart_text__regular-14"
                    key={insurance}
                  >
                    {t(`estimator.chart.underlying_insurances.${insurance}`)}
                  </li>
                ))}
              </ul>
            )}
          </section>
        </section>

        {Boolean(chartData) && (
          <>
            <QMEHelpTile
              text={t("estimator.chart.help_tiles.rwi.title")}
              tooltipText={t("estimator.chart.help_tiles.rwi.text")}
              className="QM_E-chart_helper QM_E-chart_helper__rwi"
            />
            <QMEHelpTile
              text={t("estimator.chart.help_tiles.underlying_insurance.title")}
              tooltipText={t(
                "estimator.chart.help_tiles.underlying_insurance.text"
              )}
              className="QM_E-chart_helper QM_E-chart_helper__underlyingInsurance"
            />
            <QMEHelpTile
              text={t("estimator.chart.help_tiles.make_cheaper.title")}
              tooltipText={t("estimator.chart.help_tiles.make_cheaper.text")}
              className="QM_E-chart_helper QM_E-chart_helper__cheaper"
            />
          </>
        )}
      </section>
      <EstimatorBundleCarousel />
      {Boolean(chartData) && (
        <section className="QM_E-chart_buttonSection">
          {/* <QMEButton
            btnStyle='primary'
            onClick={() => navigate('/find-broker')}
          >
            <FileSearchIcon />
            <p>Find a Broker</p>
          </QMEButton> */}
          <QMEButton btnStyle="secondary" onClick={getPdf}>
            <DownloadIcon />
            <p>Download PDF</p>
          </QMEButton>
        </section>
      )}
      {Boolean(chartData) && Boolean(pdfError) && (
        <QMEErrorTile
          errorText={pdfError}
          onClose={() => dispatch(setPdfError(null))}
        />
      )}
    </section>
  );
};

export default EstimatorChart;
