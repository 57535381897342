import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import QMTile from "../../common/QMTile";
import QMGlobalLoader from "../../../common/QMLoader";
import QMTreeItem from "../../common/QMTreeItem/QMTreeItem";
import QMToastMessage from "../../common/QMToastMessage/QMToastMessage";
import QMPdfViewer from "../../common/QMPdfViewer/QMPdfViewer";

import {
  dealDocsErrorSelector,
  dealDocsListSelector,
  dealDocsLoadingSelector,
  dealDocsSuccessSelector,
} from "../../../../redux/selectors/dealDocs-selectors";

import {
  setDealDocumentsError,
  setDealDocumentsSuccess,
  setIsLoadingDealDocuments,
} from "../../../../redux/reducers/dealDocs-reducer";

import { TOAST_CLOSE_DELAY } from "../../../../helpers";
import {
  deleteDealDocument,
  getDealDocumentPdf,
  getDealDocuments,
  uploadDealDocument,
} from "../../../../api/dealDocsApi";
import { rwiDocsPathDictionary } from "../../../../helpers/docs-helpers";
const acceptedDocsFormats = {
  "application/pdf": [".pdf"],
  "application/msword": [".doc"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    ".docx",
  ],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    ".xlsx",
  ],
};
const DocsTile = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  let { dealId } = useParams();
  const docsList = useSelector(dealDocsListSelector);
  const docSuccess = useSelector(dealDocsSuccessSelector);
  const uploadError = useSelector(dealDocsErrorSelector);
  const isLoading = useSelector(dealDocsLoadingSelector);

  const [currentPage, setCurrentPage] = useState();
  const [fileData, setFileData] = useState(null);
  const uploadSuccessMsg = t("documents.upload_success");
  const deleteSuccessMsg = t("documents.delete_success");
  useEffect(() => {
    const pathArr = pathname.split("/");
    const section = pathArr[pathArr.length - 1];
    const docsSection = rwiDocsPathDictionary[section];
    setCurrentPage(docsSection);
    if (docsSection) getDealDocuments(dispatch, dealId, docsSection);
  }, [pathname, dealId, dispatch]);

  const handleFileUpload = (files, id) => {
    if (Array.isArray(files)) {
      files.forEach((file) => {
        uploadDealDocument(
          dispatch,
          dealId,
          currentPage,
          file,
          id,
          uploadSuccessMsg
        ).catch((err) => {
          dispatch(setIsLoadingDealDocuments(false));
          dispatch(
            setDealDocumentsError({ message: t("errors.server_general") })
          );
        });
      });
    } else {
      uploadDealDocument(
        dispatch,
        dealId,
        currentPage,
        files,
        id,
        uploadSuccessMsg
      ).catch((err) => {
        dispatch(setIsLoadingDealDocuments(false));
        dispatch(
          setDealDocumentsError({ message: t("errors.server_general") })
        );
      });
    }
  };
  const handleFileDelete = (documentId) => {
    deleteDealDocument(
      dispatch,
      dealId,
      documentId,
      currentPage,
      deleteSuccessMsg
    ).catch((err) => {
      dispatch(setIsLoadingDealDocuments(false));
      dispatch(setDealDocumentsError({ message: t("errors.server_general") }));
    });
  };

  const handleViewFile = (documentId) => {
    getDealDocumentPdf(dispatch, dealId, documentId, setFileData).catch(
      (err) => {
        dispatch(setIsLoadingDealDocuments(false));
        dispatch(
          setDealDocumentsError({ message: t("errors.server_general") })
        );
      }
    );
  };

  const handleFileClose = () => {
    window.URL.revokeObjectURL(fileData.blob);
    setFileData(null);
  };

  return (
    <QMTile>
      {currentPage ? (
        <>
          <section className="QM-docWidget_header">
            <h4>{t("documents.required_documents")}</h4>
            <span className="QM-docWidget_warning">
              {t("documents.only_pdf_accepted")}
            </span>
          </section>
          <section className="QM-docWidget_body">
            {docsList?.directories ? (
              docsList?.directories.map((dir) => (
                <QMTreeItem
                  key={dir.id}
                  {...dir}
                  handleFileUpload={handleFileUpload}
                  handleFileDelete={handleFileDelete}
                  handleViewFile={handleViewFile}
                  acceptedFileTypes={acceptedDocsFormats}
                />
              ))
            ) : (
              <></>
            )}
          </section>
          {docSuccess && (
            <QMToastMessage
              text={docSuccess.message}
              iconClassName="icon-check-circle"
              selfCloseDelay={TOAST_CLOSE_DELAY}
              onClose={() => dispatch(setDealDocumentsSuccess(null))}
            />
          )}
          {uploadError && (
            <QMToastMessage
              viewType="error"
              iconClassName="icon-attention"
              text={uploadError.message}
              selfCloseDelay={TOAST_CLOSE_DELAY}
              onClose={() => dispatch(setDealDocumentsError(null))}
            />
          )}
          {isLoading && <QMGlobalLoader />}
          {fileData?.url && (
            <QMPdfViewer file={fileData.url} onClose={handleFileClose} />
          )}
        </>
      ) : (
        <></>
      )}
    </QMTile>
  );
};

export default DocsTile;
