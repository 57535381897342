import * as validation from "../../../../../helpers/validationConsts";

export const companyValidation = {
  maxLength: {
    value: validation.MAX_NAME,
    message: validation.ERROR_MESSAGE_MAX_NAME,
  },
  minLength: {
    value: validation.MIN_NAME,
    message: validation.ERROR_MESSAGE_MIN_NAME,
  },
};

export const nameValidation = {
  maxLength: {
    value: validation.MAX_NAME,
    message: validation.ERROR_MESSAGE_MAX_NAME,
  },
  minLength: {
    value: validation.MIN_NAME,
    message: validation.ERROR_MESSAGE_MIN_NAME,
  },
  pattern: {
    value: validation.LETTERS_NUMBERS_REGEXP,
    message: validation.ERROR_MESSAGE_LETTERS_NUMBERS,
  },
};

export const phoneValidation = {
  pattern: {
    value: validation.PHONE_REGEXP,
    message: validation.ERROR_MESSAGE_PHONE,
  },
};

export const emailValidation = {
  pattern: {
    value: validation.EMAIL_REGEXP,
    message: validation.ERROR_MESSAGE_EMAIL,
  },
};

export const typeValidation = {
  required: validation.ERROR_MESSAGE_REQUIRED,
};

export const countValidation = {
  // valueAsNumber: true,
  pattern: {
    value: validation.NUMBER_REGEXP,
    message: validation.ERROR_MESSAGE_NUMBERS,
  },
  maxLength: {
    value: 2,
    message: validation.ERROR_MESSAGE_MAX_NUMBER,
  },
};
