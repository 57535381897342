import { getTokenData } from "../helpers/auth-helpers";

async function post(url, data) {
  const response = await fetch(url, {
    method: "POST",
    headers: { "Content-type": "application/json" },
    body: JSON.stringify(data),
  });
  const parsedResponse = await response.json();
  return {
    status: response.status,
    data: parsedResponse,
  };
}

async function put(url, data) {
  const response = await fetch(url, {
    method: "PUT",
    mode: "cors",
    body: data,
  });
  const parsedResponse = await response.json();
  return parsedResponse;
}

async function get(url) {
  const response = await fetch(url);
  const parsedResponse = await response.json();
  return parsedResponse;
}

async function getFile(url) {
  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-type": "application/pdf",
    },
  });

  return response;
}

async function authFetch(url, options = {}) {
  const tokenData = getTokenData();
  if (tokenData?.access_token) {
    options.headers = {
      ...options.headers,
      Authorization: `Bearer ${tokenData?.access_token}`,
    };
  } else {
    console.warn(
      "'authFetch' was called without access token. Probably storage has no session or session were expired"
    );
  }
  return fetch(url, options);
}

export { post, get, getFile, put, authFetch };
