import classNames from 'classnames';
import React from 'react';

import './styles.scss';

const QMAccordion = ({summaryText, children}) => {

  const summaryClasses = classNames(
    'QM-accordion_summary',
  );

  return (
    <details className='QM-accordion'>
      <summary className={summaryClasses}>{summaryText}</summary>
      <section>
        {children}
      </section>
    </details>
  );
}

export default QMAccordion;