import { authFetch } from "./fetchConfig";
import {
  setDueDilligenceError,
  setDueDilligenceInfo,
  setIsLoadingDueDilligence,
} from "../redux/reducers/dueDilligenceTab-reducer";

const getDueDilligenceTabInfo = async (dispatch, dealId) => {
  dispatch(setIsLoadingDueDilligence(true));
  const response = await authFetch(
    `${process.env.REACT_APP_BASE_API_URL}/deals/${dealId}/rwi_application/due_diligence`
  );
  const responseData = await response.json();

  if (response.status === 200) {
    dispatch(setDueDilligenceInfo(responseData.due_diligence));
  } else {
    dispatch(setDueDilligenceError(responseData?.errors[0]?.message));
  }
  dispatch(setIsLoadingDueDilligence(false));
};

export { getDueDilligenceTabInfo };
