import React, { forwardRef } from 'react';
import Select from 'react-select';

import QMInputWrapper from './QMInputWrapper';
import reactSelectStylesConfig from './reactSelectStyles';
import { DropdownIndicator } from './ReactSelectComponents';

const QMSelectInput = forwardRef(({
  label,
  isRequired,
  errors,
  iconClassName,
  placeholder,
  ...props
  }, ref) => {
  return (
    <QMInputWrapper
      label={label}
      isRequired={isRequired}
      errors={errors}
      iconClassName={iconClassName}
    >
      <Select
        ref={ref}
        placeholder={placeholder}
        styles={reactSelectStylesConfig(!!errors)}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator
        }}
        {...props}
      />
    </QMInputWrapper>
  );
})

export default QMSelectInput;